import styled from '@emotion/styled';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  flex,
  flexbox,
  FlexboxProps,
  FlexProps,
  layout,
  LayoutProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';

import { StyledBoxWithPointer } from '../boxes/StyledBoxWithPointer';

export const FlexBoxWithPointer = styled(StyledBoxWithPointer)<
  | SpaceProps
  | LayoutProps
  | FlexProps
  | FlexboxProps
  | TypographyProps
  | ColorProps
  | BorderProps
  | ShadowProps
  >(
  {
    display: 'flex',
    boxSizing: 'border-box',
    minWidth: 0,
  },
  space,
  layout,
  flex,
  flexbox,
  typography,
  color,
  border,
  shadow,
);