import React from 'react';
import { getBoxConnectivityStatus } from '../../../utils/boxes/getBoxConnectivityStatus';

interface IProps {
  boxId: string;
}

export const BoxLocation = ({ boxId }: IProps) => {
  const boxIsOffline = getBoxConnectivityStatus(boxId);

  return <div style={{ opacity: boxIsOffline ? '.3' : 1 }}>Kaai 671-701</div>;
};
