import styled from '@emotion/styled';
import { Form } from 'antd';

import { theme } from '../../theme';

export const StyledFormItem = styled(Form.Item)({
  fontFamily: theme.fontFamilies.primary,
  fontWeight: theme.fontWeights.regular,
  fontSize: theme.fontSizes.small,
  lineHeight: theme.lineHeights.medium,
  '.ant-form-item-control-input-content': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    "@media (max-width: 768px)": {
      justifyContent: 'left',
    },
  },
});
