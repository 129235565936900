import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { defineMessages, useIntl } from 'react-intl';

import { FlexCentered } from '../../../../styled/flex/FlexCentered';
import { theme } from '../../../../theme';
import { IconSize } from '../../../../types/icon.types';
import { redirectToGoogleMaps } from '../../../../utils/shared/location/redirectToGoogleMaps';
import { toasts } from '../../../feedback/toasts';
import { CopyToClipBoardIcon } from './CopyToClipBoardIcon';
import { GoToLocationIcon } from './GoToLocationIcon';

const MESSAGES = defineMessages({
  copied: {
    id: 'box_location_icons_list.copied',
    defaultMessage: 'Copied!',
  },
});

interface IProps {
  latitude: string;
  longitude: string;
}

export const BoxLocationIconsList = ({ latitude, longitude }: IProps) => {
  const intl = useIntl();

  return (
    <FlexCentered height="100%" mr={theme.spacing.small}>
      <CopyToClipboard
        text={`${latitude} - ${longitude}`}
        onCopy={() =>
          toasts.success({
            autoHide: true,
            message: intl.formatMessage(MESSAGES.copied),
          })
        }
      >
        <CopyToClipBoardIcon size={IconSize.LARGE} mr={theme.spacing.xSmall} />
      </CopyToClipboard>
      <GoToLocationIcon
        size={IconSize.LARGE}
        onClick={() => redirectToGoogleMaps(latitude, longitude)}
      />
    </FlexCentered>
  );
};
