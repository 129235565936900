import { createSelector } from '@reduxjs/toolkit';

import {
  ConnectorStatus,
  IConnectorCompatibilityStatusParams,
} from '../../types/connector.types';
import { ConnectorTag } from '../../types/tag.types';
import { connectorsApi } from '../api/connector.api';
import { boxByIdSelector } from './box.selectors';
import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from './tags.selectors';

export const connectorStatusSelector = (boxId: string, assetId: string) =>
  createSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      assetId,
      ConnectorTag.V4_POINT_ALARM,
    ),
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      assetId,
      ConnectorTag.V3_POINT_WARNING,
    ),
    (alarmTagStatus, warningTagStatus) => {
      const alarm = alarmTagStatus && parseInt(alarmTagStatus?.value, 10);
      const warning = warningTagStatus && parseInt(warningTagStatus?.value, 10);

      if (alarm === 2) {
        return ConnectorStatus.ALARM;
      }
      if (warning === 2) {
        return ConnectorStatus.WARNING;
      }
      if (alarm === 1 || warning === 1) {
        return ConnectorStatus.OK;
      }
      return ConnectorStatus.UNKNOWN;
    },
  );

export const enabledConnectorsSelector = (boxId: string) =>
  createSelector(boxByIdSelector(boxId), (box) => {
    return box?.connectors.filter((connector) =>
      connector.tagStatuses.some(
        (ts) => ts.tag === ConnectorTag.V2_POINT_ACTIVE && ts.value === '1',
      ),
    );
  });

export const connectorCompatibilityStatusesRtkState = (
  connectorCompatibilityStatusParams: IConnectorCompatibilityStatusParams,
) =>
  connectorsApi.endpoints.getConnectorCompatibilityStatuses.select(
    connectorCompatibilityStatusParams,
  );

export const connectorCompatibilityStatusSelector = (
  connectorCompatibilityStatusParams: IConnectorCompatibilityStatusParams,
) =>
  createSelector(
    connectorCompatibilityStatusesRtkState(connectorCompatibilityStatusParams),
    (state) => state.data,
  );
