import styled from '@emotion/styled';
import React from 'react';
import {
  FlexboxProps,
  FlexProps,
  LayoutProps,
  SpaceProps,
} from 'styled-system';

import { FlexCentered } from '../../styled/flex/FlexCentered';
import { FlexCol } from '../../styled/flex/FlexCol';
import { StyledSpan } from '../../styled/text/StyledSpan';
import { colors, theme } from '../../theme';

interface IProps {
  cardKey: string;
  labelBelow: string;
  onClick: (cardKey: string) => void;
  active: boolean;
  iconSrc: string;
  styles?: FlexProps | FlexboxProps | LayoutProps | SpaceProps;
}

const StyledFlexCol = styled(FlexCol)({
  '&': {
    cursor: 'pointer',
  },
});

export const SelectableCard = ({
  active,
  cardKey,
  iconSrc,
  labelBelow,
  onClick,
  styles,
}: IProps) => {
  return (
    <StyledFlexCol alignItems="center" data-testid="selectable-card">
      <FlexCentered
        width={118}
        height={118}
        border={`1px solid ${colors.green.primary}`}
        borderRadius={theme.borderRadius.large}
        boxShadow={active ? theme.boxShadows.green : 'none'}
        {...styles}
        onClick={() => onClick(cardKey)}
      >
        <img alt="logo" src={iconSrc} />
      </FlexCentered>
      <StyledSpan
        mt={theme.spacing.small}
        fontWeight={theme.fontWeights.bolder}
        color={active ? colors.green.primary : undefined}
      >
        {labelBelow}
      </StyledSpan>
    </StyledFlexCol>
  );
};
