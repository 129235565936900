import { AuthErrorStrings } from '@aws-amplify/auth/src/common/AuthErrorStrings';
import styled from '@emotion/styled';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { appUrls } from '../../config/url.constants';
import { StyledLink } from '../../styled/text/StyledLink';
import { StyledSpan } from '../../styled/text/StyledSpan';
import { theme } from '../../theme';

interface IProps {
  error: AuthErrorStrings;
  linkToResetPasswordPage?: boolean;
}

const MESSAGES = defineMessages({
  newCode: {
    id: 'login_components.newCode',
    defaultMessage: 'Ask new code',
  },
});

const SmallStyledLink = styled(StyledLink)({
  // using same font size as antd error message is now
  fontSize: 14,
});

export const CognitoError = ({ error, linkToResetPasswordPage }: IProps) => {
  const intl = useIntl();

  return (
    <>
      <StyledSpan
        mt={theme.spacing.small}
        className="ant-form-item-explain-error"
        data-testid="cognito-error"
      >
        {error}
      </StyledSpan>
      {linkToResetPasswordPage && (
        <SmallStyledLink to={appUrls.login.resetPassword} variant="primary">
          {intl.formatMessage(MESSAGES.newCode)}
        </SmallStyledLink>
      )}
    </>
  );
};
