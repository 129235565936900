import React from 'react';

import { colors } from '../../../../theme';

export const BoxTemperatureAlarmIcon = () => (
  <svg
    width="14"
    height="29"
    viewBox="0 0 24 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="icon-box-temperature-alarm"
  >
    <path
      d="M17 25.48H21M17 27.6468C17 18.7248 17 5.83294 17 4.89998C17 2.1942 14.762 0.97998 12 0.97998C9.239 0.97998 7 2.1942 7 4.89998C7 5.83686 7 18.7611 7 27.6468C3.441 29.4313 1 33.0524 1 37.24C1 43.1935 5.925 48.02 12 48.02C18.075 48.02 23 43.1935 23 37.24C23 33.0524 20.559 29.4313 17 27.6468ZM7 9.79998H21H7ZM17 13.72H21H17ZM17 17.64H21H17ZM17 21.56H21H17Z"
      stroke={colors.red.secondary}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
