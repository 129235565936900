import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { motion, useAnimate } from 'framer-motion'
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { appUrls } from '../../config/url.constants';

import { hasCurrentUserPermissionSelector } from '../../store/selectors/user.selectors';
import { UserPermission } from '../../types/user.types';

import { colors, theme } from '../../theme';

interface UnorderedListProps {
  initial: string;
  animate: string;
  as: object;
}

interface ListItemProps {
  as: object;
  onClick: () => void;
  index: number;
  whileHover: object;
}

interface FABContainer {
  show: boolean;
}

interface FabButton {
  title: string;
  show: boolean;
  click: () => void;
}

type FabButtonArray = FabButton[];

const FOBContainer = styled.div<FABContainer>`
  position: fixed;
  bottom: 0;
  right: 0;

  z-index: 9999;
  display: ${props => props.show ? 'block' : 'none'};
`

const UnorderedList = styled.ul<UnorderedListProps>`
  list-style-type: none;

  position: absolute;
  bottom: 0px;
  right: 0px;
`
  
const ListItem = styled.li<ListItemProps>`
  padding: 4px 8px;
  border-radius: ${theme.borderRadius.xLarge};

  width: max-content;
  
  color: ${colors.white.primary};
  background-color: ${colors.green.primary};
  
  position: absolute;
  right: -120px;
  opacity: 0;
  bottom: 75px;

  cursor: pointer;

  text-align: right;

  box-shadow: ${theme.boxShadows.light};
  
  margin-bottom: ${props => !props.index ? 0 : props.index * 40}px;
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 48px;
  height: 48px;

  color: ${colors.white.primary};
  font-size: 24px;

  cursor: pointer;

  position: absolute;
  bottom: 32px;
  right: 32px;

  box-shadow: ${theme.boxShadows.light};

  border-radius: 50%;
  background-color: ${colors.green.primary};
`

export const FOB = () => {
  const [isFobOpen, setIsFobOpen] = useState(false)
  const [scope, animate] = useAnimate()
  const navigate = useNavigate();

  const canCreateSession = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.CREATE_SESSION),
  );

  const canCreateTask = true
  // const canCreateTask = useSelector(
  //   hasCurrentUserPermissionSelector(UserPermission.CREATE_TASK),
  // );
  // TO DO: check if user has permission to create a task

  const handleFobClick = () => {
    setIsFobOpen(prev => !prev)
    // eslint-disable-next-line
    for (let i = 0; i < scope.current.children[0].children.length; i++) {
      const li = scope.current.children[0].children[i]
      animate(li, { opacity: isFobOpen ? 0 : 1, right: isFobOpen ? '-120px' : '32px' }, { duration: .3, delay: i / 7 })
    }
    animate("div", { rotate: isFobOpen ? 0 : "-45deg" }, { duration: .2 })
  }

  const handleCreateNewSession = () => {
    handleFobClick()
    navigate(appUrls.sessions.base, { state: { createNewSession: true } })
  }

  const handleCreateNewTask = () => {
    handleFobClick()
    navigate(appUrls.maintenance.base, { state: { createNewTask: true } })
  }

  const buttons: FabButtonArray = [
    {
      title: 'Create new session',
      show: canCreateSession,
      click: handleCreateNewSession,
    },
    {
      title: 'Create new task',
      show: canCreateTask,
      click: handleCreateNewTask,
    },
  ]

  const showFABButton = buttons.map(b => b.show).some(e => e)

  return(
    <FOBContainer show={showFABButton} ref={scope}>
        <UnorderedList
          as={motion.ul}
          initial="hidden"
          animate="visible"
        >
          {buttons.map((button: FabButton, index) => {
            return button.show ? (
              <ListItem whileHover={{ scale: 1.1 }} as={motion.li} index={index} onClick={button.click} key={button.title}>
                  {button.title}
              </ListItem>
            ) : null;
          })}
        </UnorderedList>
      <Button
        onClick={handleFobClick}
        as={motion.div}
        whileHover={{ scale: 1.1 }}>
        <PlusOutlined />
      </Button>
    </FOBContainer>
  )
}