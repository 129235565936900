import styled from '@emotion/styled';
import React from 'react';

import { FlexBox } from '../../../styled/flex/FlexBox';
import { theme } from '../../../theme';
import { Logo, LogoProps } from './Logo';

interface IProps extends LogoProps {
  showLabel: boolean;
}

const StyledLabel = styled.h3({
  marginRight: '16px',
  fontSize: theme.fontSizes.medium,
  marginBottom: 0,
  display: 'flex',
  alignItems: 'center',
});

export const LogoWithLabel = ({ onClick, showLabel }: IProps) => (
  <FlexBox>
    <Logo onClick={onClick} />
    {showLabel && <StyledLabel>Techmetrys</StyledLabel>}
  </FlexBox>
);
