import * as React from 'react';
const Home = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M18 7.2v11.2H2V7.2"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeMiterlimit={10}
      d="M7.6 18.4v-7.6h4.8v7.6"
    />
    <path
      fill="currentColor"
      d="M14 2.4v1.206l.8.832V3.2h.8v2.081l.8.825V2.4z"
    />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      d="M.8 8 10 .821 19.2 8"
    />
  </svg>
);
export default Home;
