import { useSelector } from 'react-redux';
import { BoxTag } from '../../types/tag.types';
import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../store/selectors/tags.selectors';

export const getBoxConnectivityStatus = (boxId: string) => {
  const channelStateStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V4_CHANNEL_STATE,
    ),
  );

  const channelStateValue = channelStateStatus?.value;

  return channelStateValue !== '1';
};
