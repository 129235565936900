import { MinusOutlined } from '@ant-design/icons';
import { Radio, Select, Popconfirm, Spin } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { defineMessages, useIntl } from 'react-intl';
import moment, { Moment } from 'moment';

import { theme } from '../../../theme';
import { StyledButton } from '../../../styled/buttons/StyledButton';
import { StyledTextArea } from '../../../styled/form/StyledTextArea';
import { StyledRadioButton } from '../../../styled/buttons/StyledRadioButton';
import { MaintenanceProblemType } from '../../../types/maintenance.types';
import { StyledSelect } from '../../../styled/form/StyledSelect';
import { SelectBox } from '../../Boxes/BoxDetail/BoxesHelpers/SelectBox';
import { SelectPriority } from './Select/SelectPriority';
import { StyledDate } from '../../../styled/date/StyledDate';
import { SelectStatus } from './Select/SelectStatus';
import { ISessionConnector } from '../../../types/session.types';
import { SelectUser } from './Select/SelectUser';
import { FlexCentered } from '../../../styled/flex/FlexCentered';

const { Option } = Select;

interface ContainerProps {
  width?: string;
}

const Container = styled.div<ContainerProps>`
  margin-bottom: ${theme.spacing.small};

  display: flex;
  flex-direction: column;

  width: ${(props) => props.width || '100%'};
`;

const ButtonContainer = styled.div`
  margin-top: ${theme.spacing.small};

  display: flex;
  justify-content: space-between;
`;

const OneRowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const MESSAGES = defineMessages({
  addNewTask: {
    id: 'maintenance.addNewTask',
    defaultMessage: 'Add new task',
  },
  addNewTaskDescription: {
    id: 'maintenance.addNewTaskDescription',
    defaultMessage: 'Add task description',
  },
  selectBox: {
    id: 'maintenance.selectBox',
    defaultMessage: 'Select box',
  },
  selectConnector: {
    id: 'maintenance.selectConnector',
    defaultMessage: 'Select connector',
  },
  boxProblem: {
    id: 'maintenance.boxProblem',
    defaultMessage: 'The problem lies within the box.',
  },
  connectorProblem: {
    id: 'maintenance.connectorProblem',
    defaultMessage: 'The problem lies within one of the connectors.',
  },
  whichConnector: {
    id: 'maintenance.whichConnector',
    defaultMessage: 'In which connector does the issue lie in?',
  },
  whichBox: {
    id: 'maintenance.whichBox',
    defaultMessage: 'In which box does the issue lie in?',
  },
  createTask: {
    id: 'maintenance.createTask',
    defaultMessage: 'Create task',
  },
  editTask: {
    id: 'maintenance.editTask',
    defaultMessage: 'Edit task',
  },
  whatIsTheProblem: {
    id: 'maintenance.whatIsTheProblem',
    defaultMessage: 'What is the problem?',
  },
  deleteTask: {
    id: 'maintenance.deleteTask',
    defaultMessage: 'Delete task',
  },
  warningDeleteTask: {
    id: 'maintenance.warningDeleteTask',
    defaultMessage: 'Are you sure you want to delete this task?',
  },
  confirmDeleteTask: {
    id: 'maintenance.confirmDeleteTask',
    defaultMessage: 'Confirm delete',
  },
  priority: {
    id: 'maintenance.priority',
    defaultMessage: 'What is the priority of the task?',
  },
  targetDate: {
    id: 'maintenance.targetDate',
    defaultMessage: 'When is the target date?',
  },
  taskComment: {
    id: 'maintenance.taskComment',
    defaultMessage: 'Any comments on the task?',
  },
  addTaskComment: {
    id: 'maintenance.addTaskComment',
    defaultMessage: 'Add a comment',
  },
  taskStatus: {
    id: 'maintenance.taskStatus',
    defaultMessage: 'What is the status of the task?',
  },
  taskAssignee: {
    id: 'maintenance.taskAssignee',
    defaultMessage: 'Who is the assignee?',
  }
});

interface ITaskModalProps {
  action: string;
  task?: any; // TODO: Define the task type, after backend dev joins in
}

export const TaskDetails = ({ action, task }: ITaskModalProps) => {
  const isCreatingTask = action === 'create';
  const intl = useIntl();
  const [showSelectUsers, setShowSelectUsers] = useState(!isCreatingTask);
  const [taskDetails, setTaskDetails] = useState({
    selectedBox: task?.box || null,
    selectedConnector: task?.connector || null,
    problem: task?.problemType || '',
    targetDate: task?.targetDate || null,
    priority: task?.priority || '',
    taskDescription: task?.description || '',
    taskComment: task?.comment || '',
    status: task?.status || '',
    assignee: task?.assignee || '',
  });


  const validateTaskFields = () => {
    const { priority, problem, selectedBox, selectedConnector, status, targetDate, taskDescription } = taskDetails;

    if (problem === MaintenanceProblemType.CONNECTOR && !selectedConnector) {
      return true;
    }

    if (!taskDescription || !selectedBox || !priority || !targetDate || !status) {
      return true;
    }

    if (targetDate && moment(targetDate).isBefore(moment(), 'day')) {
      return true;
    }

    return false;
  };

  const handleChange = (field: string, value: any) => {
    setTaskDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const handleCreateTask = () => {
    const newTask = {
      ...taskDetails,
      targetDate: moment(taskDetails.targetDate).format('DD-MM-YYYY'),
    };


    // TODO: Call API or dispatch an action to create the task with the newTask object
  };

  const handleEditTask = () => {
    const taskToBeUpdated = {
      ...taskDetails,
      targetDate: moment(taskDetails.targetDate).format('DD-MM-YYYY'),
    };


    // TODO: Call API or dispatch an action to edit the task with the newTask object
  };

  const handleDeleteTask = () => {
    // TODO: Call API or dispatch an action to edit the task with the newTask object
  };

  if (!isCreatingTask) {
    setTimeout(() => {
      setShowSelectUsers(false)
    }, 300);
  }

  return (
    <>
      <Container>
        {intl.formatMessage(MESSAGES.whichBox)}
        <SelectBox
          boxSelected={taskDetails.selectedBox}
          onSelectBox={(box) => handleChange('selectedBox', box)}
        />
      </Container>

      <OneRowContainer>
        <Container width="49%">
          {intl.formatMessage(MESSAGES.taskStatus)}
          <SelectStatus
            statusSelected={taskDetails.status}
            onSelectStatus={(s) => handleChange('status', s)}
          />
        </Container>

        <Container width="49%">
          {intl.formatMessage(MESSAGES.priority)}
          <SelectPriority
            prioritySelected={taskDetails.priority}
            onSelectPriority={(p) => handleChange('priority', p)}
          />
        </Container>
      </OneRowContainer>

      <OneRowContainer>
        <Container width="49%">
          {intl.formatMessage(MESSAGES.taskAssignee)}
          {showSelectUsers && (
            <FlexCentered mt={theme.spacing.xSmall} height="100%" width="100%">
              <Spin />
            </FlexCentered>
          )} 
          <SelectUser
            show={showSelectUsers}
            userSelected={taskDetails.assignee}
            onSelectUser={(id) => handleChange('assignee', id)}
          />
        </Container>

        <Container width="49%">
          {intl.formatMessage(MESSAGES.targetDate)}
          <StyledDate
            value={taskDetails.targetDate}
            format="DD-MM-YYYY"
            picker="date"
            onChange={(value: Moment | null) =>
              handleChange('targetDate', value || null)
            }
          />
        </Container>
      </OneRowContainer>

      {taskDetails.selectedBox && (
        <Container width="90%">
          <Radio.Group
            value={taskDetails.problem}
            defaultValue={MaintenanceProblemType.BOX}
          >
            <StyledRadioButton
              key={MaintenanceProblemType.BOX}
              onClick={() =>
                handleChange('problem', MaintenanceProblemType.BOX)
              }
              value={MaintenanceProblemType.BOX}
              data-testid={`box-filters-radio-${MaintenanceProblemType.BOX}`}
            >
              {intl.formatMessage(MESSAGES.boxProblem)}
            </StyledRadioButton>
            <StyledRadioButton
              key={MaintenanceProblemType.CONNECTOR}
              onClick={() =>
                handleChange('problem', MaintenanceProblemType.CONNECTOR)
              }
              value={MaintenanceProblemType.CONNECTOR}
              data-testid={`box-filters-radio-${MaintenanceProblemType.CONNECTOR}`}
            >
              {intl.formatMessage(MESSAGES.connectorProblem)}
            </StyledRadioButton>
          </Radio.Group>
        </Container>
      )}

      {taskDetails.problem === MaintenanceProblemType.CONNECTOR && (
        <Container>
          {intl.formatMessage(MESSAGES.whichConnector)}
          <StyledSelect
            placeholder={intl.formatMessage(MESSAGES.selectConnector)}
            data-testid="select-box"
            minWidth="170px"
            value={taskDetails.selectedConnector}
            onChange={(value: unknown) =>
              handleChange('selectedConnector', value as string)
            }
          >
            {taskDetails.selectedBox?.connectors?.map(
              (connector: ISessionConnector, index: number) => (
                <Option key={connector.id} value={connector.id}>
                  Connector {index + 1}
                </Option>
              ),
            )}
          </StyledSelect>
        </Container>
      )}

      {(taskDetails.problem === MaintenanceProblemType.BOX ||
        !!taskDetails.selectedConnector) && (
        <Container>
          {intl.formatMessage(MESSAGES.whatIsTheProblem)}
          <StyledTextArea
            rows={3}
            placeholder={intl.formatMessage(MESSAGES.addNewTaskDescription)}
            value={taskDetails.taskDescription}
            onChange={(e) => handleChange('taskDescription', e.target.value)}
          />
        </Container>
      )}

      <Container>
        {intl.formatMessage(MESSAGES.taskComment)}
        <StyledTextArea
          rows={3}
          placeholder={intl.formatMessage(MESSAGES.addTaskComment)}
          value={taskDetails.taskComment}
          onChange={(e) => handleChange('taskComment', e.target.value)}
        />
      </Container>

      <ButtonContainer>
        {!isCreatingTask && (
          <Popconfirm
            title={intl.formatMessage(MESSAGES.warningDeleteTask)}
            okText={intl.formatMessage(MESSAGES.confirmDeleteTask)}
            onConfirm={handleDeleteTask}
            placement="left"
          >
            <StyledButton
              variant="danger"
              htmlType="submit"
              width={theme.input.width.fitContent}
              data-testid="btn-delete-task"
            >
              <MinusOutlined />
              {intl.formatMessage(MESSAGES.deleteTask)}
            </StyledButton>
          </Popconfirm>
        )}
        <StyledButton
          variant="primary"
          htmlType="submit"
          disabled={validateTaskFields()}
          data-testid={`btn-${action}-task`}
          onClick={isCreatingTask ? handleCreateTask : handleEditTask}
        >
          {intl.formatMessage(
            isCreatingTask ? MESSAGES.createTask : MESSAGES.editTask,
          )}
        </StyledButton>
      </ButtonContainer>
    </>
  );
};
