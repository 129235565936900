import * as React from 'react';
const WaterDrop = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 9 13"
    {...props}
  >
    <path d="M4.693.383a.26.26 0 0 0-.386 0C4.135.592.097 5.52.097 8.345A4.41 4.41 0 0 0 4.5 12.75a4.41 4.41 0 0 0 4.404-4.404c0-2.826-4.04-7.753-4.211-7.962M4.55 3.435c-.024.03-2.364 3.073-2.364 4.862 0 2.255 1.976 2.565 2.06 2.577a.25.25 0 0 1-.07.495c-.026-.003-2.49-.389-2.49-3.072 0-1.96 2.368-5.038 2.468-5.169a.25.25 0 1 1 .396.307" />
  </svg>
);
export default WaterDrop;
