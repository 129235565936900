import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { TOAST_MESSAGES } from '../../components/feedback/NotificationAutoClose';
import {
  ICurrentUser,
  IRegisterUserDto,
  IUser,
  IUserForCompanyDto,
  IUserForCompanyUpdateDto,
  IUserProfile,
} from '../../types/user.types';
import { UserActions } from '../actions/user.actions';
import { setErrorMessage, setSuccessMessage } from '../slices/layout.slice';
import { baseApi, RtkTagType } from './base.api';

const url = "/users";

export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<IUser[], void>({
      query: () => url,
      providesTags: [{ type: RtkTagType.USERS }],
    }),

    getCurrentUser: builder.query<ICurrentUser, void>({
      query: () => ({
        url: `${url}/current-user`,
      }),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(UserActions.setGetCurrentUserResponse(200));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          dispatch(UserActions.setGetCurrentUserResponse(e.error.status));
        }
      },
      providesTags: [{ type: RtkTagType.CURRENT_USER }],
    }),

    postRegisterUser: builder.mutation<QueryReturnValue, IRegisterUserDto>({
      query: (newUser) => ({
        url: `${url}/register`,
        method: 'POST',
        body: newUser,
      }),
      invalidatesTags: [{ type: RtkTagType.CURRENT_USER }],
    }),

    postCreateUserForCompany: builder.mutation<
      QueryReturnValue,
      IUserForCompanyDto
    >({
      query: (newUser) => ({
        url: `${url}/create`,
        method: 'POST',
        body: newUser,
      }),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage(TOAST_MESSAGES.successUserCreated));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          dispatch(
            setErrorMessage({
              message: TOAST_MESSAGES.errorUserCreated,
            }),
          );
        }
      },
      invalidatesTags: [{ type: RtkTagType.USERS }],
    }),

    patchUser: builder.mutation<QueryReturnValue, IUserForCompanyUpdateDto>({
      query: ({ id, payload }) => ({
        url: `${url}/${id}`,
        method: 'PATCH',
        body: payload,
      }),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage(TOAST_MESSAGES.successUserUpdated));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          dispatch(
            setErrorMessage({
              message: TOAST_MESSAGES.errorUserUpdated,
              description: e.error.data,
            }),
          );
        }
      },
      invalidatesTags: [{ type: RtkTagType.USERS }],
    }),

    getUserProfile: builder.query<IUserProfile, void>({
      query: () => ({
        url: `${url}/profile`,
      }),
      providesTags: [{ type: RtkTagType.USER_PROFILE }],
    }),
    putUserProfile: builder.mutation<IUserProfile, IUserProfile>({
      query: (profile) => ({
        url: `${url}/profile`,
        method: 'PUT',
        body: profile,
      }),
      invalidatesTags: [{ type: RtkTagType.USER_PROFILE }],
    }),
    deleteUser: builder.mutation<void, { userIds: string[] }>({
      query: (ids) => ({
        url,
        params: ids,
        method: 'DELETE',
      }),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage(TOAST_MESSAGES.successUserDeleted));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          dispatch(
            setErrorMessage({ message: TOAST_MESSAGES.errorUserDeleted }),
          );
        }
      },
      invalidatesTags: [{ type: RtkTagType.USERS }],
    }),
  }),
});

export const {
  useDeleteUserMutation,
  useGetCurrentUserQuery,
  useGetUserProfileQuery,
  useGetUsersQuery,
  usePatchUserMutation,
  usePostCreateUserForCompanyMutation,
  usePostRegisterUserMutation,
  usePutUserProfileMutation,
} = usersApi;
