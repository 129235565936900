import styled from '@emotion/styled';
import React from 'react';

import logo from '../../assets/logo.svg';

export interface LogoProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any) => unknown;
}

const StyledLogo = styled.img({
  display: 'block',
  width: '40px',
  height: '40px',
});

export const Logo = ({ onClick }: LogoProps) => (
  <StyledLogo
    alt="logo Techmetrys"
    className="logo"
    onClick={onClick}
    src={logo}
  />
);
