import React from 'react';
import { useNavigate } from 'react-router-dom';

import { FlexBox } from '../../styled/flex/FlexBox';
import { StyledHeading } from '../../styled/headings/StyledHeading';
import { theme } from '../../theme';
import { BackArrowIcon } from '../form/icons/BackArrowIcon';

interface IProps {
  heading: string;
}

export const FormHeadingWithBackArrow = ({ heading }: IProps) => {
  const navigate = useNavigate();

  return (
    <FlexBox alignItems="center">
      <BackArrowIcon onClick={() => navigate(-1)} />
      <StyledHeading
        variant="h1"
        pt={theme.spacing.small}
        ml={theme.spacing.xLarge}
        fontStyle="italic"
        fontWeight={theme.fontWeights.regular}
        data-testid="form-heading"
      >
        {heading}
      </StyledHeading>
    </FlexBox>
  );
};
