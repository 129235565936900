import styled from '@emotion/styled';

import { colors, theme } from '../../theme';
import { StyledBox } from './StyledBox';

export const PrimaryWrapperBox = styled(StyledBox)({
  background: colors.white.primary,
  borderRadius: theme.borderRadius.medium,
  padding: theme.spacing.xLarge,
});
