import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { UserRole } from '../../types/user.types';
import { RegistrationActions } from '../actions/registration.actions';

const activeUserRole = createReducer<UserRole | null>(UserRole.COMPANY_OWNER, {
  [RegistrationActions.setUserRole.type]: (state, { payload }) => payload,
});

export const registrationReducer = combineReducers({
  activeUserRole,
});
