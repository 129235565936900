import styled from '@emotion/styled';
import { Layout } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { appUrls } from '../../../config/url.constants';

import { colors, theme } from '../../../theme';
import { LogoWithLabel } from '../../brand/LogoWithLabel';

import SideMenu from './SideMenu';

interface IProps {
  isCollapsed: boolean;
  onSidebarClick: () => void;
}

const { Sider } = Layout;

const StyledSider = styled(Sider)(({ collapsed }) => ({
  borderTopRightRadius: theme.spacing.xxLarge,
  borderBottomRightRadius: theme.spacing.xxLarge,
  marginRight: theme.spacing.xxLarge,
  background: colors.white.primary,
  height: '100%',
  position: 'fixed',
  overflow: 'auto',
  boxShadow: theme.boxShadows.dropShadow,

  width: `${
    collapsed ? theme.sidebar.collapsedWith : theme.sidebar.width
  } !important`,
  minWidth: `${
    collapsed ? theme.sidebar.collapsedWith : theme.sidebar.width
  } !important`,

  '.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected': {
    backgroundColor: 'transparent',
  },

  '.ant-menu-item-selected': {
    color: colors.green.primary,
  },

  '.ant-menu-item-icon': {
    fontSize: theme.fontSizes.large,
    verticalAlign: 'sub',
  },

  '.ant-menu.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon': {
    fontSize: theme.fontSizes.large,
    verticalAlign: 'sub',
  },

  '.ant-menu-light .ant-menu-item:hover': {
    color: `${colors.white.primary}`,
    backgroundColor: `${colors.green.background}`,
  },

  '.ant-menu-title-content': {
    marginLeft: `${theme.spacing.large} !important`,
    fontSize: `${theme.fontSizes.medium}px`,
    lineHeight: `${theme.fontSizes.xLarge}px`,
    opacity: collapsed ? 0 : 1,
  },

  '.ant-menu.ant-menu-root': {
    border: 'none',
  },

  '.ant-menu.ant-menu-inline-collapsed > .ant-menu-item': {
    padding: '0 calc(50% - 25px / 2)',
  },

  '.ant-menu-item': {
    padding: '0 20px',
  },
}));

export const SideNavBar = ({ isCollapsed, onSidebarClick }: IProps) => {
  const navigate = useNavigate();

  return (
    <StyledSider collapsed={isCollapsed} collapsible trigger={null}>
      <LogoWithLabel
        showLabel={!isCollapsed}
        onClick={() => navigate(appUrls.dashboard.base)}
      />
      <SideMenu onSidebarClick={onSidebarClick} isCollapsed={isCollapsed} />
      <div />
    </StyledSider>
  );
};
