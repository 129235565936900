import React from 'react';

import LightningBolt from '../../icons/LightningBolt';
import WaterDrop from '../../icons/WaterDrop';
import { FlexRow } from '../../styled/flex/FlexRow';
import { StyledSpan } from '../../styled/text/StyledSpan';
import { theme } from '../../theme';
import { ResourceType } from '../../types/connector.types';

interface IConnectorNumber {
  connectorIndex: number;
  color: string;
}

interface IProps {
  resourceType: ResourceType;
  connectorNumber?: IConnectorNumber;
  fill: string;
  height: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any) => void;
  stroke: string;
  width: string;
}

export const ResourceIcon = ({
  connectorNumber,
  fill,
  height,
  onClick,
  resourceType,
  stroke,
  width,
}: IProps) => {
  return (
    <FlexRow alignItems="center" data-testid={`icon-resource-${resourceType}`}>
      {resourceType === ResourceType.POWER ? (
        <LightningBolt
          fill={fill}
          width={width}
          height={height}
          onClick={onClick}
        />
      ) : (
        <WaterDrop
          fill={fill}
          width={width}
          height={height}
          stroke={stroke}
          onClick={onClick}
        />
      )}
      {connectorNumber && (
        <StyledSpan color={connectorNumber.color} ml={theme.spacing.xxSmall}>
          {connectorNumber.connectorIndex + 1}
        </StyledSpan>
      )}
    </FlexRow>
  );
};
