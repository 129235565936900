import { WarningOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IAssetInfo } from '../../../types/box.types';

const Container = styled.div<{ windowWidth: number; center: boolean }>`
    height: 100%;
    width: ${(props) => (props.center ? '100%' : `${props.windowWidth - 300}px`)};
    text-align: center;
`; // Remove width from here if we dont want it centered initially

const NoConnectionText = styled.span``;

interface IProps {
  center?: boolean;
}

export const NoConnectionBox = ({ center = false }: IProps) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    if (center) return;

    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Container windowWidth={windowWidth} center={center}>
        <div>
          <WarningOutlined />
        </div>
        <NoConnectionText>
          Communication is down, try again later
        </NoConnectionText>
      </Container>
    </>
  );
};
