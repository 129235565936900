import { useForm } from 'antd/es/form/Form';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import company from '../../../assets/company.svg';
import employee from '../../../assets/employee.svg';
import { FormItemCountrySelect } from '../../../components/form/select/FormItemCountrySelect';
import {
  useGetUserProfileQuery,
  usePutUserProfileMutation,
} from '../../../store/api/users.api';
import {
  currentUserRoleSelector,
  userProfileSelector,
} from '../../../store/selectors/user.selectors';
import { StyledButton } from '../../../styled/buttons/StyledButton';
import { FlexCentered } from '../../../styled/flex/FlexCentered';
import { FlexCol } from '../../../styled/flex/FlexCol';
import { StyledForm } from '../../../styled/form/StyledForm';
import { StyledFormItem } from '../../../styled/form/StyledFormItem';
import { StyledInput } from '../../../styled/form/StyledInput';
import { StyledInputGroupHeading } from '../../../styled/form/StyledInputGroupHeading';
import { StyledSpan } from '../../../styled/text/StyledSpan';
import { colors, theme } from '../../../theme';
import {
  CompanyInfo,
  IUserProfile,
  UserPersonalInfo,
  UserProfileValues,
  UserRole,
} from '../../../types/user.types';
import { getEmailValidationRule } from '../../../utils/form/getEmailValidationRule';
import { getPhoneNumberRule } from '../../../utils/form/getPhoneNumberRule';
import { getPostalCodeRule } from '../../../utils/form/getPostalCodeRule';
import { getRequiredRule } from '../../../utils/form/getRequiredRule';

const MESSAGES = defineMessages({
  myProfile: {
    id: 'profile_form.myProfile',
    defaultMessage: 'My Profile',
  },
  personalInfo: {
    id: 'profile_form.personalInfo',
    defaultMessage: 'Personal info',
  },
  [UserProfileValues.FIRST_NAME]: {
    id: 'profile_form.firstName',
    defaultMessage: 'First name',
  },
  [UserProfileValues.LAST_NAME]: {
    id: 'profile_form.lastName',
    defaultMessage: 'Last name',
  },
  [UserProfileValues.EMAIL]: {
    id: 'profile_form.email',
    defaultMessage: 'Email',
  },
  [UserProfileValues.INVOICE_EMAIL]: {
    id: 'profile_form.invoiceEmail',
    defaultMessage: 'Invoice email',
  },
  [UserProfileValues.PHONE_NUMBER]: {
    id: 'profile_form.phoneNumber',
    defaultMessage: 'Phone number',
  },
  company: {
    id: 'profile_form.card.company',
    defaultMessage: 'Company',
  },
  employee: {
    id: 'profile_form.card.employee',
    defaultMessage: 'Employee',
  },
  [UserProfileValues.COMPANY_NAME]: {
    id: 'profile_form.companyName',
    defaultMessage: 'Company name',
  },
  [UserProfileValues.VAT_NR]: {
    id: 'profile_form.vatNr',
    defaultMessage: 'VAT',
  },
  [UserProfileValues.STREET]: {
    id: 'profile_form.street',
    defaultMessage: 'Street',
  },
  [UserProfileValues.CITY]: {
    id: 'profile_form.city',
    defaultMessage: 'City',
  },
  [UserProfileValues.POSTAL_CODE]: {
    id: 'profile_form.postalCode',
    defaultMessage: 'Postal code',
  },
  [UserProfileValues.COUNTRY]: {
    id: 'profile_form.country',
    defaultMessage: 'Country',
  },
  confirm: {
    id: 'profile_form.submit',
    defaultMessage: 'Save profile',
  },
});

export const ProfileForm = () => {
  const intl = useIntl();
  const [form] = useForm();
  const userProfile = useSelector(userProfileSelector);

  useGetUserProfileQuery();
  const [putUserProfile] = usePutUserProfileMutation();

  const userRole = useSelector(currentUserRoleSelector());

  const isCompanyOwner = userRole === UserRole.COMPANY_OWNER
  const icon = isCompanyOwner ? company : employee;
  const roleLabel =
  isCompanyOwner
      ? intl.formatMessage(MESSAGES.company).toUpperCase()
      : intl.formatMessage(MESSAGES.employee).toUpperCase();

  const handleFormSubmit = () => {
    form.validateFields().then((values) => {
      const updatedUserProfile: IUserProfile = {
        firstName: values[UserProfileValues.FIRST_NAME],
        lastName: values[UserProfileValues.LAST_NAME],
        email: values[UserProfileValues.EMAIL],
        phoneNumber: values[UserProfileValues.PHONE_NUMBER],
        companyName: values[CompanyInfo.COMPANY_NAME],
        invoiceEmail: values[CompanyInfo.INVOICE_EMAIL],
        vatNr: values[CompanyInfo.VAT_NR],
        street: values[CompanyInfo.STREET],
        city: values[CompanyInfo.CITY],
        postalCode: values[CompanyInfo.POSTAL_CODE],
        country: values[CompanyInfo.COUNTRY],
      };
      putUserProfile(updatedUserProfile);
    });
  };

  if (userProfile) {
    form.setFieldsValue({
      [UserPersonalInfo.FIRST_NAME]: userProfile.firstName,
      [UserPersonalInfo.LAST_NAME]: userProfile.lastName,
      [UserPersonalInfo.EMAIL]: userProfile.email,
      [UserPersonalInfo.PHONE_NUMBER]: userProfile.phoneNumber,
      [CompanyInfo.COMPANY_NAME]: userProfile.companyName,
      [CompanyInfo.INVOICE_EMAIL]: userProfile.invoiceEmail,
      [CompanyInfo.VAT_NR]: userProfile.vatNr,
      [CompanyInfo.STREET]: userProfile.street,
      [CompanyInfo.CITY]: userProfile.city,
      [CompanyInfo.POSTAL_CODE]: userProfile.postalCode,
      [CompanyInfo.COUNTRY]: userProfile.country,
    });
  }

  return (
    <>
      {userProfile && (
        <StyledForm
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
          autoComplete="off"
          width="100%"
          maxWidth={theme.maxWidth}
          data-testid="userprofile-form"
          initialValues={{
            [CompanyInfo.COUNTRY]: userProfile.country,
          }}
        >
          <FlexCol alignItems="center">
            <FlexCentered
              width={118}
              height={118}
              border={`1px solid ${colors.green.primary}`}
              borderRadius="50%"
            >
              <img alt="logo" src={icon} />
            </FlexCentered>
            <FlexCol alignItems="center">
              <StyledSpan
                mt={theme.spacing.small}
                fontWeight={theme.fontWeights.bolder}
                color={colors.green.primary}
              >
                {roleLabel}
              </StyledSpan>
              <StyledSpan
                mt={theme.spacing.small}
                fontWeight={theme.fontWeights.bolder}
                color={colors.green.primary}
                margin="0 0 5px 0"
              >
                {userProfile?.companyName}
              </StyledSpan>
            </FlexCol>
          </FlexCol>
          <StyledInputGroupHeading mb={theme.spacing.xLarge}>
            {intl.formatMessage(MESSAGES.personalInfo)}
          </StyledInputGroupHeading>
          <StyledFormItem
            name={UserPersonalInfo.FIRST_NAME}
            validateTrigger="onBlur"
            rules={[
              getRequiredRule(
                intl.formatMessage(MESSAGES[UserPersonalInfo.FIRST_NAME]),
                intl,
              ),
            ]}
            label={intl.formatMessage(MESSAGES[UserPersonalInfo.FIRST_NAME])}
          >
            <StyledInput
              mt={theme.spacing.medium}
              width="100%"
              type="text"
              data-testid="input-profile-firstname"
              maxLength={255}
            />
          </StyledFormItem>
          {/*  */}
          <StyledFormItem
            name={UserPersonalInfo.LAST_NAME}
            validateTrigger="onBlur"
            rules={[
              getRequiredRule(
                intl.formatMessage(MESSAGES[UserPersonalInfo.LAST_NAME]),
                intl,
              ),
            ]}
            label={intl.formatMessage(MESSAGES[UserPersonalInfo.LAST_NAME])}
          >
            <StyledInput
              width="100%"
              type="text"
              data-testid="input-profile-lastName"
              maxLength={255}
            />
          </StyledFormItem>

          <StyledFormItem
            label={intl.formatMessage(MESSAGES[UserPersonalInfo.EMAIL])}
            name={UserPersonalInfo.EMAIL}
          >
            <StyledInput
              width="100%"
              type="text"
              disabled
              data-testid="input-profile-lastName"
              maxLength={255}
            />
          </StyledFormItem>

          <StyledFormItem
            name={UserPersonalInfo.PHONE_NUMBER}
            validateTrigger="onBlur"
            rules={[
              getRequiredRule(
                intl.formatMessage(MESSAGES[UserPersonalInfo.PHONE_NUMBER]),
                intl,
              ),
              getPhoneNumberRule(
                intl.formatMessage(MESSAGES[UserPersonalInfo.PHONE_NUMBER]),
                intl,
              ),
            ]}
            label={intl.formatMessage(MESSAGES[UserPersonalInfo.PHONE_NUMBER])}
          >
            <StyledInput
              width="100%"
              type="text"
              data-testid="input-profile-phone"
            />
          </StyledFormItem>

          {isCompanyOwner && (
            <>
              <StyledInputGroupHeading mb={theme.spacing.xLarge}>
                {intl.formatMessage(MESSAGES.company)}
              </StyledInputGroupHeading>

              <StyledFormItem
                name={CompanyInfo.COMPANY_NAME}
                validateTrigger="onBlur"
                rules={[
                  getRequiredRule(
                    intl.formatMessage(MESSAGES[CompanyInfo.COMPANY_NAME]),
                    intl,
                  ),
                ]}
                label={intl.formatMessage(MESSAGES[CompanyInfo.COMPANY_NAME])}
              >
                <StyledInput
                  width="100%"
                  type="text"
                  data-testid="input-profile-company-name"
                  maxLength={255}
                />
              </StyledFormItem>
              <StyledFormItem
                label={intl.formatMessage(MESSAGES[CompanyInfo.INVOICE_EMAIL])}
                name={CompanyInfo.INVOICE_EMAIL}
                rules={[
                  getRequiredRule(
                    intl.formatMessage(MESSAGES[CompanyInfo.INVOICE_EMAIL]),
                    intl,
                  ),
                  getEmailValidationRule(
                    intl.formatMessage(MESSAGES[CompanyInfo.INVOICE_EMAIL]),
                    intl,
                  ),
                ]}
              >
                <StyledInput
                  width="100%"
                  type="text"
                  disabled={!isCompanyOwner}
                  data-testid="input-profile-lastName"
                  maxLength={255}
                />
              </StyledFormItem>

              <StyledFormItem
                name={CompanyInfo.VAT_NR}
                validateTrigger="onBlur"
                rules={[
                  getRequiredRule(
                    intl.formatMessage(MESSAGES[CompanyInfo.VAT_NR]),
                    intl,
                  ),
                ]}
                label={intl.formatMessage(MESSAGES[CompanyInfo.VAT_NR])}
              >
                <StyledInput
                  width="100%"
                  type="text"
                  data-testid="input-profile-company-vat"
                  maxLength={255}
                />
              </StyledFormItem>
              <StyledFormItem
                name={CompanyInfo.STREET}
                validateTrigger="onBlur"
                rules={[
                  getRequiredRule(
                    intl.formatMessage(MESSAGES[CompanyInfo.STREET]),
                    intl,
                  ),
                ]}
                label={intl.formatMessage(MESSAGES[CompanyInfo.STREET])}
              >
                <StyledInput
                  width="100%"
                  type="text"
                  data-testid="input-profile-company-street"
                  maxLength={255}
                />
              </StyledFormItem>
              <StyledFormItem
                name={CompanyInfo.CITY}
                validateTrigger="onBlur"
                rules={[
                  getRequiredRule(
                    intl.formatMessage(MESSAGES[CompanyInfo.CITY]),
                    intl,
                  ),
                ]}
                label={intl.formatMessage(MESSAGES[CompanyInfo.CITY])}
              >
                <StyledInput
                  width="100%"
                  type="text"
                  data-testid="input-profile-company-city"
                  maxLength={255}
                />
              </StyledFormItem>
              <StyledFormItem
                name={CompanyInfo.POSTAL_CODE}
                validateTrigger="onBlur"
                rules={[
                  getRequiredRule(
                    intl.formatMessage(MESSAGES[CompanyInfo.POSTAL_CODE]),
                    intl,
                  ),
                  getPostalCodeRule(
                    intl.formatMessage(MESSAGES[CompanyInfo.POSTAL_CODE]),
                    intl,
                  ),
                ]}
                label={intl.formatMessage(MESSAGES[CompanyInfo.POSTAL_CODE])}
              >
                <StyledInput
                  width="100%"
                  type="text"
                  data-testid="input-profile-company-postal-code"
                />
              </StyledFormItem>
              <FormItemCountrySelect
                name={CompanyInfo.COUNTRY}
                validateTrigger="onBlur"
                rules={[
                  getRequiredRule(
                    intl.formatMessage(MESSAGES[CompanyInfo.COUNTRY]),
                    intl,
                  ),
                ]}
              />
            </>
          )}
          <StyledButton
            width={theme.input.width.fitContent}
            variant="primary"
            htmlType="submit"
            data-testid="btn-profile-form-submit"
          >
            {intl.formatMessage(MESSAGES.confirm)}
          </StyledButton>
        </StyledForm>
      )}
    </>
  );
};
