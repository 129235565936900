import { Form, Select, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { boxFilterByKeySelector } from '../../../store/selectors/filters.selectors';
import { setAssetFilter } from '../../../store/slices/filters.slice';
import { updateRequestedTablePage } from '../../../store/slices/tableMetaInfo.slice';
import { PrimaryWrapperBox } from '../../../styled/boxes/PrimaryWrapperBox';
import { StyledSearch } from '../../../styled/form/StyledSearch';
import { StyledRadioButton } from '../../../styled/buttons/StyledRadioButton';
import { StyledSelect } from '../../../styled/form/StyledSelect';
import { theme } from '../../../theme';
import { FilterKey, AssetFilterKey } from '../../../types/filters.types';
import { BoxesFilter, RequestBoxFor } from '../../../types/box.types';
import { Table } from '../../../types/table.types';
import { StyledCheckbox } from '../../../styled/form/StyledCheckbox';
import { FilterElements, FilterContainer } from '../../../components/filters/FilterElements';

const MESSAGES = defineMessages({
  search: {
    id: 'boxes_table_filters.search',
    defaultMessage: 'Search',
  },
  boxDropdownPlaceholder: {
    id: 'boxes_table_filters.boxDropdownPlaceholder',
    defaultMessage: 'Sort',
  },
  errors: {
    id: 'boxes_table_filters.errors',
    defaultMessage: 'Errors',
  },
  warnings: {
    id: 'boxes_table_filters.warnings',
    defaultMessage: 'Warnings',
  },
  favorites: {
    id: 'boxes_table_filters.favorites',
    defaultMessage: 'Favorites',
  },
  all: {
    id: 'boxes_table_filters.all',
    defaultMessage: 'All',
  },
  power: {
    id: 'boxes_table_filters.power',
    defaultMessage: 'Power',
  },
  water: {
    id: 'boxes_table_filters.water',
    defaultMessage: 'Water',
  },
  [BoxesFilter.ASSET_ID]: {
    id: 'boxes_table_filters.asset_id',
    defaultMessage: 'Asset:',
  },
  [BoxesFilter.TYPE_FILTER]: {
    id: 'boxes_table_filters.for',
    defaultMessage: 'Type filter:',
  },
  [BoxesFilter.LOCATION]: {
    id: 'boxes_table_filters.location',
    defaultMessage: 'Location:',
  },
  [BoxesFilter.FILTER]: {
    id: 'boxes_table_filters.filter',
    defaultMessage: 'Filter:',
  },
});

export const BoxesTableFilter = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [names, setNames] = useState<string[]>([]);

  const handleCheckboxChange = (name: string) => {
    setNames(
      names.includes(name)
        ? names.filter((item) => item !== name)
        : [...names, name],
    );
  };

  const assetIdFilterValue = useSelector(
    boxFilterByKeySelector(AssetFilterKey.assetId),
  );

  const locationFilterValue = useSelector(
    boxFilterByKeySelector(AssetFilterKey.location),
  );

  const handleChangeFilter = (
    key: FilterKey,
    value: string | number,
    filterArray?: string[],
  ) => {
    dispatch(
      updateRequestedTablePage({
        key: Table.BOXES_TABLE,
        value: 0,
      }),
    );

    dispatch(
      setAssetFilter({
        key,
        value: filterArray || value,
      }),
    );
  };

  useEffect(() => {
    handleChangeFilter(
      AssetFilterKey.filter,
      MESSAGES.errors.defaultMessage.toLowerCase(),
      names,
    );
  }, [names]);

  return (
    <PrimaryWrapperBox mb={theme.spacing.large}>
      <Form
        form={form}
        layout="inline"
      >
        <FilterContainer>
          <FilterElements title={intl.formatMessage(MESSAGES[BoxesFilter.ASSET_ID])}>
            <StyledSearch
              onChange={(ev) =>
                handleChangeFilter(AssetFilterKey.assetId, ev.target.value)
              }
              onSearch={(value) =>
                handleChangeFilter(AssetFilterKey.assetId, value)
              }
              placeholder={intl.formatMessage(MESSAGES.search)}
              value={assetIdFilterValue}
              data-testid={`search-${AssetFilterKey.assetId}`}
            />
          </FilterElements>
          <FilterElements title={intl.formatMessage(MESSAGES[BoxesFilter.LOCATION])}>
            <StyledSelect
              value={locationFilterValue}
              showSearch
              allowClear
              minWidth="170px"
              onChange={(value) =>
                handleChangeFilter(AssetFilterKey.location, value as string)
              }
              placeholder={intl.formatMessage(MESSAGES.boxDropdownPlaceholder)}
            >
              {/* {boxesForSearchSession?.map((boxIdLabelOperator) => (
                <Option key={boxIdLabelOperator.id} value={boxIdLabelOperator.id}>
                  {`${boxIdLabelOperator.label} | ${boxIdLabelOperator.operatorName}`}
                </Option>
              ))} */}
            </StyledSelect>
          </FilterElements>
        </FilterContainer>
        <FilterElements title={intl.formatMessage(MESSAGES[BoxesFilter.FILTER])}>
          <StyledCheckbox
            data-testid={`checkbox-box-table-${MESSAGES.errors.defaultMessage}`}
            className={MESSAGES.errors.defaultMessage}
            onChange={(ev) =>
              handleCheckboxChange(MESSAGES.errors.defaultMessage.toLowerCase())
            }
          >
            {intl.formatMessage(MESSAGES.errors)}
          </StyledCheckbox>
          <StyledCheckbox
            data-testid={`checkbox-box-table-${MESSAGES.warnings.defaultMessage}`}
            className={MESSAGES.errors.defaultMessage}
            onChange={(ev) =>
              handleCheckboxChange(
                MESSAGES.warnings.defaultMessage.toLowerCase(),
              )
            }
          >
            {intl.formatMessage(MESSAGES.warnings)}
          </StyledCheckbox>
          <StyledCheckbox
            data-testid={`checkbox-box-table-${MESSAGES.favorites.defaultMessage}`}
            className={MESSAGES.errors.defaultMessage}
            onChange={(ev) =>
              handleCheckboxChange(
                MESSAGES.favorites.defaultMessage.toLowerCase(),
              )
            }
          >
            {intl.formatMessage(MESSAGES.favorites)}
          </StyledCheckbox>
        </FilterElements>
        <FilterElements title={intl.formatMessage(MESSAGES[BoxesFilter.TYPE_FILTER])} key={BoxesFilter.TYPE_FILTER}>
          <Radio.Group defaultValue={RequestBoxFor.ALL}>
            <StyledRadioButton
              key={RequestBoxFor.ALL}
              onClick={() =>
                handleChangeFilter(AssetFilterKey.typeFilter, RequestBoxFor.ALL)
              }
              value={RequestBoxFor.ALL}
              data-testid={`box-filters-radio-${RequestBoxFor.ALL}`}
            >
              {intl.formatMessage(MESSAGES.all)}
            </StyledRadioButton>
            <StyledRadioButton
              key={RequestBoxFor.POWER}
              onClick={() =>
                handleChangeFilter(AssetFilterKey.typeFilter, RequestBoxFor.POWER)
              }
              value={RequestBoxFor.POWER}
              data-testid={`box-filters-radio-${RequestBoxFor.POWER}`}
            >
              {intl.formatMessage(MESSAGES.power)}
            </StyledRadioButton>
            <StyledRadioButton
              key={RequestBoxFor.WATER}
              onClick={() =>
                handleChangeFilter(AssetFilterKey.typeFilter, RequestBoxFor.WATER)
              }
              value={RequestBoxFor.WATER}
              data-testid={`box-filters-radio-${RequestBoxFor.WATER}`}
            >
              {intl.formatMessage(MESSAGES.water)}
            </StyledRadioButton>
          </Radio.Group>
        </FilterElements>
      </Form>
    </PrimaryWrapperBox>
  );
};
