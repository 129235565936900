import styled from '@emotion/styled';
import { Input } from 'antd';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

import { colors, theme } from '../../theme';

const { TextArea } = Input;

export const StyledTextArea = styled(TextArea)<LayoutProps | SpaceProps>(
  {
    width: '100%',
    borderRadius: theme.borderRadius.medium,
    
    '.ant-input:hover': {
      border: `1px solid ${colors.green.primary}`,
    },

    '.ant-btn:focus': {
      border: `1px solid ${colors.green.primary}`,
    },
  },
  layout,
  space,
);
