import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import sortOn from 'sort-on';

import { StyledTable } from '../../styled/table/StyledTable';
import {
  BoxAlarmTag,
  BoxWarningTag,
  ConnectorAlarmTag,
  ConnectorWarningTag,
  ITagStatus,
} from '../../types/tag.types';
import { secondsToHhMmSs } from '../../utils/shared/time/secondsToHhMmSs';
import { secondsToYyyyMmDd } from '../../utils/shared/time/secondsToYyyyMmDd';
import { AlarmIcon } from '../icons/general/status/AlarmIcon';
import { WarningIcon } from '../icons/general/status/WarningIcon';

const MESSAGES = defineMessages({
  [BoxWarningTag.V3_BOX_TEMPERATURE]: {
    id: 'asset_warnings_and_errors_table.boxTemperature',
    defaultMessage: 'Box temperature alarm',
  },
  [BoxWarningTag.V3_BOX_WARNING]: {
    id: 'asset_warnings_and_errors_table.boxWarning',
    defaultMessage: 'General Box warning',
  },
  [BoxWarningTag.V3_CPU_TEMPERATURE]: {
    id: 'asset_warnings_and_errors_table.cpuTemperature',
    defaultMessage: 'CPU temperature to high',
  },
  [BoxWarningTag.V3_EXPANTION_MODULE]: {
    id: 'asset_warnings_and_errors_table.expantionModule',
    defaultMessage: 'Expantie modules',
  },
  [BoxWarningTag.V3_FUSE_AUXILLIARIES]: {
    id: 'asset_warnings_and_errors_table.fuseAuxilliaries',
    defaultMessage: 'Circuit breaker auxilliaries',
  },
  [BoxWarningTag.V3_FUSE_EXT24VDC]: {
    id: 'asset_warnings_and_errors_table.fuseExt24Vdc',
    defaultMessage: 'Fuse external 24 VDC',
  },
  [BoxWarningTag.V3_HYGROSSTAAT]: { id: 'asset_warnings_and_errors_table.hygrosstaat', defaultMessage: 'Hygrosstaat' },
  [BoxWarningTag.V3_RTU_24_VDC]: {
    id: 'asset_warnings_and_errors_table.rtu24Vdc',
    defaultMessage: 'Ingangsspanning CPU 24 VDC',
  },
  [BoxWarningTag.V3_SERVICE_DOOR]: {
    id: 'asset_warnings_and_errors_table.serviceDoor',
    defaultMessage: 'Door service compartiment',
  },
  [BoxWarningTag.V3_UPS]: {
    id: 'asset_warnings_and_errors_table.ups',
    defaultMessage: 'UPS warning',
  },

  [BoxAlarmTag.V4_BOX_ALARM]: {
    id: 'asset_warnings_and_errors.boxAlarm',
    defaultMessge: 'General Box alarms',
  },
  [BoxAlarmTag.V4_CHANNEL_STATE]: {
    id: 'asset_warnings_and_errors.channelState',
    defaultMessage: 'Channel state',
  },
  [BoxAlarmTag.V4_CPU]: {
    id: 'asset_warnings_and_errors.cpu',
    defaultMessage: 'CPU in fout',
  },
  [BoxAlarmTag.V4_EXPANTION_MODULE]: {
    id: 'asset_warnings_and_errors.expantionModule',
    defaultMessage: 'Alarm expantion module absent',
  },
  [BoxAlarmTag.V4_FUSE_AUTOMATION]: {
    id: 'asset_warnings_and_errors.fuseAutomation',
    defaultMessage: 'Beveiliging automatisatie',
  },
  [BoxAlarmTag.V4_INCOMING_FASES]: {
    id: 'asset_warnings_and_errors.incomingPhases',
    defaultMessage: 'Inkomende Fase ontbreekt',
  },
  [BoxAlarmTag.V4_LICHTNING_PROTECTION]: {
    id: 'asset_warnings_and_errors.lichtningProtection',
    defaultMessage: 'Bliksem beveiliging',
  },
  [BoxAlarmTag.V4_MAINSWITCH]: {
    id: 'asset_warnings_and_errors_table.mainSwitch',
    defaultMessage: 'Hoofdschakelaar',
  },
  [BoxAlarmTag.V4_VOLTAGE]: {
    id: 'asset_warnings_and_errors.voltage',
    defaultMessage: 'Spanning aanwezig',
  },

  [ConnectorWarningTag.V3_MAINTENANCE]: {
    id: 'asset_warnings_and_errors_table.maintenance',
    defaultMessage: 'Point requires maintenance',
  },
  [ConnectorWarningTag.V3_POINT_WARNING]: {
    id: 'asset_warnings_and_errors_table.pointWarning',
    defaultMessage: 'Point warning state',
  },
  [ConnectorWarningTag.V3_SIMULATION_MODE]: {
    id: 'asset_warnings_and_errors_table.simulation',
    defaultMessage: 'Point in simulation mode - simulates consumption ',
  },
  [ConnectorWarningTag.V3_TAMPERING]: {
    id: 'asset_warnings_and_errors_table.tampering',
    defaultMessage: 'Last stop value not equal to start value',
  },
  [ConnectorWarningTag.V3_TEST_MODE]: {
    id: 'asset_warnings_and_errors_table.testMode',
    defaultMessage: 'Point in test mode - simulates breaker values',
  },
  [ConnectorWarningTag.V3_VESSEL_DEFINITIEVE]: {
    id: 'asset_warnings_and_errors_table.vesselDefinitieve',
    defaultMessage: 'Vessel in definitieve fault state',
  },
  [ConnectorWarningTag.V3_VESSEL_DIFF_TRIP]: {
    id: 'asset_warnings_and_errors_table.vesselDiffTrip',
    defaultMessage: 'Vessel tripped on differential',
  },
  [ConnectorWarningTag.V3_VESSEL_OVERLOAD_TRIP]: {
    id: 'asset_warnings_and_errors_table.vesselOverloadTrip',
    defaultMessage: 'Vessel tripped on overload',
  },

  [ConnectorAlarmTag.V4_BENDER]: {
    id: 'asset_warnings_and_errors_table.bender',
    defaultMessage: 'No detection of bender on vessel',
  },
  [ConnectorAlarmTag.V4_BOX_FAIL]: {
    id: 'asset_warnings_and_errors_table.boxFail',
    defaultMessage: 'Alarm from box alarm',
  },
  [ConnectorAlarmTag.V4_CABLE_PULL]: {
    id: 'asset_warnings_and_errors_table.cablePull',
    defaultMessage: 'to much strength on cable to vessel',
  },
  [ConnectorAlarmTag.V4_DIFF_RESET]: {
    id: 'asset_warnings_and_errors_table.diffReset',
    defaultMessage: 'Failed to reset external diff (ex RH10)',
  },
  [ConnectorAlarmTag.V4_METER]: {
    id: 'asset_warnings_and_errors_table.meter',
    defaultMessage: 'Failure to read meter',
  },
  [ConnectorAlarmTag.V4_METER_FUSE]: {
    id: 'asset_warnings_and_errors_table.meterFuse',
    defaultMessage: 'Fuse before meter',
  },
  [ConnectorAlarmTag.V4_POINT_ALARM]: {
    id: 'asset_warnings_and_errors_table.pointAlarm',
    defaultMessage: 'Point in alarm state',
  },
  [ConnectorAlarmTag.V4_PRIME_DRIVE]: {
    id: 'asset_warnings_and_errors_table.primeDrive',
    defaultMessage: 'Alarm from primary activator (ex. Relay, RCA)',
  },
  [ConnectorAlarmTag.V4_SAFETY_LOOP]: {
    id: 'asset_warnings_and_errors_table.safetyLoop',
    defaultMessage: 'Safety loop to vessel',
  },
  [ConnectorAlarmTag.V4_SECOND_DRIVE]: {
    id: 'asset_warnings_and_errors_table.secondDrive',
    defaultMessage: 'Alarm from secondary activator (ex. FUSE, DIFF)',
  },
  [ConnectorAlarmTag.V4_VOLTAGE_ON_CABLE]: {
    id: 'asset_warnings_and_errors_table.voltageOnCable',
    defaultMessage: 'Voltage on cable before connection',
  },
  [ConnectorAlarmTag.V4_WEB_REMOVAL_FAIL]: {
    id: 'asset_warnings_and_errors_table.webRemovalFail',
    defaultMessage: 'Authorisation web remains active',
  },


  startTimeTableHeading: {
    id: 'asset_warnings_and_errors_table.startTimeTableHeading',
    defaultMessage: 'Start time',
  },
  typeTableHeading: {
    id: 'asset_warnings_and_errors_table.typeTableHeading',
    defaultMessage: 'Type',
  },
  detailsTableHeading: {
    id: 'asset_warnings_and_errors_table.detailsTableHeading',
    defaultMessage: 'Details',
  },
});

interface IProps {
  alarmsAndWarnings: ITagStatus[];
}

export const AssetAlarmsAndWarningsTable = ({ alarmsAndWarnings }: IProps) => {
  const intl = useIntl();

  const warningsAndAlarmsSortedByDate = sortOn(alarmsAndWarnings, [
    '-updatedAt',
  ]);

  const mapWarningsAndAlarmStatusToDesc = (
    tag: BoxAlarmTag | BoxWarningTag | ConnectorAlarmTag | ConnectorWarningTag,
  ) => {
    return intl.formatMessage(MESSAGES[tag]);
  };

  const columns: ColumnsType<ITagStatus> = [
    {
      title: intl.formatMessage(MESSAGES.startTimeTableHeading),
      dataIndex: 'updatedAt',
      key: 'startTime',
      render: (startedAt: number) =>
        startedAt
          ? `${secondsToYyyyMmDd(startedAt)} - ${secondsToHhMmSs(startedAt)}`
          : '',
    },
    {
      title: intl.formatMessage(MESSAGES.typeTableHeading),
      dataIndex: 'tag',
      key: 'type',
      render: (tag: number) => {
        if (
          Object.keys(BoxAlarmTag).includes(tag.toString()) ||
          Object.keys(ConnectorAlarmTag).includes(tag.toString())
        ) {
          return <AlarmIcon />;
        }
        if (
          Object.keys(BoxWarningTag).includes(tag.toString()) ||
          Object.keys(ConnectorWarningTag).includes(tag.toString())
        ) {
          return <WarningIcon />;
        }
        return null;
      },
    },
    {
      title: intl.formatMessage(MESSAGES.detailsTableHeading),
      dataIndex: 'tag',
      render: (
        tag:
          | BoxAlarmTag
          | BoxWarningTag
          | ConnectorAlarmTag
          | ConnectorWarningTag,
      ) => {
        return <span>{mapWarningsAndAlarmStatusToDesc(tag)}</span>;
      },
    },
  ];

  return (
    <StyledTable
      columns={columns}
      dataSource={warningsAndAlarmsSortedByDate}
      rowKey={(tagStatusRecord: ITagStatus) => tagStatusRecord.tag}
      pagination={{
        position: ['bottomLeft'],
        showSizeChanger: false,
      }}
    />
  );
};
