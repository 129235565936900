import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IntlProvider } from 'react-intl';
import Protected from './Protected';
import { ILanguageHandle, useLanguage } from '../hooks/useLanguage';
import { getAccessTokenLocalStorage } from '../utils/api/localStorage.utils';
import { UserActions } from '../store/actions/user.actions';
import { wsClient } from '../utils/ws/WsClient';
import LanguageCode from '../types/language.types';
import { NotificationAutoClose } from '../components/feedback/NotificationAutoClose';
import { protectedRoutes, publicRoutes } from './routes';
import PermissionGuard from './PermissionGuard';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route element={<Protected />}>
        {protectedRoutes.map(({ element, index, path, permissions }) => (
          <Route
            key={path}
            path={path}
            element={
              <PermissionGuard permissions={permissions ?? []}>
                {element}
              </PermissionGuard>
            }
            index={index}
          />
        ))}
      </Route>

      {publicRoutes.map(({ element, index, path }) => (
        <Route key={path} path={path} element={element} index={index} />
      ))}
    </Route>,
  ),
);

const Index = () => {
  const languageHook: ILanguageHandle = useLanguage(); // todo: store language choice in state
  const dispatch = useDispatch();

  useEffect(() => {
    const tokenLocalStorage = getAccessTokenLocalStorage();
    if (tokenLocalStorage) {
      dispatch(UserActions.setToken(tokenLocalStorage));
      wsClient.init(tokenLocalStorage);
    }
  }, []);

  return (
    <IntlProvider
      defaultLocale={LanguageCode.EN}
      locale={languageHook.resource.language}
      messages={languageHook.resource.messages}
    >
      <NotificationAutoClose />
      <RouterProvider router={router} />
    </IntlProvider>
  );
};

export default Index;
