import { Tag } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { StyledTable } from '../../styled/table/StyledTable';
import { appUrls } from '../../config/url.constants';
import { TransposedTable } from '../../components/tables/TransposedTable';

export const MaintenanceTable = () => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 1024;

  const canCreateTask = true;
  // const canCreateTask = useSelector(
  //   hasCurrentUserPermissionSelector(UserPermission.CREATE_TASK),
  // );
  // TO DO: check if user has permission to create a task

  const columns = [
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
    },
    {
      title: 'Box',
      dataIndex: 'box',
      key: 'box',
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      render: (_: any, { priority }: any) => {
        let color;
        switch (priority) {
          case 'Urgent':
            color = '#ff6961';
            break;
          case 'High':
            color = '#ffb54c';
            break;
          case 'Medium':
            color = '#fee4b5';
            break;
          case 'Low':
            color = '#7abd7e';
            break;
          default:
            break;
        }
        return (
          <>
            <Tag color={color} key={priority}>
              {priority.toUpperCase()}
            </Tag>
          </>
        );
      },
    },
    {
      title: 'Task Description',
      dataIndex: 'taskDescription',
      key: 'taskDescription',
    },
    {
      title: 'Target Date',
      dataIndex: 'targetDate',
      key: 'targetDate',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Reporter',
      dataIndex: 'reporter',
      key: 'reporter',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      key: 'comment',
    },
  ];

  const dataSource = [
    {
      key: '1',
      user: 'John Doe',
      box: '101',
      priority: 'High',
      taskDescription: 'Change Modem',
      targetDate: '18/04/2024',
      status: 'In progress',
      reporter: 'Michael Johnson',
      comment: 'This is a comment',
    },
    {
      key: '2',
      user: 'Jane Doe',
      box: '101',
      priority: 'Medium',
      taskDescription: 'Check water pressure',
      targetDate: '10/04/2024',
      status: 'New',
      reporter: 'Garrett Lambert',
      comment: 'Dummy comment',
    },
    {
      key: '3',
      user: 'Adrian Jones',
      box: '101',
      priority: 'Low',
      taskDescription: 'Preventive maintanace',
      targetDate: '08/09/2024',
      status: 'Acknowledged',
      reporter: 'Chris Weber',
      comment: 'This is a comment again',
    },
    {
      key: '4',
      user: 'Jesse Wilkins',
      box: '101',
      priority: 'Urgent',
      taskDescription: 'Button not responding',
      targetDate: '21/05/2024',
      status: 'Not started',
      reporter: 'Lori Gregory',
      comment: 'This needs to be fixed ASAP',
    },
    {
      key: '5',
      user: 'Bruno Fernandes',
      box: '101',
      priority: 'High',
      taskDescription: 'Refactor module',
      targetDate: '26/07/2024',
      status: 'Not started',
      repoter: 'Julius Grant',
      comment: 'This is not important at the moment',
    },
    {
      key: '6',
      user: 'Erin Steele',
      box: '101',
      priority: 'Medium',
      taskDescription: 'Check connectivity',
      targetDate: '27/11/2024',
      status: 'Finished',
      reporter: 'Mack Myers',
      comment: 'This is a comment 2',
    },
  ];

  if (isMobile) {
    return (
      <TransposedTable
        instance="maintenance"
        columns={columns}
        data={dataSource}
      />
    );
  }

  return (
    <StyledTable
      columns={columns}
      dataSource={dataSource}
      pagination={{
        position: ['bottomLeft'],
        showSizeChanger: false,
      }}
      onRow={(record) => {
        return {
          onClick: () => {
            if (canCreateTask) navigate(appUrls.maintenance.edit(record.key));
          }, // TO DO: change key here with id
        };
      }}
    />
  );
};
