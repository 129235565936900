import React from 'react';
import { Badge as AntBadge, BadgeProps } from 'antd';
import { colors, theme } from '../../../theme';

interface IProps extends BadgeProps {
  count?: React.ReactNode;
  type: 'menu';
  children?: React.ReactNode;
}

const Badge = ({ children, type, ...rest }: IProps) => {
  const defaultProps: BadgeProps = {
    dot: true,
    offset: [0, 25],
    style: {
      width: theme.spacing.small,
      height: theme.spacing.small,
      boxShadow: 'none',
    },
    color: colors.red.secondary,
  };

  return (
    <AntBadge
      {...defaultProps}
      {...rest}
      className="ant-badge ant-badge-status"
    >
      {children}
    </AntBadge>
  );
};

export default Badge;
