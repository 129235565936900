import './index.css';
import './App.less';

import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { GlobalStyles } from './theme/GlobalStyles';
import ENV from './utils/shared/environment';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Disable console logging for deployed builds
if (ENV.REACT_APP_ENABLE_LOGGING !== 'true') {
  // eslint-disable-next-line no-console
  console.log = () => {};
}
