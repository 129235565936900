export enum UserPermission {
  CREATE_SESSION = 'CREATE_SESSION',
  MANAGE_ASSETS = 'MANAGE_ASSETS',
  MANAGE_COMPANY_SESSIONS = 'MANAGE_COMPANY_SESSIONS',
  MANAGE_VESSELS = 'MANAGE_VESSELS',
  MANAGE_USERS = 'MANAGE_USERS',
  CREATE_TASK = 'CREATE_TASK',
  PLAQ_API = 'PLAQ_API',
  TAG_VALUES_API = 'TAG_VALUES_API',
  USE_DEV_PORTAL = 'USE_DEV_PORTAL',
}

export enum UserRole {
  ADMIN = 'ADMIN',
  COMPANY_OWNER = 'COMPANY_OWNER',
  COMPANY_EMPLOYEE = 'COMPANY_EMPLOYEE',
  NATURAL_PERSON = 'NATURAL_PERSON',
}

export interface ICurrentUser {
  email: string;
  invoiceEmail: string;
  firstName: string;
  id: string;
  lastName: string;
  permissions: UserPermission[];
  role: UserRole;
}

export interface IUser extends ICurrentUser {
  phoneNumber: string;
}

export interface IRegisterUserDto {
  firstName: string;
  lastName: string;
  email: string;
  invoiceEmail: string;
  phoneNumber: string;
  role: UserRole;
  companyName?: string;
  vatNr?: string;
  street?: string;
  city?: string;
  postalCode?: string;
  country?: string;
}

export interface IUserForCompany {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  permissions: UserPermission[];
}

export interface IUserProfile {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  companyName?: string;
  invoiceEmail: string;
  vatNr?: string;
  street?: string;
  city?: string;
  postalCode?: string;
  country?: string;
}

export interface IUserForCompanyUpdateDto {
  id: string;
  payload: IUserForCompany;
}

export interface IUserForCompanyDto extends IUserForCompany {
  email: string;
}

export enum UserFilter {
  'FIRST_NAME' = 'FIRST_NAME',
  'LAST_NAME' = 'LAST_NAME',
  'EMAIL' = 'EMAIL',
  'PHONE_NUMBER' = 'PHONE_NUMBER',
}

export enum UserPersonalInfo {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
}

export enum CompanyInfo {
  COMPANY_NAME = 'companyName',
  INVOICE_EMAIL = 'invoiceEmail',
  VAT_NR = 'vatNr',
  STREET = 'street',
  CITY = 'city',
  POSTAL_CODE = 'postalCode',
  COUNTRY = 'country',
}

// workaround to extend an enum with another enum, which is currently not possible in TS
export const UserFormValues = { ...UserPersonalInfo, ...UserPermission };
export const UserProfileValues = { ...UserPersonalInfo, ...CompanyInfo };
