import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { connectorStatusSelector } from '../../../../../../store/selectors/connector.selectors';
import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../../../../../store/selectors/tags.selectors';
import { StyledSpan } from '../../../../../../styled/text/StyledSpan';
import { ConnectorStatusValue } from '../../../../../../types/connector.types';
import { ConnectorTag } from '../../../../../../types/tag.types';
import { getConnectorStatusMessageColor } from '../../../../../../utils/connectors/getConnectorStatusMessageColor';

export const CONNECTOR_STATUS_MESSAGES: any = defineMessages({
  [ConnectorStatusValue.POINT_NOT_ACTIVE]: {
    id: 'connector_status_messages.pointNotActive',
    defaultMessage: 'Point not active',
  },
  [ConnectorStatusValue.WAITING_FOR_ACTIVATION]: {
    id: 'connector_status_messages.waitingForActivation',
    defaultMessage: 'Waiting for activation',
  },
  [ConnectorStatusValue.WAITING_FOR_ACTIVATION_CONNECTOR_IN]: {
    id: 'connector_status_messages.waitingForActivationConnectorIn',
    defaultMessage: 'Waiting for activation, connector plugged in',
  },
  [ConnectorStatusValue.CHECKING_ACTIVATION_PARAMETERS]: {
    id: 'connector_status_messages.checkingActivationParameters',
    defaultMessage: 'Checking activation parameters',
  },
  [ConnectorStatusValue.WEB_ACTIVATION_OK_WAITING_START]: {
    id: 'connector_status_messages.webActivationOkWaitingStart',
    defaultMessage: 'Web activation OK, waiting for start conditions',
  },
  [ConnectorStatusValue.FORCED_ACTIVATION_OK_WAITING_START]: {
    id: 'connector_status_messages.forcedActivationOkWaitingStart',
    defaultMessage: 'Forced activation OK, waiting for start conditions',
  },
  [ConnectorStatusValue.WEB_ACTIVATION_OK]: {
    id: 'connector_status_messages.webActivationOk',
    defaultMessage: 'Web activation OK',
  },
  [ConnectorStatusValue.FORCED_ACTIVATION_OK]: {
    id: 'connector_status_messages.forcedActivationOk',
    defaultMessage: 'Forced activation OK',
  },
  [ConnectorStatusValue.FLUSHING_CYCLE_ACTIVE]: {
    id: 'connector_status_messages.flushingCycleActive',
    defaultMessage: 'Flushing cycle active',
  },
  [ConnectorStatusValue.ACTIVE_CONSUMPTION]: {
    id: 'connector_status_messages.activeConsumption',
    defaultMessage: 'Active consumption',
  },
  [ConnectorStatusValue.CONSUMPTION_HALTED]: {
    id: 'connector_status_messages.consumptionHalted',
    defaultMessage: 'Consumption halted',
  },
  [ConnectorStatusValue.VESSEL_GROUND_TRIP]: {
    id: 'connector_status_messages.vesselGroundTrip',
    defaultMessage: 'Vessel caused ground fault trip',
  },
  [ConnectorStatusValue.VESSEL_OVERCURRENT_TRIP]: {
    id: 'connector_status_messages.vesselOvercurrentTrip',
    defaultMessage: 'Vessel caused overcurrent trip',
  },
  [ConnectorStatusValue.FINAL_VESSEL_FAULT]: {
    id: 'connector_status_messages.finalVesselFault',
    defaultMessage: 'Final vessel fault, no local ream disabled',
  },
  [ConnectorStatusValue.FAULT_CONNECTION_POINT]: {
    id: 'connector_status_messages.faultConnectionPoint',
    defaultMessage: 'Fault in the connection point, service necessary',
  },
  [ConnectorStatusValue.POINT_BLOCKED_MAX_CABINET_CURRENT]: {
    id: 'connector_status_messages.pointBlockedMaxCabinetCurrent',
    defaultMessage: 'Point blocked, max cabinet current reached',
  },
  [ConnectorStatusValue.POINT_BLOCKED_OTHER_CABINET]: {
    id: 'connector_status_messages.pointBlockedOtherCabinet',
    defaultMessage: 'Point blocked by other cabinet',
  },
  [ConnectorStatusValue.POINT_BLOCKED_EXTERNAL_INPUT]: {
    id: 'connector_status_messages.pointBlockedExternalInput',
    defaultMessage: 'Point blocked by external input',
  },
  [ConnectorStatusValue.POINT_BLOCKED]: {
    id: 'connector_status_messages.pointBlocked',
    defaultMessage: 'Point blocked',
  },
});

const CONNECTOR_STATUS_MAPPING: { [key: string]: ConnectorStatusValue } = {
  '1': ConnectorStatusValue.POINT_NOT_ACTIVE,
  '2': ConnectorStatusValue.WAITING_FOR_ACTIVATION,
  '3': ConnectorStatusValue.WAITING_FOR_ACTIVATION_CONNECTOR_IN,
  '4': ConnectorStatusValue.CHECKING_ACTIVATION_PARAMETERS,
  '5': ConnectorStatusValue.WEB_ACTIVATION_OK_WAITING_START,
  '6': ConnectorStatusValue.FORCED_ACTIVATION_OK_WAITING_START,
  '7': ConnectorStatusValue.WEB_ACTIVATION_OK,
  '8': ConnectorStatusValue.FORCED_ACTIVATION_OK,
  '9': ConnectorStatusValue.FLUSHING_CYCLE_ACTIVE,
  '10': ConnectorStatusValue.ACTIVE_CONSUMPTION,
  '11': ConnectorStatusValue.CONSUMPTION_HALTED,
  '13': ConnectorStatusValue.VESSEL_GROUND_TRIP,
  '14': ConnectorStatusValue.VESSEL_OVERCURRENT_TRIP,
  '15': ConnectorStatusValue.FINAL_VESSEL_FAULT,
  '16': ConnectorStatusValue.FAULT_CONNECTION_POINT,
  '17': ConnectorStatusValue.POINT_BLOCKED_MAX_CABINET_CURRENT,
  '18': ConnectorStatusValue.POINT_BLOCKED_OTHER_CABINET,
  '19': ConnectorStatusValue.POINT_BLOCKED_EXTERNAL_INPUT,
  '20': ConnectorStatusValue.POINT_BLOCKED,
};

interface IProps {
  boxId: string;
  connectorId: string;
  connector?: { [key: string]: any };
}

export const ConnectorStatusMessage = ({ boxId, connectorId, connector = {} }: IProps) => {
  const intl = useIntl();
  const connectorTechTagStatusValue = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      connectorId,
      ConnectorTag.V2_TECH_STATUS,
    ),
  )?.value;

  const connectorStatus = useSelector(
    connectorStatusSelector(boxId, connectorId),
  );

  const hasStatus =
    connectorTechTagStatusValue &&
    CONNECTOR_STATUS_MAPPING[connectorTechTagStatusValue];
    
  const tag = CONNECTOR_STATUS_MAPPING[connector.find((status: any) => status.tag === ConnectorTag.V2_TECH_STATUS)?.value]
  return (
    <>
      {hasStatus && (
        <StyledSpan color={getConnectorStatusMessageColor(connectorStatus)}>
          {CONNECTOR_STATUS_MESSAGES[String(tag)] ? intl.formatMessage(CONNECTOR_STATUS_MESSAGES[String(tag)]) : '-'}
        </StyledSpan>
      )}
    </>
  );
};
