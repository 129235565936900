export enum BoxDetailTabs {
  STATUS = 'STATUS',
  SESSIONS = 'SESSIONS',
  DEVELOPER = 'DEVELOPER',
  LOGS = 'LOGS',
  ALARMS = 'ALARMS',
}

export enum TaskTabs {
  EDIT = 'EDIT',
  LOGS = 'LOGS',
}
