import React from 'react';

import { ISession } from '../../../types/session.types';

import { MobileTableCard } from '../../../components/cards/MobileTableCard';

interface IProps {
  sessions: ISession[];
  columns: any;
  handleClick: (id: string) => void;
}

export const SessionsTableMobile = ({
  columns,
  handleClick,
  sessions,
}: IProps) => {
  return (
    <div>
      {sessions.map((session) => (
        <MobileTableCard
          key={session.id}
          columns={columns}
          data={session}
          handleEditClick={handleClick}
        />
      ))}
    </div>
  );
};
