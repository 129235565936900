import React from 'react';
import { useSelector } from 'react-redux';

import { AppLayout } from '../../AppLayout';
import {
  hasCurrentUserPermissionSelector,
  userIsLoggedInButNotRegisteredSelector,
} from '../../store/selectors/user.selectors';
import { UserPermission } from '../../types/user.types';
import { Dashboard } from '../Dashboard/Dashboard';
import { BoxesOverview } from '../Boxes/BoxesOverview/BoxesOverview';
import { SessionsOverview } from '../Sessions/SessionsOverview/SessionsOverview';
import { UsersOverview } from '../Users/UsersOverview/UsersOverview';
import { VesselsOverview } from '../Vessels/VesselsOverview/VesselsOverview';
import { RegisterTechmetrysPage } from './RegisterTechmetrysPage';
import { MaintenanceOverview } from '../Maintenance/MaintenanceOverview';

export const LandingsPage = () => {
  const canCreateSession = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.CREATE_SESSION),
  );
  const canManageAssets = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_ASSETS),
  );
  const canManageCompanySessions = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_COMPANY_SESSIONS),
  );
  const canManageVessels = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_VESSELS),
  );
  const canManageUsers = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_USERS),
  );
  const isNotRegistered = useSelector(userIsLoggedInButNotRegisteredSelector);

  const getDashboardOverview = () => canManageAssets && <Dashboard />;
  const getMaintenanceOverview = () =>
    canManageAssets && <MaintenanceOverview />;
  const getBoxesOverview = () => canManageAssets && <BoxesOverview />;
  const getSessionsOverview = () =>
    (canManageAssets || canManageCompanySessions || canCreateSession) && (
      <SessionsOverview />
    );
  const getVesselsOverview = () => canManageVessels && <VesselsOverview />;
  const getUsersOverview = () => canManageUsers && <UsersOverview />;
  const getRegisterFlow = () => isNotRegistered && <RegisterTechmetrysPage />;

  return (
    <>
      {getRegisterFlow() ||
        getDashboardOverview() ||
        getBoxesOverview() ||
        getSessionsOverview() ||
        getVesselsOverview() ||
        getMaintenanceOverview() ||
        getUsersOverview() || <AppLayout />}
    </>
  );
};
