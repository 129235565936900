import React from 'react';

export const ConnectorPluggedInIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        d="M18.9062 20.6818L15.8702 23.7178C14.699 24.889 12.7994 24.889 11.6282 23.7178L6.28221 18.3718C5.11101 17.2006 5.11101 15.301 6.28221 14.1298L9.31821 11.0938"
        stroke="#262626"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M8.10596 9.89453L20.106 21.8945"
        stroke="#262626"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.0938 9.3187L14.1298 6.2827C15.301 5.1115 17.2006 5.1115 18.3718 6.2827L23.7178 11.6287C24.889 12.7999 24.889 14.6995 23.7178 15.8707L20.6818 18.9067M8.02181 20.1787L1.7998 26.4007L8.02181 20.1787ZM9.82181 21.9787L3.5998 28.2007L9.82181 21.9787Z"
        stroke="#262626"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M21.894 20.1055L9.89404 8.10547"
        stroke="#262626"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M20.1782 8.02278L26.4002 1.80078M21.9782 9.82278L28.2002 3.60078L21.9782 9.82278Z"
        stroke="#262626"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
);
