import { Select } from 'antd';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useGetOperatorsQuery } from '../../../../store/api/companies.api';
import { operatorsSelector } from '../../../../store/selectors/operator.selectors';
import { StyledSelect } from '../../../../styled/form/StyledSelect';
import { theme } from '../../../../theme';

const MESSAGES = defineMessages({
  selectOperator: {
    id: 'select_operator_dropdown.selectCompanyLocation',
    defaultMessage: 'Select operator',
  },
});

const { Option } = Select;

interface IProps {
  onSelectOperator: (operatorId: string) => void;
  selectedOperatorId?: string;
}

export const SelectOperatorDropdown = ({
  onSelectOperator,
  selectedOperatorId,
}: IProps) => {
  const intl = useIntl();

  useGetOperatorsQuery();

  const operators = useSelector(operatorsSelector);

  useEffect(() => {
    if (operators?.length === 1) {
      onSelectOperator(operators[0].id);
    }
  }, [operators]);

  return (
    <StyledSelect
      value={selectedOperatorId}
      filterOption={(input, option) =>
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        option!.children.toLowerCase().includes(input.toLowerCase())
      }
      onSelect={(operatorId: unknown) => onSelectOperator(operatorId as string)}
      showSearch
      placeholder={intl.formatMessage(MESSAGES.selectOperator)}
      mb={theme.spacing.small}
      data-testid="select-operator"
    >
      {operators?.map((operator) => (
        <Option key={operator.id}>{operator.name}</Option>
      ))}
    </StyledSelect>
  );
};
