import styled from '@emotion/styled';
import { DatePicker } from 'antd';
import moment from 'moment';
import { layout, LayoutProps, space, SpaceProps, variant } from 'styled-system';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { colors, theme } from '../../theme';

interface IProps {
  fullWidth?: boolean;
  fontWeight?: string;
  height?: string;
}

// Extracting BaseDatePickerProps from DatePicker props
type BaseDatePickerProps = PickerProps<moment.Moment>;

// Creating a new type merging BaseDatePickerProps with IProps
type StyledDateProps = SpaceProps & LayoutProps & IProps & BaseDatePickerProps;

export const StyledDate = styled(DatePicker)<StyledDateProps>(
  ({ fontWeight, fullWidth, height }) => ({
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.borderRadius.medium,
    justifyContent: 'center',
    cursor: 'pointer',
    fontSize: theme.fontSizes.medium,
    width: fullWidth ? '100%' : 'auto',
    height: height || 'auto',
    fontWeight: fontWeight || 'auto',
  }),
  space,
  layout,
);