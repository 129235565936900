import styled from 'styled-components';

import { theme } from '../../../theme';

export const LeftSideContainer = styled.div`
  display: flex;
  align-items: center;

  gap: ${theme.spacing.small};
`

export const IconContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;


  width: 24px;
  height: 24px;
`

export const IconContainerScaled = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;


  width: 8px;
  height: 24px;

  transform: scale(1.5);
`

export const LeftSideContainerMargin = styled.div`
  display: flex;
  align-items: center;

  gap: ${theme.spacing.small};

  margin-right: 8px;
`
