import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Modal } from '../../../components/modals/Modal';
import { NewSessionForm } from './NewSession/NewSessionForm';

const MESSAGES = defineMessages({
  newSession: {
    id: 'session_form_with_modal',
    defaultMessage: 'New session',
  },
});

interface IProps {
  onCancelForm: () => void;
  showModal: boolean;
}

export const SessionFormWithModal = ({ onCancelForm, showModal }: IProps) => {
  const intl = useIntl();

  return (
    <Modal
      title={intl.formatMessage(MESSAGES.newSession)}
      showModal={showModal}
    >
      <NewSessionForm onCancelForm={onCancelForm} />
    </Modal>
  );
};
