import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { HeadingWithBackButton } from '../../../components/headings/HeadingWithBackButton';
import { useGetSessionByIdQuery } from '../../../store/api/sessions.api';
import {
  sessionDetailSelector,
  sessionHasBeenCancelled,
} from '../../../store/selectors/session.selectors';
import { FlexRow } from '../../../styled/flex/FlexRow';
import { SessionDeletedModal } from './Modal/SessionDeletedModal';
import { SessionDetail } from './SessionDetail';

const MESSAGES = defineMessages({
  heading: {
    id: 'session_detail.heading',
    defaultMessage: 'Session detail',
  },
});

export const SessionDetailOverview = () => {
  const intl = useIntl();
  const { sessionId } = useParams();

  if (!sessionId) return null;

  useGetSessionByIdQuery(sessionId);

  const sessionDetail = useSelector(sessionDetailSelector(sessionId));

  const isDeleted = useSelector(sessionHasBeenCancelled(sessionId));

  return (
    <>
      {isDeleted && <SessionDeletedModal />}
      {sessionDetail && (
        <FlexRow justifyContent="space-between">
          <HeadingWithBackButton
            heading={intl.formatMessage(MESSAGES.heading)}
            variant="h4"
          />
        </FlexRow>
      )}
      {sessionDetail && <SessionDetail sessionDetail={sessionDetail} />}
    </>
  );
};
