import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { IAssetStatusIconProps } from '../../../../types/box.types';
import { getBoxStatusIconColor } from '../../../../utils/boxes/getBoxStatusIconColor';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const WaterBoxIcon = ({
  boxTechStatus,
  size,
  ...space
}: IAssetStatusIconProps) => (
  <StyledBox {...space} height={getIconSize(size).height}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-water"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 2.40002H27V6.40002H25.8V29.2H3.2V6.40002H2V2.40002ZM3 3.40002V5.40002H26V3.40002H3ZM4.2 7.00002V28.2H24.8V7.00002H4.2Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2855 9.55713L13.8998 9.87537L13.5142 9.55713C13.6091 9.44203 13.7506 9.37537 13.8998 9.37537C14.049 9.37537 14.1905 9.44203 14.2855 9.55713ZM13.8998 10.6771C13.7783 10.8325 13.6323 11.0215 13.4679 11.239C12.9744 11.8919 12.3173 12.7978 11.6612 13.8117C11.004 14.8271 10.3537 15.9418 9.86936 17.0131C9.38003 18.0954 9.08301 19.0843 9.08301 19.8629C9.08301 22.5237 11.239 24.68 13.8998 24.68C16.5606 24.68 18.7166 22.5237 18.7166 19.8632C18.7166 19.0846 18.4196 18.0955 17.9303 17.0132C17.4459 15.9418 16.7956 14.8272 16.1384 13.8117C15.4823 12.7978 14.8252 11.8919 14.3317 11.239C14.1673 11.0215 14.0213 10.8325 13.8998 10.6771ZM18.8415 16.6012C19.349 17.7239 19.7166 18.8695 19.7166 19.8632C19.7166 23.076 17.1129 25.68 13.8998 25.68C10.6867 25.68 8.08301 23.0759 8.08301 19.8629C8.08301 18.8693 8.45059 17.7237 8.95816 16.6011C9.4707 15.4674 10.1496 14.3067 10.8216 13.2683C11.4947 12.2284 12.1668 11.302 12.6702 10.636C12.9221 10.3027 13.1322 10.0341 13.2798 9.84824C13.3535 9.75531 13.4117 9.68308 13.4516 9.63381L13.4975 9.5774L13.5096 9.56264L13.5142 9.55713C13.5142 9.55703 13.5142 9.55713 13.8998 9.87537C14.2855 9.55713 14.2854 9.55703 14.2855 9.55713L14.2868 9.55872L14.29 9.56264L14.3021 9.5774L14.348 9.63381C14.3879 9.68308 14.4461 9.75532 14.5198 9.84824C14.6674 10.0341 14.8775 10.3027 15.1294 10.636C15.6328 11.302 16.3049 12.2284 16.978 13.2684C17.65 14.3068 18.3289 15.4675 18.8415 16.6012Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.017 12.9863C14.2353 13.1554 14.2752 13.4695 14.106 13.6878L14.1045 13.6898L14.0986 13.6974L14.0743 13.7294C14.0526 13.7578 14.0205 13.8005 13.9793 13.8559C13.897 13.9668 13.7787 14.1288 13.6364 14.331C13.3516 14.7359 12.9725 15.2998 12.5941 15.9366C12.2149 16.5748 11.8416 17.2779 11.5644 17.9622C11.2841 18.654 11.1177 19.2891 11.1177 19.802C11.1177 21.2165 11.7482 21.9746 12.3584 22.3972C12.6728 22.6149 12.9901 22.7481 13.2299 22.8264C13.3491 22.8654 13.4471 22.8901 13.513 22.9047C13.5459 22.912 13.5706 22.9168 13.5857 22.9195L13.601 22.9222C13.6023 22.9224 13.6029 22.9225 13.6029 22.9225L13.601 22.9222C13.6012 22.9222 13.6008 22.9221 13.601 22.9222L13.5297 23.4171C13.4585 23.912 13.4583 23.9119 13.458 23.9119L13.4559 23.9116L13.4522 23.911L13.4416 23.9094C13.4332 23.908 13.422 23.9061 13.4082 23.9037C13.3807 23.8987 13.3429 23.8913 13.2961 23.8809C13.2028 23.8602 13.0732 23.8273 12.9194 23.777C12.6133 23.677 12.2026 23.5057 11.789 23.2193C10.9432 22.6335 10.1177 21.584 10.1177 19.802C10.1177 19.1009 10.3379 18.3264 10.6376 17.5867C10.9403 16.8395 11.3403 16.0892 11.7344 15.4259C12.1293 14.7611 12.5234 14.1751 12.8185 13.7556C12.9663 13.5456 13.0896 13.3767 13.1764 13.2598C13.2199 13.2013 13.2542 13.1558 13.2779 13.1246L13.3052 13.0887L13.3126 13.0792L13.3154 13.0755C13.3155 13.0754 13.3155 13.0753 13.7108 13.3816L13.3154 13.0755C13.4845 12.8572 13.7987 12.8172 14.017 12.9863ZM13.5297 23.4171L13.458 23.9119C13.7313 23.9512 13.9853 23.7615 14.0246 23.4882C14.0639 23.215 13.8741 22.9617 13.601 22.9222C13.6008 22.9221 13.6008 22.9222 13.5297 23.4171Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3999 15H24.8999V14H29.3999V18H24.8999V17H28.3999V15Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.8999 13C29.176 13 29.3999 13.2239 29.3999 13.5L29.3999 18.5C29.3999 18.7761 29.176 19 28.8999 19C28.6238 19 28.3999 18.7761 28.3999 18.5L28.3999 13.5C28.3999 13.2239 28.6238 13 28.8999 13Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
    </svg>
  </StyledBox>
);
