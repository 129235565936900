import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { colors } from '../../../../theme';
import { IIconProps } from '../../../../types/icon.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const BoxConnectionEthernetIcon = ({ size, ...space }: IIconProps) => (
  <StyledBox {...space} height={getIconSize(size).height}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-connection-ethernet"
    >
      <path
        d="M27 24.6C27 25.9254 25.9254 27 24.6 27H5.4C4.0746 27 3 25.9254 3 24.6V5.4C3 4.0746 4.0746 3 5.4 3H24.6C25.9254 3 27 4.0746 27 5.4V24.6Z"
        stroke={colors.black.primary}
        strokeMiterlimit="10"
      />
      <path
        d="M22.7997 8.40015H7.19971V18.0001H9.59971V19.8001H11.9997V21.6001H17.9997V19.8001H20.3997V18.0001H22.7997V8.40015Z"
        stroke="#262626"
        strokeMiterlimit="10"
      />
      <path
        d="M9 10.2002H10.2V14.4002H9V10.2002ZM10.8 10.2002H12V14.4002H10.8V10.2002ZM12.6 10.2002H13.8V14.4002H12.6V10.2002ZM14.4 10.2002H15.6V14.4002H14.4V10.2002ZM16.2 10.2002H17.4V14.4002H16.2V10.2002ZM18 10.2002H19.2V14.4002H18V10.2002ZM19.8 10.2002H21V14.4002H19.8V10.2002Z"
        fill={colors.black.primary}
      />
      <rect x="16" y="16" width="14" height="14" rx="7" fill="white" />
      <path
        d="M23 29C19.7 29 17 26.3 17 23C17 19.7 19.7 17 23 17C26.3 17 29 19.7 29 23C29 26.3 26.3 29 23 29ZM23 18.2C20.36 18.2 18.2 20.36 18.2 23C18.2 25.64 20.36 27.8 23 27.8C25.64 27.8 27.8 25.64 27.8 23C27.8 20.36 25.64 18.2 23 18.2ZM26.48 21.02L25.58 20.24L22.34 24.02L20.36 22.52L19.64 23.48L22.46 25.64L26.48 21.02Z"
        fill={colors.green.secondary}
      />
    </svg>
  </StyledBox>
);
