import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { IIconProps } from '../../../../types/icon.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const BoxNoConnectionCellularIcon = ({ size, ...space }: IIconProps) => (
  <StyledBox {...space} height={getIconSize(size).height}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-no-connection-cellular"
    >
      <g clipPath="url(#clip0_4529_30785)">
        <g clipPath="url(#clip1_4529_30785)">
          <path
            d="M15 12.6C14.3635 12.6 13.753 12.8528 13.3029 13.3029C12.8528 13.753 12.6 14.3635 12.6 15C12.6 15.6365 12.8528 16.2469 13.3029 16.697C13.753 17.1471 14.3635 17.4 15 17.4C15.6365 17.4 16.2469 17.1471 16.697 16.697C17.1471 16.2469 17.4 15.6365 17.4 15C17.4 14.3635 17.1471 13.753 16.697 13.3029C16.2469 12.8528 15.6365 12.6 15 12.6Z"
            stroke="#CF6363"
            strokeMiterlimit="10"
          />
          <path
            d="M15 28.8V16.8M15 12.6C14.3635 12.6 13.753 12.8528 13.3029 13.3029C12.8528 13.753 12.6 14.3635 12.6 15C12.6 15.6365 12.8528 16.2469 13.3029 16.697C13.753 17.1471 14.3635 17.4 15 17.4C15.6365 17.4 16.2469 17.1471 16.697 16.697C17.1471 16.2469 17.4 15.6365 17.4 15C17.4 14.3635 17.1471 13.753 16.697 13.3029C16.2469 12.8528 15.6365 12.6 15 12.6Z"
            stroke="#CF6363"
            strokeMiterlimit="10"
          />
          <path
            d="M20.2632 27.1062C24.9336 25.0728 28.2 20.4186 28.2 15C28.2 7.70999 22.29 1.79999 15 1.79999C7.71005 1.79999 1.80005 7.70999 1.80005 15C1.80005 20.4186 5.06645 25.0728 9.73685 27.1062"
            stroke="#CF6363"
            strokeMiterlimit="10"
          />
          <path
            d="M18.8268 23.802C22.2234 22.323 24.6 18.9408 24.6 15C24.6 9.69782 20.3022 5.40002 15 5.40002C9.69782 5.40002 5.40002 9.69782 5.40002 15C5.40002 18.9408 7.77662 22.323 11.1732 23.802"
            stroke="#CF6363"
            strokeMiterlimit="10"
          />
          <path
            d="M17.3916 20.5014C19.5144 19.5768 21 17.463 21 15C21 11.6862 18.3138 9 15 9C11.6862 9 9 11.6862 9 15C9 17.463 10.4856 19.5768 12.6084 20.5014"
            stroke="#CF6363"
            strokeMiterlimit="10"
          />
          <rect x="16" y="16" width="14" height="14" rx="7" fill="white" />
          <path
            d="M25.52 21.32L23.84 23L25.52 24.68C25.76 24.92 25.76 25.28 25.52 25.52C25.4 25.64 25.22 25.7 25.1 25.7C24.98 25.7 24.8 25.64 24.68 25.52L23 23.84L21.32 25.52C21.2 25.64 21.02 25.7 20.9 25.7C20.78 25.7 20.6 25.64 20.48 25.52C20.24 25.28 20.24 24.92 20.48 24.68L22.16 23L20.48 21.32C20.24 21.08 20.24 20.72 20.48 20.48C20.72 20.24 21.08 20.24 21.32 20.48L23 22.16L24.68 20.48C24.92 20.24 25.28 20.24 25.52 20.48C25.76 20.72 25.76 21.08 25.52 21.32ZM29 23C29 26.3 26.3 29 23 29C19.7 29 17 26.3 17 23C17 19.7 19.7 17 23 17C26.3 17 29 19.7 29 23ZM27.8 23C27.8 20.36 25.64 18.2 23 18.2C20.36 18.2 18.2 20.36 18.2 23C18.2 25.64 20.36 27.8 23 27.8C25.64 27.8 27.8 25.64 27.8 23Z"
            fill="#CF6363"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_4529_30785">
          <rect width="30" height="30" fill="white" />
        </clipPath>
        <clipPath id="clip1_4529_30785">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </StyledBox>
);
