import React from 'react';

import { useGetAuthorizedBoxByIdQuery } from '../../../store/api/boxes.api';

interface IProps {
  boxId: string;
}

export const SaveBoxToRedux = ({ boxId }: IProps) => {
  useGetAuthorizedBoxByIdQuery(boxId);

  return (<></>)
};
