import { Radio } from 'antd';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { sessionFilterByKeySelector } from '../../../store/selectors/filters.selectors';
import {
  resetSessionFilters,
  setSessionFilter,
} from '../../../store/slices/filters.slice';
import { StyledBox } from '../../../styled/boxes/StyledBox';
import { StyledRadioButton } from '../../../styled/buttons/StyledRadioButton';
import { theme } from '../../../theme';
import { SessionFilterKey } from '../../../types/filters.types';
import { RequestSessionFor } from '../../../types/session.types';
import { UserPermission } from '../../../types/user.types';

const MESSAGES = defineMessages({
  [UserPermission.CREATE_SESSION]: {
    id: 'session_perspective_switch.createSession',
    defaultMessage: 'User',
  },
  [UserPermission.MANAGE_ASSETS]: {
    id: 'session_perspective_switch.manageAssets',
    defaultMessage: 'Managed connectors',
  },
  [UserPermission.MANAGE_COMPANY_SESSIONS]: {
    id: 'session_perspective_switch.manageCompanySessions',
    defaultMessage: 'Company',
  },
});

interface IProps {
  canCreateSession?: boolean;
  canManageAssets?: boolean;
  canManageCompanySessions?: boolean;
}

export const SessionPerspectiveSwitch = ({
  canCreateSession,
  canManageAssets,
  canManageCompanySessions,
}: IProps) => {
  const intl = useIntl();

  const dispatch = useDispatch();

  const requestSessionFor = useSelector(
    sessionFilterByKeySelector(SessionFilterKey.for),
  ) as RequestSessionFor | undefined;

  const handleChange = (value: RequestSessionFor) => {
    // filters should be cleared when a user selects a new endpoint
    dispatch(resetSessionFilters());

    dispatch(
      setSessionFilter({
        key: SessionFilterKey.for,
        value,
      }),
    );
  };

  return (
    <StyledBox my={theme.spacing.xxLarge}>
      {requestSessionFor && (
        <Radio.Group buttonStyle="solid" value={requestSessionFor}>
          {canManageAssets && (
            <StyledRadioButton
              value={RequestSessionFor.MANAGED_CONNECTORS}
              onChange={(ev) => handleChange(ev.target.value)}
              data-testid="radio-managed-connector-sessions"
            >
              {intl.formatMessage(MESSAGES[UserPermission.MANAGE_ASSETS])}
            </StyledRadioButton>
          )}
          {canManageCompanySessions && (
            <StyledRadioButton
              value={RequestSessionFor.COMPANY}
              onChange={(ev) => handleChange(ev.target.value)}
              data-testid="radio-company-sessions"
            >
              {intl.formatMessage(
                MESSAGES[UserPermission.MANAGE_COMPANY_SESSIONS],
              )}
            </StyledRadioButton>
          )}
          {canCreateSession && (
            <StyledRadioButton
              value={RequestSessionFor.USER}
              onChange={(ev) => handleChange(ev.target.value)}
              data-testid="radio-user-sessions"
            >
              {intl.formatMessage(MESSAGES[UserPermission.CREATE_SESSION])}
            </StyledRadioButton>
          )}
        </Radio.Group>
      )}
    </StyledBox>
  );
};
