/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditOutlined } from '@ant-design/icons';
import { Checkbox, Pagination } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { appUrls } from '../../../config/url.constants';
import { currentUserSelector } from '../../../store/selectors/user.selectors';
import { StyledButton } from '../../../styled/buttons/StyledButton';
import { StyledTable } from '../../../styled/table/StyledTable';
import { ICurrentUser, IUser, UserRole } from '../../../types/user.types';
import { theme } from '../../../theme';
import { UsersTableMobile } from './UsersTableMobile';

enum UserDataType {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  ROLE = 'role',
}

const MESSAGES = defineMessages({
  editUser: {
    id: 'users_table.editUser',
    defaultMessage: 'Edit user',
  },
  [UserDataType.EMAIL]: {
    id: 'users_table.email',
    defaultMessage: 'Email',
  },
  [UserDataType.FIRST_NAME]: {
    id: 'users_table.firstName',
    defaultMessage: 'First Name',
  },
  [UserDataType.LAST_NAME]: {
    id: 'users_table.lastName',
    defaultMessage: 'Last Name',
  },
  [UserDataType.PHONE_NUMBER]: {
    id: 'users_table.phoneNumber',
    defaultMessage: 'Phone Number',
  },
});

interface UsersTableProps {
  canManageUsers: boolean;
  onSelectUser: (userId: string) => void;
  selectedUserIds: string[];
  users: IUser[];
}

export const UsersTable = ({
  canManageUsers,
  onSelectUser,
  selectedUserIds,
  users,
}: UsersTableProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 1024;

  const currentUser = useSelector(currentUserSelector);

  const handleEditUserClick = (userToBeEdited: string) => {
    navigate(appUrls.users.edit(userToBeEdited));
  };

  const columns: ColumnsType<IUser> = [
    {
      title: '',
      dataIndex: UserDataType.ROLE,
      key: UserDataType.ROLE,
      className: 'button',
      render: (role: UserRole, user: IUser) => {
        const isDisabled =
          role === UserRole.COMPANY_OWNER || user.id === currentUser?.id;
        return (
          <Checkbox
            disabled={isDisabled}
            onClick={(ev) => {
              ev.stopPropagation();
              onSelectUser(user.id);
            }}
          />
        );
      },
    },
    {
      title: intl.formatMessage(MESSAGES[UserDataType.LAST_NAME]),
      dataIndex: UserDataType.LAST_NAME,
      key: UserDataType.LAST_NAME,
      sorter: (a: ICurrentUser, b: ICurrentUser) =>
        a.lastName.localeCompare(b.lastName),
    },
    {
      title: intl.formatMessage(MESSAGES[UserDataType.FIRST_NAME]),
      dataIndex: UserDataType.FIRST_NAME,
      key: UserDataType.FIRST_NAME,
      sorter: (a: ICurrentUser, b: ICurrentUser) =>
        a.firstName.localeCompare(b.firstName),
    },
    {
      title: intl.formatMessage(MESSAGES[UserDataType.EMAIL]),
      dataIndex: UserDataType.EMAIL,
      key: UserDataType.EMAIL,
      className: 'fullWidth',
      sorter: (a: ICurrentUser, b: ICurrentUser) =>
        a.email.localeCompare(b.email),
    },
    {
      title: intl.formatMessage(MESSAGES[UserDataType.PHONE_NUMBER]),
      dataIndex: UserDataType.PHONE_NUMBER,
      key: UserDataType.PHONE_NUMBER,
      sorter: (a: IUser, b: IUser) =>
        a.phoneNumber.localeCompare(b.phoneNumber),
    },
    {
      title: '',
      dataIndex: '',
      key: 'edit',
      className: 'button',
      render: (record) => {
        if (canManageUsers) {
          return (
            <StyledButton
              onClick={() => handleEditUserClick(record.id)}
              variant="primary"
              ml="auto"
            >
              <EditOutlined />
              {intl.formatMessage(MESSAGES.editUser)}
            </StyledButton>
          );
        }
        return null;
      },
    },
  ];
  const generateRowKey = (userInfo: IUser) => userInfo.email;

  if (isMobile) {
    return (
      <UsersTableMobile
        columns={columns.slice(1)}
        users={users}
        selectedUserIds={selectedUserIds}
        handleEditClick={(record) => onSelectUser(record)}
      />
    );
  }

  return (
    <>
      <StyledTable
        columns={columns}
        dataSource={users}
        pagination={false}
        rowKey={(record: IUser) => {
          const userInfo = record as IUser;
          return generateRowKey(userInfo);
        }}
        onRow={(record) => {
          return {
            onClick: () => handleEditUserClick(record.id),
          };
        }}
      />
      <Pagination
        style={{ margin: `${theme.spacing.medium} 0` }}
        size={isMobile ? 'small' : 'default'}
      />
    </>
  );
};
