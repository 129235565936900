import { useEffect, useState } from 'react';
import { theme } from '../theme';

const { breakpoints } = theme;

const useResponsive = () => {
  const [width, setWidth] = useState<number>(0);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    handleResize();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const isSmallMobile = width < breakpoints.xs;
  const isMobile = width < breakpoints.sm;
  const isTablet = width < breakpoints.md;
  const isDesktop = width < breakpoints.lg;
  const isLargeDesktop = width < breakpoints.xl;
  const isExtraLargeDesktop = width < breakpoints.xxl;

  return {
    isMobile,
    isTablet,
    isDesktop,
    isLargeDesktop,
    isExtraLargeDesktop,
    isSmallMobile,
  };
};

export default useResponsive;
