import { UserRole } from '../../types/user.types';

export const formatUserRole = (userRole: UserRole) => {
  switch (userRole) {
    case UserRole.ADMIN:
      return 'Admin';
    case UserRole.COMPANY_OWNER:
      return 'Company Owner';
    case UserRole.COMPANY_EMPLOYEE:
      return 'Company Employee';
    case UserRole.NATURAL_PERSON:
      return 'Natural Person';
    default:
      return '';
  }
};
