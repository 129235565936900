import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { IIconProps } from '../../../../types/icon.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const ConsumptionMeterIcon = ({ size, ...space }: IIconProps) => (
  <StyledBox {...space} height={getIconSize(size).height}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 28 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-consumption"
    >
      <path
        d="M14 12.7998C13.6818 12.7998 13.3766 12.9262 13.1515 13.1513C12.9265 13.3763 12.8 13.6815 12.8 13.9998C12.8 14.3181 12.9265 14.6233 13.1515 14.8483C13.3766 15.0734 13.6818 15.1998 14 15.1998C14.3183 15.1998 14.6235 15.0734 14.8486 14.8483C15.0736 14.6233 15.2 14.3181 15.2 13.9998C15.2 13.6815 15.0736 13.3763 14.8486 13.1513C14.6235 12.9262 14.3183 12.7998 14 12.7998Z"
        stroke="#262626"
        strokeMiterlimit="10"
      />
      <path
        d="M15.0151 13.3999L20.9143 9.91211"
        stroke="#262626"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.75785 5.26201L7.21225 2.6856M14 0.799805V3.7998V0.799805ZM0.800049 13.9998H3.80005H0.800049ZM27.2 13.9998H24.8H27.2ZM27.2 13.9998H24.2H27.2ZM10.4 19.3998H17.6H10.4ZM20.2856 2.3994L18.86 5.0298L20.2856 2.3994ZM22.7378 8.75761L25.3142 7.21201L22.7378 8.75761ZM2.39965 7.71421C2.39965 7.71421 3.50425 8.3124 5.03065 9.1398L2.39965 7.71421Z"
        stroke="#262626"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.8206 19.1064L25.4 20.5998L25.3994 20.6076C26.5964 18.6234 27.2 16.4862 27.2 13.9998C27.2 6.7098 21.29 0.799805 14 0.799805C6.71005 0.799805 0.800049 6.7098 0.800049 13.9998C0.800049 16.4862 1.40365 18.6234 2.60065 20.6076L2.60005 20.5998L5.17945 19.1064"
        stroke="#262626"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </StyledBox>
);
