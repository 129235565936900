import React from 'react';

export const ConnectorPluggedOutIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7998 6.60084L17.1238 3.27684C18.295 2.10564 20.1946 2.10564 21.3658 3.27684L26.7118 8.62284C27.883 9.79404 27.883 11.6936 26.7118 12.8648L23.3998 16.2008"
      stroke="#262626"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M24.5996 17.4004L12.5996 5.40039"
      stroke="#262626"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.1999 23.4008L12.8759 26.7248C11.7047 27.896 9.80506 27.896 8.63386 26.7248L3.27586 21.3728C2.10466 20.2016 2.10466 18.302 3.27586 17.1308L6.59986 13.8008M24.9779 6.82278L28.1999 3.60078L24.9779 6.82278ZM23.1779 5.02278L26.3999 1.80078L23.1779 5.02278ZM17.1779 18.2228L21.3239 14.0768L17.1779 18.2228ZM11.7779 12.8228L15.9239 8.67678L11.7779 12.8228Z"
      stroke="#262626"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M5.40039 12.5996L17.4004 24.5996"
      stroke="#262626"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M6.8218 24.9777L3.5998 28.1997M5.0218 23.1777L1.7998 26.3997L5.0218 23.1777Z"
      stroke="#262626"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
);
