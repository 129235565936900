/* eslint-disable */
import React from 'react';
import createIcon from '../utils/icons/createIcon';

export default createIcon(
  <>
    <rect x="2.5" y="1.5" width="19" height="16" rx="3.5" stroke="black" />
    <path
      d="M9.83789 11.9717V13H6.43213V11.9717H9.83789ZM6.86279 6.60156V13H5.54443V6.60156H6.86279ZM9.39404 9.20752V10.2095H6.43213V9.20752H9.39404ZM9.8335 6.60156V7.63428H6.43213V6.60156H9.8335ZM15.8145 6.60156V13H14.4961L11.9253 8.71094V13H10.6069V6.60156H11.9253L14.5005 10.895V6.60156H15.8145ZM18.3633 6.60156V13H17.0493V6.60156H18.3633Z"
      fill="#262626"
    />
  </>,
  '0 0 24 19',
  'EniIcon',
);
