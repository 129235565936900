import styled from '@emotion/styled';
import { Button } from 'antd';
import React, { useState } from 'react';

import LightningBolt from '../../icons/LightningBolt';
import WaterDrop from '../../icons/WaterDrop';
import { FlexCentered } from '../../styled/flex/FlexCentered';
import { FlexRow } from '../../styled/flex/FlexRow';
import { colors, theme } from '../../theme';
import { ResourceType } from '../../types/connector.types';

interface IStyledProps {
  selected: boolean;
}

const StyledResourceButton = styled(Button)(({ selected }: IStyledProps) => ({
  borderColor: colors.green.primary,
  borderRadius: theme.spacing.xxSmall,
  color: selected ? colors.white.primary : colors.green.primary,
  background: selected ? colors.green.primary : colors.white.primary,
  width: '150px',
  height: '50px',
  marginInline: theme.spacing.small,
  ':hover, :focus': {
    color: colors.white.primary,
    background: colors.green.primary,
    borderColor: colors.green.primary,
  },
}));

interface IProps {
  resourceType: ResourceType;
  isDisabled: boolean;
  selected: boolean;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick: (...args: any) => void;
}

export const ResourceButtonWithIcon = ({
  isDisabled,
  label,
  onClick,
  resourceType,
  selected,
}: IProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const getColor = () => {
    if (isDisabled) {
      return colors.lightGrey.secondary;
    }
    if (isHovered || selected) {
      return colors.white.primary;
    }
    return colors.green.primary;
  };

  return (
    <StyledResourceButton
      disabled={isDisabled}
      onClick={onClick}
      selected={selected}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <FlexRow alignItems="center" justifyContent="space-evenly">
        <FlexCentered
          border={`1px solid ${getColor()}`}
          borderRadius="50%"
          width="25px"
          height="25px"
          data-testid={`btn-resource-${resourceType}`}
        >
          {resourceType === ResourceType.POWER ? (
            <LightningBolt fill={getColor()} width="10px" height="20px" />
          ) : (
            <WaterDrop fill={getColor()} width="15px" height="18px" />
          )}
        </FlexCentered>
        {label}
      </FlexRow>
    </StyledResourceButton>
  );
};
