import { FilterOutlined } from '@ant-design/icons';
import { Form, Select, Radio } from 'antd';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { boxFilterByKeySelector } from '../../../../store/selectors/filters.selectors';
import { setAssetFilter } from '../../../../store/slices/filters.slice';
import { updateRequestedTablePage } from '../../../../store/slices/tableMetaInfo.slice';
import { StyledSearch } from '../../../../styled/form/StyledSearch';
import { StyledRadioButton } from '../../../../styled/buttons/StyledRadioButton';
import { theme } from '../../../../theme';
import { FilterKey, AssetFilterKey } from '../../../../types/filters.types';
import { BoxesFilter, RequestBoxFor } from '../../../../types/box.types';
import { Table } from '../../../../types/table.types';
import { StyledCheckbox } from '../../../../styled/form/StyledCheckbox';
import { StyledButton } from '../../../../styled/buttons/StyledButton';
import { StyledSelect } from '../../../../styled/form/StyledSelect';
import { Modal } from '../../../../components/modals/Modal';

const MESSAGES = defineMessages({
  search: {
    id: 'boxes_table_filters.search',
    defaultMessage: 'Search',
  },
  boxDropdownPlaceholder: {
    id: 'boxes_table_filters.boxDropdownPlaceholder',
    defaultMessage: 'Sort',
  },
  errors: {
    id: 'boxes_table_filters.errors',
    defaultMessage: 'Errors',
  },
  warnings: {
    id: 'boxes_table_filters.warnings',
    defaultMessage: 'Warnings',
  },
  favorites: {
    id: 'boxes_table_filters.favorites',
    defaultMessage: 'Favorites',
  },
  all: {
    id: 'boxes_table_filters.all',
    defaultMessage: 'All',
  },
  power: {
    id: 'boxes_table_filters.power',
    defaultMessage: 'Power',
  },
  water: {
    id: 'boxes_table_filters.water',
    defaultMessage: 'Water',
  },
  [BoxesFilter.ASSET_ID]: {
    id: 'boxes_table_filters.asset_id',
    defaultMessage: 'Asset:',
  },
  [BoxesFilter.TYPE_FILTER]: {
    id: 'boxes_table_filters.for',
    defaultMessage: 'Type filter:',
  },
  [BoxesFilter.LOCATION]: {
    id: 'boxes_table_filters.location',
    defaultMessage: 'Location:',
  },
  [BoxesFilter.FILTER]: {
    id: 'boxes_table_filters.filter',
    defaultMessage: 'Filter:',
  },
});

type FilterContainerProps = {
  wrap?: boolean;
};

const FilterContainer = styled.div<FilterContainerProps>`
  width: 100%;
  margin-right: 0;

  display: flex;
  align-items: center;

  flex-wrap: ${(props) => (!props.wrap ? 'wrap' : 'nowrap')};

  margin-bottom: ${theme.spacing.xSmall};
}`;

const FilterTitle = styled.h4`
  font-size: ${theme.fontSizes.small};

  margin-bottom: 0;
  margin-right: ${theme.spacing.small};
`;

export const BoxesFilterMobile = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  
  const [modalOpen, setModalOpen] = useState(false);
  const [names, setNames] = useState<string[]>([]);

  const handleCheckboxChange = (name: string) => {
    setNames(
      names.includes(name)
        ? names.filter((item) => item !== name)
        : [...names, name],
    );
  };

  const assetIdFilterValue = useSelector(
    boxFilterByKeySelector(AssetFilterKey.assetId),
  );

  const sortFilterValue = useSelector(
    boxFilterByKeySelector(AssetFilterKey.location),
  );

  const handleChangeFilter = (
    key: FilterKey,
    value: string | number,
    filterArray?: string[],
  ) => {
    dispatch(
      updateRequestedTablePage({
        key: Table.BOXES_TABLE,
        value: 0,
      }),
    );

    dispatch(
      setAssetFilter({
        key,
        value: filterArray || value,
      }),
    );
  };

  useEffect(() => {
    handleChangeFilter(
      AssetFilterKey.filter,
      MESSAGES.errors.defaultMessage.toLowerCase(),
      names,
    );
  }, [names]);

  return (
    <Form
      form={form}
      layout="inline"
      style={{ justifyContent: 'space-between' }}
    >
      <FilterContainer wrap>
        <StyledSearch
          width="100%"
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeFilter(AssetFilterKey.assetId, ev.target.value)
          }
          onSearch={(value: string) =>
            handleChangeFilter(AssetFilterKey.assetId, value)
          }
          placeholder={intl.formatMessage(MESSAGES.search)}
          value={assetIdFilterValue}
          data-testid={`search-${AssetFilterKey.assetId}`}
        />
        <StyledButton
          ml={theme.spacing.xSmall}
          height={theme.spacing.xxLarge}
          variant="primary"
          data-testid="btn-open-filters-mobile-asset-list"
          onClick={() => setModalOpen(true)}
        >
          <FilterOutlined />
        </StyledButton>
      </FilterContainer>

      <Modal closable width={300} title='Asset filters' showModal={modalOpen} onCancel={() => setModalOpen(false)}>
          <FilterContainer>
            <FilterTitle>
              {intl.formatMessage(MESSAGES[BoxesFilter.LOCATION])}
            </FilterTitle>
            <StyledSelect
              value={sortFilterValue}
              showSearch
              allowClear
              minWidth="170px"
              onChange={(value) =>
                handleChangeFilter(AssetFilterKey.location, value as string)
              }
              placeholder={intl.formatMessage(MESSAGES.boxDropdownPlaceholder)}
            >
              {/* {boxesForSearchSession?.map((boxIdLabelOperator) => (
                <Option key={boxIdLabelOperator.id} value={boxIdLabelOperator.id}>
                  {`${boxIdLabelOperator.label} | ${boxIdLabelOperator.operatorName}`}
                </Option>
              ))} */}
            </StyledSelect>
          </FilterContainer>
        <FilterContainer>
          <FilterTitle>
            {intl.formatMessage(MESSAGES[BoxesFilter.FILTER])}
          </FilterTitle>
          <StyledCheckbox
            data-testid={`checkbox-box-table-${MESSAGES.errors.defaultMessage}`}
            className={MESSAGES.errors.defaultMessage}
            onChange={() =>
              handleCheckboxChange(MESSAGES.errors.defaultMessage.toLowerCase())
            }
          >
            {intl.formatMessage(MESSAGES.errors)}
          </StyledCheckbox>
          <StyledCheckbox
            data-testid={`checkbox-box-table-${MESSAGES.warnings.defaultMessage}`}
            className={MESSAGES.errors.defaultMessage}
            onChange={() =>
              handleCheckboxChange(
                MESSAGES.warnings.defaultMessage.toLowerCase(),
              )
            }
          >
            {intl.formatMessage(MESSAGES.warnings)}
          </StyledCheckbox>
          <StyledCheckbox
            data-testid={`checkbox-box-table-${MESSAGES.favorites.defaultMessage}`}
            className={MESSAGES.errors.defaultMessage}
            onChange={() =>
              handleCheckboxChange(
                MESSAGES.favorites.defaultMessage.toLowerCase(),
              )
            }
          >
            {intl.formatMessage(MESSAGES.favorites)}
          </StyledCheckbox>
        </FilterContainer>
        <FilterContainer key={BoxesFilter.TYPE_FILTER}>
          <FilterTitle>
            {intl.formatMessage(MESSAGES[BoxesFilter.TYPE_FILTER])}
          </FilterTitle>
          <Radio.Group defaultValue={RequestBoxFor.ALL}>
            <StyledRadioButton
              key={RequestBoxFor.ALL}
              onClick={() =>
                handleChangeFilter(AssetFilterKey.typeFilter, RequestBoxFor.ALL)
              }
              value={RequestBoxFor.ALL}
              data-testid={`box-filters-radio-${RequestBoxFor.ALL}`}
            >
              {intl.formatMessage(MESSAGES.all)}
            </StyledRadioButton>
            <StyledRadioButton
              key={RequestBoxFor.POWER}
              onClick={() =>
                handleChangeFilter(AssetFilterKey.typeFilter, RequestBoxFor.POWER)
              }
              value={RequestBoxFor.POWER}
              data-testid={`box-filters-radio-${RequestBoxFor.POWER}`}
            >
              {intl.formatMessage(MESSAGES.power)}
            </StyledRadioButton>
            <StyledRadioButton
              key={RequestBoxFor.WATER}
              onClick={() =>
                handleChangeFilter(AssetFilterKey.typeFilter, RequestBoxFor.WATER)
              }
              value={RequestBoxFor.WATER}
              data-testid={`box-filters-radio-${RequestBoxFor.WATER}`}
            >
              {intl.formatMessage(MESSAGES.water)}
            </StyledRadioButton>
          </Radio.Group>
        </FilterContainer>
      </Modal>

    </Form>
  );
};
