import React from 'react';
import { useSelector } from 'react-redux';

import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../store/selectors/tags.selectors';
import { IconSize } from '../../types/icon.types';
import { BoxTag } from '../../types/tag.types';
import { BoxHygrostaatAlarmIcon } from './icons/hygrosstaat/BoxHygrostaatAlarmIcon';
import { BoxHygrostaatNormalIcon } from './icons/hygrosstaat/BoxHygrostaatNormalIcon';

interface IProps {
  boxId: string;
}

export const BoxHygrostaatIcon = ({ boxId }: IProps) => {
  const hygrostaatStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V3_HYGROSSTAAT,
    ),
  );

  if (hygrostaatStatus?.value === '1') {
    return <BoxHygrostaatNormalIcon size={IconSize.LARGE} />;
  }

  if (hygrostaatStatus?.value === '2') {
    return <BoxHygrostaatAlarmIcon size={IconSize.LARGE} />;
  }

  return <BoxHygrostaatNormalIcon size={IconSize.LARGE} />;
};
