import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, theme } from '../../theme';
import { StyledSelect } from '../../styled/form/StyledSelect';
import { TransportedTableData } from './TransportedTableData';

type IProps = {
  columns: any[];
  data: any[];
  handleChangeSort?: (sort: string[]) => void;
  FEsort?: boolean;
  instance: string;
};

const SortContainer = styled.div`
  margin-bottom: ${theme.spacing.medium};
  display: flex;
  justify-content: space-between;
`;

const sortValues = [
  { label: 'Ascending', value: 'asc' },
  { label: 'Descending', value: 'desc' },
];

export const TransposedTable: React.FC<IProps> = ({
  columns,
  data,
  FEsort = false,
  handleChangeSort,
  instance,
}) => {
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortedFieldValue, setSortedFieldValue] = useState<string | null>(null);
  const [sort, setSort] = useState<string[]>([]);
  const [tableData, setTableData] = useState(data);

  // Retrieve values from sessionStorage on mount
  useEffect(() => {
    const savedSortedField = sessionStorage.getItem(`${instance}sortedField`);
    const savedSortedFieldValue = sessionStorage.getItem(
      `${instance}sortedFieldValue`,
    );

    if (savedSortedField) setSortedField(savedSortedField);
    if (savedSortedFieldValue) setSortedFieldValue(savedSortedFieldValue);
  }, []);

  // Save values to sessionStorage when they change
  useEffect(() => {
    if (sortedField)
      sessionStorage.setItem(`${instance}sortedField`, sortedField);
    if (sortedFieldValue)
      sessionStorage.setItem(`${instance}sortedFieldValue`, sortedFieldValue);
  }, [sortedField, sortedFieldValue]);

  const sortableFields = columns
    .filter((col) => col.sorter)
    .map((col) => ({ label: col.title, value: col.key }));

  const handleSortField = (value: string) => {
    setSortedField(value);
    setSort((prevSort) => {
      const existingIndex = prevSort.findIndex((s) => s.startsWith(value));
      const newValue = `${value},${sortedFieldValue || 'asc'}`;
      if (existingIndex !== -1) {
        const updatedSort = [...prevSort];
        updatedSort[existingIndex] = newValue;
        return updatedSort;
      }
      return [...prevSort, newValue];
    });
  };

  // Function to sort data
  const sortData = () => {
    if (!sortedField || !sortedFieldValue) return data;

    const sorted = [...data].sort((a, b) => {
      const aValue = a[sortedField];
      const bValue = b[sortedField];

      if (aValue < bValue) return sortedFieldValue === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortedFieldValue === 'asc' ? 1 : -1;
      return 0;
    });

    return sorted;
  };

  const handleSortValue = (value: string) => {
    setSortedFieldValue(value);
    if (sortedField) {
      setSort((prevSort) => {
        const existingIndex = prevSort.findIndex((s) =>
          s.startsWith(sortedField),
        );
        const newValue = `${sortedField},${value}`;
        if (existingIndex !== -1) {
          const updatedSort = [...prevSort];
          updatedSort[existingIndex] = newValue;
          return updatedSort;
        }
        return [...prevSort, newValue];
      });
    }
  };

  useEffect(() => {
    // Only call handleChangeSort if the sort has actually changed
    // If FEsort is true, the parent component will handle sorting, if it is false, we will not
    // Send a request to the BE, rather we will handle sorting in the FE
    if (handleChangeSort && sort.length && !FEsort) {
      handleChangeSort(sort);
    }

    if (FEsort) {
      const sortedData = sortData();
      setTableData(sortedData);
    }
  }, [sort, handleChangeSort]);

  // Trigger sorting on mount if sortedField and sortedFieldValue are set
  useEffect(() => {
    if (sortedField && sortedFieldValue) {
      const initialSort = [`${sortedField},${sortedFieldValue}`];
      if (sort.join() !== initialSort.join()) {
        setSort(initialSort);
      }
    }
  }, [sortedField, sortedFieldValue, sort]);

  return (
    <div className="mobile-table">
      <SortContainer>
        {sortableFields.length > 0 && (
          <>
            <StyledSelect
              width="49%"
              value={sortedField}
              showSearch
              options={sortableFields}
              onChange={(value) => handleSortField(value as string)}
              placeholder="Sort field"
            />
            <StyledSelect
              width="49%"
              value={sortedFieldValue}
              onChange={(value: unknown) => handleSortValue(value as string)}
              options={sortValues}
              placeholder="Sort order"
            />
          </>
        )}
      </SortContainer>

      <TransportedTableData columns={columns} data={tableData} />
    </div>
  );
};
