import styled from '@emotion/styled';
import { Table, TableProps } from 'antd';

import { colors, theme } from '../../theme';

class WrapTableType<T> {
  // eslint-disable-next-line class-methods-use-this
  wrapped(e: T) {
    return Table<TableProps<T>>(e);
  }
}

export const StyledTable = styled(Table)({
  '.ant-table': {
    boxShadow: theme.boxShadows.light,
  },
  '.ant-spin-container': {
    overflowX: 'scroll',
  },
  '.ant-table-thead > tr': {
    backgroundColor: colors.grey.primary,
    fontFamily: theme.fontFamilies.primary,
    fontSize: theme.fontSizes.small,
    fontWeight: theme.fontWeights.bolder,
    lineHeight: theme.lineHeights.small,
  },

  '.ant-table-thead > tr > th': {
    backgroundColor: colors.grey.primary,
    fontFamily: theme.fontFamilies.primary,
    fontSize: theme.fontSizes.small,
    fontWeight: theme.fontWeights.bolder,
    lineHeight: theme.lineHeights.small,
    padding: `${theme.table.header.cell}`,
    height: '46px',
  },

  '.ant-table-tbody > tr > td': {
    padding: `${theme.table.body.cell}`,
    height: '56px',
  },

  '.ant-pagination-item-active': {
    borderColor: colors.green.primary,
  },

  '.ant-pagination-item:hover': {
    borderColor: colors.green.primary,
  },

  'ul > li > a': {
    color: colors.green.primary,

    '&:hover': {
      color: colors.green.primary,
    },
  },

  '.ant-table-column-sorter-down.active, .ant-table-column-sorter-up.active': {
    color: colors.green.primary,
  },

  '.ant-pagination-item-link:hover': {
    borderColor: colors.green.primary,
    color: colors.green.primary,
  },
  '.ant-table-row > .ant-table-cell:not(.no-empty-cell-placeholder):empty:before':
    {
      content: '"-"',
      justifyContent: 'center',
      display: 'flex',
    },
  '.noPadding': {
    padding: 0,
  },
}) as React.ComponentType as <T>(
  props: TableProps<T>,
) => ReturnType<WrapTableType<T>['wrapped']>;
