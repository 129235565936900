import { AuthErrorStrings } from '@aws-amplify/auth/src/common/AuthErrorStrings';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAuth } from '../../authentication/hooks/useAuth';
import { CognitoError } from '../../components/login/CognitoError';
import { LoginContainer } from '../../components/login/LoginContainer';
import { appUrls } from '../../config/url.constants';
import { StyledButton } from '../../styled/buttons/StyledButton';
import { StyledInput } from '../../styled/form/StyledInput';
import { theme } from '../../theme';
import { IAuthHookContext } from '../../types/auth.types';
import { isValidEmail, LOGIN_MESSAGES } from '../../utils/login/login.utils';

const MESSAGES = defineMessages({
  title: {
    id: 'reset_password_page.submitCode',
    defaultMessage: 'Submit code',
  },
  confirmCode: {
    id: 'reset_password_page.confirmCode',
    defaultMessage: 'Confirm code',
  },
});

export const ResetPasswordCode = () => {
  const intl = useIntl();
  const { state }: any = useLocation();
  const { verifyCode } = useAuth() as IAuthHookContext;
  const [code, setCode] = useState<string>("");
  const [error, setError] = useState<AuthErrorStrings | null>(null);
  const navigate = useNavigate();

  const handleError = (err: any) => {
    const message = err.message as AuthErrorStrings;
    setError(message);
  };

  const handleSuccess = () => {
    navigate(appUrls.login.resetPasswordSet(code));
  }

  const handleSubmitCode = () => {
    verifyCode(
      state.email,
      code,
      handleError,
      handleSuccess,
    );

  };

  const isCodeValid = (codeString: string) => {
    if (codeString.length < 6 || codeString.length > 6) return false;

    return true;
  }

  return (
    <LoginContainer title={intl.formatMessage(MESSAGES.title)}>
      <StyledInput
        mt={theme.spacing.large}
        mb={theme.spacing.large}
        width={theme.input.width.big}
        value={code}
        type="number"
        onChange={(e) => setCode(e.target.value)}
        placeholder={intl.formatMessage(LOGIN_MESSAGES.enterResetPasswordCode)}
      />
      <StyledButton
        width={theme.input.width.big}
        variant="primary"
        htmlType="submit"
        disabled={!isCodeValid(code)}
        onClick={handleSubmitCode}
      >
        {intl.formatMessage(MESSAGES.confirmCode)}
      </StyledButton>

      {error && <CognitoError error={error} />}
    </LoginContainer>
  );
};
