import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { theme } from '../../../../theme';

import { StyledSelect } from '../../../../styled/form/StyledSelect';
import { useGetUsersQuery } from '../../../../store/api/users.api';

const MESSAGES = defineMessages({
  selectPriority: {
    id: 'maintenance.selectPriority',
    defaultMessage: 'Select priority',
  },
});

const { Option } = Select;

interface IProps {
  onSelectUser: (id: string | null) => void;
  userSelected?: string;
  show?: boolean;
}

export const SelectUser = ({
  onSelectUser,
  show,
  userSelected,
}: IProps) => {
  const intl = useIntl();
  const [selected, setSelected] = useState<string | null>(null);

  const { data } = useGetUsersQuery();

  useEffect(() => {
    if (userSelected) {
      setSelected(userSelected);
    }
  }, [userSelected]);

  const handleSelectUser = (user: string | null) => {
    onSelectUser(user);
    setSelected(user);
  };
  return (
    <>
      <StyledSelect
        onChange={(value: unknown) => handleSelectUser(value as string | null)}
        value={selected}
        style={{ display: show ? 'none' : 'block', pointerEvents: show ? 'none' : 'auto' }}
        showSearch
        allowClear
        placeholder={intl.formatMessage(MESSAGES.selectPriority)}
        mb={theme.spacing.small}
        minWidth="170px"
      >
        {data?.map((user) => (
          <Option key={user.id} value={user.id}>
            {user.firstName} {user.lastName}
          </Option>
        ))}
      </StyledSelect>
    </>
  );
};
