import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import { UserPermission } from '../types/user.types';
import { getCurrentUserPermissonsSelector } from '../store/selectors/user.selectors';
import { StyledBox } from '../styled/boxes/StyledBox';
import { theme } from '../theme';
import { StyledHeading } from '../styled/headings/StyledHeading';
import { getLayoutFlagByKeySelector } from '../store/selectors/layout.selectors';
import { LayoutStateKeys } from '../types/layout.types';

const MESSAGES = defineMessages({
  unauthorized: {
    id: 'unauthorized.title',
    defaultMessage: 'You do not have the right assigned permissions',
  },
});

const PermissionGuard = ({
  children,

  permissions,
}: {
  permissions: UserPermission[];
  children: React.ReactNode;
}) => {
  const intl = useIntl();
  const [isMobile] = useState(window.innerWidth < 768);
  const isCollapsed = useSelector(
    getLayoutFlagByKeySelector(LayoutStateKeys.SIDEBAR_TOGGLE_COLLAPSED),
  );

  const userPermissions = useSelector(getCurrentUserPermissonsSelector());

  const isAllowed =
    permissions && permissions.length > 0
      ? permissions.some((permission) => userPermissions?.includes(permission))
      : true;

  const ml = isMobile && isCollapsed ? '100px' : isMobile ? '220px' : 0;

  return isAllowed ? (
    <>{children}</>
  ) : (
    <StyledBox ml={ml} p={theme.spacing.xLarge}>
      <StyledHeading variant="h3">
        {intl.formatMessage(MESSAGES.unauthorized)}
      </StyledHeading>
    </StyledBox>
  );
};

export default PermissionGuard;
