import React from 'react';
import { useSelector } from 'react-redux';

import { currentUserSelector } from '../../../../store/selectors/user.selectors';
import { SessionsTableWithFilter } from '../../../Sessions/SessionsTable/SessionsTableWithFilter';

interface IProps {
  boxId?: string;
}

export const BoxSessionsPortal = ({ boxId }: IProps) => {
  const currentUser = useSelector(currentUserSelector);

  return <SessionsTableWithFilter boxId={boxId} currentUser={currentUser} />;
};
