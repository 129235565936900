// useUserFilters.ts
import { useSelector } from 'react-redux';
import { IUserFilters, UserFilterKey } from '../types/filters.types';
import { userFiltersByKeysSelector } from '../store/selectors/filters.selectors';
import { filteredUsersSelector } from '../store/selectors/user.selectors';

export const useUserFilters = () => {
  const userFilterKeys = [
    UserFilterKey.lastName,
    UserFilterKey.firstName,
    UserFilterKey.email,
    UserFilterKey.phoneNumber,
  ];

  const requestedUserFilters = useSelector(
    userFiltersByKeysSelector(userFilterKeys),
  ) as IUserFilters | undefined;

  const filteredUsers = useSelector(
    filteredUsersSelector(requestedUserFilters),
  );

  return { filteredUsers };
};
