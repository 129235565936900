/* eslint-disable */
import React from 'react';
import createIcon from '../utils/icons/createIcon';
import { colors } from '../theme';

export default createIcon(
  <>
    <path d="M9.9999 0.800049L12.5599 7.40005L19.5999 7.76005L14.1199 12.24L15.9199 19.04L9.9999 15.24L4.0799 19.04L5.8799 12.24L0.399902 7.76005L7.4399 7.40005L9.9999 0.800049Z" stroke-miterlimit="10" stroke-linejoin="round"/>
  </>,
  '0 0 20 20',
  'Star',
);
