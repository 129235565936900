export enum MaintenanceProblemType {
  BOX = "BOX",  
  CONNECTOR = "CONNECTOR",
}

export enum MaintenanceTaskPriority {
  Urgent = "Urgent",
  High = "High",
  Medium = "Medium",
  Low = "Low",
}
export enum MaintenanceTaskPriorityEnum {
  Urgent = 1,
  High = 2,
  Medium = 3,
  Low = 4,
}

export const MaintenanceTaskPriorityText = {
  [MaintenanceTaskPriorityEnum.Urgent]: MaintenanceTaskPriority.Urgent,
  [MaintenanceTaskPriorityEnum.High]: MaintenanceTaskPriority.High,
  [MaintenanceTaskPriorityEnum.Medium]: MaintenanceTaskPriority.Medium,
  [MaintenanceTaskPriorityEnum.Low]: MaintenanceTaskPriority.Low,
};

export const TasksPriorityEnum: { [key in MaintenanceTaskPriority]: MaintenanceTaskPriorityEnum } = {
  [MaintenanceTaskPriority.Urgent]: MaintenanceTaskPriorityEnum.Urgent,
  [MaintenanceTaskPriority.High]: MaintenanceTaskPriorityEnum.High,
  [MaintenanceTaskPriority.Medium]: MaintenanceTaskPriorityEnum.Medium,
  [MaintenanceTaskPriority.Low]: MaintenanceTaskPriorityEnum.Low,
};


// Task Status

export enum TaskStatus {
  New = "New",
  Acknowledged = "Acknowledged",
  "Not started" = "Not started",
  "In progress" = "In progress",
  Finished = "Finished",
}
export enum TaskStatusEnum {
  New = 1,
  Acknowledged = 2,
  "Not started" = 3,
  "In progress" = 4,
  "Finished" = 5,
}

export const TaskStatusText: { [key: number]: TaskStatus } = {
  [TaskStatusEnum.New]: TaskStatus.New,
  [TaskStatusEnum.Acknowledged]: TaskStatus.Acknowledged,
  [TaskStatusEnum["Not started"]]: TaskStatus["Not started"],
  [TaskStatusEnum["In progress"]]: TaskStatus["In progress"],
  [TaskStatusEnum.Finished]: TaskStatus.Finished,
};

export const TasksStatusEnum: { [key in TaskStatus]: TaskStatusEnum } = {
  [TaskStatus.New]: TaskStatusEnum.New,
  [TaskStatus.Acknowledged]: TaskStatusEnum.Acknowledged,
  [TaskStatus["Not started"]]: TaskStatusEnum["Not started"],
  [TaskStatus["In progress"]]: TaskStatusEnum["In progress"],
  [TaskStatus.Finished]: TaskStatusEnum.Finished,
};