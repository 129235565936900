import { MenuOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Button, Drawer } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { appUrls } from '../../../config/url.constants';
import { theme } from '../../../theme';
import { LogoWithLabel } from '../../brand/mobile/LogoWithLabel';
import SideMenu from './SideMenu';

interface IProps {
  isCollapsed: boolean;
  onSidebarClick: () => void;
}

const StyledDrawer = styled(Drawer)({
  '.ant-drawer-content': {
    borderTopRightRadius: theme.spacing.xxLarge,
    borderBottomRightRadius: theme.spacing.xxLarge,
  },
  '.ant-menu-title-content': {
    marginLeft: `${theme.spacing.large} !important`,
  },
  '.anticon.ant-menu-item-icon': {
    fontSize: theme.fontSizes.mediumLarge,
    verticalAlign: 'sub',
  },
});

export const MobileNavBar = ({ isCollapsed, onSidebarClick }: IProps) => {
  const navigate = useNavigate();

  return (
    <div>
      <Button
        style={{
          margin: '20px',
          borderRadius: '8px',
        }}
        onClick={onSidebarClick}
      >
        <MenuOutlined />
      </Button>
      <StyledDrawer
        placement="left"
        onClose={onSidebarClick}
        visible={isCollapsed}
        width={270}
      >
        <LogoWithLabel
          showLabel={isCollapsed}
          onClick={() => navigate(appUrls.dashboard.base)}
        />
        <SideMenu onSidebarClick={onSidebarClick} isCollapsed={isCollapsed} />
      </StyledDrawer>
    </div>
  );
};
