import React from 'react';

import Icon from './Icon';

interface IProps {
  fill?: string;
  height?: string;
  stroke?: string;
  width?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any) => void;
}

const createIcon = (
  def: React.ReactNode,
  viewBox: string,
  displayName: string,
) => {
  const icon = ({
    fill = 'currentColor',
    height,
    onClick,
    stroke,
    width,
    ...rest
  }: IProps) => (
    <Icon
      viewBox={viewBox}
      width={width}
      height={height}
      fill={fill}
      stroke={stroke}
      onClick={onClick}
      {...rest}
    >
      {def}
    </Icon>
  );
  icon.displayName = displayName;
  return icon;
};

export default createIcon;
