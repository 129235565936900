import React, { ReactNode } from 'react';

import { StyledHeading } from '../../styled/headings/StyledHeading';
import { StyledModal } from '../../styled/modals/StyledModal';

interface IProps {
  children: any;
  showModal: boolean;
  title: string;
  width?: number;
  closable?: boolean;
  onCancel?: () => void;
}

export const Modal = ({ children, closable = false, onCancel, showModal, title, width = 520 }: IProps) => {
  const ModalTitle = <StyledHeading variant="h3">{title}</StyledHeading>;

  return (
    <StyledModal
      closable={closable}
      footer={null}
      title={ModalTitle}
      visible={showModal}
      width={width}
      onCancel={onCancel}
      centered
    >
      {children}
    </StyledModal>
  );
};
