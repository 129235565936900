import { defineMessages } from 'react-intl';

import {
  PowerPhaseAndAmp,
  VesselFrequency,
  VesselType,
} from '../types/vessel.types';

export const VESSEL_POWER_TYPE_MESSAGES = defineMessages({
  [PowerPhaseAndAmp.PW_230V_16A]: {
    id: 'vessel.powerPhaseAndAmp.PW_230V_16A',
    defaultMessage: '230V - 16A',
  },
  [PowerPhaseAndAmp.PW_230V_32A]: {
    id: 'vessel.powerPhaseAndAmp.PW_230V_32A',
    defaultMessage: '230V - 32A',
  },
  [PowerPhaseAndAmp.PW_3X400V_63A]: {
    id: 'vessel.powerPhaseAndAmp.PW_3x400V_63A',
    defaultMessage: '3x400V - 63A',
  },
  [PowerPhaseAndAmp.PW_3X400V_125A]: {
    id: 'vessel.powerPhaseAndAmp.PW_3x400V_125A',
    defaultMessage: '3x400V - 125A',
  },
  [PowerPhaseAndAmp.PW_3X400V_400A]: {
    id: 'vessel.powerPhaseAndAmp.PW_3x400V_400A',
    defaultMessage: '3x400V - 400A',
  },
  [PowerPhaseAndAmp.PW_3X400V_800A]: {
    id: 'vessel.powerPhaseAndAmp.PW_3x400V_800A',
    defaultMessage: '3x400V - 800A',
  },
  [PowerPhaseAndAmp.OTHER]: {
    id: 'vessel.powerPhaseAndAmp.other',
    defaultMessage: 'Other',
  },
});

export const VESSEL_TYPE_MESSAGES = defineMessages({
  [VesselType.INLAND]: {
    id: 'vessel.type.inland',
    defaultMessage: 'Inland',
  },
  [VesselType.BARGE_INLAND]: {
    id: 'vessel.type.bargeInland',
    defaultMessage: 'Barge inland',
  },
  [VesselType.TANKER_INLAND]: {
    id: 'vessel.type.tankerInland',
    defaultMessage: 'Tanker inland',
  },
  [VesselType.BULK_INLAND]: {
    id: 'vessel.type.bulkInland',
    defaultMessage: 'Bulk inland',
  },
  [VesselType.CARGO_TUG_BOAT]: {
    id: 'vessel.type.cargoTubBoat',
    defaultMessage: 'Cargo tug boat',
  },
  [VesselType.FISHING_BOAT]: {
    id: 'vessel.type.fishingBoat',
    defaultMessage: 'Fishing boat',
  },
  [VesselType.RIVER_CRUISE]: {
    id: 'vessel.type.riverCruise',
    defaultMessage: 'River cruise',
  },
  [VesselType.OTHER]: {
    id: 'vessel.type.other',
    defaultMessage: 'Other',
  },
});

export const VESSEL_FREQUENCY_MESSAGES = defineMessages({
  [VesselFrequency.HZ_50]: {
    id: 'vessel.frequency.hz_50',
    defaultMessage: '50 Hz',
  },
  [VesselFrequency.HZ_60]: {
    id: 'vessel.frequency.hz_60',
    defaultMessage: '60 Hz',
  },
});
