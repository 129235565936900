import React from 'react';
import { useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';
import sortOn from 'sort-on';
import moment from 'moment'

import { mostRecentTagStatusesByRequestIdAssetIdAndTagsSelector } from '../../store/selectors/tags.selectors';
import {
  BoxTag,
  BoxAlarmTag,
  BoxWarningTag,
  ITagStatus,
} from '../../types/tag.types';
import { useGetAuthorizedBoxByIdQuery } from '../../store/api/boxes.api';

interface IProps {
  boxId: string;
}

const MESSAGES = defineMessages({
  [BoxWarningTag.V3_BOX_TEMPERATURE]: {
    id: 'asset_warnings_and_errors_table.boxTemperature',
    defaultMessage: 'Box temperature alarm',
  },
  [BoxWarningTag.V3_BOX_WARNING]: {
    id: 'asset_warnings_and_errors_table.boxWarning',
    defaultMessage: 'General Box warning',
  },
  [BoxWarningTag.V3_CPU_TEMPERATURE]: {
    id: 'asset_warnings_and_errors_table.cpuTemperature',
    defaultMessage: 'CPU temperature to high',
  },
  [BoxWarningTag.V3_EXPANTION_MODULE]: {
    id: 'asset_warnings_and_errors_table.expantionModule',
    defaultMessage: 'Expantie modules',
  },
  [BoxWarningTag.V3_FUSE_AUXILLIARIES]: {
    id: 'asset_warnings_and_errors_table.fuseAuxilliaries',
    defaultMessage: 'Circuit breaker auxilliaries',
  },
  [BoxWarningTag.V3_FUSE_EXT24VDC]: {
    id: 'asset_warnings_and_errors_table.fuseExt24Vdc',
    defaultMessage: 'Fuse external 24 VDC',
  },
  [BoxWarningTag.V3_HYGROSSTAAT]: { id: 'asset_warnings_and_errors_table.hygrosstaat', defaultMessage: 'Hygrosstaat' },
  [BoxWarningTag.V3_RTU_24_VDC]: {
    id: 'asset_warnings_and_errors_table.rtu24Vdc',
    defaultMessage: 'Ingangsspanning CPU 24 VDC',
  },
  [BoxWarningTag.V3_SERVICE_DOOR]: {
    id: 'asset_warnings_and_errors_table.serviceDoor',
    defaultMessage: 'Door service compartiment',
  },
  [BoxWarningTag.V3_UPS]: {
    id: 'asset_warnings_and_errors_table.ups',
    defaultMessage: 'UPS warning',
  },

  [BoxAlarmTag.V4_BOX_ALARM]: {
    id: 'asset_warnings_and_errors.boxAlarm',
    defaultMessge: 'General Box alarms',
  },
  [BoxAlarmTag.V4_CHANNEL_STATE]: {
    id: 'asset_warnings_and_errors.channelState',
    defaultMessage: 'Channel state',
  },
  [BoxAlarmTag.V4_CPU]: {
    id: 'asset_warnings_and_errors.cpu',
    defaultMessage: 'CPU in fout',
  },
  [BoxAlarmTag.V4_EXPANTION_MODULE]: {
    id: 'asset_warnings_and_errors.expantionModule',
    defaultMessage: 'Alarm expantion module absent',
  },
  [BoxAlarmTag.V4_FUSE_AUTOMATION]: {
    id: 'asset_warnings_and_errors.fuseAutomation',
    defaultMessage: 'Beveiliging automatisatie',
  },
  [BoxAlarmTag.V4_INCOMING_FASES]: {
    id: 'asset_warnings_and_errors.incomingPhases',
    defaultMessage: 'Inkomende Fase ontbreekt',
  },
  [BoxAlarmTag.V4_LICHTNING_PROTECTION]: {
    id: 'asset_warnings_and_errors.lichtningProtection',
    defaultMessage: 'Bliksem beveiliging',
  },
  [BoxAlarmTag.V4_MAINSWITCH]: {
    id: 'asset_warnings_and_errors_table.mainSwitch',
    defaultMessage: 'Hoofdschakelaar',
  },
  [BoxAlarmTag.V4_VOLTAGE]: {
    id: 'asset_warnings_and_errors.voltage',
    defaultMessage: 'Spanning aanwezig',
  },
});

export const BoxesLatestMessage = ({ boxId }: IProps) => {
  const intl = useIntl();
  useGetAuthorizedBoxByIdQuery(boxId);

  const boxWarningsAndAlarmsTagStatuses: ITagStatus[] | undefined = useSelector(
    mostRecentTagStatusesByRequestIdAssetIdAndTagsSelector(boxId, boxId, [
      BoxTag.V3_FUSE_AUXILLIARIES,
      BoxTag.V3_UPS,
      BoxTag.V3_CPU_TEMPERATURE,
      BoxTag.V3_EXPANTION_MODULE,
      BoxTag.V3_SERVICE_DOOR,
      BoxTag.V3_HYGROSSTAAT,
      BoxTag.V3_RTU_24_VDC,
      BoxTag.V3_FUSE_EXT24VDC,
      BoxTag.V3_BOX_TEMPERATURE,
      BoxTag.V4_MAINSWITCH,
      BoxTag.V4_VOLTAGE,
      BoxTag.V4_INCOMING_FASES,
      BoxTag.V4_LICHTNING_PROTECTION,
      BoxTag.V4_CPU,
      BoxTag.V4_CHANNEL_STATE,
      BoxTag.V4_FUSE_AUTOMATION,
    ]),
  );

  const boxAlarmsAndWarnings = boxWarningsAndAlarmsTagStatuses?.filter(
    (tagStatus) => tagStatus.value === '2',
  );

  let warningsAndAlarmsSortedByDate: any;
  if (boxAlarmsAndWarnings) {
    warningsAndAlarmsSortedByDate = sortOn(boxAlarmsAndWarnings, [
      '-updatedAt',
    ]);
  }

  const mapWarningsAndAlarmStatusToDesc = (
    tag: BoxAlarmTag | BoxWarningTag, timestamp: number
  ) => {
    const time = moment(timestamp).format('MM/DD/YYYY HH:mm')
    const text = intl.formatMessage(MESSAGES[tag]);

    return `${time} - ${text}`
  };

  const status = warningsAndAlarmsSortedByDate[0]
  return <div>{status ? mapWarningsAndAlarmStatusToDesc(status.tag, status.updatedAt) : "There's no alert for this box"}</div>;
};
