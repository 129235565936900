import styled from '@emotion/styled';

import { StyledBox } from '../../../styled/boxes/StyledBox';

export const ResponsiveFlex = styled(StyledBox)({
  display: 'flex',
  flexDirection: 'row',
  flexFlow: 'row wrap',
  width: '100%',
});
