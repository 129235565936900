import { colors } from '../../theme';
import { ConnectorStatus } from '../../types/connector.types';

export const getConnectorStatusIconListBorderColor = (
  connectorStatus: ConnectorStatus,
) => {
  if (connectorStatus === ConnectorStatus.ALARM) {
    return colors.red.secondary;
  }
  if (connectorStatus === ConnectorStatus.WARNING) {
    return colors.yellow.primary;
  }
  if (connectorStatus === ConnectorStatus.OK) {
    return colors.green.secondary;
  }
  return colors.lightGrey.secondary;
};
