import React from 'react';

import { StyledBoxWithPointer } from '../../../../../styled/boxes/StyledBoxWithPointer';
import { IConnectorStatusIconProps } from '../../../../../types/connector.types';
import { getIconSize } from '../../../../../utils/shared/icons/getIconSize';

export const RefreshConnectorActionIcon = ({
  onClick,
  size,
  ...space
}: IConnectorStatusIconProps) => (
  <StyledBoxWithPointer
    {...space}
    onClick={onClick}
    height={getIconSize(size).height}
  >
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-connector-action-refresh"
    >
      <path
        d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H30C31.1046 0.5 32 1.39543 32 2.5V30.5C32 31.6046 31.1046 32.5 30 32.5H2C0.895431 32.5 0 31.6046 0 30.5V2.5Z"
        fill="white"
      />
      <path
        d="M12 12H7.5V7.5M20 21.0005L24.5 21V25.5"
        stroke="#02A24F"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M24.367 21.003C22.7625 23.978 19.6175 26 16 26C10.7535 26 6.5 21.7465 6.5 16.5M7.631 12C9.2355 9.023 12.3815 7 16 7C21.2465 7 25.5 11.2535 25.5 16.5"
        stroke="#02A24F"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M2 1.5H30V-0.5H2V1.5ZM31 2.5V30.5H33V2.5H31ZM30 31.5H2V33.5H30V31.5ZM1 30.5V2.5H-1V30.5H1ZM2 31.5C1.44772 31.5 1 31.0523 1 30.5H-1C-1 32.1569 0.343146 33.5 2 33.5V31.5ZM31 30.5C31 31.0523 30.5523 31.5 30 31.5V33.5C31.6569 33.5 33 32.1569 33 30.5H31ZM30 1.5C30.5523 1.5 31 1.94772 31 2.5H33C33 0.843146 31.6569 -0.5 30 -0.5V1.5ZM2 -0.5C0.343146 -0.5 -1 0.843146 -1 2.5H1C1 1.94772 1.44772 1.5 2 1.5V-0.5Z"
        fill="#02A24F"
      />
    </svg>
  </StyledBoxWithPointer>
);
