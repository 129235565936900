import { createSelector } from '@reduxjs/toolkit';

import { LayoutStateKeys } from '../../types/layout.types';
import type { RootState } from '../store';

export const layoutStateSelector = (state: RootState) => state.layout;

export const getLayoutFlagByKeySelector = (key: LayoutStateKeys | string) =>
  createSelector(
    layoutStateSelector,
    // @ts-ignore I cant set key fixed to LayoutKey and have to allow string
    (layoutState) => !!(layoutState && layoutState.layoutFlags[key]),
  );

export const layoutSuccessMessageSelector = createSelector(
  layoutStateSelector,
  (layoutState) => layoutState.layoutMessages.success,
);

export const layoutErrorMessageSelector = createSelector(
  layoutStateSelector,
  (layoutState) => layoutState.layoutMessages.error,
);
