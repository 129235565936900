import { Form } from 'antd';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { FilterElements } from '../../../components/filters/FilterElements';
import { PrimaryWrapperBox } from '../../../styled/boxes/PrimaryWrapperBox';
import { StyledCheckbox } from '../../../styled/form/StyledCheckbox';
import { StyledSearch } from '../../../styled/form/StyledSearch';
import { theme } from '../../../theme';

const MESSAGES = defineMessages({
  unapproved: {
    id: 'log_filter.unapproved',
    defaultMessage: 'Unapproved',
  },
  active: {
    id: 'log_filter.active',
    defaultMessage: 'Active',
  },
  assetId: {
    id: 'log_filter.assetId',
    defaultMessage: 'Asset ID',
  },
  transactionId: {
    id: 'log_filter.transactionId',
    defaultMessage: 'Transaction ID',
  },
  searchPlaceholder: {
    id: 'log_filter.searchPlaceholder',
    defaultMessage: 'Search',
  },
});

export const LogsFilter = ({
  isTabComponent,
}: {
  isTabComponent?: boolean;
}) => {
  const intl = useIntl();

  const [form] = Form.useForm();

  const handleCheckboxChange = (e: any) => {};

  const handleChangeFilter = (key: string, value: string) => {};

  return (
    <PrimaryWrapperBox mb={theme.spacing.large}>
      <Form form={form} layout="inline">
        <FilterElements title="" noSpace>
          <StyledCheckbox onChange={handleCheckboxChange}>
            {intl.formatMessage(MESSAGES.unapproved)}
          </StyledCheckbox>
          <StyledCheckbox onChange={handleCheckboxChange}>
            {intl.formatMessage(MESSAGES.active)}
          </StyledCheckbox>
        </FilterElements>

        {!isTabComponent && (
          <FilterElements title={intl.formatMessage(MESSAGES.assetId)}>
            <StyledSearch
              placeholder={intl.formatMessage(MESSAGES.searchPlaceholder)}
            />
          </FilterElements>
        )}
        <FilterElements title={intl.formatMessage(MESSAGES.transactionId)}>
          <StyledSearch
            placeholder={intl.formatMessage(MESSAGES.searchPlaceholder)}
          />
        </FilterElements>
      </Form>
    </PrimaryWrapperBox>
  );
};
