import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const cancelledSessionsSlice = createSlice({
  name: 'cancelledSessions',
  initialState: [] as string[],
  reducers: {
    addCancelledSession: (
      state: string[],
      { payload }: PayloadAction<string>,
    ) => {
      state.push(payload);
    },
  },
});

export const { addCancelledSession } = cancelledSessionsSlice.actions;
export default cancelledSessionsSlice.reducer;
