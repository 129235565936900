import { PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';

import { theme } from '../../../theme';
import { StyledButton } from '../../../styled/buttons/StyledButton';
import { Modal } from '../../../components/modals/Modal';
import { TaskDetails } from './TaskDetails';
import { hasCurrentUserPermissionSelector } from '../../../store/selectors/user.selectors';
import { UserPermission } from '../../../types/user.types';

const MESSAGES = defineMessages({
  addNewTask: {
    id: 'maintenance.addNewTask',
    defaultMessage: 'Add new task',
  },
});

export const TaskCreate = () => {
  const intl = useIntl();
  const currentRoute = useLocation();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const currentRouteState = (currentRoute as any).state as {
      createNewTask?: boolean;
    };
    setShowModal((prev) => currentRouteState?.createNewTask || prev);
  }, [currentRoute]);

  // const canCreateTask = useSelector(
  //   hasCurrentUserPermissionSelector(UserPermission.CREATE_TASK),
  // );
  const canCreateTask = true // TO DO: check if user has permission to create a task

  return (
    <>
      {canCreateTask && <StyledButton
        variant="primary"
        mt={theme.spacing.large}
        mb={theme.spacing.large}
        onClick={() => setShowModal(true)}
        data-testid="btn-add-user"
      >
        <PlusOutlined />
        {intl.formatMessage(MESSAGES.addNewTask)}
      </StyledButton>}

      <Modal
        title={intl.formatMessage(MESSAGES.addNewTask)}
        showModal={showModal}
        onCancel={() => setShowModal(false)}
        closable
      >
        
        <TaskDetails action="create" />
        
      </Modal>
    </>
  );
};
