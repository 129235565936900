import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import {
  VESSEL_FREQUENCY_MESSAGES,
  VESSEL_POWER_TYPE_MESSAGES,
  VESSEL_TYPE_MESSAGES,
} from '../../../../consts/vessel.consts';
import { StyledSpan } from '../../../../styled/text/StyledSpan';
import { theme } from '../../../../theme';
import { IVessel } from '../../../../types/vessel.types';

const MESSAGES = defineMessages({
  frequency: {
    id: 'vessel_meta_info_list.frequency',
    defaultMessage: 'Frequency',
  },
  powerType: {
    id: 'vessel_meta_info_list.powerType',
    defaultMessage: 'Power type',
  },
  vesselType: {
    id: 'vessel_meta_info_list.vesselType',
    defaultMessage: 'Vessel type',
  },
});

interface IProps {
  selectedVessel?: IVessel;
}

export const VesselMetaInfoList = ({ selectedVessel }: IProps) => {
  const intl = useIntl();

  return (
    <>
      <StyledSpan
        fontSize={theme.fontSizes.small}
        data-testid="vessel-meta-info-freq"
      >
        {`${intl.formatMessage(MESSAGES.frequency)} - ${
          selectedVessel
            ? intl.formatMessage(
                VESSEL_FREQUENCY_MESSAGES[selectedVessel.powerFrequency],
              )
            : ''
        }`}
      </StyledSpan>
      <StyledSpan
        fontSize={theme.fontSizes.small}
        data-testid="vessel-meta-info-power-type"
      >
        {`${intl.formatMessage(MESSAGES.powerType)} - ${
          selectedVessel
            ? intl.formatMessage(
                VESSEL_POWER_TYPE_MESSAGES[selectedVessel.powerPhaseAndAmp],
              )
            : ''
        }`}
      </StyledSpan>
      <StyledSpan
        fontSize={theme.fontSizes.small}
        data-testid="vessel-meta-info-vessel-type"
      >
        {`${intl.formatMessage(MESSAGES.vesselType)} - ${
          selectedVessel
            ? intl.formatMessage(VESSEL_TYPE_MESSAGES[selectedVessel.type])
            : ''
        }`}
      </StyledSpan>
    </>
  );
};
