import { css, Global } from '@emotion/react';
import React from 'react';

import { colors } from './index';

export const GlobalStyles = () => (
  <Global
    styles={css`
      html,
      .ant-layout {
        background: ${colors.green.background};
      }
      ,
      table td:hover {
        cursor: pointer;
      }
    `}
  />
);
