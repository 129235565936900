import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { appUrls } from '../../../config/url.constants';
import { useDeleteUserMutation } from '../../../store/api/users.api';
import { StyledBox } from '../../../styled/boxes/StyledBox';
import { StyledButton } from '../../../styled/buttons/StyledButton';
import { FlexRow } from '../../../styled/flex/FlexRow';
import { colors, theme } from '../../../theme';
import { ICurrentUser, UserPermission } from '../../../types/user.types';
import { checkIfUserHasPermission } from '../../../utils/users/checkIfUserHasPermission';
import { UsersTable } from './UsersTable';
import { UsersTableFilter } from './UsersTableFilter';
import { useUserFilters } from '../../../hooks/useUserFilters';

const MESSAGES = defineMessages({
  addUser: {
    id: 'users_table_with_filter.addUser',
    defaultMessage: 'Add new user',
  },
  warningDeleteUsers: {
    id: 'users_table_with_filter.warningDeleteUsers',
    defaultMessage: 'Are you sure you want to delete these users?',
  },
  confirmDeleteUsers: {
    id: 'users_table_with_filter.confirmDeleteUsers',
    defaultMessage: 'Confirm delete',
  },
  deleteUsers: {
    id: 'users_table_with_filter.deleteUsers',
    defaultMessage: 'Delete user(s)',
  },
});

interface IProps {
  currentUser: ICurrentUser;
}

export const UsersTableWithFilter = ({ currentUser }: IProps) => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { filteredUsers } = useUserFilters();

  const [deleteUser] = useDeleteUserMutation();

  const [canManageUsers, setCanManageUsers] = useState(
    checkIfUserHasPermission(currentUser, UserPermission.MANAGE_USERS),
  );
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  useEffect(() => {
    setCanManageUsers(
      checkIfUserHasPermission(currentUser, UserPermission.MANAGE_USERS),
    );
  }, [currentUser]);

  const handleAddNewUserClick = () => {
    navigate(appUrls.users.new);
  };

  const handleSelectUser = (userId: string) =>
    setSelectedUserIds((prevSelectedUserIds) =>
      prevSelectedUserIds.includes(userId)
        ? prevSelectedUserIds.filter((id) => id !== userId)
        : [...prevSelectedUserIds, userId],
    );

  return (
    <>
      <UsersTableFilter />
      {canManageUsers && (
        <FlexRow alignItems="center">
          <StyledButton
            variant="primary"
            mt={theme.spacing.large}
            mb={theme.spacing.large}
            onClick={handleAddNewUserClick}
            data-testid="btn-add-user"
          >
            <PlusOutlined />
            {intl.formatMessage(MESSAGES.addUser)}
          </StyledButton>
          <Popconfirm
            title={intl.formatMessage(MESSAGES.warningDeleteUsers)}
            okText={intl.formatMessage(MESSAGES.confirmDeleteUsers)}
            onConfirm={() => deleteUser({ userIds: selectedUserIds })}
            placement="right"
          >
            <StyledButton
              disabled={!selectedUserIds.length}
              variant="danger"
              width={theme.input.width.fitContent}
              ml={theme.spacing.medium}
              data-testid="btn-delete-vessel"
            >
              <MinusOutlined />
              {intl.formatMessage(MESSAGES.deleteUsers)}
            </StyledButton>
          </Popconfirm>
        </FlexRow>
      )}
      {filteredUsers && (
        <StyledBox
          bg={colors.white.primary}
          borderRadius={theme.borderRadius.medium}
          p={theme.spacing.xLarge}
        >
          <UsersTable
            canManageUsers={canManageUsers}
            selectedUserIds={selectedUserIds}
            onSelectUser={handleSelectUser}
            users={filteredUsers}
          />
        </StyledBox>
      )}
    </>
  );
};
