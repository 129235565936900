import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { IIconProps } from '../../../../types/icon.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const BoxHygrostaatAlarmIcon = ({ size, ...space }: IIconProps) => (
  <StyledBox {...space} height={getIconSize(size).height}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 50 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-hygrostaat-alarm"
    >
      <path
        d="M13 13.7199V31.3599M17 27.2812V2.93994H9V27.2812C5.469 28.7933 3 32.2419 3 36.2599C3 41.6725 7.477 46.0599 13 46.0599C18.523 46.0599 23 41.6725 23 36.2599C23 32.2419 20.531 28.7933 17 27.2812Z"
        stroke="#CF6363"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M13 31.36C11.6739 31.36 10.4021 31.8762 9.46447 32.7952C8.52678 33.7141 8 34.9604 8 36.26C8 37.5595 8.52678 38.8059 9.46447 39.7248C10.4021 40.6437 11.6739 41.16 13 41.16C14.3261 41.16 15.5979 40.6437 16.5355 39.7248C17.4732 38.8059 18 37.5595 18 36.26C18 34.9604 17.4732 33.7141 16.5355 32.7952C15.5979 31.8762 14.3261 31.36 13 31.36Z"
        fill="#CF6363"
      />
      <path
        d="M48 36.26C48 38.4248 46.209 40.18 44 40.18C41.791 40.18 40 38.4248 40 36.26C40 34.0951 44 28.2239 44 28.2239C44 28.2239 48 34.0951 48 36.26ZM47 9.79995C47 11.4238 45.657 12.7399 44 12.7399C42.343 12.7399 41 11.4238 41 9.79995C41 8.17609 44 3.77295 44 3.77295C44 3.77295 47 8.17609 47 9.79995ZM38 23.0299C38 24.9243 36.433 26.4599 34.5 26.4599C32.567 26.4599 31 24.9243 31 23.0299C31 21.1356 34.5 15.9984 34.5 15.9984C34.5 15.9984 38 21.1356 38 23.0299Z"
        stroke="#CF6363"
        strokeMiterlimit="10"
      />
      <path
        d="M17 22.54H21M17 6.85999H21H17ZM17 10.78H20H17ZM17 14.7H21H17ZM17 18.62H20H17Z"
        stroke="#CF6363"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  </StyledBox>
);
