import React from 'react';
import LogsTable from '../../../Alarms/components/AlarmsTable';
import { PrimaryWrapperBox } from '../../../../styled/boxes/PrimaryWrapperBox';
import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { theme } from '../../../../theme';
import { LogsFilter } from '../../../Alarms/components/Filter';

const AlarmsPortal = () => {
  return (
    <div>
      <StyledBox mb={theme.spacing.large}>
        <LogsFilter isTabComponent />
      </StyledBox>
      <PrimaryWrapperBox>
        <LogsTable />
      </PrimaryWrapperBox>
    </div>
  );
};

export default AlarmsPortal;
