import { Auth } from '@aws-amplify/auth';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import { storeAccessTokenAndEmailLocalStorage } from '../../utils/api/localStorage.utils';
import ENV from '../../utils/shared/environment';
import { UserActions } from '../actions/user.actions';
import type { RootState } from '../store';

export enum RtkTagType {
  BOXES = 'BOXES',
  BOXES_FOR_NEW_SESSION = 'BOXES_FOR_NEW_SESSION',
  BOXES_FOR_SEARCH_SESSION = 'BOXES_FOR_SEARCH_SESSION',
  BOX = 'BOX',
  CONNECTORS_FOR_NEW_SESSION = 'CONNECTORS_FOR_NEW_SESSION',
  OPERATORS = 'OPERATORS',
  CURRENT_USER = 'CURRENT_USER',
  SESSION = 'SESSION',
  SESSIONS = 'SESSIONS',
  TAGS = 'TAGS',
  USERS = 'USERS',
  USER_PROFILE = 'USER_PROFILE',
  VESSELS = 'VESSELS',
  LOGS = 'LOGS',
}

const baseQuery = fetchBaseQuery({
  baseUrl: ENV.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    // eslint-disable-next-line prefer-destructuring
    const token = (getState() as RootState).user.token;

    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }

    return headers;
  },
  paramsSerializer: (params) => {
    const query = new URLSearchParams();

    Object.entries(params).forEach(([k, v]) => {
      if (Array.isArray(v)) {
        v.forEach((vv) => query.append(k, vv));
      } else if (
        v !== undefined &&
        (!(typeof v === 'string') || v.length > 0)
      ) {
        query.append(k, v);
      }
    });
    return query.toString();
  },
});

const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    await Auth.currentSession().then((session) => {
      const tokenRefreshed = session.getIdToken().getJwtToken();
      storeAccessTokenAndEmailLocalStorage(tokenRefreshed);
      api.dispatch(UserActions.setToken(tokenRefreshed));
    });

    // retry the initial query with a new token in state
    result = await baseQuery(args, api, extraOptions);
  }
  return result;
};

// initialize an empty api service that we'll inject endpoints into later as needed
// this pattern of code splitting is recommended by the Redux team to trim down bundle size
export const baseApi = createApi({
  reducerPath: 'baseApi',
  tagTypes: Object.keys(RtkTagType) as RtkTagType[],
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
});
