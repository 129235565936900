import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { PrimaryWrapperBox } from '../../../styled/boxes/PrimaryWrapperBox';
import { StyledHeading } from '../../../styled/headings/StyledHeading';
import { BoxesTable } from '../BoxesTable/BoxesTable';
import { BoxesTableFilter } from '../BoxesTable/BoxesTableFilter';
import { BoxesFilterMobile } from '../BoxesTable/BoxesMobile/BoxesFilterMobile';

const MESSAGES = defineMessages({
  heading: {
    id: 'boxes_overview.heading',
    defaultMessage: 'Assets list',
  },
});

export const BoxesOverview = () => {
  const intl = useIntl();
  const [isMobile] = useState(window.innerWidth < 768);

  return (
    <>
      <StyledHeading variant="h1">
        {intl.formatMessage(MESSAGES.heading)}
      </StyledHeading>
      {isMobile ? <BoxesFilterMobile /> : <BoxesTableFilter />}
      <PrimaryWrapperBox>
        <BoxesTable />
      </PrimaryWrapperBox>
    </>
  );
};
