import React from 'react';
import { useSelector } from 'react-redux';

import { SessionDetail } from '../../pages/Sessions/SessionDetail/SessionDetail';
import { useGetSessionByIdQuery } from '../../store/api/sessions.api';
import { sessionDetailSelector } from '../../store/selectors/session.selectors';

interface IProps {
  sessionId: string;
}

const ConnectorSessionDetail = ({ sessionId }: IProps) => {
  useGetSessionByIdQuery(sessionId);

  const sessionDetail = useSelector(sessionDetailSelector(sessionId));

  return (
    <>{sessionDetail && <SessionDetail sessionDetail={sessionDetail} />}</>
  );
};

// component has to be memoized to avoid unstable rendering
// for more details see https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/no-unstable-nested-components.md
export const MemoizedConnectorSessionDetail = React.memo(
  ConnectorSessionDetail,
);
