import { Auth } from '@aws-amplify/auth';
import { AuthError } from '@aws-amplify/auth/lib/Errors';
import Amplify, { Hub } from '@aws-amplify/core';
import { debounce } from 'lodash';
import React, { createContext, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';

import { UserActions } from '../../store/actions/user.actions';
import { resetStore } from '../../store/store';
import { IAuthHookContext, ISignInPayload } from '../../types/auth.types';
import {
  removeAccessTokenLocalStorage,
  storeAccessTokenAndEmailLocalStorage,
} from '../../utils/api/localStorage.utils';
import ENV from '../../utils/shared/environment';

Amplify.configure({
  Auth: {
    region: ENV.REACT_APP_COGNITO_REGION,
    userPoolId: ENV.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: ENV.REACT_APP_COGNITO_CLIENT_ID,
    oauth: {
      domain: ENV.REACT_APP_COGNITO_BASE_URI,
      scope: ['email', 'openid'],
      redirectSignIn: ENV.REACT_APP_COGNITO_REDIRECT_URI,
      redirectSignOut: ENV.REACT_APP_COGNITO_REDIRECT_URI,
      responseType: 'code', // Authorization code grant
    },
  },
});

const AuthContext = createContext<IAuthHookContext | null>(null);

export const useAuth = () => useContext<IAuthHookContext | null>(AuthContext);

const useProvideAuth = (): IAuthHookContext => {
  const dispatch = useDispatch();

  const signIn = (
    { email, password }: ISignInPayload,
    onError: (error: AuthError) => void,
  ) =>
    Auth.signIn(email, password).catch((e) => {
      onError(e);
    });

  const signOut = () => Auth.signOut();

  const signUp = (
    username: string,
    password: string,
    onError: (error: AuthError) => void,
    onSuccess: () => void,
  ) =>
    Auth.signUp({
      username,
      password,
    })
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        onError(e);
      });

  const confirmSignUp = (
    username: string,
    code: string,
    onError: (error: AuthError) => void,
    onSuccess: () => void,
  ) =>
    Auth.confirmSignUp(username, code)
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        onError(e);
      });

  const verifyCode = (
    username: string,
    code: string,
    onError: (error: AuthError) => void,
    onSuccess: () => void,
  ) =>
    // The purpose of this next method is to make sure the sent code is valid
    // The third parameter needs to be a useless string because the method requires it as a password
    // It needs to be wrong in every case just so that the method validates the code and returns an error
    Auth.forgotPasswordSubmit(username, code, '3babbc8d-5914-45a4-ac67-fdb630c8dbf0')
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        if (e.name === "InvalidPasswordException") {
          onSuccess();
        } else {
          // Something is wrong with the code or any other error
          onError(e);
        }
      });

  const forgotPassword = (
    email: string,
    onError: (error: AuthError) => void,
    onSuccess: () => void,
  ) =>
    Auth.forgotPassword(email)
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        onError(e);
      });

  const forgotPasswordSubmit = (
    code: string,
    username: string,
    newPassword: string,
    onError: (error: AuthError) => void,
    onSuccess: () => void,
  ) =>
    Auth.forgotPasswordSubmit(username, code, newPassword)
      .then(() => {
        onSuccess();
      })
      .catch((e) => {
        onError(e);
      });

  const debouncedLogoutDispatch = useCallback(
    debounce(() => {
      dispatch(resetStore());
    }, 1000),
    [],
  );

  const debouncedSetTokenDispatch = useCallback(
    debounce((token: string, email: string) => {
      storeAccessTokenAndEmailLocalStorage(token, email);
      dispatch(UserActions.setToken(token));
    }, 1000),
    [],
  );

  Hub.listen('auth', ({ payload: { data, event } }) => {
    // eslint-disable-next-line default-case
    switch (event) {
      case 'signIn':
        // eslint-disable-next-line no-case-declarations
        const token = data.getSignInUserSession().getIdToken().getJwtToken();
        debouncedSetTokenDispatch(token, data.attributes.email);
        break;
      case 'signOut':
        removeAccessTokenLocalStorage();
        debouncedLogoutDispatch();
        break;
      case 'customOAuthState':
        window.location = data; // restore path after authentication
        break;
    }
  });

  return {
    signIn,
    signUp,
    confirmSignUp,
    verifyCode, // Added verifyCode function
    signOut,
    forgotPassword,
    forgotPasswordSubmit,
  };
};

export const ProvideAuth = ({ children }: { children: React.ReactNode }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};
