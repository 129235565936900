import { ReactNode } from 'react';

import { ITagStatus } from '../../types/tag.types';

interface IProps {
  tagName: string;
  contentList: Record<string, string | number | ReactNode>;
  tags: ITagStatus[];
}

export const genericComponentByTagValue = ({
  contentList,
  tagName,
  tags,
}: IProps) => {
  const tag = tags.find((t) => t.tag === tagName);

  if (!tag) {
    return null;
  }

  return contentList[tag.value];
};
