import styled from '@emotion/styled';
import { Switch } from 'antd';

import { colors } from '../../theme';

interface IProps {
  checked: boolean;
}

export const StyledSwitch = styled(Switch)(({ checked }: IProps) => ({
  backgroundColor: checked ? colors.green.primary : 'rgba(0, 0, 0, 0.25)',
}));
