import React from 'react';

export const AuthorisationOKIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6" clipPath="url(#clip0_2802_28068)">
      <path
        d="M14.9999 29.216C14.9999 29.216 2.3999 23.3996 2.3999 5.29341C10.2377 3.57261 12.5411 0.599609 14.9999 0.599609C17.4587 0.599609 19.7621 3.57261 27.5999 5.29341C27.5999 22.7996 14.9999 29.216 14.9999 29.216Z"
        stroke="#63CF97"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.7998 14.3996L14.53 17.774L19.7998 9.59961"
        stroke="#63CF97"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_2802_28068">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
