export enum NavLinks {
  'DASHBOARD' = 'DASHBOARD',
  'MAP' = 'MAP',
  'LIST' = 'LIST',
  'LOGS' = 'LOGS',
  'SESSIONS' = 'SESSIONS',
  'USERS' = 'USERS',
  'VESSELS' = 'VESSELS',
  'MAINTENANCE' = 'MAINTENANCE',
  'SETTINGS' = 'SETTINGS',
}
