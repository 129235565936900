import React from 'react';

export const VesselIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M2.03944 13.3653C1.6812 12.5005 1.47051 11.5829 1.41492 10.6508L18.1851 10.6508C18.1296 11.5829 17.9189 12.5005 17.5606 13.3653C17.1385 14.3845 16.5197 15.3105 15.7397 16.0905C14.9597 16.8705 14.0337 17.4892 13.0146 17.9114C11.9954 18.3335 10.9031 18.5508 9.80003 18.5508C8.69692 18.5508 7.60462 18.3335 6.58549 17.9114C5.56635 17.4892 4.64034 16.8705 3.86033 16.0905C3.08032 15.3105 2.46158 14.3845 2.03944 13.3653Z" />
    <path d="M17.2509 5.92877C17.7857 6.80509 18.0954 7.72587 18.1773 8.65435H5.77913L5.77913 0.55141C7.32661 0.591589 8.85077 0.817977 10.2795 1.21967C11.8621 1.66466 13.2913 2.31452 14.4878 3.12671C15.6845 3.93901 16.6193 4.89364 17.2509 5.92877Z" />
  </svg>
);
