import { IOperator } from '../../types/operator.types';
import { baseApi, RtkTagType } from './base.api';

const url = "/companies";

export const companiesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOperators: builder.query<IOperator[], void>({
      query: () => `${url}/operators`,
      providesTags: [{ type: RtkTagType.OPERATORS }],
    }),
  }),
});

export const { useGetOperatorsQuery } = companiesApi;
