import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Duration } from 'date-fns';
import { colors, theme } from '../../../../theme';
import {
  ESessionConsumptionUnit,
  ISessionDetail,
} from '../../../../types/session.types';
import { SessionMetricsBox } from './SessionMetricsBox';
import { durationInMonthsDaysHoursMinutesSeconds } from '../../../../utils/shared/time/durationInMonthsDaysHoursMinutesSeconds';
import { EndSessionButtonWithModal } from '../../../../components/sessions/EndSessionButtonWithModal';
import { formatNumber } from '../../../../utils/shared/formats/formatNumbers';

interface IProps {
  session: ISessionDetail;
}

const SessionMetricsBoxContainer = styled.div`
  margin: ${theme.spacing.medium} 0;
`;

const KWHText = styled.span`
  color: ${colors.green.background};

  font-weight: ${theme.fontWeights.regular};
`;

export const SessionMetricsSummary = ({ session }: IProps) => {
  const { endedAt, startedAt } = session;

  const [duration, setDuration] = useState<Duration | undefined>(
    durationInMonthsDaysHoursMinutesSeconds(startedAt, endedAt),
  );

  const isElectricityBox =
    session.consumption.unit === ESessionConsumptionUnit.KWH;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (endedAt) {
      setDuration(durationInMonthsDaysHoursMinutesSeconds(startedAt, endedAt));
    } else {
      const interval = setInterval(() => {
        setDuration(
          durationInMonthsDaysHoursMinutesSeconds(startedAt, endedAt),
        );
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [session]);

  return (
    <>
      {session && (
        <div>
          <SessionMetricsBoxContainer>
            <SessionMetricsBox
              active={session.active}
              title="Elapsed time"
              type={1}
            >
              {duration ? (
                <>
                  {`${
                    duration.years && duration.years > 0
                      ? `${duration.years}y `
                      : ''
                  }`}
                  {`${
                    duration.months && duration.months > 0
                      ? `${duration.months}m `
                      : ''
                  }`}
                  {`${
                    duration.days && duration.days > 0
                      ? `${duration.days}d `
                      : ''
                  }`}
                  {`${duration.hours}h ${duration.minutes}m ${duration.seconds}s`}
                </>
              ) : (
                'N/A'
              )}
            </SessionMetricsBox>
            {isElectricityBox ? (
              <SessionMetricsBox
                active={session.active}
                title="Total consumption"
                type={2}>
                {formatNumber(session.consumption?.total)}{' '}
                <KWHText>kWh</KWHText>
              </SessionMetricsBox>
            ) : (
              <SessionMetricsBox
                active={session.active}
                title="Total consumption"
                type={3}
              >
                {formatNumber(session.consumption?.total)}{' '}
                <KWHText>
                  m<sup>3</sup>
                </KWHText>
              </SessionMetricsBox>
            )}
            <SessionMetricsBox
              active={session.active}
              title="Total spent"
              type={4}
            >
              {formatNumber(session.consumption?.total)}{' '}
              <KWHText>Euros</KWHText>
            </SessionMetricsBox>
          </SessionMetricsBoxContainer>
          {!session.endedAt && (
            <EndSessionButtonWithModal
              fontWeight="bold"
              height="75px"
              fullWidth
              sessionId={session.id}
            />
          )}
        </div>
      )}
    </>
  );
};
