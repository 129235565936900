import { Col, Row } from 'antd';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { BoxesAlarmsAndWarnings } from '../../../../components/boxes/BoxesAlarmsAndWarnings';
import { PrimaryWrapperBox } from '../../../../styled/boxes/PrimaryWrapperBox';
import { StyledHeading } from '../../../../styled/headings/StyledHeading';
import { theme } from '../../../../theme';
import { IAssetInfo } from '../../../../types/box.types';
import { BoxActions } from './BoxActions';
import { ConnectorsTable } from './ConnectorsTable/ConnectorsTable';
import { GeneralBoxInfo } from './GeneralBoxInfo';
import { WhiteBox } from '../../../../components/layout/boxes/WhiteBox';
import { AssetDetails } from './AssetDetails';
import { SubscribeToTags } from '../../BoxesTable/SubscribeToTags';
import { getBoxConnectivityStatus } from '../../../../utils/boxes/getBoxConnectivityStatus';

const MESSAGES = defineMessages({
  heading: {
    id: 'box_status_portal.heading',
    defaultMessage: 'Connectors',
  },
  assetActions: {
    id: 'box_status_portal.assetActions',
    defaultMessage: 'Asset actions',
  },
  assetDetails: {
    id: 'box_status_portal.assetDetails',
    defaultMessage: 'Asset details',
  },
});

interface IProps {
  boxInfo?: IAssetInfo;
}

export const BoxStatusPortal = ({ boxInfo }: IProps) => {
  const intl = useIntl();
  const boxIsOffline = boxInfo ? getBoxConnectivityStatus(boxInfo.id) : true;

  return (
    <Row>
      {boxInfo && (
        <Col span={24}>
          <Row gutter={32}>
            <Col
              span={14}
              xs={{ span: 24 }}
              lg={{ span: 16 }}
              xl={{ span: 12 }}
            >
              <GeneralBoxInfo boxInfo={boxInfo} />
            </Col>
            <Col span={10} xs={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 12 }}>
              <StyledHeading variant="h4" m={`${theme.spacing.xLarge} 0`}>
                {intl.formatMessage(MESSAGES.assetActions)}
              </StyledHeading>
              <WhiteBox width="100%">
                <BoxActions boxId={boxInfo.id} boxIsOffline={boxIsOffline} />
              </WhiteBox>

              <StyledHeading variant="h4" m={`${theme.spacing.xLarge} 0`}>
                {intl.formatMessage(MESSAGES.assetDetails)}
              </StyledHeading>
              <WhiteBox width="100%">
                <AssetDetails boxInfo={boxInfo} />
              </WhiteBox>
            </Col>
          </Row>

          <BoxesAlarmsAndWarnings boxId={boxInfo.id} />
          {boxInfo.connectors.map((connector) => {
            return <SubscribeToTags connector={connector} />;
          })}

          <Row>
            <Col span={24}>
              <>
                <StyledHeading variant="h4" m={`${theme.spacing.xLarge} 0`}>
                  {intl.formatMessage(MESSAGES.heading)}
                </StyledHeading>
                <PrimaryWrapperBox>
                  <ConnectorsTable
                    boxIsOffline={boxIsOffline}
                    boxId={boxInfo.id}
                  />
                </PrimaryWrapperBox>
              </>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};
