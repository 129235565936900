import * as React from 'react';
const Navigation = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      clipPath="url(#a)"
    >
      <path d="M16 6.8c0 5.44-6 12.4-6 12.4S4 12.36 4 6.8c0-3.32 2.68-6 6-6s6 2.68 6 6Z" />
      <path d="M10 4.8a2.4 2.4 0 1 0 0 4.8 2.4 2.4 0 0 0 0-4.8Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Navigation;
