import React from 'react';

import { StyledBox } from '../../../../../styled/boxes/StyledBox';
import { IConnectorStatusIconProps } from '../../../../../types/connector.types';
import { colors } from '../../../../../theme';

export const ActiveEnergyConsumptionIcon = ({
  active,
  connectorWebStatusValue,
  ...space
}: IConnectorStatusIconProps) => (
  <StyledBox {...space}>
    <svg
      width="10px"
      height="20px"
      viewBox="0 0 10 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-connector-consumption"
    >
      <path
        d="M9 8.00002H5.4L8.8 0.400024H5L1 10H4.6L1.4 19.6L9 8.00002Z"
        fill={colors.green.primary}
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </StyledBox>
);
