import styled from '@emotion/styled';
import { Input } from 'antd';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';
import { colors, theme } from '../../theme';

const { Search } = Input;

interface IStyledSearchProps {
  inputStyle?: React.CSSProperties;
}

export const StyledSearch = styled(Search)<
  LayoutProps & SpaceProps & IStyledSearchProps
>`
  width: 150px;

  /* Ant Design default styling overrides */
  .ant-input {
    border-radius: ${theme.borderRadius.medium};
    ${({ inputStyle }) => inputStyle && { ...inputStyle }}
  }
  .ant-input-group-addon {
    border-top-left-radius: ${theme.borderRadius.medium};
    border-bottom-left-radius: ${theme.borderRadius.medium};
  }
  .ant-input-search-button {
    border-top-right-radius: ${theme.borderRadius.medium} !important;
    border-bottom-right-radius: ${theme.borderRadius.medium} !important;
  }

  .ant-input:hover {
    border: 1px solid ${colors.green.primary};
  }

  .ant-input:focus,
  .ant-input-search-button:hover {
    box-shadow: none;
    border: 1px solid ${colors.green.primary};
  }

  .ant-btn:focus {
    border: 1px solid ${colors.green.primary};
  }

  .ant-input-search
    .ant-input:hover
    + .ant-input-group-addon
    .ant-input-search-button:not(.ant-btn-primary),
  .ant-input-search
    .ant-input:focus
    + .ant-input-group-addon
    .ant-input-search-button:not(.ant-btn-primary) {
    border-left: none;
  }
`;
