const localStorageTokenKey = 'tec2-token';
const localStorageEmailKey = 'tec2-email';

export const getAccessTokenLocalStorage = (): string | null =>
  localStorage.getItem(localStorageTokenKey);
export const getEmailLocalStorage = (): string | null =>
  localStorage.getItem(localStorageEmailKey);

export const storeAccessTokenAndEmailLocalStorage = (
  idToken: string,
  email?: string,
) => {
  localStorage.setItem(localStorageTokenKey, idToken);
  if (email) {
    localStorage.setItem(localStorageEmailKey, email);
  }
};

export const removeAccessTokenLocalStorage = () => {
  localStorage.removeItem(localStorageTokenKey);
  localStorage.removeItem(localStorageEmailKey);
};
