import React from 'react';

import { StyledBox } from '../../../../../styled/boxes/StyledBox';
import { IConnectorStatusIconProps } from '../../../../../types/connector.types';
import { IconSize } from '../../../../../types/icon.types';
import { getConnectorStatusIconAndBackgroundColor } from '../../../../../utils/connectors/getConnectorStatusIconAndBackgroundColor';
import { getIconSize } from '../../../../../utils/shared/icons/getIconSize';

export const NoWaterIcon = ({
  active,
  connectorWebStatusValue,
  ...space
}: IConnectorStatusIconProps) => (
  <StyledBox {...space}>
    <svg
      width={getIconSize(IconSize.SMALL).width}
      height={getIconSize(IconSize.SMALL).height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-connector-water-cons-inactive"
    >
      <g clipPath="url(#clip0_3368_38669)">
        <path
          d="M10 0.469238C10 0.469238 3.354 8.52324 3.354 12.9536C3.354 16.6248 6.3288 19.6 10 19.6C13.6712 19.6 16.646 16.6248 16.646 12.954C16.646 8.52324 10 0.469238 10 0.469238Z"
          stroke={
            getConnectorStatusIconAndBackgroundColor(
              active,
              connectorWebStatusValue,
            ).iconBorderColor
          }
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.53722 17.3965C9.53722 17.3965 5.89722 16.8733 5.89722 12.8777C5.89722 9.84245 9.76362 4.85205 9.76362 4.85205"
          stroke={
            getConnectorStatusIconAndBackgroundColor(
              active,
              connectorWebStatusValue,
            ).iconBorderColor
          }
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3368_38669">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </StyledBox>
);
