import { toNumber } from 'lodash';
import { Rule } from 'rc-field-form/lib/interface';
import { IntlShape } from 'react-intl';

import { FORM_VALIDATION_MESSAGES } from '../../consts/form.consts';

export const getNumberRule = (label: string, intl: IntlShape): Rule => ({
  type: 'integer',
  transform: toNumber,
  message: intl.formatMessage(FORM_VALIDATION_MESSAGES.number, {
    label,
  }),
});
