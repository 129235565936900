import { HourglassOutlined, ThunderboltOutlined, EuroCircleOutlined } from '@ant-design/icons';
import React, { ReactNode } from 'react';
import styled from 'styled-components'

import { theme, colors } from '../../../../theme';
import { WaterDrop } from '../../../../icons';

interface SessionMetricsBoxProps extends WrapperProps {
  active: boolean;
  large?: boolean;
  children: ReactNode;
  suffix?: ReactNode;
  title?: string;
  type: number;
  icon?: ReactNode;
}

interface WrapperProps {
  active: boolean;
}

interface Types {
  [key: number]: ReactNode;
}

interface SessionMetricsIconProps {
  icon?: boolean;
}

interface SessionMetricsBoxLeftProps {
  large?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;

  background-color: ${colors.green.tertiary};
  
  border-radius: ${theme.borderRadius.medium};

  &:nth-child(n+2) {
    margin-top: ${theme.borderRadius.medium};
  }
`
  
const SessionMetricsBoxLeft = styled.div<SessionMetricsBoxLeftProps>`
  border-radius: ${theme.borderRadius.medium} 0 0 ${theme.borderRadius.medium};
  background-color: ${colors.green.background};
  
  padding: ${(props) => props.large ? theme.spacing.xxLarge : theme.spacing.xLarge};

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
`

const SessionMetricsIcon = styled.div<SessionMetricsIconProps>`
  transform: ${(props) => props.icon ? 'none' : 'scale(1.5)'};

  display: flex;
`
  
const SessionMetricsBoxRight = styled.div`
  padding: ${theme.spacing.small};

  align-content: center;

  width: 100%;
`
  
const SessionMetricsBoxTitle = styled.h4`
  color: ${colors.black.primary};
  font-size: ${theme.fontSizes.small}px;
  font-weight: ${theme.fontWeights.light};

  margin-bottom: 0;
`
  
const SessionMetricsBoxInfo = styled.h4<WrapperProps>`
  color: ${(props) => props.active ? colors.green.primary : colors.black.primary};
  font-size: ${theme.fontSizes.large}px;
  font-weight: ${theme.fontWeights.bolder};

  margin-bottom: 0;
`

const SessionMetricsBoxSuffix = styled.div`

`

const types: Types = {
  1: <HourglassOutlined />,
  2: <ThunderboltOutlined />,
  3: <WaterDrop width='16px' height='16px' />,
  4: <EuroCircleOutlined />,
  5: <><ThunderboltOutlined /><WaterDrop width='14px' height='14px'/></>,
}

export const SessionMetricsBox = ({ active = false, children, icon, large = false, suffix, title, type }: SessionMetricsBoxProps) => {
  return (
    <Wrapper active={active}>
      <SessionMetricsBoxLeft large={large}>
        <SessionMetricsIcon icon={!!icon}>
          {icon || types[type]}
        </SessionMetricsIcon>
      </SessionMetricsBoxLeft>

      <SessionMetricsBoxRight>
        {title && <SessionMetricsBoxTitle>{title}</SessionMetricsBoxTitle>}
        <SessionMetricsBoxInfo active={active}>{children}</SessionMetricsBoxInfo>
      </SessionMetricsBoxRight>

      <SessionMetricsBoxSuffix>
        {suffix}
      </SessionMetricsBoxSuffix>
    </Wrapper>
  );
};
