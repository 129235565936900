import { Rule } from 'rc-field-form/lib/interface';
import { IntlShape } from 'react-intl';

import { FORM_VALIDATION_MESSAGES } from '../../consts/form.consts';

export const getPostalCodeRule = (label: string, intl: IntlShape): Rule => ({
  pattern: /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i,
  message: intl.formatMessage(FORM_VALIDATION_MESSAGES.regex, {
    label,
  }),
});
