import React from 'react';
import * as countries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import { Provider } from 'react-redux';
import { ProvideAuth } from './authentication/hooks/useAuth';
import { store } from './store/store';
import Routes from './routes';
// todo when other languages are added, make sure to register them for countries list
countries.registerLocale(en);

export const App = () => {
  return (
    <Provider store={store}>
      <ProvideAuth>
        <Routes />
      </ProvideAuth>
    </Provider>
  );
};
