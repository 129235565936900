import L, { LatLngExpression } from 'leaflet';
import React from 'react';
import { Badge } from 'antd';
import ReactDOMServer from 'react-dom/server';
import {
  MapContainer,
  Marker,
  MarkerProps,
  Popup,
  TileLayer,
} from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { config } from './config';

import boxIcon from './icons/box.svg';

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { Identifier } from '../../types/common.types';

export interface IMarker extends MarkerProps, Identifier {}

interface IMap {
  markers?: IMarker[];
  children?: React.ReactNode;
  popupRenderer?: (props: IMarker) => React.ReactNode;
}

const icons = {
  box: boxIcon,
};

export const createIcon = (type) => {
  return L.icon({
    iconUrl: icons[type],
    iconSize: [35, 35],
    className: 'leaflet-div-icon',
    attribution: type,
  });
};

const createClusterCustomIcon = (cluster) => {
  const childMarkes = cluster.getAllChildMarkers();
  const groupedByType = childMarkes.reduce((acc, marker) => {
    const type = marker.options.icon.options.attribution;
    return {
      ...acc,
      [type]: (acc[type] || 0) + 1,
    };
  }, {});

  return L.divIcon({
    html: ReactDOMServer.renderToString(
      <>
        {Object.entries(groupedByType).map(([type, count]) => (
          <Badge key={type} count={count} style={{ backgroundColor: 'red' }}>
            <img
              style={{ width: 35, height: 35 }}
              src={icons[type]}
              alt={type}
            />
          </Badge>
        ))}
      </>,
    ),
    className: 'marker-cluster-custom',
    iconSize: L.point(40, 40, true),
  });
};

const Map = React.forwardRef<L.Map, IMap>(
  ({ markers, children, popupRenderer }, ref) => {
    return (
      <MapContainer
        className="markercluster-map"
        center={config.center as LatLngExpression}
        zoom={config.zoom}
        ref={ref}
      >
        <TileLayer
          url={config.tileLayer.url}
          attribution={config.tileLayer.attribution}
        />
        <MarkerClusterGroup iconCreateFunction={createClusterCustomIcon}>
          {markers.map((marker) => (
            <Marker key={marker.id} {...marker}>
              <Popup>{popupRenderer?.(marker) || marker.id}</Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
        {children}
      </MapContainer>
    );
  },
);

export default Map;
