import { colors } from '../../theme';
import { BoxTechStatus } from '../../types/box.types';

export const getBoxStatusIconColor = (boxTechStatus: BoxTechStatus) => {
  if (boxTechStatus === BoxTechStatus.OUT_OF_SERVICE) {
    return colors.lightGrey.secondary;
  }
  if (boxTechStatus === BoxTechStatus.OK) {
    return colors.black.primary;
  }
  if (boxTechStatus === BoxTechStatus.WARNING) {
    return colors.yellow.primary;
  }
  if (boxTechStatus === BoxTechStatus.ALARM) {
    return colors.red.secondary;
  }
  return colors.black.primary;
};
