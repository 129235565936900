/* eslint-disable */
import React from 'react';
import createIcon from '../utils/icons/createIcon';
import { colors } from '../theme';

export default createIcon(
  <>
    <path d="M1.99993 20.8217L3.75734 16.1407L8.43831 17.8981" stroke="white" stroke-miterlimit="10" stroke-linecap="round"/>
    <path d="M13.6321 3V9" stroke="white" stroke-width="2" stroke-linecap="round"/>
    <path d="M4.13208 17C5.13208 20 8.10923 24 13.6321 24C19.1549 24 23.6321 19.5228 23.6321 14C23.6321 8.47715 19.1321 5 16.6321 4.5" stroke="white"/>
    <path d="M13.6321 4.00005C8.13208 3.5 3.63208 8.4772 3.63208 14C3.63208 15.2314 3.72502 15.9107 4.13208 17" stroke="white" stroke-dasharray="2 2"/>
  </>,
  '0 0 26 26',
  'Restart',
);
