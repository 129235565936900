import { createSelector } from '@reduxjs/toolkit';

import { IOperator } from '../../types/operator.types';
import { companiesApi } from '../api/companies.api';

export const operatorsRtkState = companiesApi.endpoints.getOperators.select();

export const operatorsSelector = createSelector(
  operatorsRtkState,
  (state): IOperator[] | undefined => state.data,
);
