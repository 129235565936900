import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import React, { useRef, useState } from 'react';
import { format } from 'date-fns';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables, type ChartOptions } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { StyledButton } from '../../../../styled/buttons/StyledButton';
import { theme } from '../../../../theme';
import { TechmetrysGraph } from '../../../../types/session.types';
import 'chartjs-adapter-date-fns';

const GraphContainer = styled.div``;

const GraphButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  margin-top: ${theme.spacing.xLarge};
`;

export const SessionGraph: React.FC<TechmetrysGraph> = ({ datasets }) => {
  const chartRef = useRef<any>(null);
  const [isZoomEnabled, setIsZoomEnabled] = useState(false);
  const [isMobile] = useState(window.innerWidth < 768);

  ChartJS.register(...registerables, zoomPlugin);

  const chartData: TechmetrysGraph = {
    datasets,
  };

  const generateTitle = (tooltipItems: any[]) => {
    if (tooltipItems.length > 0) {
      return format(new Date(tooltipItems[0].raw.x), 'yyyy-MM-dd HH:mm:ss');
    }
    return 'huh';
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        time: {
          displayFormats: {
            day: 'yyyy-MM-dd',
            hour: 'yyyy-MM-dd - HH:mm',
          },
          unit: 'hour',
        },
      },
    },
    plugins: {
      zoom: {
        limits: {
          y: { min: 0, max: 200, minRange: 50 },
        },
        pan: {
          enabled: true,
          mode: 'xy' as const, // Cast mode to the expected type
        },
        zoom: {
          wheel: {
            enabled: isZoomEnabled,
          },
          pinch: {
            enabled: isZoomEnabled,
          },
          mode: 'x' as const, // Cast mode to the expected type
          // speed: 0.005,
        },
      },
      tooltip: {
        callbacks: {
          title: generateTitle,
        },
        enabled: true,
        intersect: false,
        mode: 'point',
      },
    },
  };

  const handleResetZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.resetZoom();
    }
  };

  const handleIncreaseZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.zoom(1.1);
    }
  };

  const handleDecreaseZoom = () => {
    if (chartRef && chartRef.current) {
      chartRef.current.zoom(0.9);
    }
  };

  return (
    <GraphContainer>
      <Line
        ref={chartRef}
        data={chartData}
        options={options}
        width={isMobile ? '100%' : 'auto'}
      />

      <GraphButtons>
        <StyledButton
          variant="primary"
          onClick={() => setIsZoomEnabled((prev) => !prev)}
        >
          Toggle zoom
        </StyledButton>
        <StyledButton variant="primary" onClick={handleResetZoom}>
          Reset zoom
        </StyledButton>
        <StyledButton variant="primary" onClick={handleIncreaseZoom}>
          <ZoomInOutlined />
        </StyledButton>
        <StyledButton variant="primary" onClick={handleDecreaseZoom}>
          <ZoomOutOutlined />
        </StyledButton>
      </GraphButtons>
    </GraphContainer>
  );
};
