import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { MaintenanceTable } from './MaintenanceTable';

import { StyledHeading } from '../../styled/headings/StyledHeading';

import { WhiteBox } from '../../components/layout/boxes/WhiteBox';
import { TaskCreate } from './Tasks/TaskCreate';
import { MaintenanceFilters } from './MaintenanceFilters';

const MESSAGES = defineMessages({
  heading: {
    id: 'maintenance.heading',
    defaultMessage: 'Maintenance',
  },
  addNewTask: {
    id: 'maintenance.addNewTask',
    defaultMessage: 'Add new task',
  },
});

export const MaintenanceOverview = () => {
  const intl = useIntl();

  return (
    <>
      <StyledHeading variant="h1">
        {intl.formatMessage(MESSAGES.heading)}
      </StyledHeading>

      <TaskCreate />

      <MaintenanceFilters />

      <WhiteBox width="100%">
        <MaintenanceTable />
      </WhiteBox>
    </>
  );
};
