import {
  IAssetsForNewSessionParams,
  IAssetForNewSession,
  IAssetForSearchSession,
  IAssetInfo,
  IAssetResponse,
} from '../../types/box.types';
import { baseApi, RtkTagType } from './base.api';

import { IPagedAssetFilters } from '../../types/filters.types';

const url = "/boxes";

export const boxesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAuthorizedBoxes: builder.query<IAssetResponse, IPagedAssetFilters>({
      query: (params) => ({
        url: `${url}/authorized`,
        params: {
          ...params,
          filter: JSON.stringify(params.filter) // Serialize the filter array to JSON
        }
      }),
      providesTags: [{ type: RtkTagType.BOXES }],
    }),
    getAuthorizedBoxById: builder.query<IAssetInfo, string>({
      query: (boxId) => `${url}/authorized/${boxId}`,
      providesTags: (result, error, id) => [{ type: RtkTagType.BOX, id }],
    }),
    getBoxesForNewSession: builder.query<
      IAssetForNewSession[],
      IAssetsForNewSessionParams
    >({
      query: (params) => ({
        url,
        params: {
          ...params,
          fields: 'id,label',
        },
      }),
      providesTags: [{ type: RtkTagType.BOXES_FOR_NEW_SESSION }],
    }),
    getBoxesForSearchSession: builder.query<IAssetForSearchSession[], void>({
      query: () => ({
        url,
        params: {
          fields: 'id,label,operator',
        },
      }),
      providesTags: [{ type: RtkTagType.BOXES_FOR_SEARCH_SESSION }],
    }),
  }),
});

export const {
  useGetAuthorizedBoxByIdQuery,
  useGetAuthorizedBoxesQuery,
  useGetBoxesForNewSessionQuery,
  useGetBoxesForSearchSessionQuery,
} = boxesApi;
