import styled from '@emotion/styled';
import { Tabs } from 'antd';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { colors } from '../../../theme';
import { BoxDetailTabs } from '../../../types/tab.types';

const MESSAGES = defineMessages({
  statusTabPane: {
    id: 'top_bar.statusTabPane',
    defaultMessage: 'Status',
  },
  sessionsTabPane: {
    id: 'top_bar.sessionsTabPane',
    defaultMessage: 'Sessions',
  },
  alarmsTabPane: {
    id: 'top_bar.alarmsTabPane',
    defaultMessage: 'Alarms / Warnings',
  },
  developerTabPane: {
    id: 'top_bar.developerTabPane',
    defaultMessage: 'Developer',
  },
  logsTabPane: {
    id: 'top_bar.logsTabPane',
    defaultMessage: 'Logs',
  },
});

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)({
  '.ant-tabs-ink-bar': {
    background: colors.green.primary,
  },
  '.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
    color: colors.green.primary,
  },
  '.ant-tabs-tab': {
    '&:hover': {
      color: colors.green.primary,
    },
  },
});

interface IProps {
  onTabClick: (tab: BoxDetailTabs) => void;
  selectedTab: BoxDetailTabs;
}

export const TopBar = ({ onTabClick, selectedTab }: IProps) => {
  const intl = useIntl();

  const handleTabClick = (tab: string) => {
    const currentTab = tab as BoxDetailTabs;
    onTabClick(currentTab);
  };

  return (
    <StyledTabs
      defaultActiveKey={selectedTab || BoxDetailTabs.STATUS}
      activeKey={selectedTab}
      onTabClick={(tab: string) => handleTabClick(tab)}
      data-testid="box-detail-tabs"
    >
      <TabPane
        tab={intl.formatMessage(MESSAGES.statusTabPane)}
        key={BoxDetailTabs.STATUS}
      />
      <TabPane
        tab={intl.formatMessage(MESSAGES.sessionsTabPane)}
        key={BoxDetailTabs.SESSIONS}
      />
      <TabPane
        tab={intl.formatMessage(MESSAGES.alarmsTabPane)}
        key={BoxDetailTabs.ALARMS}
      />
      <TabPane
        tab={intl.formatMessage(MESSAGES.developerTabPane)}
        key={BoxDetailTabs.DEVELOPER}
      />
      <TabPane
        tab={intl.formatMessage(MESSAGES.logsTabPane)}
        key={BoxDetailTabs.LOGS}
      />
    </StyledTabs>
  );
};
