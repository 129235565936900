import { NavLinks } from '../../types/navigation.types';
import { ICurrentUser, UserPermission } from '../../types/user.types';
import { checkIfUserHasPermission } from '../users/checkIfUserHasPermission';

export const getInitialNavLink = (currentUser?: ICurrentUser | null) => {
  const canManageAssets =
    currentUser &&
    checkIfUserHasPermission(currentUser, UserPermission.MANAGE_ASSETS);
  const canManageCompanySessions =
    currentUser &&
    checkIfUserHasPermission(
      currentUser,
      UserPermission.MANAGE_COMPANY_SESSIONS,
    );
  const canManageVessels =
    currentUser &&
    checkIfUserHasPermission(currentUser, UserPermission.MANAGE_VESSELS);
  const canManageUsers =
    currentUser &&
    checkIfUserHasPermission(currentUser, UserPermission.MANAGE_USERS);

  if (!currentUser) return [];
  if (canManageAssets) return [NavLinks.DASHBOARD];
  if (canManageCompanySessions) return [NavLinks.SESSIONS];
  if (canManageVessels) return [NavLinks.VESSELS];
  if (canManageUsers) return [NavLinks.USERS];

  return [];
};
