import { UserRole } from '../../types/user.types';
import { Actions } from './actions';

class RegistrationActionsClass extends Actions {
  constructor() {
    super('REGISTRATION');
  }

  setUserRole = this.createAction<UserRole>('setUserRole');
}

export const RegistrationActions = new RegistrationActionsClass();
