import React from 'react';
import { useSelector } from 'react-redux';

import { mostRecentTagStatusesByRequestIdAssetIdAndTagsSelector } from '../../store/selectors/tags.selectors';
import { ConnectorTag, ITagStatus } from '../../types/tag.types';
import { AssetAlarmsAndWarnings } from '../assetWarningsAndAlarms/AssetAlarmsAndWarnings';

interface IProps {
  boxId: string;
  connectorId: string;
}

export const ConnectorAlarmsAndWarnings = ({ boxId, connectorId }: IProps) => {
  const connectorAlarmsAndWarningTagStatuses: ITagStatus[] | undefined =
    useSelector(
      mostRecentTagStatusesByRequestIdAssetIdAndTagsSelector(
        boxId,
        connectorId,
        [
          ConnectorTag.V3_MAINTENANCE,
          ConnectorTag.V3_POINT_WARNING,
          ConnectorTag.V3_SIMULATION_MODE,
          ConnectorTag.V3_TAMPERING,
          ConnectorTag.V3_TEST_MODE,
          ConnectorTag.V3_VESSEL_DEFINITIEVE,
          ConnectorTag.V3_VESSEL_DIFF_TRIP,
          ConnectorTag.V3_VESSEL_OVERLOAD_TRIP,
          ConnectorTag.V4_BENDER,
          ConnectorTag.V4_BOX_FAIL,
          ConnectorTag.V4_CABLE_PULL,
          ConnectorTag.V4_DIFF_RESET,
          ConnectorTag.V4_METER,
          ConnectorTag.V4_METER_FUSE,
          ConnectorTag.V4_POINT_ALARM,
          ConnectorTag.V4_PRIME_DRIVE,
          ConnectorTag.V4_SAFETY_LOOP,
          ConnectorTag.V4_SECOND_DRIVE,
          ConnectorTag.V4_VOLTAGE_ON_CABLE,
          ConnectorTag.V4_WEB_REMOVAL_FAIL,
        ],
      ),
    );

  const connectorAlarmsAndWarnings =
    connectorAlarmsAndWarningTagStatuses?.filter(
      (tagStatus) => tagStatus.value === '2',
    );

  return (
    <AssetAlarmsAndWarnings alarmsAndWarnings={connectorAlarmsAndWarnings} />
  );
};

// component has to be memoized to avoid unstable rendering
// for more details see https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/no-unstable-nested-components.md
export const MemoizedConnectorAlarmsAndWarnings = React.memo(
  ConnectorAlarmsAndWarnings,
);
