import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { IIconProps } from '../../../../types/icon.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const LocationIcon = ({ size, ...space }: IIconProps) => (
  <StyledBox {...space} height={getIconSize(size).height}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-location"
    >
      <path
        d="M24.5 10.7002C24.5 18.8602 15.5 29.3002 15.5 29.3002C15.5 29.3002 6.5 19.0402 6.5 10.7002C6.5 5.72019 10.52 1.7002 15.5 1.7002C20.48 1.7002 24.5 5.72019 24.5 10.7002Z"
        stroke="#262626"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 7.7002C14.5452 7.7002 13.6296 8.07948 12.9544 8.75461C12.2793 9.42974 11.9 10.3454 11.9 11.3002C11.9 12.255 12.2793 13.1706 12.9544 13.8458C13.6296 14.5209 14.5452 14.9002 15.5 14.9002C16.4548 14.9002 17.3705 14.5209 18.0456 13.8458C18.7207 13.1706 19.1 12.255 19.1 11.3002C19.1 10.3454 18.7207 9.42974 18.0456 8.75461C17.3705 8.07948 16.4548 7.7002 15.5 7.7002V7.7002Z"
        stroke="#262626"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  </StyledBox>
);
