import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { colors } from '../../../../theme';
import { IAssetStatusIconProps } from '../../../../types/box.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const MaintenanceIcon = ({ size, ...space }: IAssetStatusIconProps) => (
  <StyledBox {...space} height={getIconSize(size).height}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-maintenance"
    >
      <g clipPath="url(#clip0_2476_111973)">
        <path
          d="M17.2 11.5L18 10.7C19.75 11.75 21.5 11.95 22.85 10.6C24.1 9.35002 25.45 6.40002 24.85 4.60002L22.2 7.25002C21.85 7.60002 21.25 7.60002 20.85 7.25002L18.2 4.60002C17.85 4.25002 17.85 3.65002 18.2 3.25002L20.85 0.600016C19.1 1.61948e-05 16.1 1.35002 14.85 2.60002C13.5 3.95002 13.65 5.75002 14.75 7.45002L13.35 8.85002L17.2 11.5ZM9.40005 12.9L6.85005 15.45C6.45005 15.35 6.05005 15.3 5.65005 15.3C3.10005 15.3 1.05005 17.35 1.05005 19.9C1.05005 22.45 3.10005 24.5 5.65005 24.5C8.20005 24.5 10.25 22.45 10.25 19.9C10.25 19.45 10.2 19.05 10.05 18.65L11.95 16.75L9.40005 12.9ZM7.40005 21.6L5.00005 22.25L3.25005 20.5L3.90005 18.15L6.25005 17.5L8.00005 19.25L7.40005 21.6Z"
          stroke={colors.black.primary}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 15.5L22 21.5M13 11.25L5.7 3.95L5.9 2.6L2.25 0.5L1 1.75L3.1 5.4L4.45 5.2L11.75 12.5L13 11.25ZM24.25 20L18.15 13.9C17.7 13.45 17.05 13.3 16.45 13.4L15.3 12.25C15.6 11.9 15.6 11.35 15.25 11L14.9 10.65C14.55 10.3 13.95 10.3 13.6 10.65L11.15 13.1C10.8 13.45 10.8 14.05 11.15 14.4L11.5 14.75C11.85 15.1 12.4 15.1 12.75 14.8L13.9 15.95C13.75 16.55 13.95 17.2 14.4 17.65L20.5 23.75C21.55 24.8 23.2 24.8 24.25 23.75C25.25 22.7 25.25 21 24.25 20Z"
          stroke={colors.black.primary}
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2476_111973">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  </StyledBox>
);
