import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import React from 'react';

import { StyledPasswordInput } from '../../styled/form/StyledPasswordInput';
import { theme } from '../../theme';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
}

const IconRender = (visible: boolean) =>
  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />;

export const LoginPasswordInput = ({
  onChange,
  placeholder,
  value,
}: IProps) => {
  return (
    <StyledPasswordInput
      width={theme.input.width.big}
      value={value}
      iconRender={IconRender}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  );
};
