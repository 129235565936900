import React from 'react';

import { StyledBox } from '../../../../../../styled/boxes/StyledBox';
import { colors } from '../../../../../../theme';
import { IConnectorStatusIconProps } from '../../../../../../types/connector.types';
import { IconSize } from '../../../../../../types/icon.types';
import { getIconSize } from '../../../../../../utils/shared/icons/getIconSize';

export const NoAuthorisationIcon = ({
  ...space
}: IConnectorStatusIconProps) => (
  <StyledBox {...space}>
    <svg
      width={getIconSize(IconSize.MEDIUM).width}
      height={getIconSize(IconSize.MEDIUM).height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-connector-no-authorisation"
    >
      <g clipPath="url(#clip0_2330_19923)">
        <path
          d="M10.0001 19.4776C10.0001 19.4776 1.6001 15.6 1.6001 3.52922C6.8253 2.38202 8.3609 0.400024 10.0001 0.400024C11.6393 0.400024 13.1749 2.38202 18.4001 3.52922C18.4001 15.2 10.0001 19.4776 10.0001 19.4776Z"
          stroke={colors.grey.secondary}
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2330_19923">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </StyledBox>
);
