import { IPagedRequest, IParamsPagedRequest, PagedRequestKey } from './api.types';
import { RequestSessionFor } from './session.types';
import { VesselType } from './vessel.types';

export enum FilterType {
  INPUT = 'INPUT',
}

export enum AssetFilterKey {
  assetId = 'assetId',
  typeFilter = 'typeFilter',
  location = 'location',
  filter = 'filter',
}

export enum AssetLogFilterKey {
  assetId = 'assetId',
  tag = 'tag',
  from = 'from',
  to = 'to',

  params = 'params',
}

export enum SessionFilterKey {
  boxId = 'boxId',
  eniNumber = 'eniNumber',
  for = 'for',
  initiatorName = 'initiatorName',
  transactionId = 'transactionId',
  vesselName = 'vesselName',
}

export enum UserFilterKey {
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  phoneNumber = 'phoneNumber',
}

export enum VesselFilterKey {
  eniNumber = 'eniNumber',
  name = 'name',
  type = 'type',
}

export type FilterKey = AssetFilterKey | AssetLogFilterKey | SessionFilterKey | UserFilterKey | VesselFilterKey | PagedRequestKey;

export interface IFilter {
  key: FilterKey;
  type: FilterType;
  label: string;
  disabled?: boolean;
  value?: string | number | boolean;
}

export interface IPaged {
  page?: number;
  size?: number;
  sort?: string[];
}

export interface IAssetFilters {
  [AssetFilterKey.assetId]?: string;
  [AssetFilterKey.typeFilter]?: string;
  [AssetFilterKey.location]?: string;
  [AssetFilterKey.filter]?: string[];
}

export interface ISessionFilters {
  [SessionFilterKey.boxId]?: string;
  [SessionFilterKey.eniNumber]?: string;
  [SessionFilterKey.for]?: RequestSessionFor;
  [SessionFilterKey.initiatorName]?: string;
  [SessionFilterKey.transactionId]?: string;
  [SessionFilterKey.vesselName]?: string;
}

export type IPagedSessionFilters = IPagedRequest & ISessionFilters;

export type IPagedAssetFilters = IPagedRequest & IAssetFilters;

export interface IUserFilters {
  [UserFilterKey.email]?: string;
  [UserFilterKey.firstName]?: string;
  [UserFilterKey.lastName]?: string;
  [UserFilterKey.phoneNumber]?: string;
}

export interface IAssetLogFilters {
  [AssetLogFilterKey.from]?: number;
  [AssetLogFilterKey.to]?: number;
  [AssetLogFilterKey.tag]?: string[] ;
  [AssetLogFilterKey.assetId]?: string[] ;

  [AssetLogFilterKey.params]?: IParamsPagedRequest ;
}

export type IPagedAssetLogFilters = IParamsPagedRequest & IAssetLogFilters;

export interface IVesselFilters {
  [VesselFilterKey.eniNumber]?: string;
  [VesselFilterKey.name]?: string;
  [VesselFilterKey.type]?: VesselType;
}

export type IPagedVesselFilters = IPagedRequest & IVesselFilters;
