import styled from '@emotion/styled';
import { Input } from 'antd';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

import { styledInputProps } from './StyledInput';

export const StyledPasswordInput = styled(Input.Password)<
  LayoutProps | SpaceProps
>(
  styledInputProps,
  layout,
  space,
);
