import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';

import { TOAST_MESSAGES } from '../../components/feedback/NotificationAutoClose';
import { IVesselFilters } from '../../types/filters.types';
import { IVessel, IVesselsResponse } from '../../types/vessel.types';
import { setErrorMessage, setSuccessMessage } from '../slices/layout.slice';
import { baseApi, RtkTagType } from './base.api';

const url = '/vessels';

export const vesselsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getVessels: builder.query<IVesselsResponse, IVesselFilters | undefined>({
      query: (params) => ({
        url,
        params,
      }),
      providesTags: [{ type: RtkTagType.VESSELS }],
    }),

    postVessel: builder.mutation<QueryReturnValue, IVessel>({
      query: (newVessel) => ({
        url,
        method: 'POST',
        body: newVessel,
      }),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage(TOAST_MESSAGES.successVesselCreated));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          dispatch(
            setErrorMessage({
              message: TOAST_MESSAGES.errorVesselCreated,
              description: e.error.data,
            }),
          );
        }
      },
      invalidatesTags: [{ type: RtkTagType.VESSELS }],
    }),

    putVessel: builder.mutation<QueryReturnValue, IVessel>({
      query: (vessel) => ({
        url: `${url}/${vessel.id}`,
        method: 'PUT',
        body: vessel,
      }),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage(TOAST_MESSAGES.successVesselUpdated));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          dispatch(
            setErrorMessage({
              message: TOAST_MESSAGES.errorVesselUpdated,
              description: e.error.data,
            }),
          );
        }
      },
      invalidatesTags: [{ type: RtkTagType.VESSELS }],
    }),
    deleteVessel: builder.mutation<QueryReturnValue, { vesselIds: string[] }>({
      query: (ids) => ({
        url,
        params: ids,
        method: 'DELETE',
      }),
      async onQueryStarted(queryArg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(setSuccessMessage(TOAST_MESSAGES.successVesselDeleted));
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          dispatch(
            setErrorMessage({ message: TOAST_MESSAGES.errorVesselDeleted }),
          );
        }
      },
      invalidatesTags: [{ type: RtkTagType.VESSELS }],
    }),
  }),
});

export const {
  useDeleteVesselMutation,
  useGetVesselsQuery,
  usePostVesselMutation,
  usePutVesselMutation,
} = vesselsApi;
