import styled from '@emotion/styled';
import { Form } from 'antd';

import { theme } from '../../theme';

export const StyledLoginForm = styled(Form)({
  '&.ant-form-vertical': {
    display: 'flex',
    flexDirection: 'column',
    width: theme.input.width.big,
  },
});
