import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { useGetAuthorizedBoxesQuery } from '../../../../store/api/boxes.api';
import { StyledSelect } from '../../../../styled/form/StyledSelect';
import { theme } from '../../../../theme';
import { IAssetInfo } from '../../../../types/box.types';

const MESSAGES = defineMessages({
  selectBox: {
    id: 'select_boxes_dropdown.selectBox',
    defaultMessage: 'Select box',
  },
});

const { Option } = Select;

interface IProps {
  onSelectBox: (box: IAssetInfo) => void;
  boxSelected?: IAssetInfo | null;
}

export const SelectBox = ({
  boxSelected,
  onSelectBox,
}: IProps) => {
  const intl = useIntl();
  const [selected, setSelected] = useState<IAssetInfo | null>(null);
  const getBoxes = useGetAuthorizedBoxesQuery({}, {})
  const boxes: IAssetInfo[] | undefined = getBoxes.data?.content;

  useEffect(() => {
    setSelected(null);

  }, [boxes]);

  const handleSelectBox = (box: any) => {
    const found = boxes?.find((b) => b.id === box);

    if (found) {
      onSelectBox(found);
      setSelected(found);
    }
  };

  return (
    <>
      <StyledSelect
        onChange={handleSelectBox}
        value={boxSelected || selected}
        placeholder={intl.formatMessage(MESSAGES.selectBox)}
        mb={theme.spacing.small}
        minWidth="170px"
      >
        {boxes?.map((box) => {
          return (
            <Option key={box.id} value={box.id}>
              {box.label}
            </Option>
          );
        })}
      </StyledSelect>
    </>
  );
};
