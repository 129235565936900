import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { IAssetStatusIconProps } from '../../../../types/box.types';
import { getBoxStatusIconColor } from '../../../../utils/boxes/getBoxStatusIconColor';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const HybridBoxIcon = ({
  boxTechStatus,
  size,
  ...space
}: IAssetStatusIconProps) => (
  <StyledBox {...space} height={getIconSize(size).height}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-hybrid"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6586 10.2957C10.7362 10.1094 10.9183 9.98804 11.1201 9.98804H13.4951C13.6646 9.98804 13.8225 10.0739 13.9147 10.2161C14.0069 10.3583 14.0207 10.5375 13.9515 10.6922L12.1416 14.738H13.6201C13.8038 14.738 13.9727 14.8387 14.06 15.0003C14.1473 15.1619 14.139 15.3584 14.0384 15.5121L9.28835 22.7621C9.15066 22.9722 8.87839 23.0476 8.65224 22.9381C8.4261 22.8286 8.31632 22.5683 8.39578 22.3299L10.1764 16.988H8.62012C8.45294 16.988 8.29683 16.9045 8.20409 16.7654C8.11136 16.6263 8.09428 16.45 8.15858 16.2957L10.6586 10.2957ZM11.4535 10.988L9.37012 15.988H10.8701C11.0308 15.988 11.1818 16.0653 11.2757 16.1957C11.3697 16.3261 11.3953 16.4937 11.3445 16.6462L10.5621 18.9931L12.6948 15.738H11.3701C11.2006 15.738 11.0427 15.6522 10.9505 15.51C10.8584 15.3678 10.8445 15.1885 10.9137 15.0339L12.7237 10.988H11.4535Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.0058 11.852L20.6201 12.1702L20.2345 11.852C20.3294 11.7369 20.4709 11.6702 20.6201 11.6702C20.7694 11.6702 20.9108 11.7369 21.0058 11.852ZM20.6201 12.979C20.5976 13.0086 20.5743 13.0393 20.5502 13.071C20.274 13.4365 19.9065 13.9432 19.5399 14.5097C19.1723 15.0777 18.8116 15.6965 18.5445 16.2874C18.2723 16.8893 18.1201 17.4132 18.1201 17.8057C18.1201 19.1867 19.2391 20.3059 20.6201 20.3059C22.0011 20.3059 23.1201 19.1867 23.1201 17.8059C23.1201 17.4133 22.9679 16.8894 22.6958 16.2875C22.4286 15.6966 22.068 15.0777 21.7004 14.5097C21.3338 13.9432 20.9663 13.4365 20.69 13.071C20.666 13.0393 20.6427 13.0086 20.6201 12.979ZM23.607 15.8755C23.8973 16.5178 24.1201 17.1983 24.1201 17.8059C24.1201 19.739 22.5535 21.3059 20.6201 21.3059C18.6868 21.3059 17.1201 19.739 17.1201 17.8057C17.1201 17.1982 17.3429 16.5177 17.6333 15.8754C17.9286 15.2222 18.318 14.5572 18.7004 13.9663C19.0838 13.3739 19.4663 12.8467 19.7525 12.468C19.8958 12.2784 20.0154 12.1255 20.0996 12.0194C20.1417 11.9664 20.175 11.9251 20.198 11.8968L20.2245 11.8642L20.2316 11.8555L20.2345 11.852C20.2345 11.852 20.2345 11.852 20.6201 12.1702C21.0058 11.852 21.0058 11.852 21.0058 11.852L21.0086 11.8555L21.0158 11.8642L21.0423 11.8968C21.0652 11.9251 21.0985 11.9664 21.1406 12.0194C21.2248 12.1255 21.3444 12.2784 21.4877 12.468C21.774 12.8467 22.1565 13.3739 22.5399 13.9664C22.9223 14.5572 23.3116 15.2223 23.607 15.8755Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6 1.8999H28.6V5.8999H27.4V28.6999H4.8V5.8999H3.6V1.8999ZM4.6 2.8999V4.8999H27.6V2.8999H4.6ZM5.8 6.4999V27.6999H26.4V6.4999H5.8Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.50604 11.0861L5.60648 9.01917L5.05178 8.18711L1.56194 10.5137L1.43184 11.721C1.29554 12.9858 2.69674 13.8354 3.75526 13.1298L5.57739 11.915L5.02269 11.0829L3.20056 12.2977C2.84772 12.5329 2.38065 12.2497 2.42608 11.8281L2.50604 11.0861Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.50604 18.2861L5.59424 16.2273L5.03954 15.3953L1.56194 17.7137L1.43184 18.921C1.29554 20.1858 2.69674 21.0354 3.75526 20.3298L5.57739 19.115L5.02269 18.2829L3.20056 19.4977C2.84772 19.7329 2.38065 19.4497 2.42609 19.0281L2.50604 18.2861Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.5 14.4999C30.7761 14.4999 31 14.7238 31 14.9999V19.9999C31 20.276 30.7761 20.4999 30.5 20.4999C30.2239 20.4999 30 20.276 30 19.9999V14.9999C30 14.7238 30.2239 14.4999 30.5 14.4999Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 16.4999H26.5V15.4999H31V19.4999H26.5V18.4999H30V16.4999Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
    </svg>
  </StyledBox>
);
