import { AuthErrorStrings } from '@aws-amplify/auth/src/common/AuthErrorStrings';
import { Form } from 'antd';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuth } from '../../authentication/hooks/useAuth';
import { CognitoError } from '../../components/login/CognitoError';
import { LoginContainer } from '../../components/login/LoginContainer';
import { LoginPasswordConfirmation } from '../../components/login/LoginPasswordConfirmation';
import { StyledLoginForm } from '../../components/login/StyledLoginForm';
import { appUrls } from '../../config/url.constants';
import { StyledButton } from '../../styled/buttons/StyledButton';
import { StyledFormItem } from '../../styled/form/StyledFormItem';
import { StyledInput } from '../../styled/form/StyledInput';
import { theme } from '../../theme';
import { IAuthHookContext } from '../../types/auth.types';
import { isValidEmail, LOGIN_MESSAGES } from '../../utils/login/login.utils';

const MESSAGES = defineMessages({
  title: {
    id: 'reset_password_set_page.title',
    defaultMessage: 'Choose new password',
  },
  button: {
    id: 'reset_password_set_page.button',
    defaultMessage: 'Confirm new password',
  },
  passwordResetFail: {
    id: 'reset_password_set_page.passwordResetFail',
    defaultMessage:
      'Failed to reset password. Please request a new code and try again.',
  },
});

export const ResetPasswordSetPage = () => {
  const intl = useIntl();
  const params = useParams();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState<AuthErrorStrings | null>(null);
  const { forgotPasswordSubmit } = useAuth() as IAuthHookContext;
  const [form] = Form.useForm();
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (err: any) => {
    const message = err.message as AuthErrorStrings;
    setError(message);
  };

  const handleSuccess = () => {
    navigate(appUrls.login.base);
  };

  const handleFormSubmit = () => {
    if (params?.code) {
      setError(null);
      forgotPasswordSubmit(
        params.code,
        email,
        password,
        handleError,
        handleSuccess,
      );
    }
  };

  return (
    <LoginContainer title={intl.formatMessage(MESSAGES.title)}>
      <StyledLoginForm
        form={form}
        name="resetPasswordForm"
        layout="vertical"
        onFinish={handleFormSubmit}
      >
        <StyledFormItem name="email">
          <StyledInput
            mt={theme.spacing.large}
            width={theme.input.width.big}
            value={email}
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            placeholder={intl.formatMessage(LOGIN_MESSAGES.userNamePlaceholder)}
          />
        </StyledFormItem>
        <LoginPasswordConfirmation
          password={password}
          passwordConfirm={passwordConfirm}
          onChangePassword={setPassword}
          onChangePasswordConfirm={setPasswordConfirm}
        />
        <StyledButton
          width={theme.input.width.big}
          variant="primary"
          htmlType="submit"
          disabled={
            !password || !isValidEmail(email) || password !== passwordConfirm
          }
        >
          {intl.formatMessage(MESSAGES.button)}
        </StyledButton>
        {error && <CognitoError error={error} linkToResetPasswordPage />}
      </StyledLoginForm>
    </LoginContainer>
  );
};
