import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ResponsiveFlex } from '../../../components/layout/flex/ResponsiveFlex';
import {
  sessionCurrentDataPointsBySessionIdSelector,
  sessionPowerDataPointsBySessionIdSelector,
} from '../../../store/selectors/session.selectors';
import { StyledHeading } from '../../../styled/headings/StyledHeading';
import { theme } from '../../../theme';
import {
  ISessionDetail,
  TechmetrysGraphData,
} from '../../../types/session.types';
import { SessionMetricsSummary } from './SessionMetrics/SessionMetricsSummary';
import { SessionGeneralInfo } from './SessionGeneralInfo/SessionGeneralInfo';
import { SessionTechnicalInfo } from './SessionTechnicalInfo/SessionTechnicalInfo';
import { SessionTimeline } from './SessionTimeline/SessionTimeline';
import { SessionGraph } from './SessionUsageGraph/SessionGraph';

import { WhiteBox } from '../../../components/layout/boxes/WhiteBox';

const MESSAGES = defineMessages({
  graphNotAvailable: {
    id: 'session_detail.graphNotAvailable',
    defaultMessage: 'Usage graph currently not available',
  },
  technicalData: {
    id: 'session_detail.technicalData',
    defaultMessage: 'Technical data',
  },
  baseMetrics: {
    id: 'session_detail.baseMetrics',
    defaultMessage: 'Base metrics',
  },
  usageGraph: {
    id: 'session_detail.usageGraph',
    defaultMessage: 'Usage graph',
  },
  timeline: {
    id: 'session_detail.timeline',
    defaultMessage: 'Session timeline',
  },
  timelineNotAvailable: {
    id: 'session_detail.timelineNotAvailable',
    defaultMessage: 'No events have occured.',
  },
  generalInfo: {
    id: 'session_detail.generalInfo',
    defaultMessage: 'General info',
  },
});

interface IProps {
  sessionDetail: ISessionDetail;
}

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.medium};
  margin-bottom: ${theme.spacing.medium};

  @media (max-width: 1100px) {
    > div {
      width: 100%;
    }
  }
`;

export const SessionDetail = ({ sessionDetail }: IProps) => {
  const intl = useIntl();

  const currentDataPoints: TechmetrysGraphData[] = useSelector(
    sessionCurrentDataPointsBySessionIdSelector(sessionDetail.id),
  );
  const powerDataPoints: TechmetrysGraphData[] = useSelector(
    sessionPowerDataPointsBySessionIdSelector(sessionDetail.id),
  );

  const allDatasets = [...currentDataPoints, ...powerDataPoints];

  return (
    <>
      <Wrapper>
        <WhiteBox
          width="calc(100% / 3)"
          title={intl.formatMessage(MESSAGES.baseMetrics)}
        >
          <SessionMetricsSummary session={sessionDetail} />
        </WhiteBox>

        <WhiteBox
          width="calc(100% / 3 - 16px)"
          title={intl.formatMessage(MESSAGES.generalInfo)}
          center
        >
          <SessionGeneralInfo session={sessionDetail} />
        </WhiteBox>

        <WhiteBox
          width="calc(100% / 3 - 16px)"
          title={intl.formatMessage(MESSAGES.technicalData)}
          center
        >
          <SessionTechnicalInfo session={sessionDetail} />
        </WhiteBox>

        <WhiteBox width="100%" title={intl.formatMessage(MESSAGES.timeline)}>
          <ResponsiveFlex
            alignItems="center"
            height="220px"
            p={theme.spacing.large}
            overflowX="scroll"
          >
            {sessionDetail.events.length > 0 ? (
              <SessionTimeline sessionId={sessionDetail.id} />
            ) : (
              <StyledHeading variant="h6">
                {intl.formatMessage(MESSAGES.timelineNotAvailable)}
              </StyledHeading>
            )}
          </ResponsiveFlex>
        </WhiteBox>

        <WhiteBox width="100%" title={intl.formatMessage(MESSAGES.usageGraph)}>
          {powerDataPoints.length > 0 || currentDataPoints.length > 0 ? (
            <SessionGraph datasets={allDatasets} />
          ) : (
            <StyledHeading variant="h6" mt={theme.spacing.medium}>
              {intl.formatMessage(MESSAGES.graphNotAvailable)}
            </StyledHeading>
          )}
        </WhiteBox>
      </Wrapper>
    </>
  );
};
