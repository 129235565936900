import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { FlexCol } from '../../styled/flex/FlexCol';
import { StyledSubText, StyledSubTextLight } from '../../styled/text/Text';
import { theme } from '../../theme';
import { ISessionEvent, SessionTimelineEvent } from '../../types/session.types';
import { secondsToYyyyMmDdHhMmSs } from '../../utils/shared/time/secondsToYyyyMmDdHhMmSs';

const MESSAGES = defineMessages({
  [SessionTimelineEvent.AUTHORISATION_OK]: {
    id: 'session_timeline_description.authorisationOK',
    defaultMessage: 'Authorisation OK',
  },
  [SessionTimelineEvent.AUTHORISATION_STOPPED]: {
    id: 'session_timeline_description.authorisationStopped',
    defaultMessage: 'Authorisation stopped',
  },
  [SessionTimelineEvent.CONNECTOR_PLUGGED_IN]: {
    id: 'session_timeline_description.connectorPluggedIn',
    defaultMessage: 'Connector plugged in',
  },
  [SessionTimelineEvent.CONNECTOR_PLUGGED_OUT]: {
    id: 'session_timeline_description.connectorPluggedOut',
    defaultMessage: 'Connector plugged out',
  },
  [SessionTimelineEvent.RELAY_ACTIVATED]: {
    id: 'session_timeline_description.relayActivated',
    defaultMessage: 'Relay activated',
  },
  [SessionTimelineEvent.RELAY_DEACTIVATED]: {
    id: 'session_timeline_description.relayDeactivated',
    defaultMessage: 'Relay deactivated',
  },
  [SessionTimelineEvent.START_BUTTON_PRESSED]: {
    id: 'session_timeline_description.startButtonPressed',
    defaultMessage: 'Start button pressed',
  },
  [SessionTimelineEvent.STOP_BUTTON_PRESSED]: {
    id: 'session_timeline_description.stopButtonPressed',
    defaultMessage: 'Stop button pressed',
  },
  [SessionTimelineEvent.STARTED_VIA_WEB]: {
    id: 'session_timeline_description.startedViaWeb',
    defaultMessage: 'Started via web',
  },
  [SessionTimelineEvent.VESSEL_DIFF_TRIP]: {
    id: 'session_timeline_description.vesselDiffTrip',
    defaultMessage: 'Vessel diff trip',
  },
  [SessionTimelineEvent.VESSEL_OVERLOAD_TRIP]: {
    id: 'session_timeline_description.vesselOverloadTrip',
    defaultMessage: 'Vessel overload trip',
  },
  [SessionTimelineEvent.VESSEL_DEFINITIVE]: {
    id: 'session_timeline_description.vesselDefinitive',
    defaultMessage: 'Vessel definitive',
  },
  [SessionTimelineEvent.MAINTENANCE]: {
    id: 'session_timeline_description.maintenance',
    defaultMessage: 'Maintenance',
  },
  [SessionTimelineEvent.SIMULATION_MODE]: {
    id: 'session_timeline_description.simulationMode',
    defaultMessage: 'Simulation mode',
  },
  [SessionTimelineEvent.TEST_MODE]: {
    id: 'session_timeline_description.testMode',
    defaultMessage: 'Test mode',
  },
  [SessionTimelineEvent.TAMPERING]: {
    id: 'session_timeline_description.tampering',
    defaultMessage: 'Tampering',
  },
  [SessionTimelineEvent.BOX_FAIL]: {
    id: 'session_timeline_description.boxFail',
    defaultMessage: 'Box fail',
  },
  [SessionTimelineEvent.WEB_REMOVAL_FAIL]: {
    id: 'session_timeline_description.webRemovalFail',
    defaultMessage: 'Web removal fail',
  },
  [SessionTimelineEvent.METER]: {
    id: 'session_timeline_description.meter',
    defaultMessage: 'Meter',
  },
  [SessionTimelineEvent.METER_FUSE]: {
    id: 'session_timeline_description.meterRefuse',
    defaultMessage: 'Meter refuse',
  },
  [SessionTimelineEvent.RCA_NSX]: {
    id: 'session_timeline_description.rcaNsx',
    defaultMessage: 'RCA NSX',
  },
  [SessionTimelineEvent.RELAY]: {
    id: 'session_timeline_description.relay',
    defaultMessage: 'Relay',
  },
  [SessionTimelineEvent.DIFF_TRIP]: {
    id: 'session_timeline_description.diffTrip',
    defaultMessage: 'Diff trip',
  },
  [SessionTimelineEvent.DIFF_RESET]: {
    id: 'session_timeline_description.diffReset',
    defaultMessage: 'Diff reset',
  },
  [SessionTimelineEvent.BENDER]: {
    id: 'session_timeline_description.bender',
    defaultMessage: 'Bender',
  },
  [SessionTimelineEvent.SAFETY_LOOP]: {
    id: 'session_timeline_description.safetyLoop',
    defaultMessage: 'Safety loop',
  },
  [SessionTimelineEvent.CABLE_PULL]: {
    id: 'session_timeline_description.cablePull',
    defaultMessage: 'Cable pull',
  },
  [SessionTimelineEvent.VOLTAGE_ON_CABLE]: {
    id: 'session_timeline_description.voltageOnCable',
    defaultMessage: 'Voltage on cable',
  },
  unknownEvent: {
    id: 'session_timeline_description.unknownEvent',
    defaultMessage: 'Unknown event',
  },
  waitingForEvents: {
    id: 'session_timeline_description.waitingForEvents',
    defaultMessage: 'Waiting for events...',
  },
});

interface IProps {
  sessionIsActive?: boolean;
  sessionTimelineEvent?: ISessionEvent;
}

export const SessionTimelineDescription = ({
  sessionIsActive,
  sessionTimelineEvent,
}: IProps) => {
  const intl = useIntl();

  return (
    <>
      {sessionIsActive && (
        <StyledSubText mt={theme.spacing.small}>
          {intl.formatMessage(MESSAGES.waitingForEvents)}
        </StyledSubText>
      )}
      {sessionTimelineEvent && (
        <FlexCol>
          <StyledSubText>
            {intl.formatMessage(MESSAGES[sessionTimelineEvent.event])}
          </StyledSubText>
          <StyledSubTextLight>
            {secondsToYyyyMmDdHhMmSs(sessionTimelineEvent.timestamp)}
          </StyledSubTextLight>
        </FlexCol>
      )}
    </>
  );
};
