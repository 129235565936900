import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { colors } from '../../../../theme';
import { IIconProps } from '../../../../types/icon.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const HeatingRadiatorIcon = ({ size, ...space }: IIconProps) => (
  <StyledBox {...space} height={getIconSize(size).height}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-heating"
    >
      <path
        d="M14.6001 2.40015C15.8001 3.60015 16.2369 4.96335 15.2001 6.00015C14.1633 7.03695 14.6001 8.40014 15.8001 9.60014"
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M6.2002 2.40015C7.40019 3.60015 7.83699 4.96335 6.80019 6.00015C5.7634 7.03695 6.2002 8.40014 7.40019 9.60014"
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.4004 2.40015C11.6004 3.60015 12.0372 4.96335 11.0004 6.00015C9.96359 7.03695 10.4004 8.40014 11.6004 9.60014"
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M18.8003 2.40015C20.0003 3.60015 20.4371 4.96335 19.4003 6.00015C18.3635 7.03695 18.8003 8.40014 20.0003 9.60014"
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M23 2.40015C24.2 3.60015 24.6368 4.96335 23.6 6.00015C22.5632 7.03695 23 8.40014 24.2 9.60014"
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M6.20039 17.4001H5.00039C4.66919 17.4001 4.40039 17.1313 4.40039 16.8001V15.0001C4.40039 14.6689 4.66919 14.4001 5.00039 14.4001H6.20039V17.4001Z"
        fill={colors.green.secondary}
      />
      <path
        d="M6.20039 27.0001H5.00039C4.66919 27.0001 4.40039 26.7313 4.40039 26.4001V24.6001C4.40039 24.2689 4.66919 24.0001 5.00039 24.0001H6.20039V27.0001Z"
        fill={colors.green.secondary}
      />
      <path
        d="M24.2002 17.4001H25.4002C25.7314 17.4001 26.0002 17.1313 26.0002 16.8001V15.0001C26.0002 14.6689 25.7314 14.4001 25.4002 14.4001H24.2002V17.4001Z"
        fill={colors.green.secondary}
      />
      <path
        d="M24.2002 27.0001H25.4002C25.7314 27.0001 26.0002 26.7313 26.0002 26.4001V24.6001C26.0002 24.2689 25.7314 24.0001 25.4002 24.0001H24.2002V27.0001Z"
        fill={colors.green.secondary}
      />
      <path
        d="M6.2002 14.8003V26.6005C6.2002 26.8603 6.2842 27.1129 6.4402 27.3205L7.2802 28.4407C7.6402 28.9207 8.36019 28.9207 8.72019 28.4407L9.5602 27.3205C9.71619 27.1129 9.80019 26.8603 9.80019 26.6005V14.8003C9.80019 14.5405 9.71619 14.2879 9.5602 14.0803L8.72019 12.9601C8.36019 12.4801 7.6402 12.4801 7.2802 12.9601L6.4402 14.0803C6.2842 14.2879 6.2002 14.5405 6.2002 14.8003Z"
        fill="#63CF97"
        stroke="#63CF97"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M9.80029 14.8003V26.6005C9.80029 26.8603 9.88429 27.1129 10.0403 27.3205L10.8803 28.4407C11.2403 28.9207 11.9603 28.9207 12.3203 28.4407L13.1603 27.3205C13.3163 27.1129 13.4003 26.8603 13.4003 26.6005V14.8003C13.4003 14.5405 13.3163 14.2879 13.1603 14.0803L12.3203 12.9601C11.9603 12.4801 11.2403 12.4801 10.8803 12.9601L10.0403 14.0803C9.88429 14.2879 9.80029 14.5405 9.80029 14.8003Z"
        fill={colors.green.secondary}
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M13.4004 14.8003V26.6005C13.4004 26.8603 13.4844 27.1129 13.6404 27.3205L14.4804 28.4407C14.8404 28.9207 15.5604 28.9207 15.9204 28.4407L16.7604 27.3205C16.9164 27.1129 17.0004 26.8603 17.0004 26.6005V14.8003C17.0004 14.5405 16.9164 14.2879 16.7604 14.0803L15.9204 12.9601C15.5604 12.4801 14.8404 12.4801 14.4804 12.9601L13.6404 14.0803C13.4844 14.2879 13.4004 14.5405 13.4004 14.8003Z"
        fill={colors.green.secondary}
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M17 14.8003V26.6005C17 26.8603 17.084 27.1129 17.24 27.3205L18.08 28.4407C18.44 28.9207 19.16 28.9207 19.52 28.4407L20.36 27.3205C20.516 27.1129 20.6 26.8603 20.6 26.6005V14.8003C20.6 14.5405 20.516 14.2879 20.36 14.0803L19.52 12.9601C19.16 12.4801 18.44 12.4801 18.08 12.9601L17.24 14.0803C17.084 14.2879 17 14.5405 17 14.8003Z"
        fill={colors.green.secondary}
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M20.6001 14.8003V26.6005C20.6001 26.8603 20.6841 27.1129 20.8401 27.3205L21.6801 28.4407C22.0401 28.9207 22.7601 28.9207 23.1201 28.4407L23.9601 27.3205C24.1161 27.1129 24.2001 26.8603 24.2001 26.6005V14.8003C24.2001 14.5405 24.1161 14.2879 23.9601 14.0803L23.1201 12.9601C22.7601 12.4801 22.0401 12.4801 21.6801 12.9601L20.8401 14.0803C20.6841 14.2879 20.6001 14.5405 20.6001 14.8003Z"
        fill={colors.green.secondary}
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  </StyledBox>
);
