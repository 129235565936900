import React from 'react';
import { ThunderboltOutlined } from '@ant-design/icons';
import { defineMessages, useIntl } from 'react-intl';
import { LeftSideContainer, IconContainerScaled } from '../../components/layout/boxes/IconContainer';
import {
  NormalText,
  KeyText,
  StyledSubTextLight,
  LargeText,
} from '../../styled/text/Text';
import { FlexRow } from '../../styled/flex/FlexRow';
import { colors, theme } from '../../theme';
import WaterDrop from '../../icons/WaterDrop';

const MESSAGES = defineMessages({
  activeSessions: {
    id: 'dashboard.active_session',
    defaultMessage: 'Active sessions',
  },
});

export const DashboardActiveSessions = () => {
  const intl = useIntl();
  
  return (
    <div>
      <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
        <LeftSideContainer>
          <IconContainerScaled>
            <ThunderboltOutlined />
          </IconContainerScaled>
          <KeyText>Electricity</KeyText>
        </LeftSideContainer>
        <LargeText alignment="right">
          2
        </LargeText>
      </FlexRow>
      
      <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
        <LeftSideContainer>
          <IconContainerScaled>
            <WaterDrop width="13px" height="15px" />
          </IconContainerScaled>
          <KeyText>Water</KeyText>
        </LeftSideContainer>
        <LargeText alignment="right">
          0
        </LargeText>
      </FlexRow>
    </div>
  );
};
