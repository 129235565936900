import styled from '@emotion/styled';
import { Form } from 'antd';
import { layout, LayoutProps } from 'styled-system';

import { colors, theme } from '../../theme';

export const StyledForm = styled(Form)<LayoutProps>(
  {
    background: colors.white.primary,
    borderRadius: theme.borderRadius.medium,
    boxShadow: '2px 4px 4px rgba(0, 0, 0, 0.25)',
    padding: theme.spacing.xLarge,

    '.ant-checkbox-wrapper:hover': {
      '.ant-checkbox-inner': {
        border: `1px solid ${colors.green.primary}`,
      },
    },

    '.ant-input:hover, .ant-input:focus': {
      border: `1px solid ${colors.green.primary}`,
      boxShadow: theme.boxShadows.light,
    },

    '.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector':
      {
        border: `1px solid ${colors.green.primary}`,
        boxShadow: theme.boxShadows.light,
      },
  },
  layout,
);
