import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  wsClient,
  BROKER_DESTINATION_PREFIX,
} from '../../../utils/ws/WsClient';
import { IConnectorBox } from '../../../types/connector.types';
import { ITagStatus } from '../../../types/tag.types';
import { setTagStatus, initConnector } from '../../../store/slices/tags.slice';
import { RootState } from '../../../store/store';

const wsTopic = `${BROKER_DESTINATION_PREFIX}/assets`;

interface IProps {
  connector: IConnectorBox; // Accept the entire connector object
}

export const SubscribeToTags = ({ connector }: IProps) => {
  const dispatch = useDispatch();

  const subscribedIds = useSelector(
    (state: RootState) => state.tags.subscribedIds,
  );

  useEffect(() => {
    if (!connector || !connector.id) return undefined; // Exit early if connector is not valid

    if (subscribedIds.includes(connector.id)) return undefined;

    let tagsSubscription: { unsubscribe: () => void } | null = null;

    const subscribeToTags = async () => {
      const handleNewStatus = (newStatus: ITagStatus) => {
        dispatch(setTagStatus({ connectorId: connector.id, ...newStatus }));
      };

      // Subscribe to WebSocket topic
      tagsSubscription = await wsClient.subscribe(
        `${wsTopic}/${connector.id}/tags`,
        handleNewStatus,
      );

      dispatch(initConnector({ id: connector.id }));
    };

    subscribeToTags();

    // Cleanup function
    return () => {
      if (tagsSubscription) {
        // tagsSubscription.unsubscribe();
      }
      // dispatch(resetTagStatuses());
    };
  }, [connector, dispatch]);

  return null; // Component does not render anything
};
