import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Modal } from '../../../../components/modals/Modal';
import { appUrls } from '../../../../config/url.constants';
import { StyledButton } from '../../../../styled/buttons/StyledButton';
import { FlexCol } from '../../../../styled/flex/FlexCol';
import { FlexRow } from '../../../../styled/flex/FlexRow';
import { theme } from '../../../../theme';

const MESSAGES = defineMessages({
  sessionDeletedReturn: {
    id: 'sessionDeletedReturn',
    defaultMessage: 'Return',
  },
  sessionDeletedTitle: {
    id: 'sessionDeletedTitle',
    defaultMessage: 'Session cancelled',
  },
  sessionDeletedDescription: {
    id: 'sessionDeletedDescription',
    defaultMessage:
      'The session was never confirmed by the connector and will be deleted. You can return to the session overview and try again.',
  },
});

export const SessionDeletedModal = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const handleConfirm = () => {
    navigate(appUrls.sessions.base);
  };

  return (
    <Modal title={intl.formatMessage(MESSAGES.sessionDeletedTitle)} showModal>
      <FlexCol>
        <FlexRow mb={theme.spacing.small}>
          {intl.formatMessage(MESSAGES.sessionDeletedDescription)}
        </FlexRow>
        <FlexRow justifyContent="right">
          <StyledButton
            variant="primary"
            htmlType="submit"
            mt={theme.spacing.xxLarge}
            ml={theme.spacing.small}
            data-testid="bnt-deleted-session-return"
            onClick={handleConfirm}
          >
            {intl.formatMessage(MESSAGES.sessionDeletedReturn)}
          </StyledButton>
        </FlexRow>
      </FlexCol>
    </Modal>
  );
};
