import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { colors } from '../../../../theme';
import { IIconProps } from '../../../../types/icon.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const BoxHygrostaatNormalIcon = ({ size, ...space }: IIconProps) => (
  <StyledBox {...space} height={getIconSize(size).height}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-hygrostaat-normal"
    >
      <path
        d="M7.7998 8.90005V19.7M10.1998 17.2028V2.30005H5.3998V17.2028C3.2812 18.1286 1.7998 20.24 1.7998 22.7C1.7998 26.0138 4.486 28.7 7.7998 28.7C11.1136 28.7 13.7998 26.0138 13.7998 22.7C13.7998 20.24 12.3184 18.1286 10.1998 17.2028Z"
        stroke={colors.black.primary}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M7.7998 19.7C7.00416 19.7 6.24109 20.016 5.67848 20.5786C5.11588 21.1412 4.7998 21.9043 4.7998 22.7C4.7998 23.4956 5.11588 24.2587 5.67848 24.8213C6.24109 25.3839 7.00416 25.7 7.7998 25.7C8.59545 25.7 9.35852 25.3839 9.92113 24.8213C10.4837 24.2587 10.7998 23.4956 10.7998 22.7C10.7998 21.9043 10.4837 21.1412 9.92113 20.5786C9.35852 20.016 8.59545 19.7 7.7998 19.7Z"
        fill={colors.black.primary}
      />
      <path
        d="M28.8001 22.7001C28.8001 24.0255 27.7255 25.1001 26.4001 25.1001C25.0747 25.1001 24.0001 24.0255 24.0001 22.7001C24.0001 21.3747 26.4001 17.7801 26.4001 17.7801C26.4001 17.7801 28.8001 21.3747 28.8001 22.7001ZM28.2001 6.50006C28.2001 7.49426 27.3943 8.30006 26.4001 8.30006C25.4059 8.30006 24.6001 7.49426 24.6001 6.50006C24.6001 5.50586 26.4001 2.81006 26.4001 2.81006C26.4001 2.81006 28.2001 5.50586 28.2001 6.50006ZM22.8001 14.6001C22.8001 15.7599 21.8599 16.7001 20.7001 16.7001C19.5403 16.7001 18.6001 15.7599 18.6001 14.6001C18.6001 13.4403 20.7001 10.2951 20.7001 10.2951C20.7001 10.2951 22.8001 13.4403 22.8001 14.6001Z"
        stroke={colors.black.primary}
        strokeMiterlimit="10"
      />
      <path
        d="M10.2002 14.3H12.6002M10.2002 4.69995H12.6002H10.2002ZM10.2002 7.09995H12.0002H10.2002ZM10.2002 9.49995H12.6002H10.2002ZM10.2002 11.9H12.0002H10.2002Z"
        stroke={colors.black.primary}
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </svg>
  </StyledBox>
);
