import React, { useState } from 'react';
import styled from 'styled-components';
import { theme, colors } from '../../theme';

const Row = styled.div`
  padding: ${theme.spacing.xLarge} 0;
  padding-bottom: ${theme.spacing.small};

  &:nth-child(even) {
    border-bottom: 1px solid ${colors.green.primary};
    border-top: 1px solid ${colors.green.primary};
    background-color: ${colors.green.tertiary};
    width: calc(100% + 48px);
    margin-left: -24px;
    padding: ${theme.spacing.xLarge};
  }
`;

const Line = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.small} 0;
  font-size: ${theme.fontSizes.medium}px;
  color: ${colors.black.primary};
`;

const Label = styled.span`
  font-weight: ${theme.fontWeights.bold};
  margin-right: ${theme.spacing.xSmall};
  color: ${colors.black.primary};
  font-size: ${theme.fontSizes.medium}px;
  display: flex;
  align-items: center;
`;

const Value = styled.span`
  font-size: ${theme.fontSizes.medium}px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const NoData = styled.div`
  padding: ${theme.spacing.xLarge};
  text-align: center;
  color: ${colors.black.primary};
`;

type Column = {
  title: string;
  dataIndex?: string;
  render?: (value: any, record: any, index: number) => React.ReactNode;
};

type IProps = {
  columns: Column[];
  data: any[];
};

export const TransportedTableData: React.FC<IProps> = ({ columns, data }) => {
  if (data.length === 0) {
    return <NoData>No data available</NoData>;
  }

  return (
    <>
      {data.map((record, rowIndex) => (
        <Row className="row" key={record.id}>
          {columns.map((col) => {
            const value = col.render
              ? col.render(
                  col.dataIndex ? record[col.dataIndex] : record,
                  record,
                  rowIndex,
                )
              : col.dataIndex !== undefined
              ? record[col.dataIndex]
              : '-';

            return (
              <Line key={col.title}>
                <Label className="label">
                  {col.title ? `${col.title}:` : null}
                </Label>
                <Value className="value">{value ?? '-'}</Value>
              </Line>
            );
          })}
        </Row>
      ))}
    </>
  );
};
