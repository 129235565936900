import { createSelector } from '@reduxjs/toolkit';

import { ISessionFilters } from '../../types/filters.types';
import { ISessionDetail, ISessionsResponse } from '../../types/session.types';
import {
  formatAndSortUsageGraphCurrentDataPoints,
  formatAndSortUsageGraphPowerDataPoints,
} from '../../utils/sessions/formatUsageGraphDataPoints';
import { sessionsApi } from '../api/sessions.api';
import { RootState } from '../store';

export const sessionsRtkState = (requestedSessionFilters: ISessionFilters) =>
  sessionsApi.endpoints.getSessions.select(requestedSessionFilters);

export const getCurrentSessionState = (sessionId: string) =>
  sessionsApi.endpoints.getSessionById.select(sessionId);

export const sessionsDataSelector = (
  requestedSessionFilters: ISessionFilters,
) =>
  createSelector(
    sessionsRtkState(requestedSessionFilters),
    (state): ISessionsResponse | undefined => {
      return state.data;
    },
  );

export const sessionDetailSelector = (sessionId: string) =>
  createSelector(
    getCurrentSessionState(sessionId),
    (state): ISessionDetail | undefined => state.data,
  );

export const sessionCurrentDataPointsBySessionIdSelector = (
  sessionId: string,
) =>
  createSelector(sessionDetailSelector(sessionId), (session) =>
    formatAndSortUsageGraphCurrentDataPoints(session?.metrics.currentOverTime),
  );

export const sessionPowerDataPointsBySessionIdSelector = (sessionId: string) =>
  createSelector(sessionDetailSelector(sessionId), (session) =>
    formatAndSortUsageGraphPowerDataPoints(session?.metrics.powerUsageOverTime),
  );

export const sessionState = (state: RootState) => state.cancelledSessions;

export const sessionHasBeenCancelled = (sessionId: string) =>
  createSelector(sessionState, (cancelledSessions) =>
    cancelledSessions.includes(sessionId),
  );
