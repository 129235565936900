import styled from '@emotion/styled';
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from 'styled-system';

import { colors, theme } from '../../theme';

export type Variants = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

interface VariantProps {
  variant: Variants;
}

export const StyledHeading = styled('h1')<
  SpaceProps | LayoutProps | TypographyProps | VariantProps
>(
  () => ({
    width: '100%',
    maxWidth: theme.maxWidth
  }),
  layout,
  space,
  typography,
  variant({
    variants: {
      h1: {
        color: colors.black.primary,
        fontFamily: theme.fontFamilies.secondary,
        fontSize: theme.fontSizes.xxLarge,
        fontWeight: theme.fontWeights.medium,
        lineHeight: theme.lineHeights.xxLarge,
      },
      h2: {
        fontFamily: theme.fontFamilies.secondary,
        fontSize: theme.fontSizes.xxLarge,
        fontWeight: theme.fontWeights.medium,
        lineHeight: theme.lineHeights.xLarge,
      },
      h3: {
        fontFamily: theme.fontFamilies.secondary,
        fontSize: theme.fontSizes.xLarge,
        fontWeight: theme.fontWeights.regular,
        lineHeight: theme.lineHeights.large,
        color: colors.black.primary,
      },
      h4: {
        fontFamily: theme.fontFamilies.secondary,
        fontSize: theme.fontSizes.xLarge,
        fontWeight: theme.fontWeights.regular,
        lineHeight: theme.lineHeights.large,
        color: colors.black.secondary,
      },
      h5: {
        fontFamily: theme.fontFamilies.secondary,
        fontSize: theme.fontSizes.large,
        fontWeight: theme.fontWeights.regular,
        lineHeight: theme.lineHeights.medium,
        color: colors.black.secondary,
      },
      h6: {
        fontFamily: theme.fontFamilies.secondary,
        fontSize: theme.fontSizes.medium,
        fontWeight: theme.fontWeights.regular,
        lineHeight: theme.lineHeights.medium,
        color: colors.black.secondary,
      },
    },
  }),
);
