import { IUserFilters } from '../../types/filters.types';
import { IUser } from '../../types/user.types';

export const filterUsers = (
  userData: IUser[],
  requestedFilters: IUserFilters,
) => {
  const { email, firstName, lastName, phoneNumber } = requestedFilters;

  if (firstName) {
    return userData.filter((user) =>
      user.firstName.toUpperCase().includes(firstName.toUpperCase()),
    );
  }

  if (lastName) {
    return userData.filter((user) =>
      user.lastName.toUpperCase().includes(lastName.toUpperCase()),
    );
  }

  if (email) {
    return userData.filter((user) =>
      user.email.toUpperCase().includes(email.toUpperCase()),
    );
  }

  if (phoneNumber) {
    return userData.filter((user) => user.phoneNumber.includes(phoneNumber));
  }

  return userData;
};
