import { Select } from 'antd';
import { Rule } from 'antd/lib/form';
import * as countries from 'i18n-iso-countries';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { StyledFormItem } from '../../../styled/form/StyledFormItem';

const { Option } = Select;

const MESSAGES = defineMessages({
  country: {
    id: 'countrySelect.country',
    defaultMessage: 'Country',
  },
});

interface IProps {
  name: string;
  validateTrigger: string;
  rules?: Rule[];
}

export const FormItemCountrySelect = ({
  name,
  rules,
  validateTrigger,
}: IProps) => {
  const intl = useIntl();

  return (
    <StyledFormItem
      name={name}
      validateTrigger={validateTrigger}
      rules={rules}
      label={intl.formatMessage(MESSAGES.country)}
    >
      <Select
        placeholder={intl.formatMessage(MESSAGES.country)}
        data-testid="select-country"
      >
        <>
          <Option value="BE">{countries.getName('BE', 'en')}</Option>
          {Object.entries(countries.getNames('en'))
            .filter(([key]) => key !== 'BE')
            .map(([key, value]) => (
              <Option key={key} value={value}>
                {value}
              </Option>
            ))}
        </>
      </Select>
    </StyledFormItem>
  );
};
