import React from 'react';
import { useSelector } from 'react-redux';

import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../store/selectors/tags.selectors';
import { FlexBox } from '../../styled/flex/FlexBox';
import { IconSize } from '../../types/icon.types';
import { BoxTag } from '../../types/tag.types';
import { CoolingFanIcon } from './icons/cooling/CoolingFanIcon';
import { HeatingRadiatorIcon } from './icons/heating/HeatingRadiatorIcon';
import { BoxTemperatureAlarmIcon } from './icons/temperature/BoxTemperatureAlarmIcon';
import { BoxTemperatureNormalIcon } from './icons/temperature/BoxTemperatureNormalIcon';

interface IProps {
  boxId: string;
}

export const BoxTemperatureIconsList = ({ boxId }: IProps) => {
  const coolingTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V2_COOLING_ON,
    ),
  );
  const heatingTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V2_HEATING_ON,
    ),
  );
  const temperatureTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V3_BOX_TEMPERATURE,
    ),
  );
  
  

  if (
    temperatureTagStatus?.value === '1' &&
    coolingTagStatus?.value === '0' &&
    heatingTagStatus?.value === '0'
  ) {
    return <BoxTemperatureNormalIcon />;
  }

  if (
    temperatureTagStatus?.value === '2' &&
    coolingTagStatus?.value === '0' &&
    heatingTagStatus?.value === '0'
  ) {
    return <BoxTemperatureAlarmIcon />;
  }

  if (temperatureTagStatus?.value === '1' && coolingTagStatus?.value === '1') {
    return (
      <FlexBox alignItems="center">
        <BoxTemperatureNormalIcon />
        <CoolingFanIcon />
      </FlexBox>
    );
  }
  if (temperatureTagStatus?.value === '2' && coolingTagStatus?.value === '1') {
    return (
      <FlexBox alignItems="center">
        <BoxTemperatureAlarmIcon />
        <CoolingFanIcon />
      </FlexBox>
    );
  }
  if (temperatureTagStatus?.value === '1' && heatingTagStatus?.value === '1') {
    return (
      <FlexBox alignItems="center">
        <BoxTemperatureNormalIcon />
        <HeatingRadiatorIcon size={IconSize.LARGE} />
      </FlexBox>
    );
  }
  if (temperatureTagStatus?.value === '2' && heatingTagStatus?.value === '1') {
    return (
      <FlexBox alignItems="center">
        <BoxTemperatureAlarmIcon />
        <HeatingRadiatorIcon size={IconSize.LARGE} />
      </FlexBox>
    );
  }
  return <BoxTemperatureNormalIcon />;
};
