import { defineMessages } from 'react-intl';

const emailRegex = /\S+@\S+\.\S+/;

export const isValidEmail = (testString: string) => {
  return emailRegex.test(testString);
};

export const LOGIN_MESSAGES = defineMessages({
  userNamePlaceholder: {
    id: 'login_general.userNamePlaceholder',
    defaultMessage: 'Enter username (email)',
  },
  passwordPlaceholder: {
    id: 'login_general.passwordPlaceholder',
    defaultMessage: 'Password',
  },
  passwordConfirmPlaceholder: {
    id: 'login_general.passwordConfirmPlaceholder',
    defaultMessage: 'Confirm Password',
  },
  enterResetPasswordCode: {
    id: 'login_general.enterResetPasswordCode',
    defaultMessage: 'Enter forgot password code',
  },
});
