import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { theme } from '../../theme';

import { StyledHeading } from '../../styled/headings/StyledHeading';
import { WhiteBox } from '../../components/layout/boxes/WhiteBox';

import mapImage from '../../assets/map.png';

import { DashboardActiveSessions } from './DashboardActiveSessions';
import { DashboardFaults } from './DashboardFaults';
import { DashboardAssets } from './DashboardAssets';
import { DashboardPointsByType } from './DashboardPointsByType';
import { DashboardRevenue } from './DashboardRevenue';
import { DashboardTotalConsumption } from './DashboardTotalConsumption';
import { DashboardMaintenanceTasks } from './DashboardMaintenanceTasks';

import { SessionGraph } from '../Sessions/SessionDetail/SessionUsageGraph/SessionGraph';
import { TechmetrysGraphData } from '../../types/session.types';

const MESSAGES = defineMessages({
  heading: {
    id: 'dashboard.heading',
    defaultMessage: 'Dashboard',
  },
});

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.medium};
  margin-bottom: ${theme.spacing.medium};

  @media (max-width: 1100px) {
    > div {
      width: 100%;
    }
  }
`;

const Image = styled.img`
  width: calc(60% - 16px);
  height: 100%;

  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  border-radius: 8px;

  @media only screen and (max-width: 1100px) {
    width: 100%;
  }
`;

interface DashboardData {
  label: string;
  color: string;
  data: number[];
}

interface DashboardDatas {
  [key: string]: DashboardData;
}

export const Dashboard = () => {
  const intl = useIntl();

  /*
  const canManageAssets = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_ASSETS),
  );
*/

  const datasets: DashboardDatas = {
    line_1: {
      label: 'Line 1',
      color: 'rgb(207, 176, 99)',
      data: [33, 42, 54, 24, 25, 34, 45, 46, 69, 70, 24, 42],
    },
    line_2: {
      label: 'Line 2',
      color: 'rgb(255, 77, 79)',
      data: [30, 35, 50, 20, 22, 30, 39, 44, 64, 65, 18, 38],
    },
    line_3: {
      label: 'Line 2',
      color: 'rgb(73, 81, 105)',
      data: [32, 37, 52, 23, 24, 29, 35, 45, 62, 15, 11, 36],
    },
    power: {
      label: 'Power',
      color: 'rgb(2, 162, 79)',
      data: [15, 24, 32, 17, 18, 22, 29, 36, 45, 10, 5, 32],
    },
  };

  const exampleDatasets: TechmetrysGraphData[] = [];

  const now = new Date().getTime();
  const minMS = 60 * 1000;

  const lines = Object.keys(datasets);
  lines.forEach((line) => {
    const ds = datasets[line];
    exampleDatasets.push({
      backgroundColor: ds.color,
      borderColor: ds.color,
      label: ds.label,
      data: ds.data.map((v: number, k: number) => ({
        x: now - (12 - k) * minMS,
        y: v,
      })),
      tension: 0.4,
    });
  });

  return (
    <>
      <StyledHeading variant="h1">
        {intl.formatMessage(MESSAGES.heading)}
      </StyledHeading>

      <Wrapper>
        <WhiteBox title="Active sessions" width="calc(100% / 3 - 16px)">
          <DashboardActiveSessions />
        </WhiteBox>
        <WhiteBox
          title="Faults / connectivity loss"
          width="calc(100% / 3 - 16px)"
        >
          <DashboardFaults />
        </WhiteBox>
        <WhiteBox title="Assets" width="calc(100% / 3 - 16px)">
          <DashboardAssets />
        </WhiteBox>
      </Wrapper>

      <Wrapper>
        <WhiteBox title="Points by type" width="calc(40% - 16px)">
          <DashboardPointsByType />
        </WhiteBox>
        <WhiteBox title="Total revenue" width="calc(60% - 16px)">
          <DashboardRevenue />
        </WhiteBox>
      </Wrapper>

      <Wrapper>
        <Image alt="map" src={mapImage} />
        {/* <WhiteBox width="calc(60% - 16px)">
            <DashboardMap />
          </WhiteBox> */}
        <WhiteBox title="Total consumption" width="calc(40% - 16px)">
          <DashboardTotalConsumption />
        </WhiteBox>
      </Wrapper>

      <Wrapper>
        <WhiteBox title="Maintenance tasks" width="calc(60% - 16px)">
          <DashboardMaintenanceTasks />
        </WhiteBox>
        <WhiteBox title="Consumption chart" width="calc(40% - 16px)">
          <SessionGraph datasets={exampleDatasets} />
        </WhiteBox>
      </Wrapper>
    </>
  );
};
