import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useGetUsersQuery } from '../../../store/api/users.api';
import { currentUserSelector } from '../../../store/selectors/user.selectors';
import { StyledHeading } from '../../../styled/headings/StyledHeading';
import { UsersTableWithFilter } from '../UsersTable/UsersTableWithFilter';

const MESSAGES = defineMessages({
  heading: {
    id: 'users_overview.heading',
    defaultMessage: 'Users Overview',
  },
});

export const UsersOverview = () => {
  const intl = useIntl();

  useGetUsersQuery();

  const currentUser = useSelector(currentUserSelector);

  return (
    <>
      <StyledHeading variant="h1">
        {intl.formatMessage(MESSAGES.heading)}
      </StyledHeading>
      {currentUser && <UsersTableWithFilter currentUser={currentUser} />}
    </>
  );
};
