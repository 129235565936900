import { SpaceProps } from 'styled-system';

export enum IconSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  XLARGE = 'xlarge',
  XXLARGE = 'xxlarge',
  ALARM_ICON = 'alarm_icon'
}

export interface IIconProps extends SpaceProps {
  onClick?: () => void;
  size?: IconSize;
}
