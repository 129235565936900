import { defineMessages } from 'react-intl';

export const FORM_VALIDATION_MESSAGES = defineMessages({
  required: {
    id: 'form_validation_messages.required',
    defaultMessage: '{label} is required.',
  },
  regex: {
    id: 'form_validation_messages.regex',
    defaultMessage: '{label} is not correctly formatted.',
  },
  fixedLength: {
    id: 'form_validation_messages.fixedLength',
    defaultMessage: '{label} should have a length of {fixedLength}.',
  },
  number: {
    id: 'form_validation_messages.numeric',
    defaultMessage: '{label} should only contain numbers.',
  },
});
