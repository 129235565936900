import React from 'react';

import { StyledBox } from '../../../../../styled/boxes/StyledBox';
import { IConnectorStatusIconProps } from '../../../../../types/connector.types';
import { getIconSize } from '../../../../../utils/shared/icons/getIconSize';

export const ForceConnectorActionIcon = ({
  size,
  ...space
}: IConnectorStatusIconProps) => (
  <StyledBox {...space}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-connector-action-force"
    >
      <path
        d="M8.50035 17.0558C8.50035 17.0558 1.36035 13.7598 1.36035 3.49966C5.80177 2.52454 7.10703 0.839844 8.50035 0.839844C9.89367 0.839844 11.1989 2.52454 15.6403 3.49966C15.6403 13.4198 8.50035 17.0558 8.50035 17.0558Z"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  </StyledBox>
);
