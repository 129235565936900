import styled from '@emotion/styled';
import { Checkbox } from 'antd';

import { colors } from '../../theme';

export const StyledCheckbox = styled(Checkbox)({
  '.ant-checkbox-checked .ant-checkbox-inner': {
    backgroundColor: colors.green.primary,
    borderColor: colors.green.primary,
  },

  '&& .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner':
    {
      borderColor: colors.green.primary,
    },
});
