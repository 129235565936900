import { ExportOutlined } from '@ant-design/icons';
import React from 'react';
import { StyledButton } from '../../../styled/buttons/StyledButton';
import { theme } from '../../../theme';
import { Modal } from '../../../components/modals/Modal';
import { BoxActions } from '../BoxDetail/BoxStatusPortal/BoxActions';
import { getBoxConnectivityStatus } from '../../../utils/boxes/getBoxConnectivityStatus';

interface IProps {
  boxId: string;
  modalOpen: boolean;
  setModalClose: any;
  setModalOpen: any;
}

export const BoxesTableActionButton = ({
  boxId,
  modalOpen,
  setModalClose,
  setModalOpen,
}: IProps) => {
  const boxIsOffline = getBoxConnectivityStatus(boxId);

  return (
    <>
      <StyledButton
        width={theme.input.width.small}
        onClick={() => setModalOpen(boxId)}
        variant="primary"
        htmlType="submit"
        data-testid="btn-register-techmetrys-form-submit"
        disabled={boxIsOffline}
      >
        <ExportOutlined />
      </StyledButton>
      <Modal
        closable
        width={300}
        title="Asset actions"
        showModal={modalOpen}
        onCancel={() => setModalClose(boxId)}
      >
        {boxId && <BoxActions boxId={boxId} />}
      </Modal>
    </>
  );
};
