import React from 'react';

import { IAssetInfo } from '../../../../types/box.types';
import { AssetLogTableFilter } from './AssetLogTableFilter';
import { AssetLogsTable } from './AssetLogsTable';

import { PrimaryWrapperBox } from '../../../../styled/boxes/PrimaryWrapperBox';

interface IProps {
  boxInfo?: IAssetInfo;
}

export const AssetLogs = ({ boxInfo }: IProps) => {

  return (
    <>
      {boxInfo && (
        <AssetLogTableFilter
          // filters={filters}
          asset={boxInfo}
        />
      )}

        <PrimaryWrapperBox>
          <AssetLogsTable />
        </PrimaryWrapperBox>

    </>
  );
};
