import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { TopBar } from '../../../components/navigation/detail/TopBar';
import { appUrls } from '../../../config/url.constants';
import { useGetAuthorizedBoxByIdQuery } from '../../../store/api/boxes.api';
import { StyledHeading } from '../../../styled/headings/StyledHeading';
import { BoxDetailTabs } from '../../../types/tab.types';
import { BoxDeveloperPortal } from './BoxDeveloperPortal/BoxDeveloperPortal';
import { BoxSessionsPortal } from './BoxSessionsPortal/BoxSessionsPortal';
import { BoxStatusPortal } from './BoxStatusPortal/BoxStatusPortal';
import { AssetLogs } from './AssetLogs/AssetLogs';
import AlarmsPortal from './Alarms';

interface IProps {
  activeTab: BoxDetailTabs;
}

export const BoxDetail = ({ activeTab }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { assetId } = useParams() as { assetId: string };
  const { data: boxInfo } = useGetAuthorizedBoxByIdQuery(assetId);
  const [currentTab, setCurrentTab] = useState(activeTab);

  // Define routes and matching tab mappings in a single object
  const tabsConfig = {
    [BoxDetailTabs.DEVELOPER]: {
      route: appUrls.assets.devPortal(assetId),
      component: <BoxDeveloperPortal boxInfo={boxInfo} />,
    },
    [BoxDetailTabs.SESSIONS]: {
      route: appUrls.assets.sessionsPortal(assetId),
      component: <BoxSessionsPortal boxId={boxInfo?.id} />,
    },
    [BoxDetailTabs.STATUS]: {
      route: appUrls.assets.statusPortal(assetId),
      component: <BoxStatusPortal boxInfo={boxInfo} />,
    },
    [BoxDetailTabs.LOGS]: {
      route: appUrls.assets.logsPortal(assetId),
      component: boxInfo?.id ? <AssetLogs boxInfo={boxInfo} /> : null,
    },
    [BoxDetailTabs.ALARMS]: {
      route: appUrls.assets.alarmsPortal(assetId),
      component: <AlarmsPortal />,
    },
  };

  useEffect(() => {
    const tab = Object.keys(tabsConfig).find(
      (key) => tabsConfig[key as BoxDetailTabs].route === location.pathname,
    ) as BoxDetailTabs;
    setCurrentTab(tab || BoxDetailTabs.DEVELOPER);
  }, [location]);

  const handleTabClick = (tabPane: BoxDetailTabs) => {
    navigate(tabsConfig[tabPane].route);
  };

  return (
    <>
      <StyledHeading variant="h1">{boxInfo?.label}</StyledHeading>
      <TopBar selectedTab={currentTab} onTabClick={handleTabClick} />
      {tabsConfig[currentTab]?.component}
    </>
  );
};
