import React from 'react';
import styled from 'styled-components';

import { colors, theme } from '../../theme';
import { isMobile, isSmallDesktop, isTablet } from '../../utils/shared/screen';

interface IProps {
  additionalData?: any;
  data: any;
  columns: any;
  handleEditClick?: (id: string) => void;
}

const Container = styled.div<{ checked: boolean }>`
  width: 100%;
  min-height: 126px;
  background-color: ${colors.green.tertiary};
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: ${theme.borderRadius.medium};
  border: ${({ checked }) =>
    checked ? `1px solid ${colors.green.primary}` : '1px solid transparent'};
  gap: ${theme.spacing.small};
  margin-bottom: ${theme.spacing.small};
  cursor: pointer;
  padding: ${theme.spacing.medium};
`;

const DataContainer = styled.div<{ isFullWidth?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: ${({ isFullWidth }) =>
    isFullWidth ? '1 1 100%' : '1 1 48%'}; /* Full width if marked */
  margin-bottom: ${theme.spacing.small};
  align-items: baseline;

  @media (min-width: 769px) and (max-width: 1024px) {
    flex: ${({ isFullWidth }) =>
      isFullWidth
        ? '1 1 100%'
        : '1 1 40%'}; /* Two items per row unless full width */
  }

  @media (min-width: 1025px) {
    flex: ${({ isFullWidth }) =>
      isFullWidth
        ? '1 1 100%'
        : '1 1 20%'}; /* Three items per row unless full width */
  }
`;

const Label = styled.span`
  margin-bottom: ${theme.spacing.xxSmall};
  display: flex;
  align-items: center; /* Align labels to the baseline */
`;

const Value = styled.span<{ isFullWidth: boolean }>`
  margin-bottom: ${theme.spacing.small};
  white-space: ${({ isFullWidth }) =>
    isFullWidth ? 'normal' : 'nowrap'}; /* Prevent text overflow */
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center; /* Align values to the baseline */
`;

const renderDataContainer = (col: any, data: any, isFullWidth: boolean) => {
  const value = col.render
    ? col.render(col.dataIndex ? data[col.dataIndex] : data, data)
    : col.dataIndex !== undefined
    ? data[col.dataIndex]
    : '-';

  if (!value && !col.title) return null;

  return (
    <DataContainer key={col.title} isFullWidth={isFullWidth}>
      <Label>{col.title ? `${col.title}:` : null}</Label>
      <Value isFullWidth={isFullWidth}>{value ?? '-'}</Value>
    </DataContainer>
  );
};

export const MobileTableCard = ({
  additionalData,
  columns,
  data,
  handleEditClick,
}: IProps) => {
  const buttonColumns = columns.filter((col: any) =>
    col.className?.includes('button'),
  );
  const filteredColumns = columns.filter(
    (col: any) => !col.className?.includes('button'),
  );

  const checked = additionalData?.includes(data.id);

  return (
    <Container
      checked={checked}
      onClick={() => handleEditClick && handleEditClick(data.id)}
    >
      {buttonColumns.map((col: any) => {
        return renderDataContainer(col, data, buttonColumns.length === 1);
      })}
      {filteredColumns.map((col: any) => {
        let textSize = 26;
        if (isMobile) textSize = 26;
        if (isTablet) textSize = 36;
        if (isSmallDesktop) textSize = 46;

        const isFullWidth =
          col.className?.includes('fullWidth') ||
          (col.dataIndex && data[col.dataIndex]?.length > textSize);
        return renderDataContainer(col, data, isFullWidth);
      })}
    </Container>
  );
};
