import React from 'react';

import { MobileTableCard } from '../../../components/cards/MobileTableCard';
import { IUser } from '../../../types/user.types';

interface IProps {
  users: IUser[];
  columns: any;
  handleEditClick: (id: string) => void;
  selectedUserIds: string[];
}

export const UsersTableMobile = ({
  columns,
  handleEditClick,
  selectedUserIds,
  users,
}: IProps) => {
  return (
    <div>
      {users.map((user) => (
        <MobileTableCard
          key={user.id}
          columns={columns}
          data={user}
          additionalData={selectedUserIds}
          handleEditClick={handleEditClick}
        />
      ))}
    </div>
  );
};
