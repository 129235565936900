import * as React from 'react';
const Bell = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g stroke="currentColor" strokeMiterlimit={10} clipPath="url(#a)">
      <path d="M9.2 4.4v-.8c0-.44.36-.8.8-.8s.8.36.8.8v.8" />
      <path
        strokeLinejoin="round"
        d="M8.451 15.2a1.605 1.605 0 0 0 1.549 2 1.605 1.605 0 0 0 1.55-1.998l-.002-.003"
      />
      <path
        strokeLinejoin="round"
        d="M14 11.6V8.48c0-2.392-1.608-4.08-4-4.08S6 6.032 6 8.425v3.12c0 .87-.44 1.842-.875 2.568L4.4 15.2h11.2l-.725-1.088C14.44 13.387 14 12.47 14 11.6Z"
      />
      <path d="M16.533 12.533c.64-1.066 1.067-2.346 1.067-3.733a6.93 6.93 0 0 0-1.067-3.733m1.734 8.533A9.14 9.14 0 0 0 19.6 8.8c0-1.76-.48-3.413-1.333-4.8m-14.8 8.533C2.827 11.467 2.4 10.187 2.4 8.8s.373-2.613 1.067-3.733M1.733 13.6A9.14 9.14 0 0 1 .4 8.8C.4 7.04.88 5.387 1.733 4" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default Bell;
