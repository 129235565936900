import { Point } from 'chart.js';
import { IPagedResponse } from './api.types';
import { IOperator } from './operator.types';
import { ITagStatus } from './tag.types';
import { IVessel } from './vessel.types';

export enum ESessionConsumptionUnit {
  KWH = 'KWH',
  M3 = 'M3',
}

export interface ISessionConsumption {
  total: number;
  unit: ESessionConsumptionUnit;
  meterStart: number;
  meterReadout: number;
}

export interface ISessionConnector {
  id: string;
  index: number;
  boxLabel: string;
  tagStatuses: ITagStatus[];
}

export interface ISessionInitiator {
  firstName: string;
  lastName: string;
  userId: string;
}

export interface IPowerDataPoint {
  timestamp: number;
  power: number;
}

export enum CurrentDataLine {
  LINE_1 = 'LINE_1',
  LINE_2 = 'LINE_2',
  LINE_3 = 'LINE_3',
}

export interface ICurrentDataPoint {
  current: number;
  timestamp: number;
  line: CurrentDataLine;
}

export interface TechmetrysGraph {
  datasets: TechmetrysGraphData[];
}

export interface TechmetrysGraphData {
  backgroundColor: string;
  borderColor: string;
  data: Point[];
  label: string;
  tension: number;
}

export interface ISessionMetrics {
  maxCurrentL1: number;
  maxCurrentL2: number;
  maxCurrentL3: number;
  powerUsageOverTime: IPowerDataPoint[];
  currentOverTime: ICurrentDataPoint[];
}

export interface IUsageUpdate {
  consumption: number;
  meterStart: number;
  meterReadout: number;
  maxCurrentL1: number;
  maxCurrentL2: number;
  maxCurrentL3: number;
  mostRecentPowerDataPoint: IPowerDataPoint;
  mostRecentCurrentDataPoint: ICurrentDataPoint;
}

export enum SessionStatus {
  STARTED = 'STARTED',
  ENDED = 'ENDED',
  CANCELLED = 'CANCELLED',
}

export interface ISessionStatusChange {
  status: SessionStatus;
  changedAt: number;
}

export interface ISession {
  id: string;
  initiator?: ISessionInitiator;
  connector: ISessionConnector;
  startedAt: number;
  endedAt?: number;
  consumption: ISessionConsumption;
  transactionId: string;
  vessel: IVessel;
  active: boolean;
}

export interface ISessionDetail extends ISession {
  events: ISessionEvent[];
  metrics: ISessionMetrics;
  operator: IOperator;
}

export enum SessionTimelineEventSeverity {
  ERROR = 'ERROR',
  INFO = 'INFO',
  WARNING = 'WARNING',
}

export enum SessionTimelineEvent {
  AUTHORISATION_OK = 'AUTHORISATION_OK',
  AUTHORISATION_STOPPED = 'AUTHORISATION_STOPPED',
  CONNECTOR_PLUGGED_IN = 'CONNECTOR_PLUGGED_IN',
  CONNECTOR_PLUGGED_OUT = 'CONNECTOR_PLUGGED_OUT',
  RELAY_ACTIVATED = 'RELAY_ACTIVATED',
  RELAY_DEACTIVATED = 'RELAY_DEACTIVATED',
  START_BUTTON_PRESSED = 'START_BUTTON_PRESSED',
  STOP_BUTTON_PRESSED = 'STOP_BUTTON_PRESSED',
  STARTED_VIA_WEB = 'STARTED_VIA_WEB',
  VESSEL_DIFF_TRIP = 'VESSEL_DIFF_TRIP',
  VESSEL_OVERLOAD_TRIP = 'VESSEL_OVERLOAD_TRIP',
  VESSEL_DEFINITIVE = 'VESSEL_DEFINITIVE',
  MAINTENANCE = 'MAINTENANCE',
  SIMULATION_MODE = 'SIMULATION_MODE',
  TEST_MODE = 'TEST_MODE',
  TAMPERING = 'TAMPERING',
  BOX_FAIL = 'BOX_FAIL',
  WEB_REMOVAL_FAIL = 'WEB_REMOVAL_FAIL',
  METER = 'METER',
  METER_FUSE = 'METER_FUSE',
  RCA_NSX = 'RCA_NSX',
  RELAY = 'RELAY',
  DIFF_TRIP = 'DIFF_TRIP',
  DIFF_RESET = 'DIFF_RESET',
  BENDER = 'BENDER',
  SAFETY_LOOP = 'SAFETY_LOOP',
  CABLE_PULL = 'CABLE_PULL',
  VOLTAGE_ON_CABLE = 'VOLTAGE_ON_CABLE',
}

export interface ISessionEvent {
  event: SessionTimelineEvent;
  severity: SessionTimelineEventSeverity;
  timestamp: number;
}

export enum SessionFilter {
  BOX = 'BOX',
  ENI_NUMBER = 'ENI_NUMBER',
  TRANSACTION_ID = 'TRANSACTION_ID',
  VESSEL = 'VESSEL',
  INITIATOR = 'INITIATOR',
}

export enum RequestSessionFor {
  MANAGED_CONNECTORS = 'managedConnectors',
  COMPANY = 'company',
  USER = 'user',
}

export interface IActiveSession {
  id: string;
  transactionId: string;
  vesselEniNumber: string;
  vesselName: string;
  consumption: ISessionConsumption;
}

export type ISessionsResponse = IPagedResponse<ISession>;

export interface INewSessionPayload {
  connectorId: string;
  vesselId: string;
}
