import { createAction, PayloadActionCreator } from '@reduxjs/toolkit';

export class Actions {
  prefix: string;

  constructor(prefix: string) {
    this.prefix = prefix;
  }

  createAction<P = void>(actionType: string): PayloadActionCreator<P> {
    return createAction(`${this.prefix}/${actionType}`);
  }
}
