import { SmileOutlined } from '@ant-design/icons';
import { Drawer, DrawerProps, Timeline } from 'antd';
import React from 'react';

interface IAssetDrawer extends DrawerProps {
  asset: {
    assetType: string;
    label: string;
    lat: number;
    long: number;
  };
}

const AssetDrawer = ({ asset, visible, ...rest }: IAssetDrawer) => {
  return (
    <Drawer visible={visible} placement="left" mask={false} {...rest}>
      <Timeline>
        <Timeline.Item color="green">
          Create a services site 2015-09-01
        </Timeline.Item>
        <Timeline.Item color="green">
          Create a services site 2015-09-01
        </Timeline.Item>
        <Timeline.Item color="red">
          <p>Solve initial network problems 1</p>
          <p>Solve initial network problems 2</p>
          <p>Solve initial network problems 3 2015-09-01</p>
        </Timeline.Item>
        <Timeline.Item>
          <p>Technical testing 1</p>
          <p>Technical testing 2</p>
          <p>Technical testing 3 2015-09-01</p>
        </Timeline.Item>
        <Timeline.Item color="gray">
          <p>Technical testing 1</p>
          <p>Technical testing 2</p>
          <p>Technical testing 3 2015-09-01</p>
        </Timeline.Item>
        <Timeline.Item color="gray">
          <p>Technical testing 1</p>
          <p>Technical testing 2</p>
          <p>Technical testing 3 2015-09-01</p>
        </Timeline.Item>
        <Timeline.Item color="#00CCFF" dot={<SmileOutlined />}>
          <p>Custom color testing</p>
        </Timeline.Item>
      </Timeline>
    </Drawer>
  );
};

export default AssetDrawer;
