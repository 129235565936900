import styled from '@emotion/styled';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  flex,
  flexbox,
  FlexboxProps,
  FlexProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';

export const StyledDiv = styled.div<
  | SpaceProps
  | LayoutProps
  | FlexProps
  | FlexboxProps
  | TypographyProps
  | ColorProps
  | BorderProps
>(space, layout, flex, flexbox, typography, color, border);
