import { Tooltip } from 'antd';
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../store/selectors/tags.selectors';
import { ConnectorTag } from '../../types/tag.types';
import { ConnectorStartConditionLine } from './ConnectorStartConditionLine';

interface IProps {
  boxId: string;
  connectorId: string;
  children: ReactNode;
}

export const ConnectorStartConditionsTooltip = ({
  boxId,
  children,
  connectorId,
}: IProps) => {
  const authorisationStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      connectorId,
      ConnectorTag.V6_AUTHORISATION,
    ),
  );
  const pilootContactStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      connectorId,
      ConnectorTag.V6_PILOOTCONTACT,
    ),
  );
  const userDoorStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      connectorId,
      ConnectorTag.V6_USER_DOOR,
    ),
  );
  const vesselBenderStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      connectorId,
      ConnectorTag.V6_VESSEL_BENDER,
    ),
  );
  const vesselSafetyLoopStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      connectorId,
      ConnectorTag.V6_VESSEL_SAFETY_LOOP,
    ),
  );
  const voltageOnCableStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      connectorId,
      ConnectorTag.V6_VOLTAGE_ON_CABLE,
    ),
  );
  const pointBlockedStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      connectorId,
      ConnectorTag.V6_POINT_BLOCKED,
    ),
  );

  const tooltipLines = [
    authorisationStatus,
    pilootContactStatus,
    userDoorStatus,
    vesselBenderStatus,
    vesselSafetyLoopStatus,
    voltageOnCableStatus,
    pointBlockedStatus,
  ].map(
    (ts) =>
      ts && (
        <ConnectorStartConditionLine startConditionStatus={ts} key={ts.tag} />
      ),
  );

  return <Tooltip title={tooltipLines}>{children}</Tooltip>;
};
