import en from '../translations/en.json';
import languageCode from '../types/language.types';

interface IResourceHandle<T> {
  resource: T;
  setResource: (...arg: never) => void;
}

export type ILanguageHandle = IResourceHandle<{
  language: languageCode;
  messages: Record<string, string>;
}>;

const selectTranslations = (langCode: languageCode) => {
  switch (langCode) {
    default:
      return en;
  }
};

// todo: language code should be set dynamically
export const useLanguage = (): ILanguageHandle => {
  const langCode = languageCode.EN;
  const messages = selectTranslations(langCode);

  const resource = {
    language: langCode,
    messages,
  };

  return {
    resource,
    setResource: () => null, // todo: add a function to update the resource
  };
};
