import { colors } from '../../theme';

interface IProps {
  iconColor: string;
  iconBackgroundColor: string;
  iconBorderColor: string;
}

export const getConnectorStatusIconAndBackgroundColor = (
  active?: boolean,
  connectorWebStatus?: string,
): IProps => {
  if (active === false) {
    return {
      iconColor: colors.lightGrey.secondary,
      iconBackgroundColor: colors.lightGrey.tertiary,
      iconBorderColor: colors.lightGrey.secondary,
    };
  }
  switch (connectorWebStatus) {
    case '0':
      return {
        iconColor: colors.lightGrey.secondary,
        iconBackgroundColor: colors.lightGrey.tertiary,
        iconBorderColor: colors.lightGrey.secondary,
      };
    case '1':
      return {
        iconColor: colors.grey.secondary,
        iconBackgroundColor: colors.grey.tertiary,
        iconBorderColor: colors.grey.secondary,
      };
    case '2':
      return {
        iconColor: colors.brown.primary,
        iconBackgroundColor: colors.brown.secondary,
        iconBorderColor: colors.brown.primary,
      };
    case '3':
      return {
        iconColor: colors.blue.primary,
        iconBackgroundColor: colors.blue.secondary,
        iconBorderColor: colors.blue.primary,
      };
    case '4':
      return {
        iconColor: colors.green.secondary,
        iconBackgroundColor: colors.green.tertiary,
        iconBorderColor: colors.green.secondary,
      };
    case '5':
      return {
        iconColor: colors.yellow.primary,
        iconBackgroundColor: colors.yellow.tertiary,
        iconBorderColor: colors.yellow.primary,
      };
    case '6':
      return {
        iconColor: colors.red.secondary,
        iconBackgroundColor: colors.red.tertiary,
        iconBorderColor: colors.red.secondary,
      };
    default:
      return {
        iconColor: colors.lightGrey.secondary,
        iconBackgroundColor: colors.lightGrey.tertiary,
        iconBorderColor: colors.lightGrey.secondary,
      };
  }
};
