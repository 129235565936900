import { Form } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import { defineMessages, useIntl } from 'react-intl';
import { PrimaryWrapperBox } from '../../styled/boxes/PrimaryWrapperBox';
import { StyledSearch } from '../../styled/form/StyledSearch';
import { theme } from '../../theme';
import { AssetFilterKey } from '../../types/filters.types';
import { BoxesFilter } from '../../types/box.types';
import { StyledCheckbox } from '../../styled/form/StyledCheckbox';
import {
  FilterElements,
  FilterContainer,
} from '../../components/filters/FilterElements';

const MESSAGES = defineMessages({
  filter: {
    id: 'maintenance_table_filters.filter',
    defaultMessage: 'Filter:',
  },
  userFilter: {
    id: 'maintenance_table_filters.userFilter',
    defaultMessage: 'User:',
  },
  assetFilter: {
    id: 'maintenance_table_filters.assetFilter',
    defaultMessage: 'Asset:',
  },
  reporterFilter: {
    id: 'maintenance_table_filters.reporterFilter',
    defaultMessage: 'Reporter:',
  },
  priorityFilter: {
    id: 'maintenance_table_filters.priorityFilter',
    defaultMessage: 'Priority:',
  },

  search: {
    id: 'boxes_table_filters.search',
    defaultMessage: 'Search',
  },
  boxDropdownPlaceholder: {
    id: 'boxes_table_filters.boxDropdownPlaceholder',
    defaultMessage: 'Sort',
  },
  errors: {
    id: 'boxes_table_filters.errors',
    defaultMessage: 'Errors',
  },
  warnings: {
    id: 'boxes_table_filters.warnings',
    defaultMessage: 'Warnings',
  },
  favorites: {
    id: 'boxes_table_filters.favorites',
    defaultMessage: 'Favorites',
  },
  all: {
    id: 'boxes_table_filters.all',
    defaultMessage: 'All',
  },
  power: {
    id: 'boxes_table_filters.power',
    defaultMessage: 'Power',
  },
  water: {
    id: 'boxes_table_filters.water',
    defaultMessage: 'Water',
  },
  [BoxesFilter.ASSET_ID]: {
    id: 'boxes_table_filters.asset_id',
    defaultMessage: 'Asset:',
  },
  [BoxesFilter.TYPE_FILTER]: {
    id: 'boxes_table_filters.for',
    defaultMessage: 'Type filter:',
  },
  [BoxesFilter.LOCATION]: {
    id: 'boxes_table_filters.location',
    defaultMessage: 'Location:',
  },
  [BoxesFilter.FILTER]: {
    id: 'boxes_table_filters.filter',
    defaultMessage: 'Filter:',
  },
});

export const MaintenanceFilters = () => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const [filters, setFilters] = useState({
    user: '',
    asset: '',
    reporter: '',
    priority: '',
    urgent: false,
    high: false,
    hideUnfinishedTasks: false,
  });

  const handleCheckboxChange = (name: string) => {};

  const handleChangeFilter = (key: string, value: string) => {};

  return (
    <PrimaryWrapperBox mb={theme.spacing.large}>
      <Form
        form={form}
        layout="inline"
        style={{
          justifyContent: 'space-between',
        }}
      >
        <FilterContainer>
          <FilterElements title={intl.formatMessage(MESSAGES.userFilter)}>
            <StyledSearch
              onChange={(ev) => handleChangeFilter('user', ev.target.value)}
              onSearch={(value) => handleChangeFilter('user', value)}
              placeholder={intl.formatMessage(MESSAGES.search)}
              value={filters.user}
              data-testid={`search-${AssetFilterKey.assetId}`}
            />
          </FilterElements>

          <FilterElements title={intl.formatMessage(MESSAGES.assetFilter)}>
            <StyledSearch
              onChange={(ev) => handleChangeFilter('asset', ev.target.value)}
              onSearch={(value) => handleChangeFilter('asset', value)}
              placeholder={intl.formatMessage(MESSAGES.search)}
              value={filters.asset}
              data-testid={`search-${AssetFilterKey.assetId}`}
            />
          </FilterElements>

          <FilterElements title={intl.formatMessage(MESSAGES.reporterFilter)}>
            <StyledSearch
              onChange={(ev) => handleChangeFilter('reporter', ev.target.value)}
              onSearch={(value) => handleChangeFilter('reporter', value)}
              placeholder={intl.formatMessage(MESSAGES.search)}
              value={filters.reporter}
              data-testid={`search-${AssetFilterKey.assetId}`}
            />
          </FilterElements>

          <FilterElements title={intl.formatMessage(MESSAGES.priorityFilter)}>
            <StyledSearch
              onChange={(ev) => handleChangeFilter('priority', ev.target.value)}
              onSearch={(value) => handleChangeFilter('priority', value)}
              placeholder={intl.formatMessage(MESSAGES.search)}
              value={filters.priority}
              data-testid={`search-${AssetFilterKey.assetId}`}
            />
          </FilterElements>
        </FilterContainer>

        <FilterElements
          title={intl.formatMessage(MESSAGES.filter)}
          noSpace
        >
          <StyledCheckbox
            data-testid={`checkbox-box-table-${MESSAGES.errors.defaultMessage}`}
            className={MESSAGES.errors.defaultMessage}
            onChange={(ev) =>
              handleCheckboxChange(MESSAGES.errors.defaultMessage.toLowerCase())
            }
          >
            Urgent
          </StyledCheckbox>
          <StyledCheckbox
            data-testid={`checkbox-box-table-${MESSAGES.warnings.defaultMessage}`}
            className={MESSAGES.errors.defaultMessage}
            onChange={(ev) =>
              handleCheckboxChange(
                MESSAGES.warnings.defaultMessage.toLowerCase(),
              )
            }
          >
            High
          </StyledCheckbox>
          <StyledCheckbox
            data-testid={`checkbox-box-table-${MESSAGES.favorites.defaultMessage}`}
            className={MESSAGES.errors.defaultMessage}
            onChange={(ev) =>
              handleCheckboxChange(
                MESSAGES.favorites.defaultMessage.toLowerCase(),
              )
            }
          >
            Hide unfinished tasks
          </StyledCheckbox>
        </FilterElements>
      </Form>
    </PrimaryWrapperBox>
  );
};
