import { Select } from 'antd';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useGetVesselsQuery } from '../../../../store/api/vessels.api';
import { vesselsSelector } from '../../../../store/selectors/vessel.selectors';
import { StyledSelect } from '../../../../styled/form/StyledSelect';

const MESSAGES = defineMessages({
  searchVessel: {
    id: 'select_vessel_dropdown.searchVessel',
    defaultMessage: 'Search vessel',
  },
});

const { Option } = Select;

interface IProps {
  onSelectVessel: (vesselId: string) => void;
  selectedVesselId?: string;
}

export const SelectVesselDropdown = ({
  onSelectVessel,
  selectedVesselId,
}: IProps) => {
  const intl = useIntl();
  useGetVesselsQuery(undefined);
  const vessels = useSelector(vesselsSelector());

  useEffect(() => {
    if (vessels?.length === 1) {
      onSelectVessel(vessels[0].id);
    }
  }, [vessels]);

  return (
    <StyledSelect
      filterOption={(input, option) =>
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        option!.children.toLowerCase().includes(input.toLowerCase())
      }
      onSelect={(vesselId: unknown) => onSelectVessel(vesselId as string)}
      showSearch
      placeholder={intl.formatMessage(MESSAGES.searchVessel)}
      data-testid="select-vessel"
      value={selectedVesselId}
    >
      {vessels?.map((vessel) => (
        <Option
          key={vessel.id}
        >{`${vessel.name} (${vessel.eniNumber})`}</Option>
      ))}
    </StyledSelect>
  );
};
