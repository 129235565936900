import React from 'react';
import { useSelector } from 'react-redux';

import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../store/selectors/tags.selectors';
import { StyledDiv } from '../../styled/StyledDiv';
import { BoxType } from '../../types/box.types';
import { IconSize } from '../../types/icon.types';
import { BoxTag } from '../../types/tag.types';
import { getBoxTypeForSymbolBoxStatus } from '../../utils/boxes/getBoxTypeForSymbolBoxStatus';
import { ConsumptionMeterIcon } from './icons/consumption/ConsumptionMeterIcon';
import { SessionMetricsBox } from '../../pages/Sessions/SessionDetail/SessionMetrics/SessionMetricsBox';
import { formatNumber } from '../../utils/shared/formats/formatNumbers';

interface IProps {
  boxId: string;
}

export const BoxConsumptionIcon = ({ boxId }: IProps) => {
  const symbolBoxTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V2_SYMBOL_BOX,
    ),
  );
  const boxTotWhStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V7_BOX_TOT_WH,
    ),
  );
  const boxTotalLitersTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V7_BOX_TOT_LITERS,
    ),
  );
  const boxTotPulsenStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V7_BOX_TOT_PULSEN,
    ),
  );


  const boxType = getBoxTypeForSymbolBoxStatus(symbolBoxTagStatus);

  return (
    <>
      <SessionMetricsBox
        active={false}
        large
        icon={<ConsumptionMeterIcon size={IconSize.LARGE} />}
        title="Consumption"
        type={1}
      >
        {(boxType === BoxType.POWER || boxType === BoxType.POWER_WATER || boxType === BoxType.POWER_WASTE) && (
          <><StyledDiv>{formatNumber(boxTotWhStatus?.value) || 0} kWh</StyledDiv></>
        )}
        {(boxType === BoxType.WATER || boxType === BoxType.POWER_WATER || boxType === BoxType.WATER_WASTE) && (
          <><StyledDiv>{formatNumber(boxTotalLitersTagStatus?.value) || 0} m3</StyledDiv></>
        )}
        {(boxType === BoxType.WASTE || boxType === BoxType.POWER_WASTE || boxType === BoxType.WATER_WASTE) && (
          <><StyledDiv>{formatNumber(boxTotPulsenStatus?.value) || 0} m3</StyledDiv></>
        )}
      </SessionMetricsBox>
    </>
  );
};
