import { createSelector } from '@reduxjs/toolkit';

import { IVesselFilters } from '../../types/filters.types';
import { vesselsApi } from '../api/vessels.api';

export const vesselRtkState = (requestedSessionFilters?: IVesselFilters) =>
  vesselsApi.endpoints.getVessels.select(requestedSessionFilters);

export const vesselsPageResponseSelector = (
  requestedSessionFilters?: IVesselFilters,
) =>
  createSelector(
    vesselRtkState(requestedSessionFilters),
    (state) => state.data,
  );

export const vesselsSelector = (requestedSessionFilters?: IVesselFilters) =>
  createSelector(
    vesselsPageResponseSelector(requestedSessionFilters),
    (data) => data?.content,
  );

export const vesselByIdSelector = (
  vesselId: string | undefined,
  requestedSessionFilters?: IVesselFilters,
) =>
  createSelector(vesselsSelector(requestedSessionFilters), (vessels) =>
    vessels?.find((vessel) => vessel.id === vesselId),
  );
