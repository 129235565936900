import { StopOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ResourceButtonWithIcon } from '../../../../components/buttons/ResourceButtonWithIcon';
import { appUrls } from '../../../../config/url.constants';
import Vessel from '../../../../icons/Vessel';
import { usePostSessionMutation } from '../../../../store/api/sessions.api';
import { vesselByIdSelector } from '../../../../store/selectors/vessel.selectors';
import { StyledButton } from '../../../../styled/buttons/StyledButton';
import { FlexCol } from '../../../../styled/flex/FlexCol';
import { FlexRow } from '../../../../styled/flex/FlexRow';
import { StyledSpan } from '../../../../styled/text/StyledSpan';
import { colors, theme } from '../../../../theme';
import { IAssetsForNewSessionParams } from '../../../../types/box.types';
import {
  IConnectorCompatibilityStatusParams,
  ResourceType,
} from '../../../../types/connector.types';
import { DisabledDropdownPlaceholder } from './DisabledDropdownPlaceholder';
import { SelectBoxesDropdown } from './SelectBoxesDropdown';
import { SelectConnectorList } from './SelectConnectorList';
import { SelectOperatorDropdown } from './SelectOperatorDropdown';
import { SelectVesselDropdown } from './SelectVesselDropdown';
import { VesselMetaInfoList } from './VesselMetaInfoList';

const MESSAGES = defineMessages({
  cancel: {
    id: 'session_form.cancel',
    defaultMessage: 'Cancel',
  },
  selectBox: {
    id: 'session_form.selectBox',
    defaultMessage: 'Select a box',
  },
  selectConnector: {
    id: 'session_form.selectConnector',
    defaultMessage: 'Select a connector',
  },
  selectOperator: {
    id: 'session_form.selectOperator',
    defaultMessage: 'Select an operator',
  },
  searchVessel: {
    id: 'session_form.searchVessel',
    defaultMessage: 'Search vessel...',
  },
  startSession: {
    id: 'session_form.startSession',
    defaultMessage: 'Start session',
  },
  selectResource: {
    id: 'session_form.selectResource',
    defaultMessage: 'Select a resource',
  },
  power: {
    id: 'session_form.power',
    defaultMessage: 'Power',
  },
  water: {
    id: 'session_form.water',
    defaultMessage: 'Water',
  },
});

const StyledForm = styled(Form)({
  '.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector':
    {
      border: `1px solid ${colors.green.primary}`,
      borderRadius: theme.borderRadius.medium,
      boxShadow: theme.boxShadows.light,
    },
});

interface IProps {
  onCancelForm: () => void;
}

export const NewSessionForm = ({ onCancelForm }: IProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const [postSession, { data: createdSession, isSuccess: postSuccess }] =
    usePostSessionMutation();

  useEffect(() => {
    if (postSuccess && createdSession) {
      navigate(appUrls.sessions.detail(createdSession.id));
    }
  }, [postSuccess]);

  // VESSELS
  const [selectedVesselId, setSelectedVesselId] = useState<
    string | undefined
  >();

  const handleSelectVessel = (vesselId: string) =>
    setSelectedVesselId(vesselId);

  const selectedVessel = useSelector(vesselByIdSelector(selectedVesselId));

  // OPERATORS
  const [selectedOperatorId, setSelectedOperatorId] = useState<
    string | undefined
  >();

  // RESOURCES
  const [selectedResourceType, setSelectedResourceType] = useState<
    ResourceType | undefined
  >();

  // BOXES FOR NEW SESSION
  const [selectedBoxId, setSelectedBoxId] = useState<string | undefined>();

  const boxesForNewSessionParams: IAssetsForNewSessionParams | undefined =
    selectedOperatorId && selectedResourceType
      ? {
          operatorId: selectedOperatorId,
          resourceType: selectedResourceType,
        }
      : undefined;

  // BOX CONNECTORS FOR NEW SESSION
  const [selectedConnectorId, setSelectedConnectorId] = useState<
    string | undefined
  >();

  const handleSelectConnector = (connectorId: string) =>
    setSelectedConnectorId(connectorId);

  const connectorCompatibilityStatusParams:
    | IConnectorCompatibilityStatusParams
    | undefined =
    selectedBoxId && selectedVessel && selectedResourceType
      ? {
          boxId: selectedBoxId,
          vesselId: selectedVessel.id,
          resourceType: selectedResourceType,
        }
      : undefined;

  const handleSelectResourceClick = (resourceType: ResourceType) => {
    setSelectedResourceType(resourceType);
    setSelectedBoxId(undefined);
    setSelectedConnectorId(undefined);
  };

  const handleSelectBox = (boxId: string) => {
    setSelectedBoxId(boxId);
    setSelectedConnectorId(undefined);
  };

  const handleSelectOperator = (operatorId: string) => {
    setSelectedOperatorId(operatorId);
    setSelectedResourceType(undefined);
    setSelectedBoxId(undefined);
    setSelectedConnectorId(undefined);
  };

  const handleCancel = () => onCancelForm();

  const handleFormSubmit = () => {
    if (selectedConnectorId && selectedVesselId)
      postSession({
        connectorId: selectedConnectorId,
        vesselId: selectedVesselId,
      });
  };

  const isFormDisabled =
    !selectedVessel ||
    !selectedOperatorId ||
    !selectedResourceType ||
    !selectedBoxId ||
    !selectedConnectorId;

  return (
    <StyledForm
      layout="vertical"
      onFinish={handleFormSubmit}
      autoComplete="off"
      data-testid="form-create-session"
    >
      <FlexRow
        justifyContent="space-around"
        alignItems="baseline"
        mb={theme.spacing.xLarge}
      >
        <Vessel width="80px" height="60px" fill={colors.grey.secondary} />
        <FlexCol alignSelf="center" width="70%">
          <Form.Item>
            <SelectVesselDropdown
              onSelectVessel={handleSelectVessel}
              selectedVesselId={selectedVesselId}
            />
          </Form.Item>
          <VesselMetaInfoList selectedVessel={selectedVessel} />
        </FlexCol>
      </FlexRow>
      <Form.Item label={intl.formatMessage(MESSAGES.selectOperator)}>
        {selectedVessel ? (
          <SelectOperatorDropdown
            onSelectOperator={handleSelectOperator}
            selectedOperatorId={selectedOperatorId}
          />
        ) : (
          <DisabledDropdownPlaceholder />
        )}
      </Form.Item>
      <StyledSpan>{intl.formatMessage(MESSAGES.selectResource)}</StyledSpan>
      <FlexRow my={theme.spacing.xLarge} justifyContent="center">
        <ResourceButtonWithIcon
          resourceType={ResourceType.POWER}
          isDisabled={!selectedOperatorId}
          selected={selectedResourceType === ResourceType.POWER}
          label={intl.formatMessage(MESSAGES.power)}
          onClick={() => handleSelectResourceClick(ResourceType.POWER)}
        />
        <ResourceButtonWithIcon
          resourceType={ResourceType.WATER}
          isDisabled={!selectedOperatorId}
          selected={selectedResourceType === ResourceType.WATER}
          label={intl.formatMessage(MESSAGES.water)}
          onClick={() => handleSelectResourceClick(ResourceType.WATER)}
        />
      </FlexRow>
      <Form.Item label={intl.formatMessage(MESSAGES.selectBox)}>
        {boxesForNewSessionParams ? (
          <SelectBoxesDropdown
            boxesForNewSessionParams={boxesForNewSessionParams}
            onSelectBox={handleSelectBox}
          />
        ) : (
          <DisabledDropdownPlaceholder />
        )}
      </Form.Item>
      {connectorCompatibilityStatusParams && selectedResourceType && (
        <Form.Item label={intl.formatMessage(MESSAGES.selectConnector)}>
          <SelectConnectorList
            connectorCompatibilityStatusParams={
              connectorCompatibilityStatusParams
            }
            resourceType={selectedResourceType}
            onSelectConnector={handleSelectConnector}
            selectedConnectorId={selectedConnectorId}
          />
        </Form.Item>
      )}
      <FlexRow justifyContent="end" alignItems="center">
        <StyledButton
          variant="danger"
          htmlType="button"
          onClick={handleCancel}
          mt={theme.spacing.xxLarge}
          mr={theme.spacing.small}
          data-testid="bnt-session-form-cancel"
        >
          <StopOutlined />
          {intl.formatMessage(MESSAGES.cancel)}
        </StyledButton>
        <StyledButton
          variant="primary"
          htmlType="submit"
          disabled={isFormDisabled}
          mt={theme.spacing.xxLarge}
          ml={theme.spacing.small}
          data-testid="bnt-session-form-submit"
        >
          {intl.formatMessage(MESSAGES.startSession)}
        </StyledButton>
      </FlexRow>
    </StyledForm>
  );
};
