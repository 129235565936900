import { combineReducers, createReducer } from '@reduxjs/toolkit';

import { UserActions } from '../actions/user.actions';

const token = createReducer<string | null>(null, {
  [UserActions.setToken.type]: (state, { payload }) => payload,
});

const getCurrentUserResponseCode = createReducer<number | null>(null, {
  [UserActions.setGetCurrentUserResponse.type]: (state, { payload }) => payload,
});

export const userReducer = combineReducers({
  token,
  getCurrentUserResponseCode,
});
