import { StopOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { usePatchEndSessionMutation } from '../../store/api/sessions.api';
import { StyledButton } from '../../styled/buttons/StyledButton';
import { FlexRow } from '../../styled/flex/FlexRow';
import { theme } from '../../theme';
import { Modal } from '../modals/Modal';
import { getBoxConnectivityStatus } from '../../utils/boxes/getBoxConnectivityStatus';
import { NoConnectionBox } from '../../utils/boxes/components/NoConnectionBox';

const MESSAGES = defineMessages({
  endSession: {
    id: 'end_session.button',
    defaultMessage: 'End session',
  },
  cancel: {
    id: 'end_session.button.modal.cancel',
    defaultMessage: 'Cancel',
  },
  end: {
    id: 'end_session.button.modal.end',
    defaultMessage: 'End',
  },
  endSessionConfirm: {
    id: 'end_session.button.modal.end',
    defaultMessage: 'Are you sure you want to end this session?',
  },
});

interface IProps {
  fullWidth?: boolean;
  fontWeight?: string;
  height?: string;
  sessionId: string;
  boxId?: string | undefined;
}

export const EndSessionButtonWithModal = ({
  boxId = undefined,
  fontWeight,
  fullWidth,
  height,
  sessionId,
}: IProps) => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);

  const [endSession] = usePatchEndSessionMutation();
  const boxIsOffline = boxId ? getBoxConnectivityStatus(boxId) : false;

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleEnd = () => {
    endSession(sessionId);
    setShowModal(false);
  };

  return (
    // There is an issue with clicks on the buttons still propagating back up even when explicitly stopping propagation.
    // Depending on where this component is used, it might lead to unwanted clicks being fired on the parent component.
    // Wrapping the components and stopping propagation on the wrapping div solves this.
    <div onClick={(e) => e.stopPropagation()} role="presentation">
      {boxIsOffline ? (
        <Modal title="" showModal={showModal} closable onCancel={handleCancel}>
          <NoConnectionBox center />
        </Modal>
      ) : (
        <Modal
          title={intl.formatMessage(MESSAGES.endSessionConfirm)}
          showModal={showModal}
        >
          <FlexRow justifyContent="end" alignItems="right">
            <StyledButton
              variant="danger"
              htmlType="button"
              onClick={handleCancel}
              mt={theme.spacing.xxLarge}
              mr={theme.spacing.small}
              data-testid="bnt-end-session-cancel"
            >
              <StopOutlined />
              {intl.formatMessage(MESSAGES.cancel)}
            </StyledButton>
            <StyledButton
              variant="primary"
              htmlType="submit"
              mt={theme.spacing.xxLarge}
              ml={theme.spacing.small}
              data-testid="bnt-end-session-end"
              onClick={handleEnd}
            >
              {intl.formatMessage(MESSAGES.end)}
            </StyledButton>
          </FlexRow>
        </Modal>
      )}
      <StyledButton
        variant="danger"
        onClick={() => setShowModal(true)}
        data-testid="btn-end-session"
        fullWidth={fullWidth}
        fontWeight={fontWeight}
        height={height}
      >
        <StopOutlined />
        {intl.formatMessage(MESSAGES.endSession)}
      </StyledButton>
    </div>
  );
};
