import { Duration, format, intervalToDuration } from 'date-fns';

export const durationInMonthsDaysHoursMinutesSeconds = (
  startTime?: number,
  endTime?: number,
): Duration | undefined => {
  if (!startTime && !endTime) {
    return undefined;
  }

  if (startTime && endTime) {
    return intervalToDuration({
      start: new Date(startTime),
      end: new Date(endTime),
    });
  }

  if (startTime && !endTime) {
    const currentTimeStampInElapsedSeconds = Number(format(new Date(), 'T'));

    return intervalToDuration({
      start: new Date(startTime),
      end: currentTimeStampInElapsedSeconds,
    });
  }
  return undefined;
};
