import { LeafletMouseEvent } from 'leaflet';
import React, { useMemo, useState } from 'react';
import Map, { createIcon, IMarker } from '../../components/map';
import AssetDrawer from './components/AssetDrawer';
import SearchBarWithMenu from './components/Bar';

const assetList = [
  { assetType: 'box', label: '03078401', lat: 51.344309, long: 4.302504 },
  { assetType: 'box', label: '03078402', lat: 51.344087, long: 4.302545 },
  { assetType: 'box', label: '03078403', lat: 51.343506, long: 4.302642 },
  { assetType: 'box', label: '03078404', lat: 51.343034, long: 4.302807 },
  { assetType: 'box', label: '03078405', lat: 51.342221, long: 4.302979 },
  { assetType: 'box', label: '03078406', lat: 51.341625, long: 4.303186 },
  { assetType: 'box', label: '03078407', lat: 51.340922, long: 4.303334 },
  { assetType: 'box', label: '03078408', lat: 51.340324, long: 4.303513 },
  { assetType: 'box', label: '03078409', lat: 51.339392, long: 4.303818 },
  { assetType: 'box', label: '03078410', lat: 51.338432, long: 4.304146 },
  { assetType: 'box', label: '03078411', lat: 51.337769, long: 4.304371 },
  { assetType: 'box', label: '03078412', lat: 51.337154, long: 4.304453 },
  { assetType: 'box', label: '03078413', lat: 51.3365, long: 4.304759 },
  { assetType: 'box', label: '03078414', lat: 51.336023, long: 4.305104 },
];

const MapPage = () => {
  const [selectedAssetId, setSelectedAssetId] = useState<string | null>(null);
  const popupRenderer = (props: IMarker) => {
    const { id } = props;
    return <div>{id}</div>;
  };

  const onMarkerClick = (event: LeafletMouseEvent) => {
    setSelectedAssetId(event.target.options.id);
  };

  const selectedAsset = useMemo(() => {
    return assetList.find((asset) => asset.label === selectedAssetId);
  }, [selectedAssetId]);

  return (
    <div>
      <Map
        markers={assetList.map((asset) => ({
          id: asset.label,
          position: [asset.lat, asset.long],
          icon: createIcon(asset.assetType),
          eventHandlers: {
            click: (event) => onMarkerClick(event),
          },
        }))}
        popupRenderer={popupRenderer}
      >
        <SearchBarWithMenu
          assetList={assetList}
          onSearch={(asset) => setSelectedAssetId(asset.label)}
        />
      </Map>

      <AssetDrawer
        asset={selectedAsset}
        visible={!!selectedAssetId}
        onClose={() => setSelectedAssetId(null)}
        closable
      />
    </div>
  );
};

export default MapPage;
