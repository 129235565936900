import React from 'react';

import { StyledBoxWithPointer } from '../../../../../styled/boxes/StyledBoxWithPointer';
import { IConnectorStatusIconProps } from '../../../../../types/connector.types';
import { getIconSize } from '../../../../../utils/shared/icons/getIconSize';

export const ResetConnectorActionIcon = ({
  onClick,
  size,
  ...space
}: IConnectorStatusIconProps) => (
  <StyledBoxWithPointer
    {...space}
    onClick={onClick}
    height={getIconSize(size).height}
  >
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-connector-action-reset"
    >
      <path
        d="M0 2.5C0 1.39543 0.895431 0.5 2 0.5H30C31.1046 0.5 32 1.39543 32 2.5V30.5C32 31.6046 31.1046 32.5 30 32.5H2C0.895431 32.5 0 31.6046 0 30.5V2.5Z"
        fill="#02A24F"
      />
      <path
        d="M4.99969 24.3217L6.75709 19.6407L11.4381 21.3981"
        stroke="white"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M17.6318 6.5C17.6318 5.94772 17.1841 5.5 16.6318 5.5C16.0796 5.5 15.6318 5.94772 15.6318 6.5H17.6318ZM15.6318 12.5C15.6318 13.0523 16.0796 13.5 16.6318 13.5C17.1841 13.5 17.6318 13.0523 17.6318 12.5H15.6318ZM15.6318 6.5V12.5H17.6318V6.5H15.6318Z"
        fill="white"
      />
      <path
        d="M7.13184 20.5C8.13184 23.5 11.109 27.5 16.6318 27.5C22.1547 27.5 26.6318 23.0228 26.6318 17.5C26.6318 11.9772 22.1318 8.5 19.6318 8"
        stroke="white"
      />
      <path
        d="M16.6318 7.50005C11.1318 7 6.63184 11.9772 6.63184 17.5C6.63184 18.7314 6.72477 19.4107 7.13184 20.5"
        stroke="white"
        strokeDasharray="2 2"
      />
    </svg>
  </StyledBoxWithPointer>
);
