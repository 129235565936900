import React from 'react';

import { StyledBox } from '../../../../../../styled/boxes/StyledBox';
import { colors } from '../../../../../../theme';
import { IConnectorStatusIconProps } from '../../../../../../types/connector.types';
import { IconSize } from '../../../../../../types/icon.types';
import { getIconSize } from '../../../../../../utils/shared/icons/getIconSize';

export const StartConditionsSuccessIcon = ({
  ...space
}: IConnectorStatusIconProps) => (
  <StyledBox {...space}>
    <svg
      width={getIconSize(IconSize.MEDIUM).width}
      height={getIconSize(IconSize.MEDIUM).height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-connector-success-start-conditions"
    >
      <path
        d="M11.2 2.80005H15.2C15.642 2.80005 16 3.15805 16 3.60005V18.4C16 18.842 15.642 19.2 15.2 19.2H4.8C4.358 19.2 4 18.842 4 18.4V3.60005C4 3.15805 4.358 2.80005 4.8 2.80005H8.8"
        stroke={colors.green.primary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10 0.800049C9.33725 0.800049 8.80005 1.33725 8.80005 2.00005V4.00005H11.2V2.00005C11.2 1.33725 10.6628 0.800049 10 0.800049Z"
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99998 1.59998C9.89389 1.59998 9.79215 1.64212 9.71713 1.71713C9.64212 1.79215 9.59998 1.89389 9.59998 1.99998C9.59998 2.10606 9.64212 2.2078 9.71713 2.28282C9.79215 2.35783 9.89389 2.39998 9.99998 2.39998C10.1061 2.39998 10.2078 2.35783 10.2828 2.28282C10.3578 2.2078 10.4 2.10606 10.4 1.99998C10.4 1.89389 10.3578 1.79215 10.2828 1.71713C10.2078 1.64212 10.1061 1.59998 9.99998 1.59998Z"
        fill={colors.green.secondary}
      />
      <path
        d="M6.40002 11.2L8.80002 13.6L13.6 8.80005"
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
      />
    </svg>
  </StyledBox>
);
