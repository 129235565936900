import { ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import UserIcon from '../../../../icons/UserIcon';
import VesselIcon from '../../../../icons/VesselIcon';
import EniIcon from '../../../../icons/EniIcon';

import { ConnectorStatusWithNumber } from '../../../../components/connectors/ConnectorStatusWithNumber';
import { appUrls } from '../../../../config/url.constants';
import { FlexRow } from '../../../../styled/flex/FlexRow';

import { useGetBoxesForSearchSessionQuery } from '../../../../store/api/boxes.api';
import { boxesForSearchSessionSelector } from '../../../../store/selectors/box.selectors';

import {
  NormalText,
  KeyText,
} from '../../../../styled/text/Text';
import { colors, theme } from '../../../../theme';
import {
  ESessionConsumptionUnit,
  ISessionDetail,
} from '../../../../types/session.types';
import { ConnectorTag } from '../../../../types/tag.types';
import { RootState } from '../../../../store/store';

export interface IProps {
  session: ISessionDetail;
}

const LeftSideContainer = styled.div`
  display: flex;
  align-items: center;

  gap: ${theme.spacing.small};
`

const IconContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;


  width: 24px;
  height: 24px;
`

const ConnectionIcon = styled.div`
  width: 50%;
  text-align: left;
  
  @media (max-width: 1100px) {
    margin-right: -6px;
    width: unset;
    text-align: right;
  }
`

export const SessionGeneralInfo = ({ session }: IProps) => {
  const navigate = useNavigate()
  useGetBoxesForSearchSessionQuery();
  const connectorStatuses = useSelector((state: RootState) => state.tags.statuses);
  const boxesForSearchSession = useSelector(boxesForSearchSessionSelector);
  const sessionBox = boxesForSearchSession?.find(box => box.label === session?.connector?.boxLabel)

  const connectorWebStatusValue = connectorStatuses[session.connector.id]?.find(
    (status) => status.tag === ConnectorTag.V1_WEB_STATUS,
  )?.value;

  const connectorSymbolStartValue = () => {
    if (!session.active) {
      return session.consumption.unit === ESessionConsumptionUnit.KWH
        ? '2'
        : '4';
    }
    return session.connector.tagStatuses.find(
      (status) => status.tag === ConnectorTag.V2_SYMBOL_START,
    )?.value;
  };

  const handleBoxClick = () => {
    navigate(appUrls.assets.statusPortal(sessionBox?.id));
  }

  const SessionGeneralInfoContainer = styled.div`
    width: 100%;
    height: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
  `

  return (
    <SessionGeneralInfoContainer>
      <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
        <LeftSideContainer>
          <IconContainer>
            <UserIcon width='24px' height='24px' />
          </IconContainer>
          <KeyText>Initiator</KeyText>
        </LeftSideContainer>
        <NormalText textAlignMobile='right'>
          {session.initiator?.firstName || '-'} {session.initiator?.lastName || '-'}
        </NormalText>
      </FlexRow>

      <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
        <LeftSideContainer>
          <IconContainer>
            <VesselIcon width='32px' height='32px' />
          </IconContainer>
          <KeyText>Vessel</KeyText>
        </LeftSideContainer>
        <NormalText textAlignMobile='right'>
          {session.vessel?.name || '-'}
        </NormalText>
      </FlexRow>

      <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
        <LeftSideContainer>
          <IconContainer>
            <EniIcon fill='transparent' width='32px' height='26px' />
          </IconContainer>
          <KeyText>Number</KeyText>
        </LeftSideContainer>
        <NormalText textAlignMobile='right'>
          {session.vessel?.eniNumber || '-'}
        </NormalText>
      </FlexRow>

      <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
        <KeyText>Start from</KeyText>
        <NormalText textAlignMobile='right'>
          <ClockCircleOutlined style={{ marginRight: theme.spacing.xSmall }} />
          {session.startedAt ? moment(session.startedAt).format('DD/MM/YYYY - HH:mm') : '-'}
        </NormalText>
      </FlexRow>

      <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
        <KeyText>End at</KeyText>
        <NormalText textAlignMobile='right'>
          <ClockCircleOutlined style={{ marginRight: theme.spacing.xSmall }} />
          {session.endedAt ? moment(session.endedAt).format('DD/MM/YYYY - HH:mm') : '-'}
        </NormalText>
      </FlexRow>

      <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
        <KeyText>Tx Id</KeyText>
        <NormalText textAlignMobile='right'>
          {session.transactionId || '-'}
        </NormalText>
      </FlexRow>

      <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
        <KeyText>Box</KeyText>
        <NormalText textAlignMobile='right' color={colors.green.primary} decoration='underline' cursor="pointer" onClick={handleBoxClick}>
          {session.connector?.boxLabel || '-'}
        </NormalText>
      </FlexRow>

      <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
        <KeyText>Connector</KeyText>

        <ConnectionIcon>
          <ConnectorStatusWithNumber
            connectorIndex={session.connector.index}
            connectorSymbolStartValue={connectorSymbolStartValue()}
            connectorWebStatusValue={connectorWebStatusValue}
          />
        </ConnectionIcon>
      </FlexRow>
    </SessionGeneralInfoContainer>
  );
};
