import { BoxType } from '../../types/box.types';
import { ITagStatus } from '../../types/tag.types';

export const getBoxTypeForSymbolBoxStatus = (
  status: ITagStatus | undefined,
) => {
  if (status?.value === '0') {
    return BoxType.INVISIBLE;
  }
  if (status?.value === '1') {
    return BoxType.POWER;
  }
  if (status?.value === '2') {
    return BoxType.WATER;
  }
  if (status?.value === '3') {
    return BoxType.WASTE;
  }
  if (status?.value === '4') {
    return BoxType.POWER_WATER;
  }
  if (status?.value === '5') {
    return BoxType.POWER_WASTE;
  }
  if (status?.value === '6') {
    return BoxType.WATER_WASTE;
  }
  return BoxType.UNKNOWN;
};
