import { colors } from '../../theme';
import { BoxStatus } from '../../types/box.types';

export const getBoxStatusTextColor = (
  boxStatus: BoxStatus,
) => {
  if (boxStatus === BoxStatus.ALARM) {
    return colors.red.secondary;
  }
  if (boxStatus === BoxStatus.WARNING) {
    return colors.yellow.primary;
  }
  return colors.black.primary;
};
