import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  IssuesCloseOutlined,
  LogoutOutlined,
  OrderedListOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import styled from '@emotion/styled';
import { Menu, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../authentication/hooks/useAuth';
import { appUrls } from '../../../config/url.constants';
import Bell from '../../../icons/Bell';
import Clock from '../../../icons/Clock';
import Home from '../../../icons/Home';
import { VesselIcon } from '../../vessels/icons/VesselIcon';
import {
  currentUserSelector,
  hasCurrentUserPermissionSelector,
} from '../../../store/selectors/user.selectors';
import { FlexBox } from '../../../styled/flex/FlexBox';
import { FlexCentered } from '../../../styled/flex/FlexCentered';
import { StyledSpan } from '../../../styled/text/StyledSpan';
import { StyledSubTextLight } from '../../../styled/text/Text';
import { colors, theme } from '../../../theme';
import { IAuthHookContext } from '../../../types/auth.types';
import { NavLinks } from '../../../types/navigation.types';
import { UserPermission } from '../../../types/user.types';
import { getInitialNavLink } from '../../../utils/navigation/getInitialNavLink';
import { formatUserRole } from '../../../utils/users/formatUserRole';
import Badge from './Badge';
import useResponsive from '../../../hooks/useResponsive';
import Navigation from '../../../icons/Navigation';

const MESSAGES = defineMessages({
  dashboardNavLink: {
    id: 'side_navigation_bar.dashboardLink',
    defaultMessage: 'Dashboard',
  },
  mapNavLink: {
    id: 'side_navigation_bar.mapLink',
    defaultMessage: 'Map',
  },
  listNavLink: {
    id: 'side_navigation_bar.listNavigationLink',
    defaultMessage: 'Assets',
  },
  logsNavLink: {
    id: 'side_navigation_bar.logsNavigationLink',
    defaultMessage: 'Alarms / Warnings',
  },
  sessionsNavLink: {
    id: 'side_navigation_bar.sessionsNavigationLink',
    defaultMessage: 'Sessions',
  },
  usersNavLink: {
    id: 'side_navigation_bar.usersNavigationLink',
    defaultMessage: 'Users',
  },
  vesselsNavLink: {
    id: 'side_navigation_bar.vesselsNavigationLink',
    defaultMessage: 'Vessels',
  },
  maintenanceNavLink: {
    id: 'side_navigation_bar.maintenanceNavLink',
    defaultMessage: 'Maintenance',
  },
  settingsNavLink: {
    id: 'side_navigation_bar.settingsNavigationLink',
    defaultMessage: 'Settings',
  },
  confirmLogOutTitle: {
    id: 'side_navigation_bar.confirmLogOutTitle',
    defaultMessage: 'Are you sure you want to log out?',
  },
  confirmLogOutMessage: {
    id: 'side_navigation_bar.confirmLogOutMessage',
    defaultMessage: 'Logging out will redirect you to the login screen.',
  },
  confirmLogOutConfirmButton: {
    id: 'side_navigation_bar.confirmLogOutConfirmButton',
    defaultMessage: 'Log out',
  },
});

const { Item } = Menu;

const StyledMenu = styled(Menu)({
  marginTop: theme.spacing.small,
  border: 'none',
  '& .ant-menu-item-selected': {
    svg: {
      stroke: `${colors.green.primary} !important`,
    },
  },
  '& .ant-menu-item:hover': {
    svg: {
      stroke: 'white',
    },
  },
  '.vessel-icon': {
    stroke: colors.black.primary,
  },
});

const CustomIcon = ({ children }: { children: React.ReactNode }) => (
  <StyledSpan className="anticon ant-menu-item-icon">{children}</StyledSpan>
);

interface ISideMenu {
  isCollapsed: boolean;
  onSidebarClick: () => void;
}

const SideMenu = ({ isCollapsed, onSidebarClick }: ISideMenu) => {
  const intl = useIntl();
  const currentRoute = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useResponsive();
  const { signOut } = useAuth() as IAuthHookContext;

  const currentUser = useSelector(currentUserSelector);
  const canCreateSession = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.CREATE_SESSION),
  );
  const canManageAssets = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_ASSETS),
  );
  const canManageCompanySessions = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_COMPANY_SESSIONS),
  );
  const canManageVessels = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_VESSELS),
  );
  const canManageUsers = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_USERS),
  );
  const canManageLogs = true;

  const [selectedNavLink, setSelectedNavLink] = useState(
    getInitialNavLink(currentUser),
  );

  useEffect(() => {
    if (currentRoute.pathname.includes(appUrls.maintenance.base)) {
      setSelectedNavLink([NavLinks.MAINTENANCE]);
    }
    if (currentRoute.pathname.includes(appUrls.dashboard.base)) {
      setSelectedNavLink([NavLinks.DASHBOARD]);
    }
    if (currentRoute.pathname.includes(appUrls.assets.base)) {
      setSelectedNavLink([NavLinks.LIST]);
    }
    // sessions displayed in the session's portal should result in the list link to be active
    // and not the sessions link
    if (
      currentRoute.pathname.includes(appUrls.sessions.base) &&
      !currentRoute.pathname.includes(appUrls.assets.base)
    ) {
      setSelectedNavLink([NavLinks.SESSIONS]);
    }
    if (currentRoute.pathname.includes(appUrls.users.base)) {
      setSelectedNavLink([NavLinks.USERS]);
    }
    if (currentRoute.pathname.includes(appUrls.vessels.base)) {
      setSelectedNavLink([NavLinks.VESSELS]);
    }
    if (currentRoute.pathname.includes(appUrls.settings.base)) {
      setSelectedNavLink([NavLinks.SETTINGS]);
    }
    if (
      currentRoute.pathname.includes(appUrls.alarms.base) &&
      !currentRoute.pathname.includes(appUrls.assets.base)
    ) {
      setSelectedNavLink([NavLinks.LOGS]);
    }
    if (currentRoute.pathname.includes(appUrls.map.base)) {
      setSelectedNavLink([NavLinks.MAP]);
    }
  }, [currentRoute]);

  const handleNavLinkClick = (navLink: NavLinks) => {
    setSelectedNavLink([navLink]);

    if (navLink === NavLinks.MAINTENANCE) {
      navigate(appUrls.maintenance.base);
    }
    if (navLink === NavLinks.DASHBOARD) {
      navigate(appUrls.dashboard.base);
    }
    if (navLink === NavLinks.MAP) {
      navigate(appUrls.map.base);
    }
    if (navLink === NavLinks.LIST) {
      navigate(appUrls.assets.base);
    }
    if (navLink === NavLinks.SESSIONS) {
      navigate(appUrls.sessions.base);
    }
    if (navLink === NavLinks.USERS) {
      navigate(appUrls.users.base);
    }
    if (navLink === NavLinks.VESSELS) {
      navigate(appUrls.vessels.base);
    }
    if (navLink === NavLinks.SETTINGS) {
      navigate(appUrls.settings.profile);
    }
    if (navLink === NavLinks.LOGS) {
      navigate(appUrls.alarms.base);
    }

    if (isMobile) {
      onSidebarClick();
    }
  };

  const getLogoutIcon = () => (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div onClick={(e) => e.stopPropagation()} data-testid="btn-logout">
      <Popconfirm
        title={intl.formatMessage(MESSAGES.confirmLogOutTitle)}
        okText={intl.formatMessage(MESSAGES.confirmLogOutConfirmButton)}
        onConfirm={signOut}
        placement="right"
      >
        <LogoutOutlined />
      </Popconfirm>
    </div>
  );

  const MobileFooter =
    isCollapsed && currentUser ? (
      <FlexBox
        flexDirection="column"
        pl={theme.spacing.medium}
        pr={theme.spacing.xLarge}
      >
        <FlexBox alignItems="center" justifyContent="space-between">
          <StyledSpan mr={theme.spacing.small} data-testid="current-user-name">
            {`${currentUser.firstName} ${currentUser.lastName}`}
          </StyledSpan>
          {getLogoutIcon()}
        </FlexBox>
        <StyledSubTextLight data-testid="current-user-role">
          {formatUserRole(currentUser.role)}
        </StyledSubTextLight>
      </FlexBox>
    ) : (
      <FlexCentered>{getLogoutIcon()}</FlexCentered>
    );

  const DesktopFooter =
    !isCollapsed && currentUser ? (
      <FlexBox
        flexDirection="column"
        pl={theme.spacing.medium}
        pr={theme.spacing.xLarge}
        mb={theme.spacing.xxLarge}
      >
        <FlexBox alignItems="center" justifyContent="space-between">
          <StyledSpan mr={theme.spacing.small} data-testid="current-user-name">
            {`${currentUser.firstName} ${currentUser.lastName}`}
          </StyledSpan>
          {getLogoutIcon()}
        </FlexBox>
        <StyledSubTextLight data-testid="current-user-role">
          {formatUserRole(currentUser.role)}
        </StyledSubTextLight>
        <ArrowLeftOutlined onClick={onSidebarClick} />
      </FlexBox>
    ) : (
      <FlexBox flexDirection="column" mb={theme.spacing.xxLarge}>
        <FlexCentered mb={theme.spacing.medium}>{getLogoutIcon()}</FlexCentered>
        <ArrowRightOutlined onClick={onSidebarClick} />
      </FlexBox>
    );

  return (
    <FlexBox flexDirection="column" height="90%" justifyContent="space-between">
      <StyledMenu
        defaultSelectedKeys={selectedNavLink}
        selectedKeys={selectedNavLink}
      >
        {canManageAssets && (
          <Item
            key={NavLinks.DASHBOARD}
            icon={
              <CustomIcon>
                <Home />
              </CustomIcon>
            }
            onClick={() => handleNavLinkClick(NavLinks.DASHBOARD)}
          >
            {intl.formatMessage(MESSAGES.dashboardNavLink)}
          </Item>
        )}
        <Item
          key={NavLinks.MAP}
          icon={
            <CustomIcon>
              <Navigation />
            </CustomIcon>
          }
          onClick={() => handleNavLinkClick(NavLinks.MAP)}
        >
          {intl.formatMessage(MESSAGES.mapNavLink)}
        </Item>
        {canManageAssets && (
          <Item
            key={NavLinks.LIST}
            icon={<OrderedListOutlined />}
            onClick={() => handleNavLinkClick(NavLinks.LIST)}
          >
            {intl.formatMessage(MESSAGES.listNavLink)}
          </Item>
        )}
        {canManageLogs && (
          <Item
            key={NavLinks.LOGS}
            icon={
              <Badge type="menu">
                <CustomIcon>
                  <Bell />
                </CustomIcon>
              </Badge>
            }
            onClick={() => handleNavLinkClick(NavLinks.LOGS)}
          >
            {intl.formatMessage(MESSAGES.logsNavLink)}
          </Item>
        )}
        {(canManageAssets || canManageCompanySessions || canCreateSession) && (
          <Item
            key={NavLinks.SESSIONS}
            icon={
              <CustomIcon>
                <Clock />
              </CustomIcon>
            }
            onClick={() => handleNavLinkClick(NavLinks.SESSIONS)}
          >
            {intl.formatMessage(MESSAGES.sessionsNavLink)}
          </Item>
        )}
        {canManageVessels && (
          <Item
            key={NavLinks.VESSELS}
            icon={
              <CustomIcon>
                <VesselIcon className="vessel-icon" />
              </CustomIcon>
            }
            onClick={() => handleNavLinkClick(NavLinks.VESSELS)}
          >
            {intl.formatMessage(MESSAGES.vesselsNavLink)}
          </Item>
        )}
        {canManageAssets && (
          <Item
            key={NavLinks.MAINTENANCE}
            icon={<IssuesCloseOutlined />}
            onClick={() => handleNavLinkClick(NavLinks.MAINTENANCE)}
          >
            {intl.formatMessage(MESSAGES.maintenanceNavLink)}
          </Item>
        )}
        {canManageUsers && (
          <Item
            key={NavLinks.USERS}
            icon={<UserOutlined />}
            onClick={() => handleNavLinkClick(NavLinks.USERS)}
          >
            {intl.formatMessage(MESSAGES.usersNavLink)}
          </Item>
        )}
        <Item
          key={NavLinks.SETTINGS}
          icon={<SettingOutlined />}
          onClick={() => handleNavLinkClick(NavLinks.SETTINGS)}
        >
          {intl.formatMessage(MESSAGES.settingsNavLink)}
        </Item>
      </StyledMenu>
      {isMobile ? MobileFooter : DesktopFooter}
    </FlexBox>
  );
};

export default SideMenu;
