import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';

import { StyledButton } from '../../styled/buttons/StyledButton';
import { theme, colors } from '../../theme';

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  width: 100%;
  @media (max-width: 400px) {
    width: 90%;
  }
`;

const HorizontalLine = styled.hr`
  flex-grow: 1;
  border: none;
  border-top: 1px solid ${colors.green.primary};
`;

const OrText = styled.span`
  margin: 0 10px;
  color: ${colors.green.primary};
`;

const MESSAGES = defineMessages({
  signup: {
    id: 'login_page.signup',
    defaultMessage: 'Sign up',
  },
});

interface MobileSignUpProps {
  handleRegister: () => void;
}

const MobileSignUp = ({ handleRegister }: MobileSignUpProps) => {
  const intl = useIntl();

  return (
    <>

    <DividerContainer>
      <HorizontalLine />
      <OrText>or</OrText>
      <HorizontalLine />

    </DividerContainer>
      <StyledButton
        width={theme.input.width.big}
        variant="secondary"
        htmlType="submit"
        onClick={handleRegister}
        data-testid="btn-login-submit"
      >
        {intl.formatMessage(MESSAGES.signup)}
      </StyledButton>
    </>
  );
}

export default MobileSignUp;
