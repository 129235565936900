import { Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// Theme and colors
import { colors, theme } from '../../../theme';

// Icons
import Star from '../../../icons/Star';

// THIS COMPONENT ONLY SERVES SAVING THE BOX ID TO REDUX
import { SaveBoxToRedux } from './SaveBoxToRedux';

// Boxes type
import { updateRequestedTablePage } from '../../../store/slices/tableMetaInfo.slice';
import { StyledTable } from '../../../styled/table/StyledTable';
import { useGetAuthorizedBoxesQuery } from '../../../store/api/boxes.api';
import { BoxesLatestMessage } from '../../../components/boxes/BoxesLatestMessage';

// Selectors
import { authorizedBoxesSelector } from '../../../store/selectors/box.selectors';
import {
  boxFilterByKeySelector,
  boxFiltersByKeysSelector,
} from '../../../store/selectors/filters.selectors';

// Types
import {
  AssetFilterKey,
  IAssetFilters,
  IPagedAssetFilters,
} from '../../../types/filters.types';
import { IAssetInfo, RequestBoxFor } from '../../../types/box.types';
import { IConnectorBox } from '../../../types/connector.types';
import { Table } from '../../../types/table.types';
import { ConnectorTag } from '../../../types/tag.types';

// Box columns
import { BoxEthernetIconsList } from '../../../components/boxes/BoxEthernetIconsList';
import { requestedTablePageSelector } from '../../../store/selectors/tableMetaInfo.selectors';
import { setAssetFilter } from '../../../store/slices/filters.slice';
import { BoxStatusIconsList } from '../../../components/boxes/BoxStatusIconsList';
import { BoxesTableTitle } from './BoxesTableTitle';

// Modal
import { BoxesMobile } from './BoxesMobile/BoxesMobile';
import { RootState } from '../../../store/store';
import { BoxTableConsumption } from './BoxTableConsumption';
import { BoxTableActiveConsumption } from './BoxTableActiveConsumption';
import { BoxesTableActionButton } from './BoxesTableActionButton';
import { BoxesTableExpandable } from './BoxesTableExpandable';
import { BoxLocation } from './BoxLocation';
import { BoxesTableConnectors } from './BoxesTableConnectors';
import { appUrls } from '../../../config/url.constants';
import { isMobile, isTablet } from '../../../utils/shared/screen';
import { safeJsonParse } from '../../../utils/json/parser';
import useDebounce from '../../../hooks/useDebounce';
import BoxListener from './BoxListener';

const FlexContainer = styled.div`
  display: flex;
`;

const MESSAGES = defineMessages({
  connectorTableHeading: {
    id: 'boxes_table.connectorTableHeading',
    defaultMessage: 'Connectors',
  },
  labelTableHeading: {
    id: 'boxes_table.labelTableHeading',
    defaultMessage: 'Box',
  },
  connectivity: {
    id: 'boxes_table.connectivity',
    defaultMessage: 'Connectivity',
  },
  assetLabel: {
    id: 'boxes_table.assetLabel',
    defaultMessage: 'Asset',
  },
  status: {
    id: 'boxes_table.status',
    defaultMessage: 'Status',
  },
  information: {
    id: 'boxes_table.information',
    defaultMessage: 'Information',
  },
  totalConsumption: {
    id: 'boxes_table.totalConsumption',
    defaultMessage: 'Total cons. (kWh / m3)',
  },
  activeConsumption: {
    id: 'boxes_table.activeConsumption',
    defaultMessage: 'Active power consumption',
  },
  location: {
    id: 'boxes_table.location',
    defaultMessage: 'Location',
  },
  actions: {
    id: 'boxes_table.actions',
    defaultMessage: 'Actions',
  },
});

export const BoxesTable = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpenById, setModalOpenById] = useState<{
    [key: string]: boolean;
  }>({});

  const connectorStatuses =
    useSelector((state: RootState) => state.tags.statuses) || {};
  // useTagsChannelQuery(connectorId);

  const requestBoxFor = useSelector(
    boxFilterByKeySelector(AssetFilterKey.typeFilter),
  ) as RequestBoxFor | undefined;

  const boxFilterKeys = [
    AssetFilterKey.assetId,
    AssetFilterKey.location,
    AssetFilterKey.filter,
    AssetFilterKey.typeFilter,
  ];

  const boxesFilterValues = useSelector(
    boxFiltersByKeysSelector(boxFilterKeys),
  ) as IAssetFilters | undefined;
  const debouncedBoxesFilter = useDebounce(
    JSON.stringify(boxesFilterValues),
    500,
  );

  const requestedBoxTablePage = useSelector(
    requestedTablePageSelector(Table.BOXES_TABLE),
  );

  const requestedAssetFilters: IPagedAssetFilters = {
    ...{
      ...(safeJsonParse(debouncedBoxesFilter) as IAssetFilters),
      typeFilter: boxesFilterValues?.typeFilter || 'all',
    },
    page: requestedBoxTablePage,
  };

  useEffect(() => {
    const errorHandler = (event: ErrorEvent) => {
      console.log('An error occurred:', event.error);
    };

    // Add the error handler to the window object
    window.addEventListener('error', errorHandler);

    dispatch(
      setAssetFilter({
        key: AssetFilterKey.typeFilter,
        value: 'all',
      }),
    );

    return () => {
      // Remove the error handler when the component unmounts
      window.removeEventListener('error', errorHandler);
    };
  }, []);

  const { isFetching } = useGetAuthorizedBoxesQuery(requestedAssetFilters, {
    skip: !requestBoxFor,
    refetchOnMountOrArgChange: true,
  });

  const pagedBoxes = useSelector(
    authorizedBoxesSelector(requestedAssetFilters)
  );

  const handleRowClick = (boxId: string) =>
    navigate(`${appUrls.assets.statusPortal(boxId)}`);

  const handleAddToFavorite = (id: string) => {};

  const handleOpenModal = (boxId: string) => {
    setModalOpenById((prevState) => ({ ...prevState, [boxId]: true }));
  };

  const handleCloseModal = (boxId: string) => {
    setModalOpenById((prevState) => ({ ...prevState, [boxId]: false }));
  };

  const columns: ColumnsType<IAssetInfo> = [
    {
      width: '50px',
      onCell: (record) => ({
        onClick: (e) => {
          e.stopPropagation();
          handleAddToFavorite(record.id);
        },
      }),
      render: (record) => {
        return (
          <>
            <SaveBoxToRedux boxId={record.id} />
            <Star
              stroke={colors.green.primary}
              fill={colors.green.primary}
              width="22px"
              height="22px"
            />
          </>
        );
      },
    },
    {
      title: intl.formatMessage(MESSAGES.assetLabel),
      dataIndex: 'assetId',
      key: 'assetId',
      width: '100px',
      sorter: (a, b) => a.label.length - b.label.length,
      render: (status, record) => {
        return (
          <>
            <BoxesTableTitle boxId={record.id} boxLabel={record.label} />
          </>
        );
      },
    },
    {
      title: intl.formatMessage(MESSAGES.connectivity),
      dataIndex: 'connectivity',
      key: 'connectivity',
      render: (connectivity, record) => {
        return <BoxEthernetIconsList boxId={record.id} />;
      },
    },
    {
      title: intl.formatMessage(MESSAGES.status),
      dataIndex: 'tagStatuses',
      key: 'tagStatuses',
      render: (tagStatuses, record) => {
        return (
          <div style={{ display: 'flex' }}>
            <Tooltip title={<BoxesLatestMessage boxId={record.id} />}>
              <BoxStatusIconsList assets showText={false} boxId={record.id} />
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: intl.formatMessage(MESSAGES.connectorTableHeading),
      dataIndex: 'connectors',
      key: 'connectors',
      width: 300,
      render: (connectors: IConnectorBox[], record) => {
        const activeConnectors = connectors.filter((connector) =>
          connector.tagStatuses.some(
            (ts) => ts.tag === ConnectorTag.V2_POINT_ACTIVE && ts.value === '1',
          ),
        );

        return (
          <FlexContainer>
            {activeConnectors.map((connector) => {
              const connectorType = connector.tagStatuses.find(
                (status) => status.tag === ConnectorTag.V2_SYMBOL_START,
              );

              const statuses = [
                ...(connector.tagStatuses ?? []),
                ...(connectorStatuses[connector.id] ?? []),
              ];
              // connectorStatuses[connector.id] || connector.tagStatuses;
              const updatedStatuses = [...statuses, connectorType];

              const connectorWebStatusValue = updatedStatuses.find(
                (status) => status?.tag === ConnectorTag.V1_WEB_STATUS,
              )?.value;
              const connectorSymbolStartValue = updatedStatuses.find(
                (status) => status?.tag === ConnectorTag.V2_SYMBOL_START,
              )?.value;

              return (
                <BoxesTableConnectors
                  key={connector.id}
                  boxId={record.id}
                  connector={connector}
                  connectorSymbolStartValue={connectorSymbolStartValue}
                  connectorWebStatusValue={connectorWebStatusValue}
                />
              );
            })}
          </FlexContainer>
        );
      },
    },
    {
      title: intl.formatMessage(MESSAGES.totalConsumption),
      dataIndex: 'totalConsumption',
      key: 'totalConsumption',
      width: 200,
      sorter: (a, b) => a.label.length - b.label.length, // Needs to be fixed when backend is done
      render: (statuses, record) => <BoxTableConsumption boxId={record.id} />,
    },
    {
      title: intl.formatMessage(MESSAGES.activeConsumption),
      dataIndex: 'tagStatuses',
      key: 'tagStatuses',
      render: (statuses, record) => {
        return <BoxTableActiveConsumption boxId={record.id} />;
      },
    },
    {
      title: intl.formatMessage(MESSAGES.location),
      width: 200,
      sorter: (a, b) => a.label.length - b.label.length, // Needs to be fixed when backend is done
      render: (statuses, record) => <BoxLocation boxId={record.id} />,
    },
    {
      title: intl.formatMessage({
        id: 'boxes_table.actions',
        defaultMessage: 'Actions',
      }),
      fixed: 'right',
      render: (record) => {
        return (
          <BoxesTableActionButton
            boxId={record.id}
            setModalOpen={handleOpenModal}
            setModalClose={handleCloseModal}
            modalOpen={modalOpenById[record.id]}
          />
        );
      },
    },
  ];

  const handleChangePage = (requestedPage: number) => {
    dispatch(
      updateRequestedTablePage({
        key: Table.BOXES_TABLE,
        value: requestedPage - 1,
      }),
    );
  };

  return (
    <>
      {isMobile || isTablet ? ( // || isSmallDesktop
        <BoxesMobile boxes={pagedBoxes} />
      ) : (
        <StyledTable<IAssetInfo>
          columns={columns}
          dataSource={pagedBoxes?.content}
          rowKey={(record: IAssetInfo) => record.id}
          pagination={{
            current: (pagedBoxes?.number || 0) + 1,
            onChange: (requestedPage: number) =>
              handleChangePage(requestedPage),
            pageSize: pagedBoxes?.size,
            position: ['bottomLeft'],
            total: pagedBoxes?.totalElements,
            showSizeChanger: false,
          }}
          loading={isFetching}
          expandable={{
            expandedRowRender: (record) => {
              return <BoxesTableExpandable boxId={record.id} />;
            },
          }}
          onRow={(record) => {
            return {
              onClick: () => handleRowClick(record.id),
            };
          }}
        />
      )}
      {pagedBoxes?.content.map((box) => (
        <BoxListener key={box.id} boxId={box.id} />
      ))}
    </>
  );
};
