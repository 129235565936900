import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ReactNode } from 'react';

interface IProps {
  children: ReactNode;
  className?: string;
  fill?: string;
  stroke?: string;
  viewBox: string;
  width?: string;
  height?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (...args: any) => void;
}

const SvgIcon = styled(({ children, className, viewBox }: IProps) => (
  <svg
    className={className}
    viewBox={viewBox}
    preserveAspectRatio="xMidYMid meet"
  >
    {children}
  </svg>
))`
  ${({ fill, height, stroke, width }) => css`
    ${(width || height) &&
    css`
      width: ${width};
      height: ${height};
    `};
    flex-shrink: 0; /* prevent shrinking when used in flex-box */
    vertical-align: middle;
    fill: ${fill};
    stroke: ${stroke};
  `};
`;

const Icon = (props: IProps) => {
  const { children, className, fill, height, stroke, viewBox, width, ...rest } =
    props;

  return (
    <SvgIcon
      viewBox={viewBox}
      className={className}
      fill={fill}
      stroke={stroke}
      width={width}
      height={height}
      {...rest}
    >
      {children}
    </SvgIcon>
  );
};

export default Icon;
