import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../theme';
import { getBoxConnectivityStatus } from '../../../utils/boxes/getBoxConnectivityStatus';
import { NoConnectionBox } from '../../../utils/boxes/components/NoConnectionBox';
import { ConnectorsTable } from '../BoxDetail/BoxStatusPortal/ConnectorsTable/ConnectorsTable';

const ConnectorsTableContainer = styled.div`
  margin-bottom: ${theme.spacing.large};
  margin-top: ${theme.spacing.large};
`;

interface IProps {
  boxId: string;
}

export const BoxesTableExpandable = ({ boxId }: IProps) => {
  const boxIsOffline = getBoxConnectivityStatus(boxId);

  return (
    <ConnectorsTableContainer>
      <ConnectorsTable boxIsOffline={boxIsOffline} boxId={boxId} />
    </ConnectorsTableContainer>
  );
};
