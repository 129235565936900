import React from 'react';

import logo from '../../assets/logo.svg';
import { FlexCol } from '../../styled/flex/FlexCol';
import { StyledSpan } from '../../styled/text/StyledSpan';
import { colors, theme } from '../../theme';

interface IProps {
  title: string;
  onClick?: () => void;
  isMobile?: boolean;
}

export const LogoWithTitle = ({ isMobile = false, onClick, title }: IProps) => {
  return (
    <FlexCol alignItems="center" onClick={onClick}>
      <img src={logo} alt="icon" width="300" height="300" />
      <StyledSpan color={colors.white.primary} fontSize={theme.fontSizes.title}>
        {!isMobile ? title : ''}
      </StyledSpan>
    </FlexCol>
  );
};
