import * as React from 'react';
const Checkmark = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 25"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeMiterlimit={10}
      strokeWidth={2}
      d="M7.55 11.85 12 16 22 4.2"
    />
    <path
      fill="currentColor"
      d="m22.182 6.306-.685.808A10.4 10.4 0 0 1 23 12.5C23 18.29 18.29 23 12.5 23S2 18.29 2 12.5 6.71 2 12.5 2c2.722 0 5.197 1.05 7.064 2.755l.654-.772A11.46 11.46 0 0 0 12.5 1C6.159 1 1 6.159 1 12.5S6.159 24 12.5 24 24 18.842 24 12.5c0-2.28-.67-4.405-1.818-6.194"
    />
  </svg>
);
export default Checkmark;
