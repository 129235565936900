import React from 'react';

import { StyledBoxWithPointer } from '../../../../styled/boxes/StyledBoxWithPointer';
import { IIconProps } from '../../../../types/icon.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const GoToLocationIcon = ({ onClick, size, ...space }: IIconProps) => (
  <StyledBoxWithPointer
    onClick={onClick}
    {...space}
    height={getIconSize(size).height}
  >
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-marker"
    >
      <path
        d="M17.3531 9.01719C17.059 8.33888 16.6348 7.72472 16.1047 7.20937C15.5728 6.68937 14.9458 6.27665 14.2578 5.99375C13.5422 5.69687 12.7828 5.54688 12 5.54688C11.2172 5.54688 10.4578 5.69688 9.74219 5.99219C9.05 6.27812 8.42969 6.6875 7.89531 7.20781C7.3655 7.72345 6.94143 8.33753 6.64687 9.01562C6.34219 9.71875 6.1875 10.4656 6.1875 11.2344C6.1875 12.3375 6.45156 13.4359 6.97031 14.4953C7.3875 15.3469 7.97031 16.1766 8.70469 16.9656C9.95938 18.3125 11.275 19.1359 11.6484 19.3562C11.7544 19.4189 11.8753 19.4518 11.9984 19.4516C12.1203 19.4516 12.2406 19.4203 12.3484 19.3562C12.7219 19.1359 14.0375 18.3125 15.2922 16.9656C16.0266 16.1781 16.6094 15.3469 17.0266 14.4953C17.5484 13.4375 17.8125 12.3406 17.8125 11.2359C17.8125 10.4672 17.6578 9.72031 17.3531 9.01719ZM12 18.2531C10.9703 17.5984 7.3125 15.0063 7.3125 11.2359C7.3125 10.0188 7.79844 8.875 8.68125 8.0125C9.56719 7.14844 10.7453 6.67188 12 6.67188C13.2547 6.67188 14.4328 7.14844 15.3188 8.01406C16.2016 8.875 16.6875 10.0188 16.6875 11.2359C16.6875 15.0063 13.0297 17.5984 12 18.2531ZM12 8.60938C10.4812 8.60938 9.25 9.84062 9.25 11.3594C9.25 12.8781 10.4812 14.1094 12 14.1094C13.5188 14.1094 14.75 12.8781 14.75 11.3594C14.75 9.84062 13.5188 8.60938 12 8.60938ZM13.2375 12.5969C13.0752 12.7597 12.8823 12.8887 12.6699 12.9767C12.4575 13.0646 12.2299 13.1097 12 13.1094C11.5328 13.1094 11.0938 12.9266 10.7625 12.5969C10.5997 12.4346 10.4706 12.2417 10.3827 12.0293C10.2947 11.8169 10.2496 11.5892 10.25 11.3594C10.25 10.8922 10.4328 10.4531 10.7625 10.1219C11.0938 9.79062 11.5328 9.60938 12 9.60938C12.4672 9.60938 12.9062 9.79062 13.2375 10.1219C13.5688 10.4531 13.75 10.8922 13.75 11.3594C13.75 11.8266 13.5688 12.2656 13.2375 12.5969Z"
        fill="#02A24F"
      />
      <path
        d="M2 1.5H22V-0.5H2V1.5ZM23 2.5V22.5H25V2.5H23ZM22 23.5H2V25.5H22V23.5ZM1 22.5V2.5H-1V22.5H1ZM2 23.5C1.44772 23.5 1 23.0523 1 22.5H-1C-1 24.1569 0.343145 25.5 2 25.5V23.5ZM23 22.5C23 23.0523 22.5523 23.5 22 23.5V25.5C23.6569 25.5 25 24.1569 25 22.5H23ZM22 1.5C22.5523 1.5 23 1.94771 23 2.5H25C25 0.843146 23.6569 -0.5 22 -0.5V1.5ZM2 -0.5C0.343146 -0.5 -1 0.843145 -1 2.5H1C1 1.94772 1.44771 1.5 2 1.5V-0.5Z"
        fill="#02A24F"
      />
    </svg>
  </StyledBoxWithPointer>
);
