import React from 'react';
import styled from 'styled-components';
import { defineMessages, useIntl } from 'react-intl';
import { IAssetInfo } from '../../../../types/box.types';
import { BoxStatusIconsList } from '../../../../components/boxes/BoxStatusIconsList';
import { BoxEthernetIconsList } from '../../../../components/boxes/BoxEthernetIconsList';
import { theme } from '../../../../theme';

interface AssetDetailsRowIconProps {
  pl?: string;
}

const AssetDetailsWrapper = styled.div``;

const AssetDetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${theme.spacing.small};

  &:last-child {
    margin-bottom: ${theme.spacing.small};
  }
`;

const AssetDetailsRowText = styled.div`
  width: 40%;
`;

const AssetDetailsRowIcon = styled.div<AssetDetailsRowIconProps>`
  width: 40%;
  display: flex;
  justify-content: flex-start;

  padding-left: ${(props) => props.pl || 0};
`;

const MESSAGES = defineMessages({
  assetType: {
    id: 'asset_details.assetType',
    defaultMessage: 'Asset type:',
  },
  assetEthernet: {
    id: 'asset_details.assetEthernet',
    defaultMessage: 'Ethernet status:',
  },
});

interface IProps {
  boxInfo?: IAssetInfo;
}

export const AssetDetails = ({ boxInfo }: IProps) => {
  const intl = useIntl();

  return (
    <>
      {boxInfo && (
        <AssetDetailsWrapper>
          <AssetDetailsRow>
            <AssetDetailsRowText>
              {intl.formatMessage(MESSAGES.assetType)}
            </AssetDetailsRowText>
            <AssetDetailsRowIcon>
              <BoxStatusIconsList assets boxId={boxInfo.id} />
            </AssetDetailsRowIcon>
          </AssetDetailsRow>

          <AssetDetailsRow>
            <AssetDetailsRowText>
              {intl.formatMessage(MESSAGES.assetEthernet)}
            </AssetDetailsRowText>
            <AssetDetailsRowIcon pl={theme.spacing.xSmall}>
              <BoxEthernetIconsList text boxId={boxInfo.id} />
            </AssetDetailsRowIcon>
          </AssetDetailsRow>
        </AssetDetailsWrapper>
      )}
    </>
  );
};
