import { AuthErrorStrings } from '@aws-amplify/auth/src/common/AuthErrorStrings';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth } from '../../authentication/hooks/useAuth';
import { CognitoError } from '../../components/login/CognitoError';
import { LoginContainer } from '../../components/login/LoginContainer';
import { LoginPasswordInput } from '../../components/login/LoginPasswordInput';
import { appUrls } from '../../config/url.constants';
import { userTokenSelector } from '../../store/selectors/user.selectors';
import { StyledButton } from '../../styled/buttons/StyledButton';
import { FlexRow } from '../../styled/flex/FlexRow';
import { StyledInput } from '../../styled/form/StyledInput';
import { StyledLink } from '../../styled/text/StyledLink';
import { StyledText } from '../../styled/text/Text';
import { theme } from '../../theme';
import { IAuthHookContext } from '../../types/auth.types';
import { isValidEmail, LOGIN_MESSAGES } from '../../utils/login/login.utils';
import MobileSignUp from './MobileSignUp';

const ForgotPasswordText = styled.span`
  width: 100%;
  color: rgba(2,162,79,1);
  margin-top: 4px;
  @media (max-width: 400px) {
    width: 90%;
  }
`;

const CodeToSubmit = styled.span`
  margin-top: ${theme.spacing.large};
`

const MESSAGES = defineMessages({
  title: {
    id: 'login_page.title',
    defaultMessage: 'Welcome!',
  },
  loginButton: {
    id: 'login_page.loginButton',
    defaultMessage: 'Login',
  },
  forgotPasswordButton: {
    id: 'login_page.forgotPasswordButton',
    defaultMessage: 'Forgot password?',
  },
  submitCodeButton: {
    id: 'login_page.submitCodeButton',
    defaultMessage: 'Have a code to submit?',
  },
  notRegistered: {
    id: 'login_page.notRegistered',
    defaultMessage: 'Not yet registered?',
  },
  resetButton: {
    id: 'login_page.resetButton',
    defaultMessage: 'Click here to reset.',
  },
  registerLink: {
    id: 'login_page.registerLink',
    defaultMessage: 'Click here to register.',
  },
  submitCode: {
    id: 'login_page.submitCode',
    defaultMessage: 'Click here to submit code.',
  },
});

export const LoginPage = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const { signIn } = useAuth() as IAuthHookContext;
  const [isMobile] = useState(window.innerWidth <= 768);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<AuthErrorStrings | null>(null);
  const isSignedIn = useSelector(userTokenSelector);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (err: any) => {
    const message = err.message as AuthErrorStrings;
    setError(message);
  };

  const handleFormSubmit = () => {
    signIn({ email, password }, handleError);
  };

  const handleNavigation = (link: string) => {
    navigate(link)
  }

  useEffect(() => {
    if (isSignedIn) {
      navigate(appUrls.login.landingAfterLogin);
    }
  }, [isSignedIn]);

  return (
    <LoginContainer title={intl.formatMessage(MESSAGES.title)}>
      <StyledInput
        mt={theme.spacing.large}
        mb={theme.spacing.large}
        width={theme.input.width.big}
        value={email}
        type="email"
        onChange={(e) => {
          setError(null);
          setEmail(e.target.value);
        }}
        placeholder={intl.formatMessage(LOGIN_MESSAGES.userNamePlaceholder)}
        data-testid="input-login-email"
      />
      <LoginPasswordInput
        onChange={(value) => {
          setError(null);
          setPassword(value);
        }}
        value={password}
        placeholder={intl.formatMessage(LOGIN_MESSAGES.passwordPlaceholder)}
        data-testid="input-login-password"
      />
      {isMobile && (
        <ForgotPasswordText
          onClick={() => handleNavigation(appUrls.login.resetPassword)}
          role="button"
          style={{}}
        >
          Forgot password?
        </ForgotPasswordText>
      )}
      <StyledButton
        mt={theme.spacing.large}
        width={theme.input.width.big}
        variant="primary"
        htmlType="submit"
        disabled={!isValidEmail(email) || !password}
        onClick={handleFormSubmit}
        data-testid="btn-login-submit"
      >
        {intl.formatMessage(MESSAGES.loginButton)}
      </StyledButton>
      {isMobile && (
        <>
          <MobileSignUp
            handleRegister={() => handleNavigation(appUrls.login.register)}
          />
          <CodeToSubmit>
            {intl.formatMessage(MESSAGES.submitCodeButton)} <br />
            <StyledLink to={appUrls.login.resetPasswordCode} style={{ fontSize: theme.fontSizes.small }} variant="primary">
              {intl.formatMessage(MESSAGES.submitCode)}
            </StyledLink>
          </CodeToSubmit>
        </>
      )}
      {error && <CognitoError error={error} />}
      {!isMobile && (
        <>
          <FlexRow mt={theme.spacing.xxxLarge}>
            <StyledText mr={theme.spacing.small}>
              {intl.formatMessage(MESSAGES.notRegistered)}
            </StyledText>
            <StyledLink to={appUrls.login.register} variant="primary">
              {intl.formatMessage(MESSAGES.registerLink)}
            </StyledLink>
          </FlexRow>
          <FlexRow mt={theme.spacing.large}>
            <StyledText mr={theme.spacing.small}>
              {intl.formatMessage(MESSAGES.forgotPasswordButton)}
            </StyledText>
            <StyledLink to={appUrls.login.resetPassword} variant="primary">
              {intl.formatMessage(MESSAGES.resetButton)}
            </StyledLink>
          </FlexRow>
          <FlexRow justifyContent='center' mt={theme.spacing.large}>
            <StyledText mr={theme.spacing.small}>
              {intl.formatMessage(MESSAGES.submitCodeButton)}
            </StyledText>
            <StyledLink to={appUrls.login.resetPasswordCode} variant="primary">
              {intl.formatMessage(MESSAGES.submitCode)}
            </StyledLink>
          </FlexRow>
        </>
      )}
    </LoginContainer>
  );
};
