import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { IIconProps } from '../../../../types/icon.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const BoxConnectionCellularIcon = ({ size, ...space }: IIconProps) => (
  <StyledBox {...space} height={getIconSize(size).height}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-connection-cellular"
    >
      <g clipPath="url(#clip0_4007_50210)">
        <path
          d="M15.0001 12.6001C14.3636 12.6001 13.7531 12.853 13.303 13.303C12.853 13.7531 12.6001 14.3636 12.6001 15.0001C12.6001 15.6366 12.853 16.2471 13.303 16.6972C13.7531 17.1472 14.3636 17.4001 15.0001 17.4001C15.6366 17.4001 16.2471 17.1472 16.6972 16.6972C17.1472 16.2471 17.4001 15.6366 17.4001 15.0001C17.4001 14.3636 17.1472 13.7531 16.6972 13.303C16.2471 12.853 15.6366 12.6001 15.0001 12.6001Z"
          stroke="#262626"
          strokeMiterlimit="10"
        />
        <path
          d="M15.0001 28.8001V16.8001M15.0001 12.6001C14.3636 12.6001 13.7531 12.853 13.303 13.303C12.853 13.7531 12.6001 14.3636 12.6001 15.0001C12.6001 15.6366 12.853 16.2471 13.303 16.6972C13.7531 17.1472 14.3636 17.4001 15.0001 17.4001C15.6366 17.4001 16.2471 17.1472 16.6972 16.6972C17.1472 16.2471 17.4001 15.6366 17.4001 15.0001C17.4001 14.3636 17.1472 13.7531 16.6972 13.303C16.2471 12.853 15.6366 12.6001 15.0001 12.6001Z"
          stroke="#262626"
          strokeMiterlimit="10"
        />
        <path
          d="M20.2632 27.1062C24.9336 25.0728 28.2 20.4186 28.2 15C28.2 7.71005 22.29 1.80005 15 1.80005C7.71005 1.80005 1.80005 7.71005 1.80005 15C1.80005 20.4186 5.06645 25.0728 9.73685 27.1062"
          stroke="#262626"
          strokeMiterlimit="10"
        />
        <path
          d="M18.8267 23.8019C22.2233 22.3229 24.5999 18.9407 24.5999 14.9999C24.5999 9.6977 20.3021 5.3999 14.9999 5.3999C9.6977 5.3999 5.3999 9.6977 5.3999 14.9999C5.3999 18.9407 7.7765 22.3229 11.1731 23.8019"
          stroke="#262626"
          strokeMiterlimit="10"
        />
        <path
          d="M17.3916 20.5014C19.5144 19.5768 21 17.463 21 15C21 11.6862 18.3138 9 15 9C11.6862 9 9 11.6862 9 15C9 17.463 10.4856 19.5768 12.6084 20.5014"
          stroke="#262626"
          strokeMiterlimit="10"
        />
        <rect x="16" y="16" width="14" height="14" rx="7" fill="white" />
        <path
          d="M23 29C19.7 29 17 26.3 17 23C17 19.7 19.7 17 23 17C26.3 17 29 19.7 29 23C29 26.3 26.3 29 23 29ZM23 18.2C20.36 18.2 18.2 20.36 18.2 23C18.2 25.64 20.36 27.8 23 27.8C25.64 27.8 27.8 25.64 27.8 23C27.8 20.36 25.64 18.2 23 18.2ZM26.48 21.02L25.58 20.24L22.34 24.02L20.36 22.52L19.64 23.48L22.46 25.64L26.48 21.02Z"
          fill="#63CF97"
        />
      </g>
      <defs>
        <clipPath id="clip0_4007_50210">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </StyledBox>
);
