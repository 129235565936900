import { AuthErrorStrings } from '@aws-amplify/auth/src/common/AuthErrorStrings';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../../authentication/hooks/useAuth';
import { CognitoError } from '../../components/login/CognitoError';
import { LoginContainer } from '../../components/login/LoginContainer';
import { appUrls } from '../../config/url.constants';
import { StyledButton } from '../../styled/buttons/StyledButton';
import { StyledInput } from '../../styled/form/StyledInput';
import { theme } from '../../theme';
import { IAuthHookContext } from '../../types/auth.types';
import { isValidEmail, LOGIN_MESSAGES } from '../../utils/login/login.utils';

const MESSAGES = defineMessages({
  title: {
    id: 'reset_password_page.title',
    defaultMessage: 'Reset Password!',
  },
  button: {
    id: 'reset_password_page.button',
    defaultMessage: 'Send reset instructions.',
  },
});

export const ResetPasswordRequestPage = () => {
  const intl = useIntl();
  const [email, setEmail] = useState('');
  const { forgotPassword } = useAuth() as IAuthHookContext;
  const navigate = useNavigate();
  const [error, setError] = useState<AuthErrorStrings | null>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleError = (err: any) => {
    const message = err.message as AuthErrorStrings;
    setError(message);
  };

  const handleSuccess = () => {
    navigate(appUrls.login.resetPasswordCode, { state: { email } });
  };

  const handleFormSubmit = () => {
    forgotPassword(email, handleError, handleSuccess);
  };

  return (
    <LoginContainer title={intl.formatMessage(MESSAGES.title)}>
      <StyledInput
        mt={theme.spacing.large}
        mb={theme.spacing.large}
        width={theme.input.width.big}
        value={email}
        type="email"
        onChange={(e) => setEmail(e.target.value)}
        placeholder={intl.formatMessage(LOGIN_MESSAGES.userNamePlaceholder)}
      />
      <StyledButton
        width={theme.input.width.big}
        variant="primary"
        htmlType="submit"
        disabled={!isValidEmail(email)}
        onClick={handleFormSubmit}
      >
        {intl.formatMessage(MESSAGES.button)}
      </StyledButton>
      {error && <CognitoError error={error} linkToResetPasswordPage />}
    </LoginContainer>
  );
};
