import React from 'react';

import { BoxStatus, BoxTechStatus } from '../../types/box.types';
import { IconSize } from '../../types/icon.types';
import { AlarmIcon } from '../icons/general/status/AlarmIcon';
import { WarningIcon } from '../icons/general/status/WarningIcon';
import { MaintenanceIcon } from './icons/maintenance/MaintenanceIcon';
import { OutOfServiceIcon } from './icons/maintenance/OutOfServiceIcon';

interface IProps {
  boxStatus: BoxStatus;
  boxTechStatus: BoxTechStatus;
  assets?: boolean;
}

export const BoxStatusIcon = ({
  assets = false,
  boxStatus,
  boxTechStatus,
}: IProps) => {
  if (
    !boxStatus ||
    !boxTechStatus ||
    (boxStatus === BoxStatus.OK && boxTechStatus === BoxTechStatus.OK)
  ) {
    return null;
  }
  if (boxTechStatus === BoxTechStatus.OUT_OF_SERVICE) {
    return (
      <OutOfServiceIcon boxTechStatus={boxTechStatus} size={IconSize.XXLARGE} />
    );
  }
  if (boxStatus === BoxStatus.WARNING) {
    return <WarningIcon assets={assets} />;
  }
  if (boxStatus === BoxStatus.ALARM) {
    return <AlarmIcon assets={assets} />;
  }
  if (boxStatus === BoxStatus.MAINTENANCE) {
    return (
      <MaintenanceIcon boxTechStatus={boxTechStatus} size={IconSize.XLARGE} />
    );
  }
  return null;
};
