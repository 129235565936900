import styled from '@emotion/styled';

import { colors, theme } from '../../theme';
import { StyledSpan } from './StyledSpan';

interface NormalTextProps {
  color?: string;
  alignment?: string;
  width?: string;
  decoration?: string;
  onClick?: () => void;
  cursor?: string;
  textAlignMobile?: string;
}

export const StyledText = styled(StyledSpan)({
  fontFamily: theme.fontFamilies.secondary,
  fontWeight: theme.fontWeights.regular,
  fontSize: theme.fontSizes.large,
  lineHeight: theme.lineHeights.large,
  color: colors.black.primary,
});

export const StyledSubTextLight = styled(StyledSpan)({
  fontFamily: theme.fontFamilies.primary,
  fontWeight: theme.fontWeights.light,
  fontSize: theme.fontSizes.small,
  lineHeight: theme.lineHeights.small,
  color: colors.black.primary,
  display: 'flex',
  alignItems: 'center',
});

export const StyledSubText = styled(StyledSpan)({
  fontFamily: theme.fontFamilies.primary,
  fontWeight: theme.fontWeights.medium,
  fontSize: theme.fontSizes.small,
  lineHeight: theme.lineHeights.small,
  color: colors.black.primary,
});

export const NormalText = styled.span<NormalTextProps>`
  font-family: ${theme.fontFamilies.primary};
  font-weight: ${theme.fontWeights.regular};
  font-size: ${theme.fontSizes.medium}px;
  line-height: ${theme.lineHeights.xLarge};
  color: ${(props) => props.color || colors.black.primary};
  text-align: ${(props) => props.alignment || 'left'};
  width: ${(props) => props.width || '50%'};
  text-decoration: ${(props) => props.decoration || 'none'};
  cursor: ${(props) => props.cursor || "auto"};

  @media (max-width: 1100px) {
    text-align: ${(props) => props.textAlignMobile || "auto"};
  }
`

export const KeyText = styled.span<NormalTextProps>`
  font-family: ${theme.fontFamilies.primary};
  font-weight: ${theme.fontWeights.light};
  font-size: ${theme.fontSizes.medium}px;
  line-height: ${theme.lineHeights.xLarge};
  color: ${colors.black.primary};
`

export const LargeText = styled.span<NormalTextProps>`
  font-family: ${theme.fontFamilies.primary};
  font-weight: ${theme.fontWeights.light};
  font-size: ${theme.fontSizes.large}px;
  line-height: ${theme.lineHeights.xLarge};
  color: ${colors.black.primary};
`
