import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { AppLayout } from '../../AppLayout';
import { StyledBox } from '../../styled/boxes/StyledBox';
import { StyledHeading } from '../../styled/headings/StyledHeading';
import { theme } from '../../theme';
import { ProfileForm } from './Profile/ProfileForm';

const MESSAGES = defineMessages({
  heading: {
    id: 'profile_page.heading',
    defaultMessage: 'My Profile',
  },
});

export const ProfilePage = () => {
  const intl = useIntl();

  return (
    <StyledBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <StyledHeading variant="h1" textAlign="left">
        {intl.formatMessage(MESSAGES.heading)}
      </StyledHeading>
      <ProfileForm />
    </StyledBox>
  );
};
