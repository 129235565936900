import { BoxTechStatus } from '../../types/box.types';
import { ITagStatus } from '../../types/tag.types';

export const getBoxTechStatusForTechStatus = (
  status: ITagStatus | undefined,
) => {
  if (status?.value === '0') {
    return BoxTechStatus.OUT_OF_SERVICE;
  }
  if (status?.value === '1') {
    return BoxTechStatus.OK;
  }
  if (status?.value === '2') {
    return BoxTechStatus.WARNING;
  }
  if (status?.value === '3') {
    return BoxTechStatus.ALARM;
  }
  
  return BoxTechStatus.UNKNOWN;
};
