import React from 'react';
import { Select } from 'antd';
import { useSelector } from 'react-redux';
import { StyledSelect } from '../../../styled/form/StyledSelect';
import { useUserFilters } from '../../../hooks/useUserFilters';

interface LinkedUsersProps {
  value?: any[];
  onChange?: (value: any[] | unknown, option: any) => void;
}

export const LinkedUsers: React.FC<LinkedUsersProps> = ({
  value = [],
  onChange,
}) => {
  const { filteredUsers } = useUserFilters();

  return (
    <StyledSelect
      mode="multiple"
      allowClear
      value={value}
      onChange={onChange}
      data-testid="vessel-linked-users"
    >
      {filteredUsers?.map((user) => (
        <Select.Option key={user.id} value={user.id}>
          {user.firstName} {user.lastName}
        </Select.Option>
      ))}
    </StyledSelect>
  );
};
