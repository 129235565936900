import React from 'react';

export const RelayDeactivatedIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6" clipPath="url(#clip0_2802_28376)">
      <path
        d="M28.7263 9.31341C28.9939 10.121 28.5115 10.7996 27.6385 10.7996H2.36174C1.48934 10.7996 1.00634 10.121 1.27394 9.31341L3.10094 1.45641C3.25994 0.977609 3.90554 0.599609 4.54754 0.599609H25.4521C26.0941 0.599609 26.7403 0.977609 26.8987 1.45641L28.7263 9.31341Z"
        stroke="#262626"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6002 23.9992V29.4916M28.8002 10.1992V19.8562C28.8002 20.8984 28.2074 21.5992 27.1112 21.5992H2.9972C1.901 21.5992 1.2002 20.8984 1.2002 19.8922V10.1992H28.8002Z"
        stroke="#262626"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6001 22.8008V24.6008"
        stroke="#262626"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M5.3999 24V29.4924"
        stroke="#262626"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5999 22.8008V29.4008H11.3999V22.8008M5.3999 22.8008V24.6008V22.8008Z"
        stroke="#262626"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M14.9998 24.5996C14.6815 24.5996 14.3763 24.726 14.1513 24.9511C13.9262 25.1761 13.7998 25.4813 13.7998 25.7996C13.7998 26.1179 13.9262 26.4231 14.1513 26.6481C14.3763 26.8732 14.6815 26.9996 14.9998 26.9996C15.3181 26.9996 15.6233 26.8732 15.8483 26.6481C16.0734 26.4231 16.1998 26.1179 16.1998 25.7996C16.1998 25.4813 16.0734 25.1761 15.8483 24.9511C15.6233 24.726 15.3181 24.5996 14.9998 24.5996Z"
        fill="#262626"
      />
    </g>
    <defs>
      <clipPath id="clip0_2802_28376">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
