import React from 'react';

import { StyledBox } from '../../../../../../styled/boxes/StyledBox';
import { colors } from '../../../../../../theme';
import { IConnectorStatusIconProps } from '../../../../../../types/connector.types';
import { IconSize } from '../../../../../../types/icon.types';
import { getIconSize } from '../../../../../../utils/shared/icons/getIconSize';

export const PilotContactOfflineIcon = ({
  ...space
}: IConnectorStatusIconProps) => (
  <StyledBox {...space}>
    <svg
      width={getIconSize(IconSize.MEDIUM).width}
      height={getIconSize(IconSize.MEDIUM).height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-connector-pilot-contact-offline"
    >
      <path
        d="M9.2002 4.39999L11.4162 2.18399C12.197 1.40319 13.4634 1.40319 14.2442 2.18399L17.8082 5.74799C18.589 6.52879 18.589 7.79519 17.8082 8.57599L15.6002 10.8"
        stroke={colors.grey.secondary}
        strokeMiterlimit="10"
      />
      <path
        d="M16.3999 11.6L8.3999 3.59998"
        stroke={colors.grey.secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.8002 15.6L8.58423 17.816C7.80343 18.5968 6.53703 18.5968 5.75623 17.816L2.18423 14.2479C1.40343 13.4672 1.40343 12.2008 2.18423 11.42L4.40023 9.19995M16.6522 4.54795L18.8002 2.39995L16.6522 4.54795ZM15.4522 3.34795L17.6002 1.19995L15.4522 3.34795ZM11.4522 12.148L14.2162 9.38395L11.4522 12.148ZM7.85223 8.54795L10.6162 5.78395L7.85223 8.54795Z"
        stroke={colors.grey.secondary}
        strokeMiterlimit="10"
      />
      <path
        d="M3.6001 8.40002L11.6001 16.4"
        stroke={colors.grey.secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M4.5482 16.652L2.4002 18.8M3.3482 15.452L1.2002 17.6L3.3482 15.452Z"
        stroke={colors.grey.secondary}
        strokeMiterlimit="10"
      />
    </svg>
  </StyledBox>
);
