import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { useGetCurrentUserQuery } from '../store/api/users.api';
import { userTokenSelector } from '../store/selectors/user.selectors';
import { AppLayout } from '../AppLayout';
import { appUrls } from '../config/url.constants';

const Protected = () => {
  const { isLoading: isFetchingUser } = useGetCurrentUserQuery();
  const isSignedIn = useSelector(userTokenSelector);

  if (!isSignedIn && !isFetchingUser) {
    return <Navigate to={appUrls.login.base} replace />;
  }

  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export default Protected;
