import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { currentUserSelector } from '../../../store/selectors/user.selectors';
import { StyledHeading } from '../../../styled/headings/StyledHeading';
import { VesselsTableWithFilter } from '../VesselsTable/VesselsTableWithFilter';

const MESSAGES = defineMessages({
  heading: {
    id: 'vessels_overview.heading',
    defaultMessage: 'Vessels Overview',
  },
});

export const VesselsOverview = () => {
  const intl = useIntl();
  const currentUser = useSelector(currentUserSelector);

  return (
    <>
      <StyledHeading variant="h1">
        {intl.formatMessage(MESSAGES.heading)}
      </StyledHeading>
      {currentUser && <VesselsTableWithFilter currentUser={currentUser} />}
    </>
  );
};
