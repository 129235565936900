/* eslint-disable */
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

// Theme and colors
import { colors, theme } from '../../../../theme';
import styled from 'styled-components';

// Boxes type
import { StyledTable } from '../../../../styled/table/StyledTable';

// Types
import { IAssetLogs, TagValueType } from '../../../../types/tag.types';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from '../../../../types/table.types';
import {
  updateRequestedTablePage,
  updateRequestedTablePageSize,
  updateRequestedTableSort,
} from '../../../../store/slices/tableMetaInfo.slice';
import {
  AssetLogFilterKey,
  IAssetLogFilters,
  IPagedAssetLogFilters,
} from '../../../../types/filters.types';
import { assetLogFiltersByKeysSelector } from '../../../../store/selectors/filters.selectors';
import {
  requestedTablePageSelector,
  requestedTableSizeSelector,
  requestedTableSortSelector,
} from '../../../../store/selectors/tableMetaInfo.selectors';
import { usePostAssetLogFilteredQuery } from '../../../../store/api/tags.api';
import { assetLogSelector } from '../../../../store/selectors/tags.selectors';
import { Pagination } from 'antd';
import { TransposedTable } from '../../../../components/tables/TransposedTable';

const Text = styled.p`
  margin-bottom: 0;

  text-align: ${() => (isMobile ? 'right' : 'left')};
`;

const CenteredDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const MESSAGES = defineMessages({
  timestamp: {
    id: 'asset_logs_table.timestamp',
    defaultMessage: 'Timestamp',
  },
  asset: {
    id: 'asset_logs_table.asset',
    defaultMessage: 'Asset',
  },
  tag: {
    id: 'asset_logs_table.tag',
    defaultMessage: 'Tag',
  },
  tagDescription: {
    id: 'asset_logs_table.tagDescription',
    defaultMessage: 'Tag description',
  },
  value: {
    id: 'asset_logs_table.value',
    defaultMessage: 'Value',
  },
});

export const assetLogFilterKeys = [
  AssetLogFilterKey.assetId,
  AssetLogFilterKey.from,
  AssetLogFilterKey.tag,
  AssetLogFilterKey.to,
];

const isMobile = window.innerWidth <= 1024;
export const AssetLogsTable = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const assetLogFilterValues = useSelector(
    assetLogFiltersByKeysSelector(assetLogFilterKeys),
  ) as IAssetLogFilters | undefined;

  const assetLogTablePage = useSelector(
    requestedTablePageSelector(Table.ASSET_LOG_TABLE),
  );
  const assetLogTableSize = useSelector(
    requestedTableSizeSelector(Table.ASSET_LOG_TABLE),
  );
  const assetLogTableSort: string[] | undefined = useSelector(
    requestedTableSortSelector(Table.ASSET_LOG_TABLE),
  );

  const assetLogFilters: IPagedAssetLogFilters = {
    ...assetLogFilterValues,
    params: {
      page: assetLogTablePage,
      sort: assetLogTableSort,
      size: assetLogTableSize,
    },
  };

  usePostAssetLogFilteredQuery(assetLogFilters);

  const pagedAssetLogs = useSelector(assetLogSelector(assetLogFilters));

  const columns: ColumnsType<any> = [
    {
      title: intl.formatMessage(MESSAGES.timestamp),
      dataIndex: 'timestamp',
      key: 'timestamp',
      width: 100,
      render: (timestamp) => {
        return (
          <Text>{moment(timestamp).format('DD/MM/YYYY HH:mm:ss.SSS')}</Text>
        );
      },
      showSorterTooltip: true,
      defaultSortOrder: 'descend',
      sorter: true,
    },
    {
      title: intl.formatMessage(MESSAGES.asset),
      dataIndex: 'asset',
      key: 'asset',
      width: 200,
      render: (asset, record) => {
        return <Text>{asset}</Text>;
      },
    },
    {
      title: intl.formatMessage(MESSAGES.tag),
      dataIndex: 'tag',
      key: 'tag',
      width: 200,
      render: (tag, record) => {
        return <Text>{tag.id}</Text>;
      },
    },
    {
      title: intl.formatMessage(MESSAGES.tagDescription),
      dataIndex: 'tag',
      key: 'tag',
      width: 200,
      render: (tag, record) => {
        return <Text>{tag.description}</Text>;
      },
    },
    {
      title: intl.formatMessage(MESSAGES.value),
      dataIndex: 'value',
      key: 'value',
      width: 100,
      render: (value, record) => {
        if (record.tag.type === TagValueType.BOOL && value == 1) {
          return (
            <CheckCircleOutlined style={{ color: colors.green.primary }} />
          );
        } else if (record.tag.type === TagValueType.BOOL && value == 0) {
          return <CloseCircleOutlined style={{ color: colors.red.primary }} />;
        }

        return <Text>{value}</Text>;
      },
    },
  ];

  const handleChangePage = (requestedPage: number) => {
    dispatch(
      updateRequestedTablePage({
        key: Table.ASSET_LOG_TABLE,
        value: requestedPage - 1,
      }),
    );
  };
  const handleChangeSort = (requestedSort: string[]) => {
    dispatch(
      updateRequestedTableSort({
        key: Table.ASSET_LOG_TABLE,
        value: requestedSort,
      }),
    );
  };

  const handleChangePageSize = (
    requestedPage: number,
    requestedSize: number,
  ) => {
    dispatch(
      updateRequestedTablePageSize({
        key: Table.ASSET_LOG_TABLE,
        page: requestedPage,
        size: requestedSize,
      }),
    );
  };

  const sortOrder = (sortOrder: string) =>
    sortOrder === 'descend' ? 'desc' : 'asc';

  return (
    <>
      {pagedAssetLogs ? (
        isMobile ? (
          <TransposedTable
            instance="assetLogs"
            handleChangeSort={handleChangeSort}
            FEsort={pagedAssetLogs.totalElements <= pagedAssetLogs.size}
            columns={columns}
            data={pagedAssetLogs?.content}
          />
        ) : (
          <StyledTable<IAssetLogs>
            columns={columns}
            dataSource={pagedAssetLogs?.content}
            rowKey={(record) => uuidv4()}
            onChange={(pagination, filters, sorter) => {
              let sortArray: string[] = [];
              if (Array.isArray(sorter)) {
                // Handle the case when multiple sorting is applied (sorter is an array)
                sorter.forEach((sort) => {
                  if (sort.order && sort.column) {
                    sortArray.push(sort.field + ',' + sortOrder(sort.order));
                  }
                });
              } else if (sorter && sorter.order && sorter.column) {
                // Handle the case when single sorting is applied (sorter is an object)
                // console.log('Sorting applied on:', sorter.column, 'with order:', sorter.order);
                sortArray.push(sorter.field + ',' + sortOrder(sorter.order));
              }

              handleChangeSort(sortArray);
            }}
            pagination={false}
          />
        )
      ) : (
        <CenteredDiv>
          <LoadingOutlined />
        </CenteredDiv>
      )}

      {pagedAssetLogs && (
        <Pagination
          current={(pagedAssetLogs?.number || 0) + 1}
          onChange={(requestedPage: number, requestedSize: number) => {
            handleChangePageSize(requestedPage - 1, requestedSize);
          }}
          size={isMobile ? 'small' : 'default'}
          style={{ margin: `${theme.spacing.medium} 0` }}
          defaultPageSize={100}
          pageSize={pagedAssetLogs?.size}
          total={pagedAssetLogs?.totalElements}
          showQuickJumper
          showSizeChanger
        />
      )}
    </>
  );
};
