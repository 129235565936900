import React from 'react';

export const StartButtonIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6">
      <path
        d="M14.9998 1.80078C11.4989 1.80078 8.14148 3.19149 5.666 5.66697C3.19052 8.14245 1.7998 11.4999 1.7998 15.0008C1.7998 18.5016 3.19052 21.8591 5.666 24.3346C8.14148 26.8101 11.4989 28.2008 14.9998 28.2008C18.5007 28.2008 21.8581 26.8101 24.3336 24.3346C26.8091 21.8591 28.1998 18.5016 28.1998 15.0008C28.1998 11.4999 26.8091 8.14245 24.3336 5.66697C21.8581 3.19149 18.5007 1.80078 14.9998 1.80078V1.80078Z"
        stroke="#262626"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.3999 21L21.5999 15L11.3999 9V21Z"
        stroke="#262626"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
);
