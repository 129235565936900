import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { IAssetStatusIconProps } from '../../../../types/box.types';
import { getBoxStatusIconColor } from '../../../../utils/boxes/getBoxStatusIconColor';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const ElectricityBoxIcon = ({
  boxTechStatus,
  size,
  ...space
}: IAssetStatusIconProps) => (
  <StyledBox
    {...space}
    height={getIconSize(size).height}
    data-testid="ElectricityBoxIcon"
  >
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 32 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-electricity"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.94 9.662C16.0006 9.51666 16.1426 9.422 16.3 9.422H19.264C19.3962 9.422 19.5194 9.48895 19.5913 9.59988C19.6632 9.7108 19.674 9.8506 19.62 9.97126L17.2138 15.35H19.42C19.5633 15.35 19.695 15.4286 19.7632 15.5546C19.8313 15.6806 19.8248 15.8339 19.7463 15.9537L13.8183 25.0017C13.7109 25.1657 13.4985 25.2244 13.3221 25.139C13.1457 25.0536 13.0601 24.8506 13.1221 24.6647L15.4469 17.69H13.18C13.0496 17.69 12.9279 17.6248 12.8555 17.5163C12.7832 17.4078 12.7699 17.2704 12.82 17.15L15.94 9.662ZM16.56 10.202L13.765 16.91H15.988C16.1134 16.91 16.2311 16.9703 16.3044 17.072C16.3777 17.1737 16.3977 17.3044 16.358 17.4233L14.8118 22.062L18.6983 16.13H16.612C16.4799 16.13 16.3567 16.063 16.2848 15.9521C16.2129 15.8412 16.2021 15.7014 16.256 15.5807L18.6623 10.202H16.56Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6 2.3999H28.6V6.3999H27.4V29.1999H4.8V6.3999H3.6V2.3999ZM4.6 3.3999V5.3999H27.6V3.3999H4.6ZM5.8 6.9999V28.1999H26.4V6.9999H5.8Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.484 8.62255C26.6372 8.39279 26.9476 8.3307 27.1774 8.48388L30.9721 11.0137L31.1021 12.221C31.2384 13.4858 29.8373 14.3354 28.7787 13.6298L26.9566 12.415C26.7268 12.2618 26.6648 11.9514 26.8179 11.7216C26.9711 11.4919 27.2815 11.4298 27.5113 11.5829L29.3334 12.7977C29.6863 13.0329 30.1533 12.7497 30.1079 12.3281L30.028 11.5861L26.6227 9.31593C26.3929 9.16275 26.3308 8.85232 26.484 8.62255Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.50604 11.5861L5.63768 9.49837L5.08298 8.66632L1.56194 11.0137L1.43184 12.221C1.29554 13.4858 2.69674 14.3354 3.75526 13.6298L5.57739 12.415L5.02269 11.5829L3.20056 12.7977C2.84772 13.0329 2.38065 12.7497 2.42608 12.3281L2.50604 11.5861Z"
        fill={getBoxStatusIconColor(boxTechStatus)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.484 15.8226C26.6372 15.5928 26.9476 15.5307 27.1774 15.6839L30.9721 18.2137L31.1021 19.421C31.2384 20.6858 29.8373 21.5354 28.7787 20.8298L26.9566 19.615C26.7268 19.4618 26.6648 19.1514 26.8179 18.9216C26.9711 18.6919 27.2815 18.6298 27.5113 18.7829L29.3334 19.9977C29.6863 20.2329 30.1533 19.9497 30.1079 19.5281L30.028 18.7861L26.6227 16.5159C26.3929 16.3628 26.3308 16.0523 26.484 15.8226Z"
        fill={boxTechStatus && getBoxStatusIconColor(boxTechStatus)}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.50604 18.7861L5.61825 16.7113L5.06355 15.8793L1.56194 18.2137L1.43184 19.421C1.29554 20.6858 2.69674 21.5354 3.75526 20.8298L5.57739 19.615L5.02269 18.7829L3.20056 19.9977C2.84772 20.2329 2.38065 19.9497 2.42608 19.5281L2.50604 18.7861Z"
        fill={boxTechStatus && getBoxStatusIconColor(boxTechStatus)}
      />
    </svg>
  </StyledBox>
);
