import {
  combineReducers,
  configureStore,
  createAction,
  Reducer,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { baseApi } from './api/base.api';
import { registrationReducer } from './reducers/registration.reducers';
import { userReducer } from './reducers/user.reducers';
import { filtersReducer } from './slices/filters.slice';
import { layoutReducer } from './slices/layout.slice';

import tagsReducer from './slices/tags.slice';
import cancelledSessionsReducer from './slices/session.slice';
import tableMetaInfoReducer from './slices/tableMetaInfo.slice';

const middleware = [baseApi.middleware];

const reducer = combineReducers({
  [baseApi.reducerPath]: baseApi.reducer,
  layout: layoutReducer,
  user: userReducer,
  registration: registrationReducer,
  filters: filtersReducer,
  tableMetaInfo: tableMetaInfoReducer,
  cancelledSessions: cancelledSessionsReducer,
  tags: tagsReducer,
});

export const resetStore = createAction('resetStore');

const rootReducer: Reducer = (state: RootState, action) => {
  if (action.type === resetStore.type) {
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
});

setupListeners(store.dispatch);

// Infer the `RootState` type from the reducer
export type RootState = ReturnType<typeof reducer>;
