import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { theme } from '../../../../theme';

import { TaskStatus, TasksStatusEnum, TaskStatusText } from '../../../../types/maintenance.types';
import { StyledSelect } from '../../../../styled/form/StyledSelect';

const statuses = Array.from(Object.values(TaskStatus)) as TaskStatus[];

const MESSAGES = defineMessages({
  [TaskStatus.New]: {
    id: 'task_select_status.new',
    defaultMessage: 'New',
  },
  [TaskStatus["In progress"]]: {
    id: 'task_select_status.in_progress',
    defaultMessage: 'In progress',
  },
  [TaskStatus.Finished]: {
    id: 'task_select_status.done',
    defaultMessage: 'Done',
  },
  [TaskStatus.Acknowledged]: {
    id: 'task_select_status.ACKNOWLEDGED',
    defaultMessage: 'Acknowledged',
  },
  [TaskStatus["Not started"]]: {
    id: 'task_select_status.NOT_STARTED',
    defaultMessage: 'Not started',
  },

  selectStatus: {
    id: 'maintenance.selectStatus',
    defaultMessage: 'Select status',
  },
});

const { Option } = Select;

interface IProps {
  onSelectStatus: (status: string) => void;
  statusSelected?: number;
}

export const SelectStatus = ({
  onSelectStatus,
  statusSelected,
}: IProps) => {
  const intl = useIntl();
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    if (statusSelected) {
      setSelected(TaskStatusText[statusSelected]);
    }
  }, [statusSelected]);

  const handleSelectStatus = (status: number) => {
    if (status) {
      const statusText = (TasksStatusEnum as any)[status];
      onSelectStatus(statusText);
      setSelected(statusText);
    }
  };
  return (
    <>
      <StyledSelect
        onChange={(value: unknown) => handleSelectStatus(value as number)}
        value={selected}
        showSearch
        placeholder={intl.formatMessage(MESSAGES.selectStatus)}
        mb={theme.spacing.small}
        minWidth="170px"
      >
        {statuses.map((status) => (
          <Option key={status} value={status}>
            {status}
          </Option>
        ))}
      </StyledSelect>
    </>
  );
};
