import * as React from 'react';
const Vessel = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 49 55"
    {...props}
  >
    <path d="M21.432.086v8.068h6.216V.086zm-9.03 10.4a2.93 2.93 0 0 0-2.927 2.927h29.427a2.93 2.93 0 0 0-2.928-2.927zm-2.927 2.927v9.781L23.38 13.67a1.46 1.46 0 0 1 1.654 0l13.868 9.499v-9.755zm16.177 4.229V54.31h13.973l8.639-21.18zm-2.928.026L.151 33.13 8.79 54.31h13.934z" />
  </svg>
);
export default Vessel;
