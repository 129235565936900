import { Tooltip } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../store/selectors/tags.selectors';
import { FlexCentered } from '../../styled/flex/FlexCentered';
import { IconSize } from '../../types/icon.types';
import { BoxTag } from '../../types/tag.types';
import { BoxConnectionCellularIcon } from './icons/connection/BoxConnectionCellularIcon';
import { BoxConnectionEthernetIcon } from './icons/connection/BoxConnectionEthernetIcon';
import { BoxNoConnectionCellularIcon } from './icons/connection/BoxNoConnectionCellularIcon';
import { BoxNoConnectionEthernetIcon } from './icons/connection/BoxNoConnectionEthernetIcon';
import { colors, theme } from '../../theme';

interface IProps {
  boxId: string;
  text?: boolean;
}

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const IconText = styled.span<{ color: string }>`
  margin-left: ${theme.spacing.large};
  color: ${(props) => props.color || colors.black.primary};
`;

const renderIcon = (
  title: string,
  IconComponent: React.FC<any>,
  text = '',
  color = colors.black.primary,
) => (
  <FlexCentered>
    <Tooltip title={title}>
      <IconContainer>
        <IconComponent size={IconSize.LARGE} />
        {text.length > 0 && <IconText color={color}>{text}</IconText>}
      </IconContainer>
    </Tooltip>
  </FlexCentered>
);

export const BoxEthernetIconsList = ({ boxId, text = false }: IProps) => {
  const ethernetTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V9_COMM_TYPE_ETHERNET,
    ),
  );

  const channelStateStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V4_CHANNEL_STATE,
    ),
  );

  const ethernetValue = ethernetTagStatus?.value;
  const channelStateValue = channelStateStatus?.value;

  if (ethernetValue === '0' && channelStateValue === '1') {
    return renderIcon(
      '4G Ok',
      BoxConnectionCellularIcon,
      text ? '4G' : undefined,
    );
  }
  if (ethernetValue === '0') {
    return renderIcon(
      '4G Not Ok',
      BoxNoConnectionCellularIcon,
      text ? '4G' : undefined,
      colors.red.secondary,
    );
  }
  if (ethernetValue === '1' && channelStateValue === '1') {
    return renderIcon(
      'Ethernet Ok',
      BoxConnectionEthernetIcon,
      text ? 'Ethernet' : undefined,
    );
  }
  if (ethernetValue === '1') {
    return renderIcon(
      'Ethernet Not Ok',
      BoxNoConnectionEthernetIcon,
      text ? 'Ethernet' : undefined,
      colors.red.secondary,
    );
  }

  return null;
};
