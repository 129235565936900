import { Rule } from 'rc-field-form/lib/interface';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { StyledFormItem } from '../../styled/form/StyledFormItem';
import { LOGIN_MESSAGES } from '../../utils/login/login.utils';
import { LoginPasswordInput } from './LoginPasswordInput';

const MESSAGES = defineMessages({
  title: {
    id: 'reset_password_set_page.title',
    defaultMessage: 'Choose new password',
  },
  button: {
    id: 'reset_password_set_page.button',
    defaultMessage: 'Confirm new password',
  },
  passwordsNotTheSame: {
    id: 'reset_password_set_page.passwordsDoNotMatch',
    defaultMessage: 'The passwords you have entered do not match!',
  },
  passwordResetFail: {
    id: 'reset_password_set_page.passwordResetFail',
    defaultMessage:
      'Failed to reset password. Please request a new code and try again.',
  },
});

interface IProps {
  password: string;
  passwordConfirm: string;
  onChangePassword: (value: string) => void;
  onChangePasswordConfirm: (value: string) => void;
}

export const LoginPasswordConfirmation = ({
  onChangePassword,
  onChangePasswordConfirm,
  password,
  passwordConfirm,
}: IProps) => {
  const intl = useIntl();

  const getPassWordsAreSameRule = (passw: string): Rule => ({
    validator: (rule, value, callback) => {
      if (value !== passw) {
        callback(intl.formatMessage(MESSAGES.passwordsNotTheSame));
      } else {
        callback();
      }
    },
  });

  return (
    <>
      <StyledFormItem name="password">
        <LoginPasswordInput
          onChange={onChangePassword}
          value={password}
          placeholder={intl.formatMessage(LOGIN_MESSAGES.passwordPlaceholder)}
          data-testid="input-cognito-register-password"
        />
      </StyledFormItem>
      <StyledFormItem
        name="passwordConfirm"
        rules={[getPassWordsAreSameRule(password)]}
      >
        <LoginPasswordInput
          onChange={onChangePasswordConfirm}
          value={passwordConfirm}
          placeholder={intl.formatMessage(
            LOGIN_MESSAGES.passwordConfirmPlaceholder,
          )}
          data-testid="input-cognito-register-password-confirm"
        />
      </StyledFormItem>
    </>
  );
};
