import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { IIconProps } from '../../../../types/icon.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const BoxNoConnectionEthernetIcon = ({ size, ...space }: IIconProps) => (
  <StyledBox {...space} height={getIconSize(size).height}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-no-connection-ethernet"
    >
      <path
        d="M27 24.6C27 25.9254 25.9254 27 24.6 27H5.4C4.0746 27 3 25.9254 3 24.6V5.4C3 4.0746 4.0746 3 5.4 3H24.6C25.9254 3 27 4.0746 27 5.4V24.6Z"
        stroke="#CF6363"
        strokeMiterlimit="10"
      />
      <path
        d="M22.7997 8.40015H7.19971V18.0001H9.59971V19.8001H11.9997V21.6001H17.9997V19.8001H20.3997V18.0001H22.7997V8.40015Z"
        stroke="#CF6363"
        strokeMiterlimit="10"
      />
      <path
        d="M9 10.2001H10.2V14.4001H9V10.2001ZM10.8 10.2001H12V14.4001H10.8V10.2001ZM12.6 10.2001H13.8V14.4001H12.6V10.2001ZM14.4 10.2001H15.6V14.4001H14.4V10.2001ZM16.2 10.2001H17.4V14.4001H16.2V10.2001ZM18 10.2001H19.2V14.4001H18V10.2001ZM19.8 10.2001H21V14.4001H19.8V10.2001Z"
        fill="#CF6363"
      />
      <rect x="16" y="16" width="14" height="14" rx="7" fill="white" />
      <path
        d="M25.52 21.32L23.84 23L25.52 24.68C25.76 24.92 25.76 25.28 25.52 25.52C25.4 25.64 25.22 25.7 25.1 25.7C24.98 25.7 24.8 25.64 24.68 25.52L23 23.84L21.32 25.52C21.2 25.64 21.02 25.7 20.9 25.7C20.78 25.7 20.6 25.64 20.48 25.52C20.24 25.28 20.24 24.92 20.48 24.68L22.16 23L20.48 21.32C20.24 21.08 20.24 20.72 20.48 20.48C20.72 20.24 21.08 20.24 21.32 20.48L23 22.16L24.68 20.48C24.92 20.24 25.28 20.24 25.52 20.48C25.76 20.72 25.76 21.08 25.52 21.32ZM29 23C29 26.3 26.3 29 23 29C19.7 29 17 26.3 17 23C17 19.7 19.7 17 23 17C26.3 17 29 19.7 29 23ZM27.8 23C27.8 20.36 25.64 18.2 23 18.2C20.36 18.2 18.2 20.36 18.2 23C18.2 25.64 20.36 27.8 23 27.8C25.64 27.8 27.8 25.64 27.8 23Z"
        fill="#CF6363"
      />
    </svg>
  </StyledBox>
);
