export const tagStatusOptions = [
  {
      "label": "V4_METER_FUSE",
      "value": "V4_METER_FUSE"
  },
  {
      "label": "V9_SET_PROCESS_CONTROL",
      "value": "V9_SET_PROCESS_CONTROL"
  },
  {
      "label": "V7_SOFTWARE_VERSION",
      "value": "V7_SOFTWARE_VERSION"
  },
  {
      "label": "V7_VOLTAGE_L2_L3",
      "value": "V7_VOLTAGE_L2_L3"
  },
  {
      "label": "V7_SECONDE_DRIVE_ACTIVE",
      "value": "V7_SECONDE_DRIVE_ACTIVE"
  },
  {
      "label": "V9_PRG_MODE",
      "value": "V9_PRG_MODE"
  },
  {
      "label": "V8_OVERRIDE_SAFTEY",
      "value": "V8_OVERRIDE_SAFTEY"
  },
  {
      "label": "V9_SERVICE_DOOR_PRESENT",
      "value": "V9_SERVICE_DOOR_PRESENT"
  },
  {
      "label": "V8_SIMULATE_STARTBUTTON",
      "value": "V8_SIMULATE_STARTBUTTON"
  },
  {
      "label": "V8_FORCE_HEATING",
      "value": "V8_FORCE_HEATING"
  },
  {
      "label": "V9_SET_USER_DOOR_ACTIVE",
      "value": "V9_SET_USER_DOOR_ACTIVE"
  },
  {
      "label": "V9_SET_CPU_MONITORING",
      "value": "V9_SET_CPU_MONITORING"
  },
  {
      "label": "V7_PRIME_DRIVE",
      "value": "V7_PRIME_DRIVE"
  },
  {
      "label": "V9_OPTIE_TEMP_REGULATION",
      "value": "V9_OPTIE_TEMP_REGULATION"
  },
  {
      "label": "V7_CPU_FIRMWARE",
      "value": "V7_CPU_FIRMWARE"
  },
  {
      "label": "V4_MAINSWITCH",
      "value": "V4_MAINSWITCH"
  },
  {
      "label": "V2_SYMBOL_AUTORISATION",
      "value": "V2_SYMBOL_AUTORISATION"
  },
  {
      "label": "V4_PRIME_DRIVE",
      "value": "V4_PRIME_DRIVE"
  },
  {
      "label": "V4_BOX_ALARM",
      "value": "V4_BOX_ALARM"
  },
  {
      "label": "V9_OPTIE_UPS",
      "value": "V9_OPTIE_UPS"
  },
  {
      "label": "V3_SIMULATION_MODE",
      "value": "V3_SIMULATION_MODE"
  },
  {
      "label": "V1_METER_READOUT",
      "value": "V1_METER_READOUT"
  },
  {
      "label": "V2_COOLING_ON",
      "value": "V2_COOLING_ON"
  },
  {
      "label": "V2_SHOW_RESET_BUTTON",
      "value": "V2_SHOW_RESET_BUTTON"
  },
  {
      "label": "V9_SET_AUT_METER",
      "value": "V9_SET_AUT_METER"
  },
  {
      "label": "V7_TRIPS",
      "value": "V7_TRIPS"
  },
  {
      "label": "EXP_POS_1",
      "value": "EXP_POS_1"
  },
  {
      "label": "EXP_POS_2",
      "value": "EXP_POS_2"
  },
  {
      "label": "EXP_POS_3",
      "value": "EXP_POS_3"
  },
  {
      "label": "V7_VOLTAGE_L3_L1",
      "value": "V7_VOLTAGE_L3_L1"
  },
  {
      "label": "EXP_POS_4",
      "value": "EXP_POS_4"
  },
  {
      "label": "EXP_POS_0",
      "value": "EXP_POS_0"
  },
  {
      "label": "V4_SECOND_DRIVE",
      "value": "V4_SECOND_DRIVE"
  },
  {
      "label": "V3_FUSE_EXT24VDC",
      "value": "V3_FUSE_EXT24VDC"
  },
  {
      "label": "V7_BOX_TOT_LITERS",
      "value": "V7_BOX_TOT_LITERS"
  },
  {
      "label": "V9_PILOT_BLOCKING",
      "value": "V9_PILOT_BLOCKING"
  },
  {
      "label": "V7_CURRENT_L3",
      "value": "V7_CURRENT_L3"
  },
  {
      "label": "V7_CURRENT_L2",
      "value": "V7_CURRENT_L2"
  },
  {
      "label": "V7_CURRENT_L1",
      "value": "V7_CURRENT_L1"
  },
  {
      "label": "V2_SYMBOL_BOX",
      "value": "V2_SYMBOL_BOX"
  },
  {
      "label": "V9_MAX_TAKE_OFF",
      "value": "V9_MAX_TAKE_OFF"
  },
  {
      "label": "V3_BOX_TEMPERATURE",
      "value": "V3_BOX_TEMPERATURE"
  },
  {
      "label": "V4_METER",
      "value": "V4_METER"
  },
  {
      "label": "V2_TECH_STATUS",
      "value": "V2_TECH_STATUS"
  },
  {
      "label": "V4_BOX_FAIL",
      "value": "V4_BOX_FAIL"
  },
  {
      "label": "V2_SHOW_REFRESH_BUTTON",
      "value": "V2_SHOW_REFRESH_BUTTON"
  },
  {
      "label": "V4_CABLE_PULL",
      "value": "V4_CABLE_PULL"
  },
  {
      "label": "V9_LANGUAGE",
      "value": "V9_LANGUAGE"
  },
  {
      "label": "V7_POINT_CONSUMING",
      "value": "V7_POINT_CONSUMING"
  },
  {
      "label": "V9_MAXIMUM_TRIPS",
      "value": "V9_MAXIMUM_TRIPS"
  },
  {
      "label": "V9_COMM_TYPE_ETHERNET",
      "value": "V9_COMM_TYPE_ETHERNET"
  },
  {
      "label": "V2_ETHERNET_PRESENT",
      "value": "V2_ETHERNET_PRESENT"
  },
  {
      "label": "V9_DELAY_READ_OUT",
      "value": "V9_DELAY_READ_OUT"
  },
  {
      "label": "V9_SETPOINT_HEATING",
      "value": "V9_SETPOINT_HEATING"
  },
  {
      "label": "V9_SET_TAMPERING_ACTIVE",
      "value": "V9_SET_TAMPERING_ACTIVE"
  },
  {
      "label": "V1_METER_STOP",
      "value": "V1_METER_STOP"
  },
  {
      "label": "V9_CHANGE_DOOR_IO",
      "value": "V9_CHANGE_DOOR_IO"
  },
  {
      "label": "V1_CONS_SESSION",
      "value": "V1_CONS_SESSION"
  },
  {
      "label": "V3_MAINTENANCE",
      "value": "V3_MAINTENANCE"
  },
  {
      "label": "V7_TRIPPED",
      "value": "V7_TRIPPED"
  },
  {
      "label": "V4_VOLTAGE_ON_CABLE",
      "value": "V4_VOLTAGE_ON_CABLE"
  },
  {
      "label": "V1_WEB_STATUS",
      "value": "V1_WEB_STATUS"
  },
  {
      "label": "V7_VOLTAGE_L1_L2",
      "value": "V7_VOLTAGE_L1_L2"
  },
  {
      "label": "V7_MAX_POWER",
      "value": "V7_MAX_POWER"
  },
  {
      "label": "V1_CONS_ACTUAL",
      "value": "V1_CONS_ACTUAL"
  },
  {
      "label": "V9_SCANTIME_REFRESH",
      "value": "V9_SCANTIME_REFRESH"
  },
  {
      "label": "V9_FEEDBACK_SECOND_OFF",
      "value": "V9_FEEDBACK_SECOND_OFF"
  },
  {
      "label": "V9_ACTIVE_PUSHBUTTONS",
      "value": "V9_ACTIVE_PUSHBUTTONS"
  },
  {
      "label": "V2_SYMBOL_START",
      "value": "V2_SYMBOL_START"
  },
  {
      "label": "V9_CONFIG_DRIVE",
      "value": "V9_CONFIG_DRIVE"
  },
  {
      "label": "V8_SIMULATE_STOPBUTTON",
      "value": "V8_SIMULATE_STOPBUTTON"
  },
  {
      "label": "V4_LICHTNING_PROTECTION",
      "value": "V4_LICHTNING_PROTECTION"
  },
  {
      "label": "V8_SET_SERVICE_MODE",
      "value": "V8_SET_SERVICE_MODE"
  },
  {
      "label": "V1_WEB_STATUS_OLD",
      "value": "V1_WEB_STATUS_OLD"
  },
  {
      "label": "V7_CPU_VOLTAGE",
      "value": "V7_CPU_VOLTAGE"
  },
  {
      "label": "V2_AUTORISATION_CONTROL",
      "value": "V2_AUTORISATION_CONTROL"
  },
  {
      "label": "V9_SET_VOLTAGE_CABLE_ACTIVE",
      "value": "V9_SET_VOLTAGE_CABLE_ACTIVE"
  },
  {
      "label": "V6_VESSEL_BENDER",
      "value": "V6_VESSEL_BENDER"
  },
  {
      "label": "V7_ACTIVE_W",
      "value": "V7_ACTIVE_W"
  },
  {
      "label": "V2_TECH_BOX_STATUS",
      "value": "V2_TECH_BOX_STATUS"
  },
  {
      "label": "V7_SWITCHING_RCA",
      "value": "V7_SWITCHING_RCA"
  },
  {
      "label": "V7_BOX_POINTS_FAULT",
      "value": "V7_BOX_POINTS_FAULT"
  },
  {
      "label": "V3_TAMPERING",
      "value": "V3_TAMPERING"
  },
  {
      "label": "V8_SET_BOX_OUT_OF_SERVICE",
      "value": "V8_SET_BOX_OUT_OF_SERVICE"
  },
  {
      "label": "V4_BENDER",
      "value": "V4_BENDER"
  },
  {
      "label": "V7_PILOT_CONTACT_IN",
      "value": "V7_PILOT_CONTACT_IN"
  },
  {
      "label": "V3_CPU_TEMPERATURE",
      "value": "V3_CPU_TEMPERATURE"
  },
  {
      "label": "V7_FB_STOPBUTTON",
      "value": "V7_FB_STOPBUTTON"
  },
  {
      "label": "V7_TAMPERING_VALUE",
      "value": "V7_TAMPERING_VALUE"
  },
  {
      "label": "V3_EXPANTION_MODULE",
      "value": "V3_EXPANTION_MODULE"
  },
  {
      "label": "V9_SET_MONO_FASE",
      "value": "V9_SET_MONO_FASE"
  },
  {
      "label": "V2_POINT_ACTIVE",
      "value": "V2_POINT_ACTIVE"
  },
  {
      "label": "V6_VESSEL_SAFETY_LOOP",
      "value": "V6_VESSEL_SAFETY_LOOP"
  },
  {
      "label": "V3_RTU_24_VDC",
      "value": "V3_RTU_24_VDC"
  },
  {
      "label": "V7_RESET_TIME",
      "value": "V7_RESET_TIME"
  },
  {
      "label": "V3_FUSE_AUXILLIARIES",
      "value": "V3_FUSE_AUXILLIARIES"
  },
  {
      "label": "V9_DELAY_FAULT_CHECK",
      "value": "V9_DELAY_FAULT_CHECK"
  },
  {
      "label": "V4_VOLTAGE",
      "value": "V4_VOLTAGE"
  },
  {
      "label": "V4_WEB_REMOVAL_FAIL",
      "value": "V4_WEB_REMOVAL_FAIL"
  },
  {
      "label": "V2_SYMBOL_PILOT",
      "value": "V2_SYMBOL_PILOT"
  },
  {
      "label": "V8_MAIN_RESET_BOX",
      "value": "V8_MAIN_RESET_BOX"
  },
  {
      "label": "V2_EXECUTE_QUERY",
      "value": "V2_EXECUTE_QUERY"
  },
  {
      "label": "V7_BOX_TOT_PULSEN",
      "value": "V7_BOX_TOT_PULSEN"
  },
  {
      "label": "V4_EXPANTION_MODULE",
      "value": "V4_EXPANTION_MODULE"
  },
  {
      "label": "V9_CONFIG_LICENSE",
      "value": "V9_CONFIG_LICENSE"
  },
  {
      "label": "V3_HYGROSSTAAT",
      "value": "V3_HYGROSSTAAT"
  },
  {
      "label": "V9_OVERVOLTAGE_PRESENT",
      "value": "V9_OVERVOLTAGE_PRESENT"
  },
  {
      "label": "V7_PRIME_DRIVE_ACTIVE",
      "value": "V7_PRIME_DRIVE_ACTIVE"
  },
  {
      "label": "V8_AUTORISATION_WEB",
      "value": "V8_AUTORISATION_WEB"
  },
  {
      "label": "V8_REFRESH_POINT",
      "value": "V8_REFRESH_POINT"
  },
  {
      "label": "V7_CPU_TYPE",
      "value": "V7_CPU_TYPE"
  },
  {
      "label": "V9_CONFIG_METER",
      "value": "V9_CONFIG_METER"
  },
  {
      "label": "V9_SET_POINT_ACTIVE",
      "value": "V9_SET_POINT_ACTIVE"
  },
  {
      "label": "V6_PILOOTCONTACT",
      "value": "V6_PILOOTCONTACT"
  },
  {
      "label": "V4_POINT_ALARM",
      "value": "V4_POINT_ALARM"
  },
  {
      "label": "V9_BOX_ALARMS_ACTIVE",
      "value": "V9_BOX_ALARMS_ACTIVE"
  },
  {
      "label": "V9_MAX_CURRENT_POINT",
      "value": "V9_MAX_CURRENT_POINT"
  },
  {
      "label": "V7_CPU_TEMPERATURE",
      "value": "V7_CPU_TEMPERATURE"
  },
  {
      "label": "V8_TRIP_COMMAND",
      "value": "V8_TRIP_COMMAND"
  },
  {
      "label": "V8_STOP_WEB_AUTORISATION",
      "value": "V8_STOP_WEB_AUTORISATION"
  },
  {
      "label": "V2_SESSION_ACTIVE",
      "value": "V2_SESSION_ACTIVE"
  },
  {
      "label": "V7_BOX_ACT_W",
      "value": "V7_BOX_ACT_W"
  },
  {
      "label": "V9_SET_EXTERNAL_DIFF",
      "value": "V9_SET_EXTERNAL_DIFF"
  },
  {
      "label": "V8_SET_FORCED_CONTROL_OFF",
      "value": "V8_SET_FORCED_CONTROL_OFF"
  },
  {
      "label": "V3_BOX_WARNING",
      "value": "V3_BOX_WARNING"
  },
  {
      "label": "V2_UNIT",
      "value": "V2_UNIT"
  },
  {
      "label": "V9_SET_BENDER_ACTIVE",
      "value": "V9_SET_BENDER_ACTIVE"
  },
  {
      "label": "V1_STOP_BIT",
      "value": "V1_STOP_BIT"
  },
  {
      "label": "V2_SERVICE_MODE_ACTIVE",
      "value": "V2_SERVICE_MODE_ACTIVE"
  },
  {
      "label": "V7_SWITCHING_RELAY",
      "value": "V7_SWITCHING_RELAY"
  },
  {
      "label": "V9_PULS_DIVIDER",
      "value": "V9_PULS_DIVIDER"
  },
  {
      "label": "V6_AUTHORISATION",
      "value": "V6_AUTHORISATION"
  },
  {
      "label": "V9_AUTO_RESET_TIME",
      "value": "V9_AUTO_RESET_TIME"
  },
  {
      "label": "V2_STARTED_BY",
      "value": "V2_STARTED_BY"
  },
  {
      "label": "V4_CHANNEL_STATE",
      "value": "V4_CHANNEL_STATE"
  },
  {
      "label": "V8_RESET_POINT",
      "value": "V8_RESET_POINT"
  },
  {
      "label": "V2_SYMBOL_CONTROL",
      "value": "V2_SYMBOL_CONTROL"
  },
  {
      "label": "V2_MIMIC_05",
      "value": "V2_MIMIC_05"
  },
  {
      "label": "V2_MIMIC_06",
      "value": "V2_MIMIC_06"
  },
  {
      "label": "V6_VOLTAGE_ON_CABLE",
      "value": "V6_VOLTAGE_ON_CABLE"
  },
  {
      "label": "V2_MIMIC_01",
      "value": "V2_MIMIC_01"
  },
  {
      "label": "V6_START_CONDITION",
      "value": "V6_START_CONDITION"
  },
  {
      "label": "V2_MIMIC_02",
      "value": "V2_MIMIC_02"
  },
  {
      "label": "V2_MIMIC_03",
      "value": "V2_MIMIC_03"
  },
  {
      "label": "V3_VESSEL_DEFINITIEVE",
      "value": "V3_VESSEL_DEFINITIEVE"
  },
  {
      "label": "V2_MIMIC_04",
      "value": "V2_MIMIC_04"
  },
  {
      "label": "V2_HYGROSSTAAT_PRESENT",
      "value": "V2_HYGROSSTAAT_PRESENT"
  },
  {
      "label": "V2_HEATING_ON",
      "value": "V2_HEATING_ON"
  },
  {
      "label": "V4_INCOMING_FASES",
      "value": "V4_INCOMING_FASES"
  },
  {
      "label": "V8_SIMULATE_PILOTCONTACT",
      "value": "V8_SIMULATE_PILOTCONTACT"
  },
  {
      "label": "V2_TECH_ACTION",
      "value": "V2_TECH_ACTION"
  },
  {
      "label": "V2_POINT_BLOCKED",
      "value": "V2_POINT_BLOCKED"
  },
  {
      "label": "V7_BOX_POINTS_ACTIVE",
      "value": "V7_BOX_POINTS_ACTIVE"
  },
  {
      "label": "V9_SET_SESSION_CONTROL",
      "value": "V9_SET_SESSION_CONTROL"
  },
  {
      "label": "V1_METER_START",
      "value": "V1_METER_START"
  },
  {
      "label": "V7_BENDER_DETECTED",
      "value": "V7_BENDER_DETECTED"
  },
  {
      "label": "V9_SET_SAFETY_LOOP_ACTIVE",
      "value": "V9_SET_SAFETY_LOOP_ACTIVE"
  },
  {
      "label": "V7_TEMPERATURE",
      "value": "V7_TEMPERATURE"
  },
  {
      "label": "V7_FB_STARTBUTTON",
      "value": "V7_FB_STARTBUTTON"
  },
  {
      "label": "V9_EXT_FOXBORO",
      "value": "V9_EXT_FOXBORO"
  },
  {
      "label": "V7_BOX_POINTS",
      "value": "V7_BOX_POINTS"
  },
  {
      "label": "V7_BOX_MAX_W",
      "value": "V7_BOX_MAX_W"
  },
  {
      "label": "V8_TEST_MODE",
      "value": "V8_TEST_MODE"
  },
  {
      "label": "V2_SYMBOL_ALARM",
      "value": "V2_SYMBOL_ALARM"
  },
  {
      "label": "V3_VESSEL_DIFF_TRIP",
      "value": "V3_VESSEL_DIFF_TRIP"
  },
  {
      "label": "V9_START_B_FUNCTION",
      "value": "V9_START_B_FUNCTION"
  },
  {
      "label": "V9_DELAY_SECOND_PRIM",
      "value": "V9_DELAY_SECOND_PRIM"
  },
  {
      "label": "V4_CPU",
      "value": "V4_CPU"
  },
  {
      "label": "V3_SERVICE_DOOR",
      "value": "V3_SERVICE_DOOR"
  },
  {
      "label": "V2_PRG_STEP",
      "value": "V2_PRG_STEP"
  },
  {
      "label": "V2_MAGNET_ON",
      "value": "V2_MAGNET_ON"
  },
  {
      "label": "V9_ONE_FAULT_LED",
      "value": "V9_ONE_FAULT_LED"
  },
  {
      "label": "V8_SET_MAINTENANCE",
      "value": "V8_SET_MAINTENANCE"
  },
  {
      "label": "V9_SET_EXT_24VDC_FUSE",
      "value": "V9_SET_EXT_24VDC_FUSE"
  },
  {
      "label": "V3_POINT_WARNING",
      "value": "V3_POINT_WARNING"
  },
  {
      "label": "V7_BOX_TOT_WH",
      "value": "V7_BOX_TOT_WH"
  },
  {
      "label": "V7_HUMIDITY",
      "value": "V7_HUMIDITY"
  },
  {
      "label": "V9_IO_CONFIG",
      "value": "V9_IO_CONFIG"
  },
  {
      "label": "V8_REFRESH_BOX",
      "value": "V8_REFRESH_BOX"
  },
  {
      "label": "V9_SETPOINT_COOLING",
      "value": "V9_SETPOINT_COOLING"
  },
  {
      "label": "V9_BOX_MAX_POWER",
      "value": "V9_BOX_MAX_POWER"
  },
  {
      "label": "V9_TIME_PILOT_OUT",
      "value": "V9_TIME_PILOT_OUT"
  },
  {
      "label": "V8_AUTORISATION_FORCED",
      "value": "V8_AUTORISATION_FORCED"
  },
  {
      "label": "V3_UPS",
      "value": "V3_UPS"
  },
  {
      "label": "V8_SET_AMBIENT_LIGHT",
      "value": "V8_SET_AMBIENT_LIGHT"
  },
  {
      "label": "V7_RESERVED_CURRENT",
      "value": "V7_RESERVED_CURRENT"
  },
  {
      "label": "V9_SET_START_PILOTCONTACT",
      "value": "V9_SET_START_PILOTCONTACT"
  },
  {
      "label": "V3_VESSEL_OVERLOAD_TRIP",
      "value": "V3_VESSEL_OVERLOAD_TRIP"
  },
  {
      "label": "V8_DEACTIVATE_MAGNEET_CC1",
      "value": "V8_DEACTIVATE_MAGNEET_CC1"
  },
  {
      "label": "V7_SWITCHING_NSX",
      "value": "V7_SWITCHING_NSX"
  },
  {
      "label": "V9_MAX_CURRENT_BOX",
      "value": "V9_MAX_CURRENT_BOX"
  },
  {
      "label": "V4_SAFETY_LOOP",
      "value": "V4_SAFETY_LOOP"
  },
  {
      "label": "V7_SECOND_IN",
      "value": "V7_SECOND_IN"
  },
  {
      "label": "V4_FUSE_AUTOMATION",
      "value": "V4_FUSE_AUTOMATION"
  },
  {
      "label": "V6_POINT_BLOCKED",
      "value": "V6_POINT_BLOCKED"
  },
  {
      "label": "V7_SECOND_DRIVE",
      "value": "V7_SECOND_DRIVE"
  },
  {
      "label": "V2_STARTED",
      "value": "V2_STARTED"
  },
  {
      "label": "V7_PRIME_IN",
      "value": "V7_PRIME_IN"
  },
  {
      "label": "V3_TEST_MODE",
      "value": "V3_TEST_MODE"
  },
  {
      "label": "V4_DIFF_RESET",
      "value": "V4_DIFF_RESET"
  },
  {
      "label": "V9_FEEDBACK_PRIME_OFF",
      "value": "V9_FEEDBACK_PRIME_OFF"
  },
  {
      "label": "V7_POWER_FACTOR",
      "value": "V7_POWER_FACTOR"
  },
  {
      "label": "V9_PORT_MODBUS",
      "value": "V9_PORT_MODBUS"
  },
  {
      "label": "V9_BOX_ID",
      "value": "V9_BOX_ID"
  },
  {
      "label": "V6_USER_DOOR",
      "value": "V6_USER_DOOR"
  }
];
