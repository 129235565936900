import React from 'react';
import { useSelector } from 'react-redux';
import { BoxTag } from '../../../types/tag.types';
import { getBoxStatusTextColor } from '../../../utils/boxes/getBoxStatusTextColor';

import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../../store/selectors/tags.selectors';
import { getBoxStatusForSymbolAlarmStatus } from '../../../utils/boxes/getBoxStatusForSymbolAlarmStatus';
import { getBoxConnectivityStatus } from '../../../utils/boxes/getBoxConnectivityStatus';

interface IProps {
  boxId: string;
  boxLabel: string;
}

export const BoxesTableTitle = ({ boxId, boxLabel }: IProps) => {
  const boxIsOffline = getBoxConnectivityStatus(boxId);
  const alarmTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V2_SYMBOL_ALARM,
    ),
  );

  const boxStatus = getBoxStatusForSymbolAlarmStatus(alarmTagStatus);

  return (
    <span
      style={{
        color: getBoxStatusTextColor(boxStatus),
        opacity: boxIsOffline ? '.3' : 1,
      }}
    >
      {boxLabel}
    </span>
  );
};
