import { StopOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AppLayout } from '../../../AppLayout';
import { FormHeadingWithBackArrow } from '../../../components/headings/FormHeadingWithBackArrow';
import { appUrls } from '../../../config/url.constants';
import {
  useDeleteUserMutation,
  useGetUsersQuery,
  usePatchUserMutation,
  usePostCreateUserForCompanyMutation,
} from '../../../store/api/users.api';
import {
  currentUserSelector,
  hasCurrentUserPermissionSelector,
  userByIdSelector,
} from '../../../store/selectors/user.selectors';
import { StyledBox } from '../../../styled/boxes/StyledBox';
import { StyledButton } from '../../../styled/buttons/StyledButton';
import { FlexCol } from '../../../styled/flex/FlexCol';
import { FlexRow } from '../../../styled/flex/FlexRow';
import { StyledCheckbox } from '../../../styled/form/StyledCheckbox';
import { StyledForm } from '../../../styled/form/StyledForm';
import { StyledFormItem } from '../../../styled/form/StyledFormItem';
import { StyledInput } from '../../../styled/form/StyledInput';
import { StyledInputGroupHeading } from '../../../styled/form/StyledInputGroupHeading';
import { theme } from '../../../theme';
import {
  IUserForCompany,
  IUserForCompanyDto,
  IUserForCompanyUpdateDto,
  UserFormValues,
  UserPermission,
  UserPersonalInfo,
  UserRole,
} from '../../../types/user.types';
import { getEmailValidationRule } from '../../../utils/form/getEmailValidationRule';
import { getPhoneNumberRule } from '../../../utils/form/getPhoneNumberRule';
import { getRequiredRule } from '../../../utils/form/getRequiredRule';
import { checkIfUserHasPermission } from '../../../utils/users/checkIfUserHasPermission';
import { getDifferenceInCurrentAndUserToBeEditedPermissions } from '../../../utils/users/getDifferenceInCurrentAndUserToBeEditedPermissions';

const MESSAGES = defineMessages({
  cancel: {
    id: 'user_form.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'user_form.confirm',
    defaultMessage: 'Confirm',
  },
  deleteUser: {
    id: 'user_form.deleteUser',
    defaultMessage: 'Delete user',
  },
  [UserFormValues.EMAIL]: {
    id: 'user_form.email',
    defaultMessage: 'Email',
  },
  [UserFormValues.FIRST_NAME]: {
    id: 'user_form.firstName',
    defaultMessage: 'First name',
  },
  info: {
    id: 'user_form.info',
    defaultMessage: 'Info',
  },
  [UserFormValues.LAST_NAME]: {
    id: 'user_form.lastName',
    defaultMessage: 'Last name',
  },
  newUser: {
    id: 'user_form.newUser',
    defaultMessage: 'New user',
  },
  userAddedSuccess: {
    id: 'user_form.userAddedSuccess',
    defaultMessage: 'User added successfully!',
  },
  userAddedFailure: {
    id: 'user_form.userAddedFailure',
    defaultMessage: 'Failed to add user!',
  },
  userEditSuccess: {
    id: 'user_form.userEditedSuccess',
    defaultMessage: 'User successfully updated!',
  },
  userEditFailure: {
    id: 'user_form.userEditedFailure',
    defaultMessage: 'Failed to update user!',
  },
  [UserFormValues.CREATE_SESSION]: {
    id: 'user_form.createSession',
    defaultMessage: 'Create session',
  },
  [UserFormValues.MANAGE_ASSETS]: {
    id: 'user_form.manageAssets',
    defaultMessage: 'Manage assets',
  },
  [UserFormValues.CREATE_TASK]: {
    id: 'user_form.createTask',
    defaultMessage: 'Create task',
  },
  [UserFormValues.MANAGE_COMPANY_SESSIONS]: {
    id: 'user_form.manageCompanySessions',
    defaultMessage: 'Manage company sessions',
  },
  [UserFormValues.MANAGE_USERS]: {
    id: 'user_form.manageUsers',
    defaultMessage: 'Manage users',
  },
  [UserFormValues.MANAGE_VESSELS]: {
    id: 'user_form.manageVessels',
    defaultMessage: 'Manage vessels',
  },
  [UserFormValues.PHONE_NUMBER]: {
    id: 'user_form.phoneNumber',
    defaultMessage: 'Phone number',
  },
  [UserFormValues.PLAQ_API]: {
    id: 'user_form.plaqApi',
    defaultMessage: 'Plaq API',
  },
  [UserFormValues.TAG_VALUES_API]: {
    id: 'user_form.tagValuesApi',
    defaultMessage: 'Manage tag values API',
  },
  [UserFormValues.USE_DEV_PORTAL]: {
    id: 'user_form.useDevPortal',
    defaultMessage: 'Use development portal',
  },
  permissions: {
    id: 'user_form.permissions',
    defaultMessage: 'Permissions',
  },
});

export const UserForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [form] = useForm();
  const params = useParams();
  const currentUser = useSelector(currentUserSelector);

  useGetUsersQuery();
  const userToBeEdited = useSelector(userByIdSelector(params.id));
  const [postCreateUser, { isSuccess: postSuccess }] =
    usePostCreateUserForCompanyMutation();
  const [patchUser, { isSuccess: patchSuccess }] = usePatchUserMutation();
  const [deleteUser, { isSuccess: deleteSuccess }] = useDeleteUserMutation();

  useEffect(() => {
    if (userToBeEdited) {
      form.setFieldsValue({
        [UserFormValues.FIRST_NAME]: userToBeEdited.firstName,
        [UserFormValues.LAST_NAME]: userToBeEdited.lastName,
        [UserFormValues.EMAIL]: userToBeEdited.email,
        [UserFormValues.PHONE_NUMBER]: userToBeEdited.phoneNumber,
        [UserFormValues.CREATE_SESSION]: checkIfUserHasPermission(
          userToBeEdited,
          UserFormValues.CREATE_SESSION,
        ),
        [UserFormValues.MANAGE_ASSETS]: checkIfUserHasPermission(
          userToBeEdited,
          UserFormValues.MANAGE_ASSETS,
        ),
        [UserFormValues.MANAGE_COMPANY_SESSIONS]: checkIfUserHasPermission(
          userToBeEdited,
          UserFormValues.MANAGE_COMPANY_SESSIONS,
        ),
        [UserFormValues.MANAGE_VESSELS]: checkIfUserHasPermission(
          userToBeEdited,
          UserFormValues.MANAGE_VESSELS,
        ),
        [UserFormValues.CREATE_TASK]: checkIfUserHasPermission(
          userToBeEdited,
          UserFormValues.CREATE_TASK,
        ),
        [UserFormValues.MANAGE_USERS]: checkIfUserHasPermission(
          userToBeEdited,
          UserFormValues.MANAGE_USERS,
        ),
        [UserFormValues.PLAQ_API]: checkIfUserHasPermission(
          userToBeEdited,
          UserFormValues.PLAQ_API,
        ),
        [UserFormValues.TAG_VALUES_API]: checkIfUserHasPermission(
          userToBeEdited,
          UserFormValues.TAG_VALUES_API,
        ),
        [UserFormValues.USE_DEV_PORTAL]: checkIfUserHasPermission(
          userToBeEdited,
          UserFormValues.USE_DEV_PORTAL,
        ),
      });
    }
  }, [userToBeEdited]);

  const canManageUsers = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_USERS),
  );

  useEffect(() => {
    if (postSuccess || patchSuccess || deleteSuccess) {
      navigate(appUrls.users.base);
    }
  }, [postSuccess, patchSuccess, deleteSuccess]);

  const handleFormSubmit = () => {
    form.validateFields().then((values) => {
      const nonPermissionKeys = Object.values(UserPersonalInfo) as string[];
      const permissionValues = Object.entries(values)
        .filter(([key, value]) => {
          return nonPermissionKeys.indexOf(key) < 0 && !!value;
        })
        .map(([key]) => key as UserPermission);

      const payload: IUserForCompany = {
        phoneNumber: values[UserFormValues.PHONE_NUMBER],
        firstName: values[UserFormValues.FIRST_NAME],
        lastName: values[UserFormValues.LAST_NAME],
        permissions: permissionValues,
      };
      if (!userToBeEdited) {
        const createPayload: IUserForCompanyDto = {
          ...payload,
          email: values[UserFormValues.EMAIL],
        };
        postCreateUser(createPayload);
      } else {
        const updatePayload: IUserForCompanyUpdateDto = {
          id: params.id as string,
          payload,
        };
        patchUser(updatePayload);
      }
    });
  };

  const handleCancel = () => navigate(appUrls.users.base);

  const renderPermissions = () => {
    const currentUserPermissions =
      currentUser && Object.values(currentUser.permissions);

    // if there is no user to be edited, then it involves creating a new user
    // no additional checks are needed in this case

    if (!userToBeEdited) {
      return currentUserPermissions?.map((permission) => (
        <Form.Item name={permission} key={permission} valuePropName="checked">
          <StyledCheckbox
            data-testid={`checkbox-user-permission-${permission}`}
          >
            {intl.formatMessage(MESSAGES[permission])}
          </StyledCheckbox>
        </Form.Item>
      ));
    }

    // in case the current user is editing an existing user, there are two cases:
    // 1. the current user is editing a user with the role of company owner
    // in that case the permissions are fixed and cannot be changed (disabled)
    // 2. the current user is editing a user with a role different from company owner
    // in that case we show a combination of a current user's permissions and the currently edited user
    // the current user can not edit permissions that he/she does not have
    // these are shown as disabled checkboxes, other permissions can be freely checked or unchecked

    const userToBeEditedIsCompanyOwner =
      userToBeEdited.role === UserRole.COMPANY_OWNER;

    const userToBeEditedPermissions = Object.values(userToBeEdited.permissions);

    const nonUniqueCombinedPermissions = currentUserPermissions?.concat(
      userToBeEditedPermissions,
    );

    const permissions = nonUniqueCombinedPermissions?.filter(
      (item, index) => nonUniqueCombinedPermissions.indexOf(item) === index,
    );

    const differentPermissions =
      getDifferenceInCurrentAndUserToBeEditedPermissions(
        userToBeEditedPermissions,
        currentUserPermissions,
      );

    return permissions?.map((permission) => (
      <Form.Item
        rules={[
          getRequiredRule(intl.formatMessage(MESSAGES.permissions), intl),
        ]}
        name={permission}
        key={permission}
        valuePropName="checked"
      >
        <StyledCheckbox
          disabled={
            differentPermissions.includes(permission) ||
            userToBeEditedIsCompanyOwner
          }
        >
          {intl.formatMessage(MESSAGES[permission])}
        </StyledCheckbox>
      </Form.Item>
    ));
  };

  const userCanBeDeleted = !!(
    currentUser &&
    userToBeEdited &&
    currentUser.id !== userToBeEdited.id &&
    userToBeEdited.role !== UserRole.COMPANY_OWNER
  );

  return (
    <StyledBox mt={theme.spacing.large}>
      <FlexCol width="100%" flexDirection="column" alignItems="center">
        <FlexRow
          width="100%"
          maxWidth={theme.maxWidth}
          alignItems="center"
          justifyContent="space-between"
        >
          <FormHeadingWithBackArrow
            heading={
              !userToBeEdited
                ? intl.formatMessage(MESSAGES.newUser)
                : `${userToBeEdited.lastName} ${userToBeEdited.firstName}`
            }
          />
          {userCanBeDeleted && (
            <StyledButton
              variant="danger"
              onClick={() => deleteUser({ userIds: [userToBeEdited.id] })}
              data-testid="btn-delete-user"
            >
              <StopOutlined />
              {intl.formatMessage(MESSAGES.deleteUser)}
            </StyledButton>
          )}
        </FlexRow>
        <StyledForm
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
          autoComplete="off"
          width="100%"
          maxWidth={theme.maxWidth}
          data-testid="form-user"
        >
          <StyledInputGroupHeading mb={theme.spacing.xLarge}>
            {intl.formatMessage(MESSAGES.info)}
          </StyledInputGroupHeading>
          <Form.Item
            name={UserFormValues.FIRST_NAME}
            validateTrigger="onBlur"
            rules={[
              getRequiredRule(
                intl.formatMessage(MESSAGES[UserFormValues.FIRST_NAME]),
                intl,
              ),
            ]}
            label={intl.formatMessage(MESSAGES[UserFormValues.FIRST_NAME])}
          >
            <Input data-testid="input-user-first-name" maxLength={255} />
          </Form.Item>
          <Form.Item
            name={UserFormValues.LAST_NAME}
            validateTrigger="onBlur"
            rules={[
              getRequiredRule(
                intl.formatMessage(MESSAGES[UserFormValues.LAST_NAME]),
                intl,
              ),
            ]}
            label={intl.formatMessage(MESSAGES[UserFormValues.LAST_NAME])}
          >
            <Input data-testid="input-user-last-name" maxLength={255} />
          </Form.Item>
          <Form.Item
            name={UserFormValues.EMAIL}
            validateTrigger="onBlur"
            rules={[
              getEmailValidationRule(
                intl.formatMessage(MESSAGES[UserFormValues.EMAIL]),
                intl,
              ),
              getRequiredRule(intl.formatMessage(MESSAGES.email), intl),
            ]}
            label={intl.formatMessage(MESSAGES[UserFormValues.EMAIL])}
          >
            <Input
              disabled={!!userToBeEdited}
              data-testid="input-user-email"
              maxLength={255}
            />
          </Form.Item>
          <Form.Item>
            <StyledFormItem
              name={UserFormValues.PHONE_NUMBER}
              validateTrigger="onBlur"
              rules={[
                getRequiredRule(
                  intl.formatMessage(MESSAGES[UserFormValues.PHONE_NUMBER]),
                  intl,
                ),
                getPhoneNumberRule(
                  intl.formatMessage(MESSAGES[UserFormValues.PHONE_NUMBER]),
                  intl,
                ),
              ]}
              label={intl.formatMessage(MESSAGES[UserFormValues.PHONE_NUMBER])}
            >
              <StyledInput
                width="100%"
                type="text"
                data-testid="input-user-phone"
              />
            </StyledFormItem>
          </Form.Item>
          <StyledInputGroupHeading mb={theme.spacing.xLarge}>
            {intl.formatMessage(MESSAGES.permissions)}
          </StyledInputGroupHeading>
          {renderPermissions()}
          <FlexRow>
            <StyledButton
              variant="danger"
              htmlType="button"
              onClick={handleCancel}
              mr={theme.spacing.medium}
              data-testid="btn-user-form-cancel"
            >
              {intl.formatMessage(MESSAGES.cancel)}
            </StyledButton>
            <StyledButton
              variant="primary"
              htmlType="submit"
              data-testid="btn-user-form-submit"
            >
              {intl.formatMessage(MESSAGES.confirm)}
            </StyledButton>
          </FlexRow>
        </StyledForm>
      </FlexCol>
    </StyledBox>
  );
};
