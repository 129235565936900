import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { StyledHeading } from '../../styled/headings/StyledHeading';
import { StyledBox } from '../../styled/boxes/StyledBox';
import { theme } from '../../theme';
import { LogsFilter } from './components/Filter';
import { PrimaryWrapperBox } from '../../styled/boxes/PrimaryWrapperBox';
import AlarmsTable from './components/AlarmsTable';

const MESSAGES = defineMessages({
  heading: {
    id: 'logs.heading',
    defaultMessage: 'Alarms / Warnings',
  },
  filter: {},
});

const Alarms = () => {
  const intl = useIntl();

  // useSubscribeById({
  //  path: 'warnings_errors',
  //  onMessage: (message) => {},
  // });

  return (
    <div>
      <StyledHeading variant="h1">
        {intl.formatMessage(MESSAGES.heading)}
      </StyledHeading>

      <StyledBox mb={theme.spacing.large}>
        <LogsFilter />
      </StyledBox>

      <PrimaryWrapperBox>
        <AlarmsTable />
      </PrimaryWrapperBox>
    </div>
  );
};

export default Alarms;
