import styled from '@emotion/styled';
import { Modal } from 'antd';

import { theme } from '../../theme';

export const StyledModal = styled(Modal)({
  '.ant-modal-content': {
    borderRadius: theme.borderRadius.large,
    boxShadow: theme.boxShadows.light,
    margin: `${theme.spacing.xLarge} 0`,
  },

  '.ant-modal-header': {
    borderRadius: theme.borderRadius.large,
    borderBottom: 'none',
    paddingBottom: 0,
  },
});
