import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import styled from 'styled-components';
import { colors, theme } from '../../theme';

import { StyledSwitch } from '../../styled/dataEntry/StyledSwitch';
import { SessionMetricsBox } from '../Sessions/SessionDetail/SessionMetrics/SessionMetricsBox';

const MESSAGES = defineMessages({
  activeSessions: {
    id: 'dashboard.active_session',
    defaultMessage: 'Active sessions',
  },
});

const KWHText = styled.span`
  color: ${colors.green.background};

  font-weight: ${theme.fontWeights.regular};
`

export const DashboardTotalConsumption = () => {
  const [checked, setChecked] = useState(false)

  const intl = useIntl();
  
  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <span style={{ marginRight: '4px' }}>Month</span>
        <StyledSwitch
          checked={checked}
          onClick={() => setChecked(prev => !prev)}
          data-testid="switch-asset-action"
        />
        <span style={{ marginLeft: '4px' }}>YTD</span>
      </div>

      <div>
        <SessionMetricsBox
          active={false}
          type={2}
        >
          {!checked ? 15.821 : 72.412} <KWHText>kWh</KWHText>
        </SessionMetricsBox>

        <SessionMetricsBox
          active={false}
          type={3}
        >
          {!checked ? 11.245 : 59.927} <KWHText>m<sup>3</sup></KWHText>
        </SessionMetricsBox>
      </div>
    </>
  );
};
