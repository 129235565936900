import React from 'react';

import { MobileTableCard } from '../../../../../components/cards/MobileTableCard';

interface IProps {
  data: any;
  columns: any;
}

export const TagsInfoTableMobile = ({ columns, data }: IProps) => {
  return (
    <div>
      {data.map((log: any) => (
        <MobileTableCard key={log.id} columns={columns} data={log} />
      ))}
    </div>
  );
};
