import React from 'react';
import { FlexRow } from '../../../../styled/flex/FlexRow';
import { StyledSubTextLight, NormalText } from '../../../../styled/text/Text';
import { theme } from '../../../../theme';
import {
  ESessionConsumptionUnit,
  ISessionDetail,
} from '../../../../types/session.types';
import { formatNumber } from '../../../../utils/shared/formats/formatNumbers';

interface IProps {
  session: ISessionDetail;
}
export const SessionTechnicalInfo = ({ session }: IProps) => {
  const isElectricityBox =
    session.consumption.unit === ESessionConsumptionUnit.KWH;
  const consumption = isElectricityBox ? (
    'kWh'
  ) : (
    <span>
      m<sup>3</sup>
    </span>
  );

  return (
    <>
      {session && (
        <div>
          {isElectricityBox && (
            <>
              <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
                <StyledSubTextLight>Max current L1 (A)</StyledSubTextLight>
                <NormalText width="60%" alignment="right">
                  {formatNumber(session.metrics.maxCurrentL1)}
                </NormalText>
              </FlexRow>

              <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
                <StyledSubTextLight>Max current L2 (A)</StyledSubTextLight>
                <NormalText width="60%" alignment="right">
                  {formatNumber(session.metrics.maxCurrentL2)}
                </NormalText>
              </FlexRow>

              <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
                <StyledSubTextLight>Max current L3 (A)</StyledSubTextLight>
                <NormalText width="60%" alignment="right">
                  {formatNumber(session.metrics.maxCurrentL3)}
                </NormalText>
              </FlexRow>
            </>
          )}

          <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
            <StyledSubTextLight>Meter start ({consumption})</StyledSubTextLight>
            <NormalText width="60%" alignment="right">
              {formatNumber(session.consumption.meterStart) || '-'}
            </NormalText>
          </FlexRow>

          <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
            <StyledSubTextLight>Meter stop ({consumption})</StyledSubTextLight>
            <NormalText width="60%" alignment="right">
              {formatNumber(session.consumption.meterReadout) || '-'}
            </NormalText>
          </FlexRow>
        </div>
      )}
    </>
  );
};
