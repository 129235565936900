import React from 'react';

import { StyledBoxWithPointer } from '../../../styled/boxes/StyledBoxWithPointer';
import { IIconProps } from '../../../types/icon.types';

export const BackArrowIcon = ({ onClick, ...space }: IIconProps) => (
  <StyledBoxWithPointer
    onClick={onClick}
    {...space}
    data-testid="btn-back-arrow"
  >
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.213867 22.2363C0.213867 10.0861 10.0636 0.236328 22.2139 0.236328V0.236328C34.3641 0.236328 44.2139 10.0861 44.2139 22.2363V22.2363C44.2139 34.3866 34.3641 44.2363 22.2139 44.2363V44.2363C10.0636 44.2363 0.213867 34.3866 0.213867 22.2363V22.2363Z"
        fill="white"
      />
      <path
        d="M29.2139 22.2363H15.2139"
        stroke="#02A24F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.2139 29.2363L15.2139 22.2363L22.2139 15.2363"
        stroke="#02A24F"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </StyledBoxWithPointer>
);
