import { Rule } from 'antd/lib/form';
import { IntlShape } from 'react-intl';

import { FORM_VALIDATION_MESSAGES } from '../../consts/form.consts';

export const getRequiredRule = (label: string, intl: IntlShape): Rule => ({
  required: true,
  message: intl.formatMessage(FORM_VALIDATION_MESSAGES.required, {
    label,
  }),
});
