/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    ENV?: unknown;
  }
}

function createEnv<T>() {
  return (window.ENV || process.env) as T;
}

export default createEnv<any>();
