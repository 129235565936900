import styled from '@emotion/styled';
import { Tabs } from 'antd';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { colors } from '../../../../theme';
import { TaskTabs } from '../../../../types/tab.types';

const MESSAGES = defineMessages({
  editTab: {
    id: 'task_tabs.edit',
    defaultMessage: 'Edit',
  },
  logsTab: {
    id: 'task_tabs.logs',
    defaultMessage: 'Logs',
  },
});

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)({
  '.ant-tabs-ink-bar': {
    background: colors.green.primary,
  },
  '.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
    color: colors.green.primary,
  },
  '.ant-tabs-tab': {
    '&:hover': {
      color: colors.green.primary,
    },
  },
});

interface IProps {
  onTabClick: (tab: TaskTabs) => void;
  selectedTab: TaskTabs;
}

export const MaintenanceTaskTabs = ({ onTabClick, selectedTab }: IProps) => {
  const intl = useIntl();

  const handleTabClick = (tab: string) => {
    const currentTab = tab as TaskTabs;
    onTabClick(currentTab);
  };

  return (
    <StyledTabs
      defaultActiveKey={selectedTab || TaskTabs.EDIT}
      activeKey={selectedTab}
      onTabClick={(tab: string) => handleTabClick(tab)}
      data-testid="task-tabs"
    >
      <TabPane
        tab={intl.formatMessage(MESSAGES.editTab)}
        key={TaskTabs.EDIT}
      />
      <TabPane
        tab={intl.formatMessage(MESSAGES.logsTab)}
        key={TaskTabs.LOGS}
      />
    </StyledTabs>
  );
};
