import React from 'react';
import { useDispatch } from 'react-redux';
import { useSubscribeById } from '../../../hooks/useSubscribeById';
import { setBoxStatus } from '../../../store/slices/tags.slice';
import { ITagStatusBoxId } from '../../../types/tag.types';

const BoxListener = ({ boxId }: { boxId: string }) => {
  const dispatch = useDispatch();

  useSubscribeById({
    entityId: boxId,
    path: `assets/${boxId}/tags`,
    onMessage: (newStatus: ITagStatusBoxId) => {
      dispatch(setBoxStatus({ boxId, ...newStatus }));
    },
  });

  return null;
};

export default BoxListener;
