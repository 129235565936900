import { Rule } from 'rc-field-form/lib/interface';
import { IntlShape } from 'react-intl';

import { FORM_VALIDATION_MESSAGES } from '../../consts/form.consts';

export const getPhoneNumberRule = (label: string, intl: IntlShape): Rule => ({
  pattern: /^(\+|00|0)[1-9][0-9 ()/.]{7,32}$$/,
  message: intl.formatMessage(FORM_VALIDATION_MESSAGES.regex, {
    label,
  }),
});
