import { Actions } from './actions';

class UserActionsClass extends Actions {
  constructor() {
    super('USER');
  }

  setToken = this.createAction<string | null>('setToken');
  setGetCurrentUserResponse = this.createAction<number | null>(
    'setGetCurrentUserResponse',
  );
}

export const UserActions = new UserActionsClass();
