import { RequestSessionFor } from '../../types/session.types';
import { ICurrentUser, UserPermission } from '../../types/user.types';
import { checkIfUserHasPermission } from '../users/checkIfUserHasPermission';

// eslint-disable-next-line consistent-return
export const getRequestSessionFor = (currentUser: ICurrentUser) => {
  const canManageAssets = checkIfUserHasPermission(
    currentUser,
    UserPermission.MANAGE_ASSETS,
  );
  const canCreateSession = checkIfUserHasPermission(
    currentUser,
    UserPermission.CREATE_SESSION,
  );
  const canManageCompanySessions = checkIfUserHasPermission(
    currentUser,
    UserPermission.MANAGE_COMPANY_SESSIONS,
  );

  if (canManageAssets) {
    return RequestSessionFor.MANAGED_CONNECTORS;
  }
  if (canManageCompanySessions) {
    return RequestSessionFor.COMPANY;
  }
  if (canCreateSession) {
    return RequestSessionFor.USER;
  }
};
