import { CheckOutlined } from '@ant-design/icons';
import React from 'react';
import { defineMessages, IntlShape, useIntl } from 'react-intl';
import { StyledButton } from '../../../styled/buttons/StyledButton';
import { StyledTable } from '../../../styled/table/StyledTable';
import { theme } from '../../../theme';

const MESSAGES = defineMessages({
  startTime: {
    id: 'logs_table.startTime',
    defaultMessage: 'Start time',
  },
  endTime: {
    id: 'logs_table.endTime',
    defaultMessage: 'End time',
  },
  assetTypeId: {
    id: 'logs_table.assetTypeId',
    defaultMessage: 'Asset type & ID',
  },
  connector: {
    id: 'logs_table.connector',
    defaultMessage: 'Connector',
  },
  type: {
    id: 'logs_table.type',
    defaultMessage: 'Type',
  },
  transactionId: {
    id: 'logs_table.transactionId',
    defaultMessage: 'Transaction ID',
  },
  description: {
    id: 'logs_table.description',
    defaultMessage: 'Description',
  },
  confirmationStatus: {
    id: 'logs_table.confirmationStatus',
    defaultMessage: 'Confirmation status',
  },
  confirm: {
    id: 'logs_table.confirm',
    defaultMessage: 'Confirm',
  },
});

const columns = (intl: IntlShape) => [
  {
    title: intl.formatMessage(MESSAGES.startTime),
    dataIndex: 'startTime',
    key: 'startTime',
  },
  {
    title: intl.formatMessage(MESSAGES.endTime),
    dataIndex: 'endTime',
    key: 'endTime',
  },
  {
    title: intl.formatMessage(MESSAGES.assetTypeId),
    dataIndex: 'assetTypeId',
    key: 'assetTypeId',
  },
  {
    title: intl.formatMessage(MESSAGES.connector),
    dataIndex: 'connector',
    key: 'connector',
  },
  {
    title: intl.formatMessage(MESSAGES.type),
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: intl.formatMessage(MESSAGES.transactionId),
    dataIndex: 'transactionId',
    key: 'transactionId',
  },
  {
    title: intl.formatMessage(MESSAGES.description),
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: intl.formatMessage(MESSAGES.confirmationStatus),
    dataIndex: '',
    key: 'action',
    render: () => {
      return (
        <StyledButton
          variant="primary"
          onClick={() => {}}
          style={{ borderRadius: theme.borderRadius.xsmall }}
        >
          <CheckOutlined />
          {intl.formatMessage(MESSAGES.confirm)}
        </StyledButton>
      );
    },
  },
];

const dataSource = [
  {
    id: '1',
    startTime: '2021-07-01 10:00',
    endTime: '2021-07-01 10:30',
    assetTypeId: 'Asset type & ID',
    connector: 'Connector',
    type: 'Type',
    transactionId: 'Transaction ID',
    description: 'Description',
  },
];

const AlarmsTable = () => {
  const intl = useIntl();
  return (
    <div>
      <StyledTable
        columns={columns(intl)}
        dataSource={dataSource}
        rowKey="id"
        pagination={{
          position: ['bottomLeft'],
        }}
        loading={false}
      />
    </div>
  );
};

export default AlarmsTable;
