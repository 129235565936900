import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useMap } from 'react-leaflet';
import { StyledButton } from '../../../styled/buttons/StyledButton';
import { StyledSearch } from '../../../styled/form/StyledSearch';
import MapMenu from './Menu';

interface IAsset {
  assetType: string;
  label: string;
  lat: number;
  long: number;
}

const SearchBarWithMenu = ({
  assetList,
  onSearch,
}: {
  assetList: IAsset[];
  onSearch: (asset: IAsset) => void;
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [searchValue, setSearchValue] = useState('');

  const map = useMap();

  const handleSearch = () => {
    const asset = assetList.find((_asset) => _asset.label === searchValue);
    map.flyTo([asset.lat, asset.long], 18, {
      animate: false,
      duration: 1,
    });
    onSearch(asset);
  };

  return (
    <div className="absolute bg-white rounded-b-none z-[999] px-5 py-5 w-fit rounded-lg right-4 top-3">
      <div className="flex items-center">
        <StyledButton
          className="!h-[32px] !w-[32px] !rounded-r-none"
          variant="primary"
          icon={<GiHamburgerMenu />}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        />
        <StyledSearch
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onSearch={handleSearch}
          placeholder="Search for asset ID"
          className="py-2 bg-white !w-[300px]"
          inputStyle={{
            borderTopLeftRadius: '0px',
            borderBottomLeftRadius: '0px',
          }}
        />
      </div>
      <div
        className={`absolute mt-5 right-0 left-0 bg-white border shadow-lg transition-all duration-300 ease-in-out overflow-hidden ${
          isMenuOpen ? 'max-h-60 opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <MapMenu />
      </div>
    </div>
  );
};

export default SearchBarWithMenu;
