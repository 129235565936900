import React from 'react';

import { StyledBox } from '../../../../../../styled/boxes/StyledBox';
import { colors } from '../../../../../../theme';
import { IConnectorStatusIconProps } from '../../../../../../types/connector.types';
import { IconSize } from '../../../../../../types/icon.types';
import { getIconSize } from '../../../../../../utils/shared/icons/getIconSize';

export const AuthorisationForcedIcon = ({
  ...space
}: IConnectorStatusIconProps) => (
  <StyledBox {...space}>
    <svg
      width={getIconSize(IconSize.MEDIUM).width}
      height={getIconSize(IconSize.MEDIUM).height}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-connector-forced-authorisation"
    >
      <path
        d="M10.0001 20.4776C10.0001 20.4776 1.6001 16.6 1.6001 4.52922C6.8253 3.38202 8.3609 1.40002 10.0001 1.40002C11.6393 1.40002 13.1749 3.38202 18.4001 4.52922C18.4001 16.2 10.0001 20.4776 10.0001 20.4776Z"
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.70508 5.75781V15H7.47998V5.75781H8.70508ZM12.5771 9.91553V10.9185H8.43848V9.91553H12.5771ZM13.2056 5.75781V6.76074H8.43848V5.75781H13.2056Z"
        fill={colors.green.secondary}
      />
    </svg>
  </StyledBox>
);
