import React from 'react';
import { Form } from 'antd';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import moment, { Moment } from 'moment';
import { tagStatusOptions } from './AssetTagVariables';
import { PrimaryWrapperBox } from '../../../../styled/boxes/PrimaryWrapperBox';
import { theme } from '../../../../theme';
import { FilterContainer, FilterElements } from '../../../../components/filters/FilterElements';
import { StyledDate } from '../../../../styled/date/StyledDate';
import { StyledSelect } from '../../../../styled/form/StyledSelect';
import { IAssetInfo } from '../../../../types/box.types';
import { IConnectorBox } from '../../../../types/connector.types';
import { updateRequestedTablePage } from '../../../../store/slices/tableMetaInfo.slice';
import { Table } from '../../../../types/table.types';
import { setAssetLogFilter } from '../../../../store/slices/filters.slice';
import {
  AssetLogFilterKey,
  IAssetLogFilters,
  IPagedAssetLogFilters,
} from '../../../../types/filters.types';
import {
  assetLogFilterByKeySelector,
  assetLogFiltersByKeysSelector,
} from '../../../../store/selectors/filters.selectors';
import {
  requestedTablePageSelector,
  requestedTableSizeSelector, requestedTableSortSelector,
} from '../../../../store/selectors/tableMetaInfo.selectors';
import { assetLogFilterKeys } from './AssetLogsTable';

const DateInputContainer = styled.div`
    @media (max-width: 767px) {
        width: 80%;
        display: flex;
        justify-content: flex-end;
    }
`;

const MultipleSelectContainer = styled.div`
    width: 60%;
    @media (max-width: 767px) {
        .ant-select {
            width: 100% !important;
        }

        width: 100%;
    }
`;

const MESSAGES = defineMessages({
  from: {
    id: 'asset_logs.from',
    defaultMessage: 'From:',
  },
  to: {
    id: 'asset_logs.to',
    defaultMessage: 'To:',
  },
  tagStatus: {
    id: 'asset_logs.tag_status',
    defaultMessage: 'Tag:',
  },
  selectTagStatuses: {
    id: 'asset_logs.select_tag_statuses',
    defaultMessage: 'Select tag statuses',
  },
  sort: {
    id: 'asset_logs.sort',
    defaultMessage: 'Sort timestamp:',
  },
  sortPlaceholder: {
    id: 'asset_logs.sort_placeholder',
    defaultMessage: 'Sort for timestamp',
  },
  refreshData: {
    id: 'asset_logs.refresh_data',
    defaultMessage: 'Refresh data every minute',
  },
  assets: {
    id: 'asset_logs.assets',
    defaultMessage: 'Assets:',
  },
});

interface IProps {
  asset: IAssetInfo;
}

export const AssetLogTableFilter = ({ asset }: IProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const assetIdOptions = [
    { label: asset.label, value: asset.id },
  ];
  // eslint-disable-next-line
  assetIdOptions.push(...asset?.connectors?.map((connector: IConnectorBox) => ({
    label: `Connector ${connector.index + 1}`,
    value: connector.id,
  })));

  // const [refreshData, setRefreshData] = useState<boolean>(false);
  // const [timeoutFn, setTimeoutFn] = useState<NodeJS.Timeout>();

  // const allAssetsArray = asset?.connectors
  //   ?.map((c) => c.id)
  //   .concat(asset?.id)
  //   .sort();

  // const handleReload = () => {
  //   const timeout = setTimeout(() => {
  //     window.location.reload();
  //   }, 60000);
  //   setTimeoutFn(timeout);
  // };

  let assetIdFilterValue = useSelector(
    assetLogFilterByKeySelector(AssetLogFilterKey.assetId),
  ) as string[];
  const assetIds = assetIdOptions.map((a) => a.value);
  const commonElements = assetIdFilterValue ? assetIdFilterValue.filter(element => assetIds.includes(element)) : [];
  if (commonElements.length === 0) {
    assetIdFilterValue = assetIds;
    dispatch(
      setAssetLogFilter({
        key: AssetLogFilterKey.assetId,
        value: assetIds,
      }),
    );
  }

  const assetLogFilterValues = useSelector(
    assetLogFiltersByKeysSelector(assetLogFilterKeys),
  ) as IAssetLogFilters | undefined;

  const assetLogTablePage = useSelector(
    requestedTablePageSelector(Table.ASSET_LOG_TABLE),
  );
  const assetLogTableSize = useSelector(
    requestedTableSizeSelector(Table.ASSET_LOG_TABLE),
  );
  const assetLogTableSort: string[] | undefined = useSelector(
    requestedTableSortSelector(Table.ASSET_LOG_TABLE),
  );

  const assetLogFilters: IPagedAssetLogFilters = {
    ...assetLogFilterValues,
    params: {
      page: assetLogTablePage,
      sort: assetLogTableSort,
      size: assetLogTableSize,
    }
  };

  const handleEmitFilter = (key: AssetLogFilterKey, value: string | number | string[] | undefined) => {
    // console.log('handleEmitFilter', key, value);
    if (key !== AssetLogFilterKey.assetId || (value as string[]).length > 0) {
      dispatch(
        updateRequestedTablePage({
          key: Table.ASSET_LOG_TABLE,
          value: 0,
        }),
      );
    }
    dispatch(
      setAssetLogFilter({
        key,
        value,
      }),
    );

  };

  // @ts-ignore
  return (
    <PrimaryWrapperBox mb={theme.spacing.large}>
      <Form
        form={form}
        layout="inline"
      >
        <FilterContainer>
          <FilterElements title={intl.formatMessage(MESSAGES.from)}>
            <DateInputContainer>
              <StyledDate
                value={assetLogFilters.from ? moment(assetLogFilters.from) : null}
                format="DD-MM-YYYY"
                picker="date"
                width="200px"
                onChange={(value: Moment | null) => handleEmitFilter(AssetLogFilterKey.from, value ? value.startOf('day').valueOf() : undefined)}
              />
            </DateInputContainer>
          </FilterElements>

          <FilterElements title={intl.formatMessage(MESSAGES.to)}>
            <DateInputContainer>
              <StyledDate
                value={assetLogFilters.to ? moment(assetLogFilters.to) : null}
                format="DD-MM-YYYY"
                picker="date"
                width="200px"
                onChange={(value: Moment | null) => handleEmitFilter(AssetLogFilterKey.to, value ? value.endOf('day').valueOf() : undefined)}
              />
            </DateInputContainer>
          </FilterElements>

          <FilterElements title={intl.formatMessage(MESSAGES.tagStatus)}>
            <MultipleSelectContainer>
              <StyledSelect
                mode="multiple"
                allowClear
                width="300px"
                defaultValue={assetLogFilters.tag}
                value={assetLogFilters.tag}
                onChange={(value: unknown) => handleEmitFilter(AssetLogFilterKey.tag, value as string[])}
                options={tagStatusOptions}
                placeholder={intl.formatMessage(MESSAGES.selectTagStatuses)}
              />
            </MultipleSelectContainer>
          </FilterElements>

          <FilterElements title={intl.formatMessage(MESSAGES.assets)}>
            <MultipleSelectContainer>
              <StyledSelect
                mode="tags"
                allowClear={false}
                width="400px"
                value={assetIdFilterValue}
                onChange={(value: unknown) => handleEmitFilter(AssetLogFilterKey.assetId, value as string[])}
                options={assetIdOptions}
                placeholder={intl.formatMessage(MESSAGES.sortPlaceholder)}
              />
            </MultipleSelectContainer>
          </FilterElements>

          {/*<FilterElements title={intl.formatMessage(MESSAGES.refreshData)}>*/}
          {/*  <StyledCheckbox*/}
          {/*    checked={refreshData}*/}
          {/*    disabled={(filters?.page ?? 1) > 0}*/}
          {/*    onChange={(ev) => handleRefresh(ev.target.checked)}*/}
          {/*  />*/}
          {/*</FilterElements>*/}
        </FilterContainer>
      </Form>
    </PrimaryWrapperBox>
  );
};

