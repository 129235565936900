import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  useGetTagsInfoByAssetIdQuery,
  useTagsChannelQuery,
} from '../../../../store/api/tags.api';
import { PrimaryWrapperBox } from '../../../../styled/boxes/PrimaryWrapperBox';
import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { StyledHeading } from '../../../../styled/headings/StyledHeading';
import { theme } from '../../../../theme';
import { IAssetInfo } from '../../../../types/box.types';
import { BoxConnectorsList } from './BoxConnectorsList/BoxConnectorsList';
import { TagsInfoTable } from './TagsInfoTable/TagsInfoTable';

interface IProps {
  boxInfo?: IAssetInfo;
}

const MESSAGES = defineMessages({
  heading: {
    id: 'asset.heading',
    defaultMessage: 'Tags and their value',
  },
});

export const BoxDeveloperPortal = ({ boxInfo }: IProps) => {
  const intl = useIntl();
  const { assetId } = useParams() as { assetId: string };
  const [currentAssetId, setCurrentAssetId] = useState(assetId);
  const { data } = useGetTagsInfoByAssetIdQuery(currentAssetId);

  useTagsChannelQuery(currentAssetId);

  const handleSelectBox = (boxId: string) => {
    setCurrentAssetId(boxId);
  };

  const handleSelectAsset = (connectorId: string) => {
    setCurrentAssetId(connectorId);
  };

  return (
    <>
      <StyledHeading variant="h3">
        {intl.formatMessage(MESSAGES.heading)}
      </StyledHeading>
      {boxInfo && (
        <StyledBox my={theme.spacing.xLarge}>
          <BoxConnectorsList
            boxInfo={boxInfo}
            onSelectBox={handleSelectBox}
            onSelectConnector={handleSelectAsset}
          />
        </StyledBox>
      )}
      {data && (
        <PrimaryWrapperBox>
          <TagsInfoTable assetId={currentAssetId} tagInfoList={data} />
        </PrimaryWrapperBox>
      )}
    </>
  );
};
