import React from 'react';
import { useSelector } from 'react-redux';

import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../store/selectors/tags.selectors';
import { StyledBox } from '../../styled/boxes/StyledBox';
import { FlexBox } from '../../styled/flex/FlexBox';
import { theme } from '../../theme';
import { BoxTag } from '../../types/tag.types';
import { getBoxStatusForSymbolAlarmStatus } from '../../utils/boxes/getBoxStatusForSymbolAlarmStatus';
import { getBoxTechStatusForTechStatus } from '../../utils/boxes/getBoxTechStatusForTechStatus';
import { getBoxTypeForSymbolBoxStatus } from '../../utils/boxes/getBoxTypeForSymbolBoxStatus';
import { BoxStatusIcon } from './BoxStatusIcon';
import { BoxTypeIcon } from './BoxTypeIcon';
import { getBoxConnectivityStatus } from '../../utils/boxes/getBoxConnectivityStatus';

interface IProps {
  boxId: string;
  showText?: boolean;
  assets?: boolean;
  details?: boolean;
}

export const BoxStatusIconsList = ({
  assets,
  boxId,
  details = false,
  showText = true,
}: IProps) => {
  const boxIsOffline = getBoxConnectivityStatus(boxId);

  const symbolBoxTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V2_SYMBOL_BOX,
    ),
  );
  const alarmTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V2_SYMBOL_ALARM,
    ),
  );
  const techTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V2_TECH_BOX_STATUS,
    ),
  );

  const boxType = getBoxTypeForSymbolBoxStatus(symbolBoxTagStatus);
  const boxStatus = getBoxStatusForSymbolAlarmStatus(alarmTagStatus);
  const boxTechStatus = getBoxTechStatusForTechStatus(techTagStatus);

  return (
    <div
      data-testid="box-status-icons-list"
      style={{
        display: 'flex',
        alignItems: 'center',
        opacity: boxIsOffline && !details ? '.3' : 1,
      }}
    >
      <StyledBox ml={theme.spacing.xxSmall}>
        {BoxTypeIcon({ boxType, boxTechStatus, showText })}
      </StyledBox>
      <StyledBox ml={theme.spacing.xxSmall}>
        {BoxStatusIcon({ boxStatus, boxTechStatus, assets })}
      </StyledBox>
    </div>
  );
};
