import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { theme } from '../../../../theme';

import { MaintenanceTaskPriorityEnum, TasksPriorityEnum, MaintenanceTaskPriorityText, MaintenanceTaskPriority } from '../../../../types/maintenance.types';
import { StyledSelect } from '../../../../styled/form/StyledSelect';

const priorities = Array.from(Object.values(MaintenanceTaskPriority)) as MaintenanceTaskPriority[];

const MESSAGES = defineMessages({
  [MaintenanceTaskPriorityEnum.Urgent]: {
    id: 'select_boxes_dropdown.urgent',
    defaultMessage: 'Urgent',
  },
  [MaintenanceTaskPriorityEnum.High]: {
    id: 'select_boxes_dropdown.high',
    defaultMessage: 'High',
  },
  [MaintenanceTaskPriorityEnum.Medium]: {
    id: 'select_boxes_dropdown.medium',
    defaultMessage: 'Medium',
  },
  [MaintenanceTaskPriorityEnum.Low]: {
    id: 'select_boxes_dropdown.low',
    defaultMessage: 'Low',
  },
  selectPriority: {
    id: 'maintenance.selectPriority',
    defaultMessage: 'Select priority',
  },
});

const { Option } = Select;

interface IProps {
  onSelectPriority: (priority: string) => void;
  prioritySelected?: MaintenanceTaskPriorityEnum;
}

export const SelectPriority = ({
  onSelectPriority,
  prioritySelected,
}: IProps) => {
  const intl = useIntl();
  const [selected, setSelected] = useState<string | null>(null);

  useEffect(() => {
    if (prioritySelected) {
      setSelected(MaintenanceTaskPriorityText[prioritySelected]);
    }
  }, [prioritySelected]);

  const handleSelectPriority = (priority: MaintenanceTaskPriorityEnum) => {
    if (priority) {
      onSelectPriority((TasksPriorityEnum as any)[priority]);
      setSelected((TasksPriorityEnum as any)[priority]);
    }
  };

  const renderColor = (priority: MaintenanceTaskPriority) => {
    let color;

    switch (priority) {
      case 'Urgent': color = '#ff6961'; break;
      case 'High': color = '#ffb54c'; break;
      case 'Medium': color = '#fee4b5'; break;
      case 'Low': color = '#7abd7e'; break;
      default: break;
    }

    return color;
  }

  return (
    <>
      <StyledSelect
        onChange={(value: unknown) => handleSelectPriority(value as MaintenanceTaskPriorityEnum)}
        value={selected}
        showSearch
        placeholder={intl.formatMessage(MESSAGES.selectPriority)}
        mb={theme.spacing.small}
        minWidth="170px"
      >
        {priorities.map((priority) => (
          <Option key={priority} value={priority}>
            <span style={{ color: renderColor(priority) }}>
              {priority}
            </span>
          </Option>
        ))}
      </StyledSelect>
    </>
  );
};
