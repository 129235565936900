import { Auth } from '@aws-amplify/auth';
import { Client, StompSubscription, IMessage } from '@stomp/stompjs';
import ENV from '../shared/environment';
import { storeAccessTokenAndEmailLocalStorage } from '../api/localStorage.utils';

export const BROKER_DESTINATION_PREFIX = '/topic';

interface Subscription {
  topic: string;
  callback: (payload: unknown) => void;
  subscription: StompSubscription;
}

class WsClient {
  private client: Client;
  private connected: Promise<void>;
  private resolveConnected!: () => void;
  private subscriptions: Subscription[] = [];

  constructor() {
    this.client = new Client();
    this.connected = new Promise((resolve) => {
      this.resolveConnected = resolve;
    });
  }

  init(token: string): void {
    this.client.configure({
      brokerURL: ENV.REACT_APP_WS_URL,
      connectHeaders: {
        Authorization: `Bearer ${token}`,
      },
      onStompError: this.onStompError.bind(this),
      onConnect: this.onConnect.bind(this),
    });
    this.client.activate();
  }

  private async onStompError(frame: IMessage): Promise<void> {
    const errorMessage = frame.headers?.message || '';
    if (errorMessage.includes('Jwt expired')) {
      await this.handleTokenExpiration();
    } else {
      await this.stopConnection();
    }
  }

  private onConnect(): void {
    this.resolveConnected();
    this.subscriptions.forEach(({ topic, callback, subscription }) => {
      subscription.unsubscribe(); // Unsubscribe previous subscriptions
      this.subscribe(topic, callback); // Re-subscribe
    });
  }

  private async handleTokenExpiration(): Promise<void> {
    try {
      const session = await Auth.currentSession();
      const refreshedToken = session.getIdToken().getJwtToken();
      storeAccessTokenAndEmailLocalStorage(refreshedToken);
      await this.reconnect(refreshedToken);
    } catch (error) {
      console.error('Failed to refresh token:', error);
      this.stopConnection();
    }
  }

  private async reconnect(token: string): Promise<void> {
    await this.client.deactivate();
    this.client = new Client();
    this.init(token);
  }

  private async stopConnection(): Promise<void> {
    await this.client.deactivate();
  }

  async subscribe(
    topic: string,
    callback: (payload: unknown) => void,
  ): Promise<StompSubscription> {
    await this.connected;
    const subscription = this.client.subscribe(topic, (message: IMessage) => {
      const payload = JSON.parse(message.body);
      callback(payload);
    });

    // Enhance the unsubscribe function to clean up the subscription list
    const originalUnsubscribe = subscription.unsubscribe.bind(subscription);
    subscription.unsubscribe = () => {
      if (this.client.connected) originalUnsubscribe();
      this.subscriptions = this.subscriptions.filter(
        (subsc) => subsc.subscription.id !== subscription.id,
      );
    };

    this.subscriptions.push({ topic, callback, subscription });
    return subscription;
  }
}

export const wsClient = new WsClient();
