/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledTable } from '../../../styled/table/StyledTable';
import { appUrls } from '../../../config/url.constants';

const logs = [
  {
    _id: 1,
    message: 'John Doe changed the assignee of the task <b>New manager</b> from <b>Michael</b> to <b>Dwight</b>',
    timestamp: '12:00',
  },
  {
    _id: 2,
    message: 'Michael added a comment: <b>"Please review the latest changes"</b> to the issue <b task>How to tackle problems</b>',
    timestamp: '12:05',
    task: 1,
  },
  {
    _id: 3,
    message: 'Dwight resolved the issue',
    timestamp: '12:10',
  },
  {
    _id: 4,
    message: 'Pam reopened the issue <b task>How to tackle problems</b>',
    task: 1,
    timestamp: '12:15',
  },
  {
    _id: 5,
    message: 'Jim closed the issue <b task>How to tackle problems</b>',
    timestamp: '12:20',
    task: 2,
  },
  {
    _id: 6,
    message: 'Angela assigned the task to Oscar',
    timestamp: '12:25',
  },
  {
    _id: 7,
    message: 'Kevin added a comment: <b>"I think we should escalate this"</b>',
    timestamp: '12:30',
  },
  {
    _id: 8,
    message: 'Oscar resolved the issue <b>How to tackle problems</b>',
    timestamp: '12:35',
  },
  {
    _id: 9,
    message: 'Stanley reopened the issue <b>Working with the team</b>',
    timestamp: '12:40',
  },
  {
    _id: 10,
    message: 'Phyllis closed the issue <b>Working with the team</b>',
    timestamp: '12:45',
  },
  {
    _id: 11,
    message: 'Creed changed the assignee from Oscar to Toby and added a comment: <b>"Please review the latest changes"</b>',
    timestamp: '12:50',
  },
  {
    _id: 12,
    message: 'Toby added a comment: "Let\'s schedule a meeting to discuss"',
    timestamp: '12:55',
  },
  {
    _id: 13,
    message: 'Meredith resolved the issue <b>X</b> to <b>Y</b>',
    timestamp: '13:00',
  },
  {
    _id: 14,
    message: 'Ryan reopened the issue <b>Working with the team</b>',
    timestamp: '13:05',
  },
  {
    _id: 15,
    message: 'Kelly closed the issue <b>Working with the team</b>',
    timestamp: '13:10',
  },
];



const data = {
  content: logs,
  first: true,
  last: true,
  number: 0,
  numberOfElements: 2,
  size: 20,
  totalElements: 2,
  totalPages :1
  }

const TaskLogs = () => {
  const [page, setPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const navigate = useNavigate();

  // TO DO: Need to add logic here to retrieve the logs from the endpoint
  const handlePaginationChange = (pageNumber: number, pageSize: number) => {
    const limit = pageSize;
    const offsetLogs = (pageNumber - 1) * pageSize;
    setPage(pageNumber)
    setOffset(offsetLogs)
  }

  const handleOnClick = (task: string) => {
    navigate(appUrls.maintenance.edit(task));
  }

  const columns = [
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      key: 'timestamp',
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      render(text: string, record: any) {
        return (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <div
            role='button'
            dangerouslySetInnerHTML={{ __html: text }}
            onClick={record.task ? () => handleOnClick(record.task) : undefined}
          />
        );
      }
    },
  ]

  return <StyledTable pagination={{
    position: ['bottomLeft'],
    defaultCurrent: page,
    showSizeChanger: false,
    onChange: (pageNumber, pageSize) => handlePaginationChange(pageNumber, pageSize),
  }} columns={columns} dataSource={data.content} />;
};

export default TaskLogs;
