import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components'

import { Variants } from '../../../styled/headings/StyledHeading';
import { colors, theme } from '../../../theme';

interface WhiteBoxProps {
  children: ReactNode;
  title?: string;
  width: string;
  center?: boolean;
}

interface WrapperProps {
  width: string;
  center?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  padding: 24px;
  border-radius: 8px;
  background-color: ${() => colors.white.primary};
  width: ${(props) => props.width};
  box-shadow: ${theme.boxShadows.light};

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const Heading = styled.h4`
  font-weight: bold;
  font-size: 14px;
`

export const WhiteBox = ({
  center,
  children,
  title,
  width,
}: WhiteBoxProps) => (
  <Wrapper width={width} center={center}>
    <Heading>{title}</Heading>
    {children}
  </Wrapper>
);
