import styled from '@emotion/styled';
import { Input } from 'antd';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

import { colors, theme } from '../../theme';

export const styledInputProps = {
  width: theme.input.width.normal,
  "@media (max-width: 768px)": {
    height: '48px',
    fontSize: '18px',
  },
  "@media (max-width: 400px)": {
    width: '90%',
  },
  '&& ': {
    border: `1px solid ${colors.lightGrey.secondary}`,
    boxShadow: 'none',
    borderRadius: '4px',
  },
  '&&&:hover, &:focus': {
    borderColor: colors.green.primary,
    boxShadow: theme.boxShadows.light,
  },
};

export const StyledInput = styled(Input)<LayoutProps | SpaceProps>(
  styledInputProps,
  layout,
  space,
);
