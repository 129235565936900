import React from 'react';

import { MobileTableCard } from '../../../components/cards/MobileTableCard';
import { IVessel } from '../../../types/vessel.types';

interface IProps {
  vessels: IVessel[];
  columns: any;
  handleEditClick: (id: string) => void;
  selectedVesselIds: string[];
}

export const VesselsTableMobile = ({
  columns,
  handleEditClick,
  selectedVesselIds,
  vessels,
}: IProps) => {
  return (
    <div>
      {vessels.map((vessel) => (
        <MobileTableCard
          key={vessel.id}
          columns={columns}
          data={vessel}
          additionalData={selectedVesselIds}
          handleEditClick={handleEditClick}
        />
      ))}
    </div>
  );
};
