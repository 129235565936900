import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { StyledSwitch } from '../../styled/dataEntry/StyledSwitch';
import { SessionMetricsBox } from '../Sessions/SessionDetail/SessionMetrics/SessionMetricsBox';

const MESSAGES = defineMessages({
  activeSessions: {
    id: 'dashboard.active_session',
    defaultMessage: 'Active sessions',
  },
});

export const DashboardRevenue = () => {
  const [checked, setChecked] = useState(false)

  const intl = useIntl();
  
  return (
    <>
      <div style={{ marginBottom: '16px' }}>
        <span style={{ marginRight: '4px' }}>Month</span>
        <StyledSwitch
          checked={checked}
          onClick={() => setChecked(prev => !prev)}
          data-testid="switch-asset-action"
        />
        <span style={{ marginLeft: '4px' }}>YTD</span>
      </div>

      <div>
        <SessionMetricsBox
          active={false}
          type={2}
        >
          {!checked ? '15.821 €' : '124.462 €'}
        </SessionMetricsBox>

        <SessionMetricsBox
          active={false}
          type={3}
        >
          {!checked ? '11.425 €' : '98.384 €'}
        </SessionMetricsBox>

        <SessionMetricsBox
          active={false}
          type={5}
        >
          {!checked ? `${(15.821 + 11.425).toFixed(3)} €` : `${(124.462 + 98.384).toFixed(3)} €`}
        </SessionMetricsBox>
      </div>
    </>
  );
};
