export function safeJsonParse(jsonString: string): unknown {
  if (typeof jsonString !== 'string') {
    return null;
  }

  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
}
