import styled from '@emotion/styled';
import { space, SpaceProps } from 'styled-system';

import { theme } from '../../theme';

export const StyledInputGroupHeading = styled('h1')<SpaceProps>(
  {
    fontFamily: theme.fontFamilies.secondary,
    fontWeight: theme.fontWeights.regular,
    fontSize: theme.fontSizes.xxLarge,
    lineHeight: theme.lineHeights.xLarge,
  },
  space,
);
