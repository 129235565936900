import styled from '@emotion/styled';
import { Steps } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { SessionTimelineDescription } from '../../../../components/sessions/SessionTimelineDescription';
import { SessionTimelineIcon } from '../../../../components/sessions/SessionTimelineIcon';
import { sessionDetailSelector } from '../../../../store/selectors/session.selectors';
import { colors } from '../../../../theme';

const { Step } = Steps;

const StyledStep = styled(Step)({
  '&& .ant-steps-item-tail::after': {
    height: '1px',
    backgroundColor: colors.black.secondary,
  },
  '&&& .ant-steps-icon-dot': {
    background: colors.black.secondary,
  },
});

interface IProps {
  sessionId: string;
}

export const SessionTimeline = ({ sessionId }: IProps) => {
  const session = useSelector(sessionDetailSelector(sessionId));

  const sessionIsActive = !session?.endedAt;

  return (
    <>
      {session && (
        <Steps size="small" progressDot>
          {session.events
            .slice() // can't sort immutable array in-place
            .sort((e1, e2) => e1.timestamp - e2.timestamp)
            .map((timelineEvent) => (
              <StyledStep
                description={
                  <SessionTimelineDescription
                    sessionTimelineEvent={timelineEvent}
                  />
                }
                key={`${timelineEvent.event} - ${timelineEvent.timestamp}`}
                title={
                  <SessionTimelineIcon sessionTimelineEvent={timelineEvent} />
                }
                status="finish"
              />
            ))}
          {sessionIsActive && (
            <StyledStep
              description={
                <SessionTimelineDescription sessionIsActive={sessionIsActive} />
              }
              title={<SessionTimelineIcon sessionIsActive={sessionIsActive} />}
              status="finish"
            />
          )}
        </Steps>
      )}
    </>
  );
};
