import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { StyledDiv } from '../../styled/StyledDiv';
import { StyledSpan } from '../../styled/text/StyledSpan';
import { colors, theme } from '../../theme';
import { ConnectorTag, ITagStatus } from '../../types/tag.types';

const MESSAGES = defineMessages({
  [ConnectorTag.V6_AUTHORISATION]: {
    id: 'tagAuthorisation',
    defaultMessage: 'Activation',
  },
  [ConnectorTag.V6_PILOOTCONTACT]: {
    id: 'tagPilootContact',
    defaultMessage: 'Connector in',
  },
  [ConnectorTag.V6_USER_DOOR]: {
    id: 'tagUserDoor',
    defaultMessage: 'User door',
  },
  [ConnectorTag.V6_VESSEL_BENDER]: {
    id: 'tagVesselBender',
    defaultMessage: 'Powerlock vessel',
  },
  [ConnectorTag.V6_VESSEL_SAFETY_LOOP]: {
    id: 'tagSafetyLoop',
    defaultMessage: 'Safety loop',
  },
  [ConnectorTag.V6_VOLTAGE_ON_CABLE]: {
    id: 'tagVoltageOnCable',
    defaultMessage: 'Voltage on cable detected',
  },
  [ConnectorTag.V6_POINT_BLOCKED]: {
    id: 'tagPointBlocked',
    defaultMessage: 'Point blocked',
  },
});

interface IProps {
  startConditionStatus: ITagStatus;
}

export const ConnectorStartConditionLine = ({
  startConditionStatus,
}: IProps) => {
  const intl = useIntl();

  const generateLine = () => {
    // @ts-ignore
    const text = intl.formatMessage(MESSAGES[startConditionStatus.tag]);
    switch (startConditionStatus.value) {
      case '1':
        return (
          <StyledDiv color={colors.green.secondary}>
            <CheckCircleOutlined />
            <StyledSpan ml={theme.spacing.xxSmall}>{text}</StyledSpan>
          </StyledDiv>
        );
      case '2':
        return (
          <StyledDiv color={colors.yellow.primary}>
            <WarningOutlined />
            <StyledSpan ml={theme.spacing.xxSmall}>{text}</StyledSpan>
          </StyledDiv>
        );
      default:
        return null;
    }
  };

  return generateLine();
};
