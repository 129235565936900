import { createSelector } from '@reduxjs/toolkit';

import { Table } from '../../types/table.types';
import type { RootState } from '../store';

export const tableMetaInfoClientState = (state: RootState) =>
  state.tableMetaInfo;

export const requestedTablePageSelector = (tableName: Table) =>
  createSelector(
    tableMetaInfoClientState,
    (state) => state.requestedPage[tableName],
  );

export const requestedTableSizeSelector = (tableName: Table) =>
  createSelector(
    tableMetaInfoClientState,
    (state) => state.requestedSize[tableName],
  );

export const requestedTableSortSelector = (tableName: Table) =>
  createSelector(
    tableMetaInfoClientState,
    (state) => state.requestedSort[tableName],
  );
