// store/slices/tags.slice.ts

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ITagStatus,
  ITagStatusBoxId,
  ITagStatusConnectorId,
} from '../../types/tag.types';

interface TagsState {
  statuses: {
    [connectorId: string]: ITagStatus[];
  };
  subscribedIds: string[];
  boxStatuses: {
    [boxId: string]: ITagStatus[];
  };
}

const initialState: TagsState = {
  statuses: {},
  subscribedIds: [],
  boxStatuses: {},
};

const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    initConnector: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;

      if (!state.subscribedIds.includes(id)) {
        state.subscribedIds.push(id);
      }
    },
    removeSubscribedId: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      state.subscribedIds = state.subscribedIds.filter(
        (subscribedId) => subscribedId !== id,
      );
    },
    resetTagStatuses: (state) => {
      return {
        ...state,
        statuses: {},
      };
    },
    setTagStatus: (state, action: PayloadAction<ITagStatusConnectorId>) => {
      const { connectorId, tag, value } = action.payload;
      if (!state.statuses[connectorId]) {
        state.statuses[connectorId] = [];
      }
      const index = state.statuses[connectorId].findIndex(
        (status) => status.tag === tag,
      );
      if (index < 0) {
        state.statuses[connectorId].push({ tag, value, updatedAt: '' });
      } else {
        state.statuses[connectorId][index] = { tag, value, updatedAt: '' };
      }
    },
    setBoxStatus: (state, action: PayloadAction<ITagStatusBoxId>) => {
      const { boxId, tag, value, updatedAt } = action.payload;
      if (!state.boxStatuses[boxId]) {
        state.boxStatuses[boxId] = [];
      }
      const index = state.boxStatuses[boxId].findIndex(
        (status) => status.tag === tag,
      );
      if (index < 0) {
        state.boxStatuses[boxId].push({ tag, value, updatedAt });
      } else {
        state.boxStatuses[boxId][index] = { tag, value, updatedAt };
      }
    },
  },
});

export const {
  initConnector,
  removeSubscribedId,
  resetTagStatuses,
  setTagStatus,
  setBoxStatus,
} = tagSlice.actions;

export default tagSlice.reducer;
