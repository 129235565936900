import { combineReducers, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  IIntlErrorMessage,
  IIntlMessage,
  ILayoutFlagPayload,
  ILayoutFlags,
  ILayoutMessages,
} from '../../types/layout.types';

const layoutMessagesInitialState: ILayoutMessages | null = {
  success: null,
  error: {
    message: null,
    description: null,
  },
};

const layoutFlagsSlice = createSlice({
  name: 'layoutFlags',
  initialState: {},
  reducers: {
    setLayoutFlag: (
      state: ILayoutFlags | null,
      { payload }: PayloadAction<ILayoutFlagPayload>,
    ) => {
      const newState = { ...state };
      newState[payload.key] = payload.flagValue;
      return newState;
    },
  },
});

const layoutMessagesSlice = createSlice({
  name: 'layoutMessages',
  initialState: layoutMessagesInitialState,
  reducers: {
    setSuccessMessage: (
      state: ILayoutMessages | null,
      { payload }: PayloadAction<IIntlMessage | null>,
    ) => {
      const newState = { ...state };
      newState.success = payload;
      return newState;
    },
    setErrorMessage: (
      state: ILayoutMessages | null,
      { payload }: PayloadAction<IIntlErrorMessage | null>,
    ) => {
      const newState = { ...state };
      newState.error = payload;
      return newState;
    },
  },
});

export const { setLayoutFlag } = layoutFlagsSlice.actions;
export const { setErrorMessage, setSuccessMessage } =
  layoutMessagesSlice.actions;
export const layoutReducer = combineReducers({
  layoutFlags: layoutFlagsSlice.reducer,
  layoutMessages: layoutMessagesSlice.reducer,
});
