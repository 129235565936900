import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../store';

export const registrationStateSelector = (state: RootState) =>
  state.registration;

export const registrationActiveUserRoleSelector = createSelector(
  registrationStateSelector,
  (registrationState) => registrationState.activeUserRole,
);
