import React from 'react';
import styled from 'styled-components';
import { theme } from '../../theme';

interface FilterContainerProps {
  noSpace?: boolean;
}

export const FilterContainer = styled.div<FilterContainerProps>`
  margin-right: ${theme.spacing.medium};

  display: flex;
  align-items: center;

  flex-wrap: wrap;

  gap: ${theme.spacing.small};

  @media (max-width: 767px) {
    margin-bottom: ${theme.spacing.xSmall};
    margin-right: 0;

    width: 100%;
    justify-content: ${(props) => props.noSpace ? 'flex-start' : 'space-between'};
    > .ant-input-search {
      width: max-content !important;
    }
  }
`;

const FilterTitle = styled.h4`
  font-size: ${theme.fontSizes.small}px;

  margin-bottom: 0;
`;

interface FilterElementProps {
  title: string;
  children: React.ReactNode;
  noSpace?: boolean;
}

export const FilterElements = ({ children, noSpace = false, title }: FilterElementProps) => {
  return (
    <FilterContainer noSpace={noSpace}>
      <FilterTitle>
        {title}
      </FilterTitle>
      {children}
    </FilterContainer>
  )
}