import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';

import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../../store/selectors/tags.selectors';
import { StyledDiv } from '../../../styled/StyledDiv';
import { BoxTag } from '../../../types/tag.types';
import { getBoxConnectivityStatus } from '../../../utils/boxes/getBoxConnectivityStatus';
import { getBoxTypeForSymbolBoxStatus } from '../../../utils/boxes/getBoxTypeForSymbolBoxStatus';
import { BoxType } from '../../../types/box.types';
import { formatNumber } from '../../../utils/shared/formats/formatNumbers';

interface IProps {
  boxId: string;
}

export const BoxTableActiveConsumption = ({ boxId }: IProps) => {
  const boxIsOffline = getBoxConnectivityStatus(boxId);

  const symbolBoxTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V2_SYMBOL_BOX,
    ),
  );
  const boxActiveConsumption = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V7_BOX_ACT_W,
    ),
  );

  const boxType = getBoxTypeForSymbolBoxStatus(symbolBoxTagStatus);
  let content;
  switch (boxType) {
    case BoxType.POWER:
    case BoxType.POWER_WATER:
    case BoxType.POWER_WASTE:
      content = (formatNumber(boxActiveConsumption?.value) || 0);
      break;
    default:
      content = <>n/a</>;
      break;
  }

  const COLUMN_STYLE: CSSProperties = {
    opacity: boxIsOffline ? '.3' : 1,
    textWrap: 'nowrap',
    textAlign: 'right',
  };
  return (<StyledDiv style={COLUMN_STYLE}>{content}</StyledDiv>);
};
