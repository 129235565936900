import { Form } from 'antd';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { usePostRegisterUserMutation } from '../../store/api/users.api';
import { StyledButton } from '../../styled/buttons/StyledButton';
import { FlexCol } from '../../styled/flex/FlexCol';
import { StyledFormItem } from '../../styled/form/StyledFormItem';
import { StyledInput } from '../../styled/form/StyledInput';
import { StyledHeading } from '../../styled/headings/StyledHeading';
import { theme } from '../../theme';
import { IRegisterUserDto, UserRole } from '../../types/user.types';
import { getEmailLocalStorage } from '../../utils/api/localStorage.utils';
import { getEmailValidationRule } from '../../utils/form/getEmailValidationRule';
import { getPhoneNumberRule } from '../../utils/form/getPhoneNumberRule';
import { getPostalCodeRule } from '../../utils/form/getPostalCodeRule';
import { getRequiredRule } from '../../utils/form/getRequiredRule';
import { FormItemCountrySelect } from '../form/select/FormItemCountrySelect';

const MESSAGES = defineMessages({
  title: {
    id: 'company_register.title',
    defaultMessage: 'Info',
  },
  personalInfo: {
    id: 'company_register.personalInfo',
    defaultMessage: 'Personal Info',
  },
  firstname: {
    id: 'company_register.firstname',
    defaultMessage: 'First name',
  },
  email: {
    id: 'company_register.email',
    defaultMessage: 'Email',
  },
  lastname: {
    id: 'company_register.lastname',
    defaultMessage: 'Surname',
  },
  phoneNumber: {
    id: 'company_register.phoneNumber',
    defaultMessage: 'Phone number',
  },
  company: {
    id: 'company_register.company',
    defaultMessage: 'Company',
  },
  companyName: {
    id: 'company_register.companyName',
    defaultMessage: 'Company name',
  },
  invoiceEmail: {
    id: 'company_register.invoiceEmail',
    defaultMessage: 'Invoice Email',
  },
  vat: {
    id: 'company_register.vat',
    defaultMessage: 'VAT Number',
  },
  street: {
    id: 'company_register.street',
    defaultMessage: 'Address',
  },
  city: {
    id: 'company_register.city',
    defaultMessage: 'City',
  },
  postalCode: {
    id: 'company_register.postalCode',
    defaultMessage: 'Postal code',
  },
  country: {
    id: 'company_register.country',
    defaultMessage: 'Country',
  },
  confirmButton: {
    id: 'company_register.confirmButton',
    defaultMessage: 'Register',
  },
});

enum FormFields {
  FIRSTNAME = 'firstname',
  LASTNAME = 'lastname',
  PHONE_NUMBER = 'phoneNumber',
  INVOICE_EMAIL = 'invoiceEmail',
  STREET = 'street',
  COUNTRY = 'country',
  CITY = 'city',
  POSTAL_CODE = 'postalCode',
  COMPANY_NAME = 'companyName',
  VAT_NR = 'vat',
}

interface IProps {
  role: UserRole;
}

export const RegisterForm = ({ role }: IProps) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [postRegisterUser] = usePostRegisterUserMutation();
  const email = getEmailLocalStorage();

  const handleFormSubmit = () => {
    form.validateFields().then((fields) => {
      const payload: IRegisterUserDto = {
        email: email ?? '',
        invoiceEmail: fields[FormFields.INVOICE_EMAIL],
        phoneNumber: fields[FormFields.PHONE_NUMBER],
        firstName: fields[FormFields.FIRSTNAME],
        lastName: fields[FormFields.LASTNAME],
        street: fields[FormFields.STREET],
        city: fields[FormFields.CITY],
        postalCode: fields[FormFields.POSTAL_CODE],
        country: fields[FormFields.COUNTRY],
        companyName: fields[FormFields.COMPANY_NAME],
        vatNr: fields[FormFields.VAT_NR],
        role,
      };
      postRegisterUser(payload);
    });
  };

  return (
    <FlexCol>
      <StyledHeading variant="h5">
        {intl.formatMessage(MESSAGES.title)}
      </StyledHeading>
      <StyledHeading variant="h6">
        {intl.formatMessage(MESSAGES.personalInfo)}
      </StyledHeading>
      <Form
        name="companyRegisterForm"
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        data-testid="register-techmetrys-form"
      >
        <StyledFormItem
          name={FormFields.FIRSTNAME}
          validateTrigger="onBlur"
          rules={[
            getRequiredRule(intl.formatMessage(MESSAGES.firstname), intl),
          ]}
        >
          <StyledInput
            mt={theme.spacing.medium}
            width="100%"
            type="text"
            placeholder={intl.formatMessage(MESSAGES.firstname)}
            data-testid="input-register-techmetrys-firstname"
          />
        </StyledFormItem>
        <StyledFormItem
          name={FormFields.LASTNAME}
          validateTrigger="onBlur"
          rules={[getRequiredRule(intl.formatMessage(MESSAGES.lastname), intl)]}
        >
          <StyledInput
            width="100%"
            type="text"
            placeholder={intl.formatMessage(MESSAGES.lastname)}
            data-testid="input-register-techmetrys-lastname"
          />
        </StyledFormItem>
        <StyledFormItem
          name={FormFields.PHONE_NUMBER}
          validateTrigger="onBlur"
          rules={[
            getRequiredRule(intl.formatMessage(MESSAGES.phoneNumber), intl),
            getPhoneNumberRule(intl.formatMessage(MESSAGES.phoneNumber), intl),
          ]}
        >
          <StyledInput
            width="100%"
            type="text"
            placeholder={intl.formatMessage(MESSAGES.phoneNumber)}
            data-testid="input-register-techmetrys-phone"
          />
        </StyledFormItem>
        {role === UserRole.COMPANY_OWNER && (
          <>
            <StyledHeading variant="h5">
              {intl.formatMessage(MESSAGES.company)}
            </StyledHeading>
            <StyledFormItem
              name={FormFields.COMPANY_NAME}
              validateTrigger="onBlur"
              rules={[
                getRequiredRule(intl.formatMessage(MESSAGES.companyName), intl),
              ]}
            >
              <StyledInput
                width="100%"
                type="text"
                placeholder={intl.formatMessage(MESSAGES.companyName)}
                data-testid="input-register-techmetrys-company-name"
              />
            </StyledFormItem>
            <StyledFormItem
              name={FormFields.INVOICE_EMAIL}
              validateTrigger="onBlur"
              rules={[
                getRequiredRule(intl.formatMessage(MESSAGES.invoiceEmail), intl),
                getEmailValidationRule(intl.formatMessage(MESSAGES.invoiceEmail), intl),
              ]}
            >
              <StyledInput
                width="100%"
                type="text"
                placeholder={intl.formatMessage(MESSAGES.invoiceEmail)}
                data-testid="input-register-techmetrys-company-name"
              />
            </StyledFormItem>
            <StyledFormItem
              name={FormFields.VAT_NR}
              validateTrigger="onBlur"
              rules={[getRequiredRule(intl.formatMessage(MESSAGES.vat), intl)]}
            >
              <StyledInput
                width="100%"
                type="text"
                placeholder={intl.formatMessage(MESSAGES.vat)}
                data-testid="input-register-techmetrys-company-vat"
              />
            </StyledFormItem>
          </>
        )}
        <StyledFormItem
          name={FormFields.STREET}
          validateTrigger="onBlur"
          rules={[getRequiredRule(intl.formatMessage(MESSAGES.street), intl)]}
        >
          <StyledInput
            width="100%"
            type="text"
            placeholder={intl.formatMessage(MESSAGES.street)}
            data-testid="input-register-techmetrys-company-street"
          />
        </StyledFormItem>
        <StyledFormItem
          name={FormFields.CITY}
          validateTrigger="onBlur"
          rules={[getRequiredRule(intl.formatMessage(MESSAGES.city), intl)]}
        >
          <StyledInput
            width="100%"
            type="text"
            placeholder={intl.formatMessage(MESSAGES.city)}
            data-testid="input-register-techmetrys-company-city"
          />
        </StyledFormItem>
        <StyledFormItem
          name={FormFields.POSTAL_CODE}
          validateTrigger="onBlur"
          rules={[
            getRequiredRule(intl.formatMessage(MESSAGES.postalCode), intl),
            getPostalCodeRule(intl.formatMessage(MESSAGES.postalCode), intl),
          ]}
          label={intl.formatMessage(MESSAGES.postalCode)}
        >
          <StyledInput
            width="100%"
            type="text"
            data-testid="input-register-techmetrys-company-postal-code"
          />
        </StyledFormItem>
        <FormItemCountrySelect
          name={FormFields.COUNTRY}
          validateTrigger="onBlur"
          rules={[getRequiredRule(intl.formatMessage(MESSAGES.country), intl)]}
        />
        <StyledButton
          width={theme.input.width.fitContent}
          variant="primary"
          htmlType="submit"
          data-testid="btn-register-techmetrys-form-submit"
        >
          {intl.formatMessage(MESSAGES.confirmButton)}
        </StyledButton>
      </Form>
    </FlexCol>
  );
};
