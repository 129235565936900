import styled from '@emotion/styled';

import { FlexBox } from './FlexBox';

interface FlexCenteredProps {
  isLogin?: boolean; // Add the prop here
}

export const FlexCentered = styled(FlexBox)<FlexCenteredProps>((props) => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  ...(props.isLogin && {
    "@media (max-width: 768px)": {
      background: 'linear-gradient(180deg, rgba(255,255,255,1) 60%, rgba(2,162,79,1))',
      width: '100%',
      height: '100%',
    },
  }),
}));