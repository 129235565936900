import { Rule } from 'rc-field-form/lib/interface';
import { IntlShape } from 'react-intl';

import { FORM_VALIDATION_MESSAGES } from '../../consts/form.consts';

export const getEmailValidationRule = (
  label: string,
  intl: IntlShape,
): Rule => ({
  type: 'email',
  message: intl.formatMessage(FORM_VALIDATION_MESSAGES.regex, {
    label,
  }),
});
