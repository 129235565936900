import { createSelector } from '@reduxjs/toolkit';
import { pick } from 'lodash';

import {
  AssetFilterKey,
  SessionFilterKey,
  AssetLogFilterKey,
  UserFilterKey,
  VesselFilterKey,
} from '../../types/filters.types';
import type { RootState } from '../store';

export const filtersRtkState = (state: RootState) => state.filters;

export const AssetFiltersSelector = createSelector(
  filtersRtkState,
  (state) => state.boxFilters,
);

export const sessionFiltersSelector = createSelector(
  filtersRtkState,
  (state) => state.sessionFilters,
);

export const assetLogFiltersSelector = createSelector(
  filtersRtkState,
  (state) => state.assetLogFilters,
);

export const userFiltersSelector = createSelector(
  filtersRtkState,
  (state) => state.userFilters,
);

export const vesselFiltersSelector = createSelector(
  filtersRtkState,
  (state) => state.vesselFilters,
);

export const boxFilterByKeySelector = (key: AssetFilterKey) =>
  createSelector(
    AssetFiltersSelector,
    (boxFilters) => boxFilters[key],
  );

export const sessionFilterByKeySelector = (key: SessionFilterKey) =>
  createSelector(
    sessionFiltersSelector,
    (sessionFilters) => sessionFilters[key],
  );

export const assetLogFilterByKeySelector = (key: AssetLogFilterKey) =>
  createSelector(
    assetLogFiltersSelector,
    (assetLogFilters) => assetLogFilters[key],
  );

export const userFilterByKeySelector = (key: UserFilterKey) =>
  createSelector(userFiltersSelector, (userFilters) => userFilters[key]);

export const vesselFilterByKeySelector = (key: VesselFilterKey) =>
  createSelector(vesselFiltersSelector, (vesselFilters) => vesselFilters[key]);

export const sessionFiltersByKeysSelector = (keys: SessionFilterKey[]) =>
  createSelector(sessionFiltersSelector, (sessionFilters) =>
    pick(sessionFilters, keys),
  );

export const userFiltersByKeysSelector = (keys: UserFilterKey[]) =>
  createSelector(userFiltersSelector, (userFilters) => pick(userFilters, keys));

export const boxFiltersByKeysSelector = (keys: AssetFilterKey[]) =>
  createSelector(AssetFiltersSelector, (boxFilters) => pick(boxFilters, keys));

export const assetLogFiltersByKeysSelector = (keys: AssetLogFilterKey[]) => {
  return createSelector(assetLogFiltersSelector, (assetLogFilters) => pick(assetLogFilters, keys));
};

export const vesselFiltersByKeysSelector = (keys: VesselFilterKey[]) =>
  createSelector(vesselFiltersSelector, (vesselFilters) =>
    pick(vesselFilters, keys),
  );
