import { BoxStatus } from '../../types/box.types';
import { ITagStatus } from '../../types/tag.types';

export const getBoxStatusForSymbolAlarmStatus = (
  status: ITagStatus | undefined,
) => {
  if (status?.value === '0' || status?.value === '1') {
    return BoxStatus.OK;
  }
  if (status?.value === '2') {
    return BoxStatus.WARNING;
  }
  if (status?.value === '3') {
    return BoxStatus.ALARM;
  }
  if (status?.value === '4') {
    return BoxStatus.MAINTENANCE;
  }
  return BoxStatus.UNKNOWN;
};
