import { RollbackOutlined } from '@ant-design/icons';
import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { StyledButton } from '../../styled/buttons/StyledButton';
import { FlexRow } from '../../styled/flex/FlexRow';
import { StyledHeading, Variants } from '../../styled/headings/StyledHeading';
import { theme } from '../../theme';

const MESSAGES = {
  goBackButton: {
    id: 'heading_with_back_button.button',

    defaultMessage: 'Back',
  },
};

interface IProps {
  heading: string | undefined;

  variant: Variants;
}

export const HeadingWithBackButton = ({ heading, variant }: IProps) => {
  const intl = useIntl();

  const navigate = useNavigate();

  return (
    <FlexRow alignItems="center" mb={theme.spacing.large}>
      <StyledHeading mb={0} variant={variant}>
        {heading}
      </StyledHeading>

      <StyledButton
        onClick={() => navigate(-1)}
        variant="primary"
        data-testid="btn-back"
      >
        <RollbackOutlined />

        {intl.formatMessage(MESSAGES.goBackButton)}
      </StyledButton>
    </FlexRow>
  );
};
