import { Form, Select } from 'antd';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { vesselFilterByKeySelector } from '../../../store/selectors/filters.selectors';
import { setVesselFilter } from '../../../store/slices/filters.slice';
import { updateRequestedTablePage } from '../../../store/slices/tableMetaInfo.slice';
import { PrimaryWrapperBox } from '../../../styled/boxes/PrimaryWrapperBox';
import { StyledFormItem } from '../../../styled/form/StyledFormItem';
import { StyledSearch } from '../../../styled/form/StyledSearch';
import { StyledSelect } from '../../../styled/form/StyledSelect';
import { FilterKey, VesselFilterKey } from '../../../types/filters.types';
import { Table } from '../../../types/table.types';
import { VesselFilter, VesselType } from '../../../types/vessel.types';
import { FilterElements } from '../../../components/filters/FilterElements';

const MESSAGES = defineMessages({
  search: {
    id: 'vessels_table_filter.search',
    defaultMessage: 'Search',
  },
  [VesselFilter.ENI_NUMBER]: {
    id: 'vessels_table_filter.eniNumber',
    defaultMessage: 'E.N.I. Number',
  },
  [VesselFilter.NAME]: {
    id: 'vessels_table_filter.name',
    defaultMessage: 'Vessel Name',
  },
  [VesselFilter.TYPE]: {
    id: 'vessels_table_filter.type',
    defaultMessage: 'Vessel Type',
  },
  vesselTypeDropdownPlaceholder: {
    id: 'vessels_table_filter.vesselTypeDropdownPlaceholder',
    defaultMessage: 'Select a type',
  },
});

export const VesselsTableFilter = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const vesselNameFilterValue = useSelector(
    vesselFilterByKeySelector(VesselFilterKey.name),
  );
  const eniNumberFilterValue = useSelector(
    vesselFilterByKeySelector(VesselFilterKey.eniNumber),
  );

  const handleChangeFilter = (key: FilterKey, value: string | number) => {
    dispatch(
      updateRequestedTablePage({
        key: Table.VESSELS_TABLE,
        value: 0,
      }),
    );
    dispatch(
      setVesselFilter({
        key,
        value,
      }),
    );
  };

  return (
    <PrimaryWrapperBox>
      <Form form={form} layout="inline">
        <FilterElements title={intl.formatMessage(MESSAGES[VesselFilter.NAME])}>
          <StyledSearch
            onChange={(ev) =>
              handleChangeFilter(VesselFilterKey.name, ev.target.value)
            }
            onSearch={(value) =>
              handleChangeFilter(VesselFilterKey.name, value)
            }
            placeholder={intl.formatMessage(MESSAGES.search)}
            value={vesselNameFilterValue}
            data-testid={`search-${VesselFilterKey.name}`}
          />
        </FilterElements>

        <FilterElements
          title={intl.formatMessage(MESSAGES[VesselFilter.ENI_NUMBER])}
        >
          <StyledSearch
            onChange={(ev) =>
              handleChangeFilter(VesselFilterKey.eniNumber, ev.target.value)
            }
            onSearch={(value) =>
              handleChangeFilter(VesselFilterKey.eniNumber, value)
            }
            placeholder={intl.formatMessage(MESSAGES.search)}
            value={eniNumberFilterValue}
            data-testid={`search-${VesselFilterKey.eniNumber}`}
          />
        </FilterElements>

        <FilterElements title={intl.formatMessage(MESSAGES[VesselFilter.TYPE])}>
          <StyledSelect
            showSearch
            allowClear
            minWidth="213px"
            onChange={(value) =>
              handleChangeFilter(VesselFilterKey.type, value as string)
            }
            placeholder={intl.formatMessage(
              MESSAGES.vesselTypeDropdownPlaceholder,
            )}
          >
            {Object.values(VesselType).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </StyledSelect>
        </FilterElements>
      </Form>
    </PrimaryWrapperBox>
  );
};
