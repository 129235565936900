import React from 'react';
import { useSelector } from 'react-redux';

import { mostRecentTagStatusesByRequestIdAssetIdAndTagsSelector } from '../../store/selectors/tags.selectors';
import { BoxTag, ITagStatus } from '../../types/tag.types';
import { AssetAlarmsAndWarnings } from '../assetWarningsAndAlarms/AssetAlarmsAndWarnings';

interface IProps {
  boxId: string;
}

export const BoxesAlarmsAndWarnings = ({ boxId }: IProps) => {
  const boxWarningsAndAlarmsTagStatuses: ITagStatus[] | undefined = useSelector(
    mostRecentTagStatusesByRequestIdAssetIdAndTagsSelector(boxId, boxId, [
      BoxTag.V3_FUSE_AUXILLIARIES,
      BoxTag.V3_UPS,
      BoxTag.V3_CPU_TEMPERATURE,
      BoxTag.V3_EXPANTION_MODULE,
      BoxTag.V3_SERVICE_DOOR,
      BoxTag.V3_HYGROSSTAAT,
      BoxTag.V3_RTU_24_VDC,
      BoxTag.V3_FUSE_EXT24VDC,
      BoxTag.V3_BOX_TEMPERATURE,
      BoxTag.V4_MAINSWITCH,
      BoxTag.V4_VOLTAGE,
      BoxTag.V4_INCOMING_FASES,
      BoxTag.V4_LICHTNING_PROTECTION,
      BoxTag.V4_CPU,
      BoxTag.V4_CHANNEL_STATE,
      BoxTag.V4_FUSE_AUTOMATION,
    ]),
  );

  const boxAlarmsAndWarnings = boxWarningsAndAlarmsTagStatuses?.filter(
    (tagStatus) => tagStatus.value === '2',
  );

  return <AssetAlarmsAndWarnings alarmsAndWarnings={boxAlarmsAndWarnings} />;
};
