import * as React from 'react';
const LightningBolt = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 9 19"
    {...props}
  >
    <path d="M1.26 18.969a.36.36 0 0 1-.341-.474l2.722-8.166H.9a.36.36 0 0 1-.333-.498l3.6-8.64A.36.36 0 0 1 4.5.969h3.42a.36.36 0 0 1 .329.507L5.416 7.809H8.1a.361.361 0 0 1 .301.557l-6.84 10.44a.36.36 0 0 1-.3.163" />
  </svg>
);
export default LightningBolt;
