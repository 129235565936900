import React from 'react';
import { ThunderboltOutlined } from '@ant-design/icons';
import { defineMessages, useIntl } from 'react-intl';
import {
  LeftSideContainer,
  IconContainerScaled,
  LeftSideContainerMargin,
  IconContainer,
} from '../../components/layout/boxes/IconContainer';
import {
  NormalText,
  KeyText,
  StyledSubTextLight,
  LargeText,
} from '../../styled/text/Text';
import { FlexRow } from '../../styled/flex/FlexRow';
import { theme } from '../../theme';
import WaterDrop from '../../icons/WaterDrop';
import { BoxNoConnectionCellularIcon } from '../../components/boxes/icons/connection/BoxNoConnectionCellularIcon';
import { IconSize } from '../../types/icon.types';

const MESSAGES = defineMessages({
  activeSessions: {
    id: 'dashboard.active_session',
    defaultMessage: 'Active sessions',
  },
});

export const DashboardFaults = () => {
  const intl = useIntl();

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall} width='40%'>
          <LeftSideContainerMargin>
            <IconContainerScaled>
              <ThunderboltOutlined />
            </IconContainerScaled>
            <KeyText>Assets</KeyText>
          </LeftSideContainerMargin>
          <LargeText alignment="right">2</LargeText>
        </FlexRow>

        <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall} width='40%'>
            <LeftSideContainerMargin>
              <KeyText>Connectors</KeyText>
            </LeftSideContainerMargin>
            <LargeText alignment="right">2</LargeText>
        </FlexRow>
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall} width='40%'>
          <LeftSideContainerMargin>
            <IconContainerScaled>
              <WaterDrop width="13px" height="15px" />
            </IconContainerScaled>
            <KeyText>Assets</KeyText>
          </LeftSideContainerMargin>
          <LargeText alignment="right">2</LargeText>
        </FlexRow>

        <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall} width='40%'>
          <LeftSideContainerMargin>
            <KeyText>Connectors</KeyText>
          </LeftSideContainerMargin>
          <LargeText alignment="right">2</LargeText>
        </FlexRow>
      </div>

      {/* <div style={{ marginLeft: '-6px', height: '42px', display: 'flex', alignItems: 'center' }}> */}
      <FlexRow justifyContent="space-between" mb={theme.spacing.xSmall}>
          <LeftSideContainerMargin>
            <IconContainer style={{ marginLeft: '-6px', width: '14px', transform: 'scale(1.1)' }}>
              <BoxNoConnectionCellularIcon size={IconSize.SMALL} />
              {/* <WaterDrop width="13px" height="15px" /> */}
            </IconContainer>
            <KeyText>Connectivity</KeyText>
          </LeftSideContainerMargin>
          <LargeText alignment="right">0</LargeText>
        </FlexRow>
      {/* </div> */}
    </>
  );
};
