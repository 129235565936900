import {
  IConnectorCompatibilityStatus,
  IConnectorCompatibilityStatusParams,
  IRailConfiguration,
  RailComponent,
} from '../../types/connector.types';
import { baseApi, RtkTagType } from './base.api';

const url = "/connectors";

const sampleRailConfiguration: IRailConfiguration = {
  components: [
    RailComponent.MOTOR_ACTIVE,
    RailComponent.CIRCUIT_BREAKER_ACTIVE,
    RailComponent.RESIDUAL_CURRENT_DEVICE_ACTIVE,
    RailComponent.KWH_METER,
    RailComponent.RELAY_ACTIVE,
  ],
  connected: true,
};

// mock API
const getRailConfiguration = (connectorId: string): IRailConfiguration => {
  // eslint-disable-next-line no-console
  console.log(`Fetching rail config for ${connectorId}.`);
  return sampleRailConfiguration;
};


export const connectorsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getConnectorCompatibilityStatuses: builder.query<
        IConnectorCompatibilityStatus[],
        IConnectorCompatibilityStatusParams
        >({
      query: (params) => ({
        url: `${url}/compatibility`,
        params,
      }),
      providesTags: [{ type: RtkTagType.CONNECTORS_FOR_NEW_SESSION }],
    }),
  }),
});

export const {
  useGetConnectorCompatibilityStatusesQuery,
} = connectorsApi;

export default getRailConfiguration;
