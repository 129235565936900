import React from 'react';

import { StyledBox } from '../../../../../../styled/boxes/StyledBox';
import { colors } from '../../../../../../theme';
import { IConnectorStatusIconProps } from '../../../../../../types/connector.types';
import { IconSize } from '../../../../../../types/icon.types';
import { getIconSize } from '../../../../../../utils/shared/icons/getIconSize';

export const PilotContactOnlineIcon = ({
  ...space
}: IConnectorStatusIconProps) => (
  <StyledBox {...space}>
    <svg
      width={getIconSize(IconSize.MEDIUM).width}
      height={getIconSize(IconSize.MEDIUM).height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-connector-pilot-contact-online"
    >
      <path
        d="M12.6041 13.788L10.5801 15.812C9.79934 16.5928 8.53294 16.5928 7.75214 15.812L4.18814 12.248C3.40734 11.4672 3.40734 10.2008 4.18814 9.42L6.21214 7.396"
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
      />
      <path
        d="M5.40381 6.59595L13.4038 14.5959"
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M7.3962 6.21202L9.4202 4.18802C10.201 3.40722 11.4674 3.40722 12.2482 4.18802L15.8122 7.75202C16.593 8.53282 16.593 9.79922 15.8122 10.58L13.7882 12.604M5.3482 13.452L1.2002 17.6L5.3482 13.452ZM6.5482 14.652L2.4002 18.8L6.5482 14.652Z"
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
      />
      <path
        d="M14.5962 13.4041L6.59619 5.40405"
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.4521 5.34795L17.6001 1.19995M14.6521 6.54795L18.8001 2.39995L14.6521 6.54795Z"
        stroke={colors.green.secondary}
        strokeMiterlimit="10"
      />
    </svg>
  </StyledBox>
);
