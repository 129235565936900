import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';

import { IconSize } from '../../types/icon.types';
import {
  ISessionEvent,
  SessionTimelineEvent,
  SessionTimelineEventSeverity,
} from '../../types/session.types';
import { AlarmIcon } from '../icons/general/status/AlarmIcon';
import { WarningIcon } from '../icons/general/status/WarningIcon';
import { AuthorisationOKIcon } from './icons/AuthorisationOKIcon';
import { AuthorisationStoppedIcon } from './icons/AuthorisationStoppedIcon';
import { ConnectorPluggedInIcon } from './icons/ConnectorPluggedInIcon';
import { ConnectorPluggedOutIcon } from './icons/ConnectorPluggedOutIcon';
import { RelayActivatedIcon } from './icons/RelayActivatedIcon';
import { RelayDeactivatedIcon } from './icons/RelayDeactivatedIcon';
import { StartButtonIcon } from './icons/StartButtonIcon';
import { StopButtonIcon } from './icons/StopButtonIcon';

interface IProps {
  sessionIsActive?: boolean;
  sessionTimelineEvent?: ISessionEvent;
}

export const SessionTimelineIcon = ({
  sessionIsActive,
  sessionTimelineEvent,
}: IProps) => {
  if (
    sessionTimelineEvent?.event === SessionTimelineEvent.CONNECTOR_PLUGGED_IN
  ) {
    return <ConnectorPluggedInIcon />;
  }
  if (
    sessionTimelineEvent?.event === SessionTimelineEvent.CONNECTOR_PLUGGED_OUT
  ) {
    return <ConnectorPluggedOutIcon />;
  }
  if (sessionTimelineEvent?.event === SessionTimelineEvent.AUTHORISATION_OK) {
    return <AuthorisationOKIcon />;
  }
  if (
    sessionTimelineEvent?.event === SessionTimelineEvent.AUTHORISATION_STOPPED
  ) {
    return <AuthorisationStoppedIcon />;
  }
  if (
    sessionTimelineEvent?.event === SessionTimelineEvent.START_BUTTON_PRESSED ||
    sessionTimelineEvent?.event === SessionTimelineEvent.STARTED_VIA_WEB
  ) {
    return <StartButtonIcon />;
  }
  if (
    sessionTimelineEvent?.event === SessionTimelineEvent.STOP_BUTTON_PRESSED
  ) {
    return <StopButtonIcon />;
  }
  if (sessionTimelineEvent?.event === SessionTimelineEvent.RELAY_ACTIVATED) {
    return <RelayActivatedIcon />;
  }
  if (sessionTimelineEvent?.event === SessionTimelineEvent.RELAY_DEACTIVATED) {
    return <RelayDeactivatedIcon />;
  }
  if (sessionTimelineEvent?.severity === SessionTimelineEventSeverity.ERROR) {
    return <AlarmIcon size={IconSize.LARGE} />;
  }
  if (sessionTimelineEvent?.severity === SessionTimelineEventSeverity.WARNING) {
    return <WarningIcon size={IconSize.LARGE} />;
  }
  if (sessionIsActive) {
    return <LoadingOutlined />;
  }
  return null;
};
