import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  flex,
  flexbox,
  FlexboxProps,
  FlexProps,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from 'styled-system';

import { colors, theme } from '../../theme';

type Variants = 'primary' | 'secondary';

interface IProps {
  variant: Variants;
}

export const StyledLink = styled(Link)<
  | SpaceProps
  | LayoutProps
  | FlexProps
  | FlexboxProps
  | TypographyProps
  | ColorProps
  | BorderProps
  | IProps
>(
  space,
  layout,
  flex,
  flexbox,
  typography,
  color,
  border,
  variant({
    variants: {
      primary: {
        fontFamily: theme.fontFamilies.secondary,
        fontWeight: theme.fontWeights.regular,
        fontSize: theme.fontSizes.large,
        lineHeight: theme.lineHeights.large,
        color: colors.green.primary,
        textDecoration: 'underline',
        ':hover': { color: colors.green.primary },
      },
      secondary: {
        fontFamily: theme.fontFamilies.secondary,
        fontWeight: theme.fontWeights.regular,
        fontSize: theme.fontSizes.small,
        lineHeight: theme.lineHeights.small,
        color: colors.green.primary,
        textDecoration: 'underline',
        ':hover': { color: colors.green.primary },
      },
    },
  }),
);
