import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { MaintenanceTable } from '../Maintenance/MaintenanceTable';

const MESSAGES = defineMessages({
  activeSessions: {
    id: 'dashboard.active_session',
    defaultMessage: 'Active sessions',
  },
});

export const DashboardMaintenanceTasks = () => {
  const intl = useIntl();
  
  return (
    <div>
      <MaintenanceTable/>
    </div>
  );
};
