import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import React from 'react';

type MenuItem = Required<MenuProps>['items'][number];

const items: MenuItem[] = [
  {
    key: 'sub1',
    icon: <MailOutlined />,
    label: 'Navigation One',
    children: [
      {
        key: '1-1',
        label: 'Item 1',
        type: 'group',
        children: [
          { key: '1', label: 'Option 1' },
          { key: '2', label: 'Option 2' },
        ],
      },
      {
        key: '1-2',
        label: 'Item 2',
        type: 'group',
        children: [
          { key: '3', label: 'Option 3' },
          { key: '4', label: 'Option 4' },
        ],
      },
    ],
  },
  {
    key: 'sub2',
    icon: <AppstoreOutlined />,
    label: 'Navigation Two',
    children: [
      { key: '5', label: 'Option 5' },
      { key: '6', label: 'Option 6' },
      {
        key: 'sub3',
        label: 'Submenu',
        children: [
          { key: '7', label: 'Option 7' },
          { key: '8', label: 'Option 8' },
        ],
      },
    ],
  },
  {
    key: 'sub4',
    label: 'Navigation Three',
    icon: <SettingOutlined />,
    children: [
      { key: '9', label: 'Option 9' },
      { key: '10', label: 'Option 10' },
      { key: '11', label: 'Option 11' },
      { key: '12', label: 'Option 12' },
    ],
  },
  {
    key: 'sub5',
    label: 'Navigation Four',
    icon: <SettingOutlined />,
    children: [
      { key: '13', label: 'Option 13' },
      { key: '14', label: 'Option 14' },
      { key: '15', label: 'Option 15' },
      { key: '16', label: 'Option 16' },
    ],
  },
  {
    key: 'sub6',
    label: 'Navigation Five',
    icon: <SettingOutlined />,
    children: [
      { key: '17', label: 'Option 17' },
      { key: '18', label: 'Option 18' },
      { key: '19', label: 'Option 19' },
      { key: '20', label: 'Option 20' },
    ],
  },
  {
    key: 'sub7',
    label: 'Navigation Six',
    icon: <SettingOutlined />,
    children: [
      { key: '21', label: 'Option 21' },
      { key: '22', label: 'Option 22' },
      { key: '23', label: 'Option 23' },
      { key: '24', label: 'Option 24' },
    ],
  },
];

const MapMenu = () => {
  return <Menu mode="vertical" items={items} />;
};

export default MapMenu;
