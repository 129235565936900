import React, { ReactNode, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePostAssetCommandMutation } from '../../../../store/api/assets.api';
import { getLayoutFlagByKeySelector } from '../../../../store/selectors/layout.selectors';
import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../../../store/selectors/tags.selectors';
import { setLayoutFlag } from '../../../../store/slices/layout.slice';
import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { StyledSwitch } from '../../../../styled/dataEntry/StyledSwitch';
import { FlexBox } from '../../../../styled/flex/FlexBox';
import { theme } from '../../../../theme';
import { LayoutStateKeys } from '../../../../types/layout.types';
import {
  BoxTag,
  ConnectorTag,
  TagActionValue,
} from '../../../../types/tag.types';
import { getTimeoutWaitForUpdateInStream } from '../../../../utils/shared/assets/asset.utils';

interface IProps {
  actionLabel?: string;
  tag: BoxTag | ConnectorTag;
  requestId: string;
  assetId: string;
  childrenIcon?: ReactNode;
  checked?: boolean;
}

export const AssetAction = ({
  actionLabel,
  assetId,
  checked,
  childrenIcon,
  requestId,
  tag,
}: IProps) => {
  const dispatch = useDispatch();
  const [postAssetCommand] = usePostAssetCommandMutation();
  const loadingKey =
    LayoutStateKeys.ASSET_ACTION_TOGGLE_LOADING + assetId + tag;
  const [timeoutInstance, setTimeoutInstance] = useState<
    ReturnType<typeof setTimeout> | undefined
  >();
  const [unconfirmedChange, setUnconfirmedChange] =
    useState<TagActionValue | null>(null);
  const loading = useSelector(getLayoutFlagByKeySelector(loadingKey));
  const tagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      requestId,
      assetId,
      tag,
    ),
  );

  const tagStatusValueIsTrue = tagStatus?.value === TagActionValue.TRUE;

  const dispatchLoading = (value: boolean) =>
    dispatch(
      setLayoutFlag({
        key: loadingKey,
        flagValue: value,
      }),
    );

  useEffect(() => {
    if (unconfirmedChange === tagStatus?.value) {
      dispatchLoading(false);
    }
  }, [unconfirmedChange, tagStatus]);

  useEffect(() => {
    return () => {
      dispatchLoading(false);
      return timeoutInstance && clearTimeout(timeoutInstance);
    };
  }, []);

  const handleClick = () => {
    dispatchLoading(true);
    const change = tagStatusValueIsTrue
      ? TagActionValue.FALSE
      : TagActionValue.TRUE;
    setUnconfirmedChange(change);
    postAssetCommand({
      assetId,
      command: {
        tag,
        input: change,
      },
    });
    setTimeoutInstance(getTimeoutWaitForUpdateInStream(dispatchLoading));
  };

  const getValue = () => {
    if (checked) return checked;

    if (loading) {
      return unconfirmedChange === TagActionValue.TRUE;
    }
    return tagStatusValueIsTrue;
  };

  return (
    <FlexBox my={theme.spacing.small}>
      <StyledSwitch
        checked={getValue()}
        checkedChildren={childrenIcon}
        loading={loading}
        onClick={handleClick}
        unCheckedChildren={childrenIcon}
        data-testid="switch-asset-action"
      />

      {!!actionLabel && (
        <StyledBox ml={theme.spacing.small} fontSize={theme.fontSizes.medium}>
          {actionLabel}
        </StyledBox>
      )}
    </FlexBox>
  );
};
