import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { colors } from '../../../../theme';
import { IConnectorStatusAlarmIconProps } from '../../../../types/connector.types';
import { IconSize } from '../../../../types/icon.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const AlarmIcon = ({ assets, ...space }: IConnectorStatusAlarmIconProps) => (
  <StyledBox {...space} display={assets ? 'flex' : 'block'} alignItems={assets ? 'center' : 'flex-start'}>
    <svg
      width={getIconSize(assets ? IconSize.ALARM_ICON : IconSize.MEDIUM).width}
      height={getIconSize(assets ? IconSize.ALARM_ICON : IconSize.MEDIUM).height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2330_19939)">
        <path
          d="M0.910512 16.7569L9.29331 2.01445C9.60771 1.46165 10.3929 1.46165 10.7073 2.01445L19.0897 16.7565C19.4037 17.3093 19.0109 18.0001 18.3825 18.0001H1.61771C0.989312 18.0001 0.596512 17.3093 0.910512 16.7569Z"
          stroke={colors.red.secondary}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.8 14.8V15.6C10.8 15.8208 10.6216 16 10.4008 16C10.156 16 9.84395 16 9.59955 16C9.37835 16 9.19995 15.8208 9.19995 15.6V14.8C9.19995 14.5792 9.37835 14.4 9.59915 14.4C9.84395 14.4 10.156 14.4 10.4004 14.4C10.6216 14.4 10.8 14.5792 10.8 14.8ZM10.8 7.20005L10.7992 12.8C10.7992 13.0208 10.6208 13.2 10.4 13.2C10.1552 13.2 9.84315 13.2 9.59875 13.2C9.37755 13.2 9.19995 13.0208 9.19995 12.8V7.20005C9.19995 6.97925 9.37835 6.80005 9.59915 6.80005C9.84395 6.80005 10.156 6.80005 10.4004 6.80005C10.6216 6.80005 10.8 6.97925 10.8 7.20005Z"
          fill={colors.red.secondary}
        />
        <path
          d="M14.8 4.3999L16.4 2.3999"
          stroke={colors.red.secondary}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3999 6.39995L18.7999 5.19995"
          stroke={colors.red.secondary}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.2 8.80005H19.6"
          stroke={colors.red.secondary}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.2001 4.3999L3.6001 2.3999"
          stroke={colors.red.secondary}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.59995 6.39995L1.19995 5.19995"
          stroke={colors.red.secondary}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.7999 8.80005H0.399902"
          stroke={colors.red.secondary}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2330_19939">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </StyledBox>
);
