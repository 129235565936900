import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { useGetBoxesForNewSessionQuery } from '../../../../store/api/boxes.api';
import { boxesForNewSessionSelector } from '../../../../store/selectors/box.selectors';
import { StyledSelect } from '../../../../styled/form/StyledSelect';
import { theme } from '../../../../theme';
import { IAssetsForNewSessionParams } from '../../../../types/box.types';

const MESSAGES = defineMessages({
  selectBox: {
    id: 'select_boxes_dropdown.selectBox',
    defaultMessage: 'Select box',
  },
});

const { Option } = Select;

interface IProps {
  boxesForNewSessionParams: IAssetsForNewSessionParams;
  onSelectBox: (boxId: string) => void;
}

export const SelectBoxesDropdown = ({
  boxesForNewSessionParams,
  onSelectBox,
}: IProps) => {
  const intl = useIntl();

  const [selected, setSelected] = useState<string | null>();

  useGetBoxesForNewSessionQuery(boxesForNewSessionParams);

  const boxesForSession = useSelector(
    boxesForNewSessionSelector(boxesForNewSessionParams),
  );

  useEffect(() => {
    setSelected(null);
    if (boxesForSession?.length === 1) {
      onSelectBox(boxesForSession[0].id);
      setSelected(boxesForSession[0].id);
    }
  }, [boxesForSession]);

  const handleSelectBox = (boxId: string) => {
    onSelectBox(boxId as string);
    setSelected(boxId as string);
  };

  return (
    <StyledSelect
      filterOption={(input, option) =>
        (option?.children.toLowerCase() as unknown as string).includes(
          input.toLowerCase(),
        )
      }
      onSelect={(boxId: unknown) => handleSelectBox(boxId as string)}
      value={selected}
      showSearch
      placeholder={intl.formatMessage(MESSAGES.selectBox)}
      mb={theme.spacing.small}
      data-testid="select-box"
    >
      {boxesForSession?.map((box) => (
        <Option key={box.id}>{box.label}</Option>
      ))}
    </StyledSelect>
  );
};
