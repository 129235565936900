import React from 'react';

import { StyledBox } from '../../../../styled/boxes/StyledBox';
import { colors } from '../../../../theme';
import { IAssetStatusIconProps } from '../../../../types/box.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const OutOfServiceIcon = ({ size, ...space }: IAssetStatusIconProps) => (
  <StyledBox {...space} height={getIconSize(size).height}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-out-of-service"
    >
      <path
        d="M10.7 20.3L20.3 10.7"
        stroke={colors.lightGrey.secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M10.7 10.7L20.3 20.3"
        stroke={colors.lightGrey.secondary}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M15.5 2.29999C8.21005 2.29999 2.30005 8.20999 2.30005 15.5C2.30005 22.79 8.21005 28.7 15.5 28.7C22.79 28.7 28.7 22.79 28.7 15.5C28.7 8.20999 22.79 2.29999 15.5 2.29999Z"
        stroke={colors.lightGrey.secondary}
        strokeMiterlimit="10"
      />
    </svg>
  </StyledBox>
);
