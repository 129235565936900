import React from 'react';

import { StyledBox } from '../../../../../../styled/boxes/StyledBox';
import { colors } from '../../../../../../theme';
import { IConnectorStatusIconProps } from '../../../../../../types/connector.types';
import { IconSize } from '../../../../../../types/icon.types';
import { getIconSize } from '../../../../../../utils/shared/icons/getIconSize';

export const StartConditionsFailedIcon = ({
  ...space
}: IConnectorStatusIconProps) => (
  <StyledBox {...space}>
    <svg
      width={getIconSize(IconSize.MEDIUM).width}
      height={getIconSize(IconSize.MEDIUM).height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-connector-failed-start-conditions"
    >
      <g clipPath="url(#clip0_2330_38724)">
        <path
          d="M12.8 1.59998H16.4C16.842 1.59998 17.2 1.95798 17.2 2.39998V18C17.2 18.442 16.842 18.8 16.4 18.8H3.60005C3.15805 18.8 2.80005 18.442 2.80005 18V2.39998C2.80005 1.95798 3.15805 1.59998 3.60005 1.59998H7.20005"
          stroke={colors.grey.secondary}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.79995 1.60002C8.79995 0.937224 9.33715 0.400024 9.99995 0.400024C10.6628 0.400024 11.2 0.937224 11.2 1.60002H12.8V3.20002H7.19995V1.60002H8.79995Z"
          stroke={colors.grey.secondary}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.19995 13.6L12.8 8"
          stroke={colors.grey.secondary}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.8 13.6L7.19995 8"
          stroke={colors.grey.secondary}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2330_38724">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </StyledBox>
);
