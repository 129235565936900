import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { BoxConsumptionIcon } from '../../../../components/boxes/BoxConsumptionIcon';
import { BoxEthernetIconsList } from '../../../../components/boxes/BoxEthernetIconsList';
import { BoxHygrostaatIcon } from '../../../../components/boxes/BoxHygrostaatIcon';
import { BoxStatusIconsList } from '../../../../components/boxes/BoxStatusIconsList';
import { BoxTemperatureIconsList } from '../../../../components/boxes/BoxTemperatureIconsList';
import { BoxLocationIconsList } from '../../../../components/boxes/icons/location/BoxLocationIconsList';
import { LocationIcon } from '../../../../components/boxes/icons/location/LocationIcon';
import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../../../store/selectors/tags.selectors';
import { FlexRow } from '../../../../styled/flex/FlexRow';
import { StyledHeading } from '../../../../styled/headings/StyledHeading';
import { colors, theme } from '../../../../theme';
import { IAssetInfo } from '../../../../types/box.types';
import { IconSize } from '../../../../types/icon.types';
import { BoxTag } from '../../../../types/tag.types';
import { genericComponentByValue } from '../../../../utils/connectors/genericComponentByValue';
import { WhiteBox } from '../../../../components/layout/boxes/WhiteBox';
import { SessionMetricsBox } from '../../../Sessions/SessionDetail/SessionMetrics/SessionMetricsBox';

const MESSAGES = defineMessages({
  heading: {
    id: 'general_box_info_list.heading',
    defaultMessage: 'Asset metrics',
  },
});

interface IProps {
  boxInfo: IAssetInfo;
}

export const GeneralBoxInfo = ({ boxInfo }: IProps) => {
  const intl = useIntl();

  const setTwoNumbersAfterDecimal = (value: string) => {
    return parseFloat(value).toFixed(2);
  };

  const humidityStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxInfo.id,
      boxInfo.id,
      BoxTag.V7_HUMIDITY,
    ),
  );
  const temperatureStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxInfo.id,
      boxInfo.id,
      BoxTag.V7_TEMPERATURE,
    ),
  );

  return (
    <>
      <StyledHeading variant="h4" m={`${theme.spacing.xLarge} 0`}>
        {intl.formatMessage(MESSAGES.heading)}
      </StyledHeading>
      <WhiteBox width="100%">


        <div>

          <BoxConsumptionIcon boxId={boxInfo.id} /> {/* Shows a SessionMetricsBox */}

          <SessionMetricsBox
            large
            active={false}
            icon={BoxTemperatureIconsList({
              boxId: boxInfo.id,
            })}
            title="Temperature"
            type={2}
          >
            {temperatureStatus ? `${parseFloat(temperatureStatus.value).toFixed(1)} °C` : "-"}
          </SessionMetricsBox>

          <SessionMetricsBox
            large
            active={false}
            icon={BoxHygrostaatIcon({
              boxId: boxInfo.id,
            })}
            title="Humidity"
            type={2}
          >
            {humidityStatus ? `${parseFloat(humidityStatus.value).toFixed(1)} %` : "-"}
          </SessionMetricsBox>

          {boxInfo.location && (
            <SessionMetricsBox
              large
              active={false}
              icon={<LocationIcon size={IconSize.LARGE} />}
              suffix={
                <BoxLocationIconsList
                  latitude={boxInfo.location.latitude}
                  longitude={boxInfo.location.longitude}
                />
              }
              title="Location"
              type={2}
            >
              {setTwoNumbersAfterDecimal(boxInfo.location.latitude)} -{' '}
              {setTwoNumbersAfterDecimal(boxInfo.location.longitude)}
            </SessionMetricsBox>
          )}
        </div>
      </WhiteBox>
    </>
  );
};
