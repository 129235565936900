import styled from '@emotion/styled';
import { Radio } from 'antd';

import { colors } from '../../theme';

export const StyledRadioButton = styled(Radio)({
  '.ant-radio-checked .ant-radio-inner': {
    borderColor: colors.green.primary,
  },

  '.ant-radio-inner::after': {
    backgroundColor: colors.green.primary,
  },
});
