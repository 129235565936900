import { defineMessages } from 'react-intl';

import { ESessionConsumptionUnit } from '../types/session.types';

export const UNIT_MESSAGES = defineMessages({
  [ESessionConsumptionUnit.KWH]: {
    id: 'sessions_info_table.consumption.unit.KWH',
    defaultMessage: 'kWh',
  },
  [ESessionConsumptionUnit.M3]: {
    id: 'sessions_info_table.consumption.unit.M3',
    defaultMessage: 'M3',
  },
});
