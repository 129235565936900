import React from 'react';

import { StyledBoxWithPointer } from '../../../../styled/boxes/StyledBoxWithPointer';
import { IIconProps } from '../../../../types/icon.types';
import { getIconSize } from '../../../../utils/shared/icons/getIconSize';

export const CopyToClipBoardIcon = ({ size, ...space }: IIconProps) => (
  <StyledBoxWithPointer {...space} height={getIconSize(size).height}>
    <svg
      width={getIconSize(size).width}
      height={getIconSize(size).height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="icon-box-clipboard"
    >
      <path
        d="M17 5.5H8.625C8.55625 5.5 8.5 5.55625 8.5 5.625V6.5C8.5 6.56875 8.55625 6.625 8.625 6.625H16.375V17.375C16.375 17.4438 16.4312 17.5 16.5 17.5H17.375C17.4438 17.5 17.5 17.4438 17.5 17.375V6C17.5 5.72344 17.2766 5.5 17 5.5ZM15 7.5H7C6.72344 7.5 6.5 7.72344 6.5 8V16.2922C6.5 16.425 6.55312 16.5516 6.64687 16.6453L9.35469 19.3531C9.38906 19.3875 9.42813 19.4156 9.47031 19.4391V19.4688H9.53594C9.59062 19.4891 9.64844 19.5 9.70781 19.5H15C15.2766 19.5 15.5 19.2766 15.5 19V8C15.5 7.72344 15.2766 7.5 15 7.5ZM9.46875 17.8781L8.12344 16.5312H9.46875V17.8781ZM14.375 18.375H10.4688V16.1562C10.4688 15.8109 10.1891 15.5312 9.84375 15.5312H7.625V8.625H14.375V18.375Z"
        fill="#02A24F"
      />
      <path
        d="M2 1.5H22V-0.5H2V1.5ZM23 2.5V22.5H25V2.5H23ZM22 23.5H2V25.5H22V23.5ZM1 22.5V2.5H-1V22.5H1ZM2 23.5C1.44772 23.5 1 23.0523 1 22.5H-1C-1 24.1569 0.343145 25.5 2 25.5V23.5ZM23 22.5C23 23.0523 22.5523 23.5 22 23.5V25.5C23.6569 25.5 25 24.1569 25 22.5H23ZM22 1.5C22.5523 1.5 23 1.94771 23 2.5H25C25 0.843146 23.6569 -0.5 22 -0.5V1.5ZM2 -0.5C0.343146 -0.5 -1 0.843145 -1 2.5H1C1 1.94772 1.44771 1.5 2 1.5V-0.5Z"
        fill="#02A24F"
      />
    </svg>
  </StyledBoxWithPointer>
);
