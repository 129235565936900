import { notification } from 'antd';

// message should be of type any since thrown errors are of type any as well

interface IProps {
  autoHide: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  message: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  description?: any;
}

export const toasts = {
  success: ({ autoHide, message }: IProps) =>
    notification.success({
      message,
      duration: autoHide ? 3 : 0,
      key: 'success',
    }),
  info: ({ autoHide, message }: IProps) =>
    notification.info({
      message,
      duration: autoHide ? 3 : 0,
      key: 'info',
    }),
  error: ({ autoHide, description, message }: IProps) =>
    notification.error({
      message,
      description,
      duration: autoHide ? 3 : 0,
      key: 'error',
    }),
};
