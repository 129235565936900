import styled from '@emotion/styled';
import { Select } from 'antd';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

import { colors, theme } from '../../theme';

export const StyledSelect = styled(Select, {
  shouldForwardProp: (propName: PropertyKey) => propName !== 'minWidth',
})<LayoutProps | SpaceProps>(
  {
    boxShadow: 'none',

    '.ant-select-selector': {
      borderRadius: `${theme.borderRadius.medium} !important`,
    },

    '&& .ant-select-selector:hover': {
      border: `1px solid ${colors.green.primary}`,
    },

    '.ant-select-in-form-item:hover': {
      border: 'none',
    },

    '&&&& .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector':
      {
        border: `1px solid ${colors.green.primary}`,
      },
  },
  layout,
  space,
);
