import { notification } from 'antd';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  layoutErrorMessageSelector,
  layoutSuccessMessageSelector,
} from '../../store/selectors/layout.selectors';
import {
  setErrorMessage,
  setSuccessMessage,
} from '../../store/slices/layout.slice';

export const TOAST_MESSAGES = defineMessages({
  successUserCreated: {
    id: 'toast_messages.successUserCreated',
    defaultMessage: 'User Successfully created.',
  },
  errorUserCreated: {
    id: 'toast_messages.errorUserCreated',
    defaultMessage: 'Something went wrong. User was not created.',
  },
  successUserUpdated: {
    id: 'toast_messages.successUserUpdated',
    defaultMessage: 'User Successfully updated.',
  },
  errorUserUpdated: {
    id: 'toast_messages.errorUserUpdated',
    defaultMessage: 'Something went wrong. User was not updated.',
  },
  successVesselCreated: {
    id: 'toast_messages.successVesselCreated',
    defaultMessage: 'Vessel Successfully created.',
  },
  errorVesselCreated: {
    id: 'toast_messages.errorVesselCreated',
    defaultMessage: 'Something went wrong. Vessel was not created.',
  },
  successVesselUpdated: {
    id: 'toast_messages.successVesselUpdated',
    defaultMessage: 'Vessel successfully updated.',
  },
  errorVesselUpdated: {
    id: 'toast_messages.errorVesselUpdated',
    defaultMessage: 'Something went wrong. Vessel was not updated.',
  },
  successVesselDeleted: {
    id: 'toast_messages.successVesselDeleted',
    defaultMessage: 'Vessel(s) successfully deleted.',
  },
  errorVesselDeleted: {
    id: 'toast_messages.errorVesselDeleted',
    defaultMessage: 'Something went wrong. Vessel(s) were not deleted.',
  },
  errorSessionCreated: {
    id: 'toast_messages.errorSessionCreated',
    defaultMessage: 'Session could not be started.',
  },
  errorSessionEnded: {
    id: 'toast_messages.errorSessionEnded',
    defaultMessage: 'Session could not be ended.',
  },
  successUserDeleted: {
    id: 'toast_messages.successUserDeleted',
    defaultMessage: 'User succesfully deleted.',
  },
  errorUserDeleted: {
    id: 'toast_messages.errorUserDeleted',
    defaultMessage: 'Something went wrong. User was not deleted.',
  },
  errorSessionExport: {
    id: 'toast_messages.errorSessionExport',
    defaultMessage: 'Sessions could not be exported.',
  },
});

export const NotificationAutoClose = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const successMessage = useSelector(layoutSuccessMessageSelector);
  const errorMessage = useSelector(layoutErrorMessageSelector);

  useEffect(() => {
    if (successMessage) {
      notification.success({
        // @ts-ignore for indexing with string
        message: intl.formatMessage(successMessage),
        duration: 3,
      });
      dispatch(setSuccessMessage(null));
    }
  }, [successMessage]);
  useEffect(() => {
    if (errorMessage && errorMessage.message) {
      notification.error({
        // @ts-ignore for indexing with string
        message: intl.formatMessage(errorMessage.message),
        duration: 3,
        description: errorMessage.description || null,
      });
      dispatch(setErrorMessage(null));
    }
  }, [errorMessage]);
  return <></>;
};
