import { StopOutlined, ToolOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import styled from 'styled-components';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Restart from '../../../../icons/Restart';
import { useTagsChannelQuery } from '../../../../store/api/tags.api';
import { colors, theme } from '../../../../theme';
import {
  BoxStatus,
  BoxTechStatus,
  IAssetInfo,
} from '../../../../types/box.types';
import {
  BoxTag,
  TagActionValue,
  ConnectorTag,
} from '../../../../types/tag.types';
import { usePostAssetCommandMutation } from '../../../../store/api/assets.api';
import { toasts } from '../../../../components/feedback/toasts';
import { AssetAction } from './AssetAction';
import { getBoxTechStatusForTechStatus } from '../../../../utils/boxes/getBoxTechStatusForTechStatus';
import { mostRecentTagStatusByRequestIdAssetIdAndTagSelector } from '../../../../store/selectors/tags.selectors';
import { getBoxStatusForSymbolAlarmStatus } from '../../../../utils/boxes/getBoxStatusForSymbolAlarmStatus';
import { NoConnectionBox } from '../../../../utils/boxes/components/NoConnectionBox';

interface IRestartButtonProps {
  width: string;
}

const MESSAGES = defineMessages({
  heading: {
    id: 'box_actions.heading',
    defaultMessage: 'Actions',
  },
  resetAssetAction: {
    id: 'box_actions.resetAssetAction',
    defaultMessage: 'Reset box',
  },
  lightsAction: {
    id: 'box_actions.lightsAction',
    defaultMessage: 'Lights',
  },
  outOfServiceAction: {
    id: 'box_actions.outOfServiceAction',
    defaultMessage: 'Out of service',
  },
  serviceAction: {
    id: 'box_actions.serviceModeAction',
    defaultMessage: 'Service mode',
  },
  actionSent: {
    id: 'send_action_form.actionSent',
    defaultMessage: 'Action has been sent.',
  },
});

const Row = styled.div<{ boxIsOffline: boolean }>`
  width: 100%;

  opacity: ${(props) => (props.boxIsOffline ? 0.3 : 1)};
  pointer-events: ${(props) => (props.boxIsOffline ? 'none' : 'auto')};
`;

const RestartButton = styled.div<IRestartButtonProps>`
  background-color: ${colors.green.primary};
  border-radius: ${theme.borderRadius.small};
  width: ${(props) => props.width || 'auto'};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.spacing.xxSmall};
  height: fit-content;
`;

const ButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  height: 40px;
`;

const Span = styled.span``;

interface IProps {
  boxId: string;
  boxIsOffline?: boolean;
}

export const BoxActions = ({ boxId, boxIsOffline = false }: IProps) => {
  const intl = useIntl();
  const [postCommand] = usePostAssetCommandMutation();

  useTagsChannelQuery(boxId);

  const handleActionClick = (tagName: ConnectorTag) => {
    postCommand({
      assetId: boxId,
      command: {
        tag: tagName,
        input: TagActionValue.TRUE,
      },
    });
    toasts.success({
      autoHide: true,
      message: intl.formatMessage(MESSAGES.actionSent),
    });
  };

  const techTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V2_TECH_BOX_STATUS,
    ),
  );

  const alarmTagStatus = useSelector(
    mostRecentTagStatusByRequestIdAssetIdAndTagSelector(
      boxId,
      boxId,
      BoxTag.V2_SYMBOL_ALARM,
    ),
  );

  const boxTechStatus = getBoxTechStatusForTechStatus(techTagStatus);
  const boxStatus = getBoxStatusForSymbolAlarmStatus(alarmTagStatus);

  return (
    <>
      {boxIsOffline && <NoConnectionBox center />}
      <Row boxIsOffline={boxIsOffline}>
        <div>
          <ButtonsRow>
            <Span>Restart asset</Span>

            <RestartButton
              onClick={() => handleActionClick(ConnectorTag.V8_MAIN_RESET_BOX)}
              data-testid="btn-reset-box"
              width={theme.input.width.small}
            >
              <Restart fill="none" height="24px" width="24px" />
            </RestartButton>
          </ButtonsRow>

          <ButtonsRow>
            <Span>Service</Span>

            <AssetAction
              requestId={boxId}
              assetId={boxId}
              tag={BoxTag.V8_SET_SERVICE_MODE}
              checked={boxStatus === BoxStatus.MAINTENANCE}
              childrenIcon={<ToolOutlined />}
            />
          </ButtonsRow>

          <ButtonsRow>
            <Span>Out of service</Span>

            <AssetAction
              requestId={boxId}
              assetId={boxId}
              tag={BoxTag.V8_SET_BOX_OUT_OF_SERVICE}
              checked={boxTechStatus === BoxTechStatus.OUT_OF_SERVICE}
              childrenIcon={<StopOutlined />}
            />
          </ButtonsRow>
        </div>
      </Row>
    </>
  );
};
