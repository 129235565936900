/* eslint-disable */
import React from 'react';
import createIcon from '../utils/icons/createIcon';

export default createIcon(
  <>
    <mask
      id="mask0_5704_5169"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_5704_5169)">
      <path
        d="M4.325 18.475L2.675 12.5C2.625 12.3167 2.63767 12.1083 2.713 11.875C2.78767 11.6417 2.95 11.4917 3.2 11.425L4.5 10.975V6.50001C4.5 6.00001 4.675 5.57501 5.025 5.22501C5.375 4.87501 5.8 4.70001 6.3 4.70001H9.5V1.70001H14.5V4.70001H17.7C18.2 4.70001 18.625 4.87501 18.975 5.22501C19.325 5.57501 19.5 6.00001 19.5 6.50001V10.975L20.8 11.425C21.0833 11.525 21.2627 11.6833 21.338 11.9C21.4127 12.1167 21.425 12.3167 21.375 12.5L19.675 18.475C18.9583 18.375 18.304 18.1373 17.712 17.762C17.1207 17.3873 16.55 16.8833 16 16.25C15.4667 16.8667 14.871 17.3957 14.213 17.837C13.5543 18.279 12.8167 18.5 12 18.5C11.1833 18.5 10.446 18.279 9.788 17.837C9.12933 17.3957 8.53333 16.8667 8 16.25C7.45 16.8833 6.87933 17.3873 6.288 17.762C5.696 18.1373 5.04167 18.375 4.325 18.475ZM2.25 22.5V21H4C4.7 21 5.39167 20.8917 6.075 20.675C6.75833 20.4583 7.4 20.1333 8 19.7C8.6 20.1333 9.24167 20.45 9.925 20.65C10.6083 20.85 11.3 20.95 12 20.95C12.7 20.95 13.3917 20.85 14.075 20.65C14.7583 20.45 15.4 20.1333 16 19.7C16.6 20.1333 17.2417 20.4583 17.925 20.675C18.6083 20.8917 19.3 21 20 21H21.75V22.5H20C19.3 22.5 18.6167 22.4167 17.95 22.25C17.2833 22.0833 16.6333 21.8333 16 21.5C15.3667 21.8333 14.7127 22.0833 14.038 22.25C13.3627 22.4167 12.6833 22.5 12 22.5C11.3167 22.5 10.6377 22.4167 9.963 22.25C9.28767 22.0833 8.63333 21.8333 8 21.5C7.36667 21.8333 6.71667 22.0833 6.05 22.25C5.38333 22.4167 4.7 22.5 4 22.5H2.25ZM6 10.475L12 8.52501L18 10.475V6.50001C18 6.41668 17.971 6.34568 17.913 6.28701C17.8543 6.22901 17.7833 6.20001 17.7 6.20001H6.3C6.21667 6.20001 6.146 6.22901 6.088 6.28701C6.02933 6.34568 6 6.41668 6 6.50001V10.475ZM12 17C12.7833 17 13.4417 16.725 13.975 16.175C14.5083 15.625 15.1833 14.8833 16 13.95C16.8 14.85 17.3417 15.4623 17.625 15.787C17.9083 16.1123 18.25 16.3917 18.65 16.625L19.75 12.65L12 10.1L4.225 12.65L5.35 16.6C5.75 16.3667 6.09167 16.1 6.375 15.8C6.65833 15.5 7.2 14.8833 8 13.95C8.83333 14.9 9.51267 15.646 10.038 16.188C10.5627 16.7293 11.2167 17 12 17Z"
        fill="#1C1B1F"
      />
    </g>
  </>,
  '0 0 24 24',
  'VesselIcon',
);
