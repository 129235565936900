import { createSelector } from '@reduxjs/toolkit';

import { IUserFilters } from '../../types/filters.types';
import { ICurrentUser, IUser, UserPermission } from '../../types/user.types';
import { checkIfUserHasPermission } from '../../utils/users/checkIfUserHasPermission';
import { filterUsers } from '../../utils/users/filterUsers';
import { usersApi } from '../api/users.api';
import type { RootState } from '../store';

export const userClientState = (state: RootState) => state.user;

export const usersRtkState = usersApi.endpoints.getUsers.select();
export const userRtkState = usersApi.endpoints.getCurrentUser.select();
export const userProfileRtkState = usersApi.endpoints.getUserProfile.select();

export const usersSelector = createSelector(
  usersRtkState,
  (state) => state.data,
);

export const userByIdSelector = (id: string | undefined) =>
  createSelector(usersRtkState, (state) =>
    state.data?.find((user) => user.id === id),
  );

export const currentUserSelector = createSelector(
  userRtkState,
  (state): ICurrentUser | undefined => state.data,
);

export const userTokenSelector = createSelector(
  userClientState,
  (state) => state.token,
);

export const hasCurrentUserPermissionSelector = (permission: UserPermission) =>
  createSelector(currentUserSelector, (currentUser) => {
    return checkIfUserHasPermission(currentUser, permission);
  });

export const currentUserRoleSelector = () =>
  createSelector(currentUserSelector, (currentUser) => {
    return currentUser?.role;
  });

export const hasCurrentUserNoPermissionsSelector = createSelector(
  currentUserSelector,
  (currentUser) => {
    return currentUser?.permissions.length === 0;
  },
);

export const getCurrentUserPermissonsSelector = () =>
  createSelector(currentUserSelector, (currentUser) => {
    return currentUser?.permissions;
  });

export const getCurrentUserResponseCodeSelector = createSelector(
  userClientState,
  (state) => state.getCurrentUserResponseCode,
);

export const userIsLoggedInButNotRegisteredSelector = createSelector(
  getCurrentUserResponseCodeSelector,
  (code) => code === 404,
);

export const filteredUsersSelector = (requestedUserFilters?: IUserFilters) =>
  createSelector(
    usersSelector,
    (users): IUser[] | undefined =>
      users && requestedUserFilters && filterUsers(users, requestedUserFilters),
  );

export const userProfileSelector = createSelector(
  userProfileRtkState,
  (state) => state.data,
);
