import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { FlexCentered } from '../../styled/flex/FlexCentered';
import { StyledHeading } from '../../styled/headings/StyledHeading';

const MESSAGES = defineMessages({
  message: {
    id: 'employee_register.message',
    defaultMessage:
      'Something went wrong, as an employee, your employer first needs to register you in his employer portal.',
  },
});

export const EmployeeRegisterMessage = () => {
  const intl = useIntl();

  return (
    <FlexCentered>
      <StyledHeading variant="h5">
        {intl.formatMessage(MESSAGES.message)}
      </StyledHeading>
    </FlexCentered>
  );
};
