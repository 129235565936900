export const formatNumber = (value: number | string | undefined): string => {
  if (value === 0) {
    return '0';
  }

  const number = typeof value === 'string' ? parseFloat(value) : value;

  if (!number) return '';

  const formatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 3,
    maximumFractionDigits: 3,
  });

  return formatter.format(number);
};
