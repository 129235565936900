import styled from '@emotion/styled';
import React from 'react';

import { FlexBox } from '../../styled/flex/FlexBox';
import { theme } from '../../theme';
import { Logo, LogoProps } from './Logo';

interface IProps extends LogoProps {
  showLabel: boolean;
}

const StyledLabel = styled.h3({
  fontSize: theme.fontSizes.xLarge,
  margin: 0,
  marginLeft: '15px',
});

export const LogoWithLabel = ({ onClick, showLabel }: IProps) => (
  <FlexBox
    display="flex"
    justifyContent={showLabel ? 'flex-start' : 'center'}
    alignItems="center"
    paddingLeft={showLabel ? theme.spacing.medium : 0}
    height="80px"
  >
    <Logo onClick={onClick} />
    {showLabel && <StyledLabel>Techmetrys</StyledLabel>}
  </FlexBox>
);
