/* eslint-disable react/prop-types */
import { Pagination } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../../theme';
import { BoxMobile } from './BoxMobile';
import { SaveBoxToRedux } from '../SaveBoxToRedux';

const UnorderedList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-bottom: ${theme.spacing.small};
`;

export const BoxesMobile = ({ boxes, handleOnPageChange }: any) => {
  return (
    boxes && (
      <>
        <UnorderedList>
          {boxes?.content.map((box: any) => {
            return (
              <React.Fragment key={box.id}>
                <SaveBoxToRedux boxId={box.id} />
                <BoxMobile box={box} />
              </React.Fragment>
            );
          })}
        </UnorderedList>
        <Pagination
          current={(boxes.number || 0) + 1}
          pageSize={boxes.size}
          onChange={handleOnPageChange}
          total={boxes.totalElements}
        />
      </>
    )
  );
};
