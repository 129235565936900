import React, { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import logo from '../../assets/logo.svg';
import { appUrls } from '../../config/url.constants';
import { FlexCentered } from '../../styled/flex/FlexCentered';
import { FlexCol } from '../../styled/flex/FlexCol';
import { FlexRow } from '../../styled/flex/FlexRow';
import { colors, theme } from '../../theme';
import { LogoWithTitle } from '../brand/LogoWithTitle';

interface IProps {
  title: string;
  children: ReactNode;
}

interface TitleSpanProps {
  color?: string;
  fontSize?: number;
}

const TitleSpan = styled.span<TitleSpanProps>`
  color: ${props => props.color || 'black'};
  font-size: ${props => props.fontSize}px;
`

export const LoginContainer = ({ children, title }: IProps) => {
  const [isMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  return (
    <FlexRow height="100vh" style={{ cursor: 'pointer' }}>
      {!isMobile && <FlexCentered
        width="50%"
        height="100%"
        backgroundColor={colors.green.primary}
        isLogin
      >
        <LogoWithTitle
          title="Techmetrys 2.0"
          onClick={() => navigate(appUrls.login.base)}
          isMobile={isMobile}
        />
      </FlexCentered>}
      <FlexCentered isLogin width={isMobile ? "100%" : "50%"} height="100%">
        <FlexCol alignItems="center">
          <img src={logo} alt="icon" width="60" height="60" />
          <TitleSpan
            color={colors.green.primary}
            fontSize={isMobile ? theme.fontSizes.xxLarge : theme.fontSizes.title}
          >
            {title}
          </TitleSpan>
          {children}
        </FlexCol>
      </FlexCentered>
    </FlexRow>
  );
};
