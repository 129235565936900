import React from 'react';

import { ActiveEnergyConsumptionIcon } from './icons/connectorsTable/electricity/ActiveEnergyConsumptionIcon';
import { NoPowerIcon } from './icons/connectorsTable/electricity/NoPowerIcon';
import { ActiveWaterConsumptionIcon } from './icons/connectorsTable/water/ActiveWaterConsumptionIcon';
import { NoWaterIcon } from './icons/connectorsTable/water/NoWaterIcon';

interface IProps {
  active?: boolean;
  connectorSymbolStartValue?: string;
  connectorWebStatusValue?: string;
}

export const ConnectorStatusComponent = ({
  active,
  connectorSymbolStartValue,
  connectorWebStatusValue,
}: IProps) => {
  switch (connectorSymbolStartValue) {
    case '0':
      return null;
    case '1':
      return (
        <NoPowerIcon
          active={active}
          connectorWebStatusValue={connectorWebStatusValue}
        />
      );
    case '2':
      return (
        <ActiveEnergyConsumptionIcon
          active={active}
          connectorWebStatusValue={connectorWebStatusValue}
        />
      );
    case '3':
      return (
        <NoWaterIcon
          active={active}
          connectorWebStatusValue={connectorWebStatusValue}
        />
      );
    case '4':
      return (
        <ActiveWaterConsumptionIcon
          active={active}
          connectorWebStatusValue={connectorWebStatusValue}
        />
      );
    default:
      return null;
  }
};
