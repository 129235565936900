/* eslint-disable */
import React from 'react';
import createIcon from '../utils/icons/createIcon';

export default createIcon(
  <>
    <path d="M8 7.70001C7.03333 7.70001 6.20833 7.35401 5.525 6.66201C4.84167 5.97068 4.5 5.15001 4.5 4.20001C4.5 3.23335 4.84167 2.40835 5.525 1.72501C6.20833 1.04168 7.03333 0.700012 8 0.700012C8.96667 0.700012 9.79167 1.04168 10.475 1.72501C11.1583 2.40835 11.5 3.23335 11.5 4.20001C11.5 5.15001 11.1583 5.97068 10.475 6.66201C9.79167 7.35401 8.96667 7.70001 8 7.70001ZM0.5 15.3V13.075C0.5 12.5917 0.633333 12.1417 0.9 11.725C1.16667 11.3083 1.525 10.9833 1.975 10.75C2.95833 10.2667 3.95433 9.90401 4.963 9.66201C5.971 9.42068 6.98333 9.30001 8 9.30001C9.01667 9.30001 10.0293 9.42068 11.038 9.66201C12.046 9.90401 13.0417 10.2667 14.025 10.75C14.475 10.9833 14.8333 11.3083 15.1 11.725C15.3667 12.1417 15.5 12.5917 15.5 13.075V15.3H0.5ZM2 13.8H14V13.075C14 12.875 13.9417 12.6917 13.825 12.525C13.7083 12.3583 13.55 12.2167 13.35 12.1C12.4833 11.6833 11.6043 11.3623 10.713 11.137C9.821 10.9123 8.91667 10.8 8 10.8C7.08333 10.8 6.179 10.9123 5.287 11.137C4.39567 11.3623 3.51667 11.6833 2.65 12.1C2.45 12.2167 2.29167 12.3583 2.175 12.525C2.05833 12.6917 2 12.875 2 13.075V13.8ZM8 6.20001C8.55 6.20001 9.021 6.00401 9.413 5.61201C9.80433 5.22068 10 4.75001 10 4.20001C10 3.65001 9.80433 3.17901 9.413 2.78701C9.021 2.39568 8.55 2.20001 8 2.20001C7.45 2.20001 6.97933 2.39568 6.588 2.78701C6.196 3.17901 6 3.65001 6 4.20001C6 4.75001 6.196 5.22068 6.588 5.61201C6.97933 6.00401 7.45 6.20001 8 6.20001Z" fill="#1C1B1F" />
  </>,
  '0 0 15 16',
  'UserIcon',
);
