import { StopOutlined } from '@ant-design/icons';
import { Form, Input, Select } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { AppLayout } from '../../../AppLayout';
import { FormHeadingWithBackArrow } from '../../../components/headings/FormHeadingWithBackArrow';
import { appUrls } from '../../../config/url.constants';
import {
  VESSEL_FREQUENCY_MESSAGES,
  VESSEL_POWER_TYPE_MESSAGES,
  VESSEL_TYPE_MESSAGES,
} from '../../../consts/vessel.consts';
import {
  useDeleteVesselMutation,
  useGetVesselsQuery,
  usePostVesselMutation,
  usePutVesselMutation,
} from '../../../store/api/vessels.api';
import { hasCurrentUserPermissionSelector } from '../../../store/selectors/user.selectors';
import { vesselByIdSelector } from '../../../store/selectors/vessel.selectors';
import { StyledBox } from '../../../styled/boxes/StyledBox';
import { StyledButton } from '../../../styled/buttons/StyledButton';
import { FlexCol } from '../../../styled/flex/FlexCol';
import { FlexRow } from '../../../styled/flex/FlexRow';
import { StyledForm } from '../../../styled/form/StyledForm';
import { StyledInputGroupHeading } from '../../../styled/form/StyledInputGroupHeading';
import { StyledSelect } from '../../../styled/form/StyledSelect';
import { theme } from '../../../theme';
import { UserPermission } from '../../../types/user.types';
import {
  IVessel,
  PowerPhaseAndAmp,
  VesselFormValues,
  VesselFrequency,
  VesselType,
} from '../../../types/vessel.types';
import { getNumberRule } from '../../../utils/form/getNumberRule';
import { getRequiredRule } from '../../../utils/form/getRequiredRule';
import { LinkedUsers } from '../../../components/vessels/utils/LinkedUsers';
import { useGetUsersQuery } from '../../../store/api/users.api';

const MESSAGES = defineMessages({
  cancel: {
    id: 'vessel_form.cancel',
    defaultMessage: 'Cancel',
  },
  confirm: {
    id: 'vessel_form.confirm',
    defaultMessage: 'Confirm',
  },
  deleteVessel: {
    id: 'vessel_form.deleteVessel',
    defaultMessage: 'Delete vessel',
  },
  enterVesselName: {
    id: 'vessel_form.enterVesselName',
    defaultMessage: 'Enter vessel name...',
  },
  vesselLinkedUsers: {
    id: 'vessel_form.vesselLinkedUsers',
    defaultMessage: 'Linked users',
  },
  [VesselFormValues.ENI_NUMBER]: {
    id: 'vessel_form.eniNumber',
    defaultMessage: 'E.N.I number',
  },
  enterEniNumber: {
    id: 'vessel_form.enterEniNumber',
    defaultMessage: 'For example 47940596 (8 numbers)',
  },
  newVessel: {
    id: 'vessels_form.newVessel',
    defaultMessage: 'New vessel',
  },
  [VesselFormValues.FREQUENCY]: {
    id: 'vessel_form.vesselFrequency',
    defaultMessage: 'Frequency',
  },
  [VesselFormValues.TYPE]: {
    id: 'vessel_form.vesselType',
    defaultMessage: 'Vessel type',
  },
  [VesselFormValues.POWER_TYPE]: {
    id: 'vessel_form.vesselPowerType',
    defaultMessage: 'Power type',
  },
  vesselInfo: {
    id: 'vessels_form.vesselInfo',
    defaultMessage: 'Vessel info',
  },
  [VesselFormValues.NAME]: {
    id: 'vessels_form.vesselName',
    defaultMessage: 'Vessel name',
  },
  [VesselFormValues.ID]: {
    id: 'vessels_form.vesselID',
    defaultMessage: 'Id',
  },
});

export const VesselForm = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [form] = useForm();
  const params = useParams();
  useGetUsersQuery(); // To retrieve users for selecting users linked to vessel

  useGetVesselsQuery(undefined);
  const [postVessel, { isSuccess: postSuccess }] = usePostVesselMutation();
  const [putVessel, { isSuccess: updateSuccess }] = usePutVesselMutation();
  const [deleteVessel, { isSuccess: deleteSuccess }] =
    useDeleteVesselMutation();

  const canManageVessels = useSelector(
    hasCurrentUserPermissionSelector(UserPermission.MANAGE_VESSELS),
  );
  const vesselToBeEdited = useSelector(vesselByIdSelector(params.vesselId));

  useEffect(() => {
    if (postSuccess || updateSuccess || deleteSuccess) {
      navigate(appUrls.vessels.base);
    }
  }, [postSuccess, updateSuccess, deleteSuccess]);

  const handleFormSubmit = () => {
    form.validateFields().then((values: IVessel) => {
      if (vesselToBeEdited) {
        putVessel(values);
      } else {
        postVessel(values);
      }
    });
  };

  useEffect(() => {
    if (vesselToBeEdited) {
      form.setFieldsValue({
        [VesselFormValues.ID]: vesselToBeEdited.id,
        [VesselFormValues.NAME]: vesselToBeEdited.name,
        [VesselFormValues.ENI_NUMBER]: vesselToBeEdited.eniNumber,
        [VesselFormValues.TYPE]: vesselToBeEdited.type,
        [VesselFormValues.FREQUENCY]: vesselToBeEdited.powerFrequency,
        [VesselFormValues.POWER_TYPE]: vesselToBeEdited.powerPhaseAndAmp,
        [VesselFormValues.USER_IDS]: vesselToBeEdited.userIds,
      });
    }
  }, [vesselToBeEdited]);

  const handleCancel = () => navigate(appUrls.vessels.base);

  return (
    <FlexCol width="100%" flexDirection="column" alignItems="center">
      <FlexRow
        alignItems="center"
        width="100%"
        maxWidth={theme.maxWidth}
        justifyContent="space-between"
      >
        <FormHeadingWithBackArrow
          heading={
            !vesselToBeEdited
              ? intl.formatMessage(MESSAGES.newVessel)
              : `${vesselToBeEdited.name} - ENI ${vesselToBeEdited.eniNumber}`
          }
        />
        {vesselToBeEdited && (
          <StyledButton
            variant="danger"
            onClick={() => deleteVessel({ vesselIds: [vesselToBeEdited.id] })}
          >
            <StopOutlined />
            {intl.formatMessage(MESSAGES.deleteVessel)}
          </StyledButton>
        )}
      </FlexRow>
      <StyledForm
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        autoComplete="off"
        width="100%"
        maxWidth={theme.maxWidth}
      >
        <StyledInputGroupHeading mb={theme.spacing.xLarge}>
          {intl.formatMessage(MESSAGES.vesselInfo)}
        </StyledInputGroupHeading>

        <Form.Item
          hidden
          name={VesselFormValues.ID}
          label={intl.formatMessage(MESSAGES[VesselFormValues.NAME])}
        >
          <Input hidden data-testid="input-vessel-id" maxLength={36} />
        </Form.Item>
        <Form.Item
          name={VesselFormValues.NAME}
          validateTrigger="onBlur"
          rules={[
            getRequiredRule(
              intl.formatMessage(MESSAGES[VesselFormValues.NAME]),
              intl,
            ),
          ]}
          label={intl.formatMessage(MESSAGES[VesselFormValues.NAME])}
        >
          <Input data-testid="input-vessel-name" maxLength={255} />
        </Form.Item>
        <Form.Item
          name={VesselFormValues.ENI_NUMBER}
          validateTrigger="onBlur"
          rules={[
            // getFixedLengthRule(
            //   intl.formatMessage(MESSAGES[VesselFormValues.ENI_NUMBER]),
            //   8,
            //   intl,
            // ),
            getRequiredRule(
              intl.formatMessage(MESSAGES[VesselFormValues.ENI_NUMBER]),
              intl,
            ),
            getNumberRule(
              intl.formatMessage(MESSAGES[VesselFormValues.ENI_NUMBER]),
              intl,
            ),
          ]}
          label={intl.formatMessage(MESSAGES[VesselFormValues.ENI_NUMBER])}
        >
          <Input
            placeholder={intl.formatMessage(MESSAGES.enterEniNumber)}
            data-testid="input-vessel-eni"
          />
        </Form.Item>
        <Form.Item
          name={VesselFormValues.TYPE}
          validateTrigger="onBlur"
          rules={[
            getRequiredRule(
              intl.formatMessage(MESSAGES[VesselFormValues.TYPE]),
              intl,
            ),
          ]}
          label={intl.formatMessage(MESSAGES[VesselFormValues.TYPE])}
        >
          <StyledSelect width={100} data-testid="select-vessel-type">
            {Object.values(VesselType).map((type) => (
              <Select.Option key={type} value={type}>
                {intl.formatMessage(VESSEL_TYPE_MESSAGES[type])}
              </Select.Option>
            ))}
          </StyledSelect>
        </Form.Item>
        <Form.Item
          name={VesselFormValues.FREQUENCY}
          validateTrigger="onBlur"
          rules={[
            getRequiredRule(
              intl.formatMessage(MESSAGES[VesselFormValues.FREQUENCY]),
              intl,
            ),
          ]}
          label={intl.formatMessage(MESSAGES[VesselFormValues.FREQUENCY])}
        >
          <StyledSelect width={100} data-testid="select-vessel-freq">
            {Object.values(VesselFrequency).map((type) => (
              <Select.Option key={type} value={type}>
                {intl.formatMessage(VESSEL_FREQUENCY_MESSAGES[type])}
              </Select.Option>
            ))}
          </StyledSelect>
        </Form.Item>
        <Form.Item
          name={VesselFormValues.POWER_TYPE}
          validateTrigger="onBlur"
          rules={[
            getRequiredRule(
              intl.formatMessage(MESSAGES[VesselFormValues.POWER_TYPE]),
              intl,
            ),
          ]}
          label={intl.formatMessage(MESSAGES[VesselFormValues.POWER_TYPE])}
        >
          <StyledSelect width={100} data-testid="select-vessel-power">
            {Object.values(PowerPhaseAndAmp).map((type) => (
              <Select.Option key={type} value={type}>
                {intl.formatMessage(VESSEL_POWER_TYPE_MESSAGES[type])}
              </Select.Option>
            ))}
          </StyledSelect>
        </Form.Item>

        <Form.Item
          name={VesselFormValues.USER_IDS}
          validateTrigger="onBlur"
          label={intl.formatMessage(MESSAGES.vesselLinkedUsers)}
        >
          <LinkedUsers />
        </Form.Item>

        <FlexRow>
          <StyledButton
            variant="danger"
            htmlType="button"
            onClick={handleCancel}
            mr={theme.spacing.medium}
            data-testid="btn-vessel-form-cancel"
          >
            {intl.formatMessage(MESSAGES.cancel)}
          </StyledButton>
          <StyledButton
            variant="primary"
            htmlType="submit"
            data-testid="btn-vessel-form-submit"
          >
            {intl.formatMessage(MESSAGES.confirm)}
          </StyledButton>
        </FlexRow>
      </StyledForm>
    </FlexCol>
  );
};
