import { Radio } from 'antd';
import React from 'react';

import { StyledRadioButton } from '../../../../../styled/buttons/StyledRadioButton';
import { IAssetInfo } from '../../../../../types/box.types';

interface IProps {
  boxInfo: IAssetInfo;
  onSelectBox(boxId: string): void;
  onSelectConnector(connectorId: string): void;
}

export const BoxConnectorsList = ({
  boxInfo,
  onSelectBox,
  onSelectConnector,
}: IProps) => {
  const handleSelectBox = (boxId: string) => onSelectBox(boxId);

  const handleSelectConnector = (connectorId: string) =>
    onSelectConnector(connectorId);

  return (
    <Radio.Group name="boxConnectorsList" defaultValue={boxInfo.label}>
      <StyledRadioButton
        key={boxInfo.id}
        onClick={() => handleSelectBox(boxInfo.id)}
        value={boxInfo.label}
        data-testid="radio-box"
      >
        {boxInfo.label}
      </StyledRadioButton>
      {boxInfo.connectors.map((connector) => (
        <StyledRadioButton
          key={connector.id}
          onClick={() => handleSelectConnector(connector.id)}
          value={connector.index}
          data-testid={`radio-connector-${connector.index}`}
        >
          {connector.index}
        </StyledRadioButton>
      ))}
    </Radio.Group>
  );
};
