import { colors } from '../../theme';

interface IResourceIconColors {
  backgroundColor: string;
  iconFill: string;
  iconStroke: string;
  innerCircleStroke: string;
  outerCircleStroke: string;
}

export const getResourceIconColors = (
  selected: boolean,
  isCompatible?: boolean,
  isOccupied?: boolean,
  isHovered?: boolean,
): IResourceIconColors => {
  if (!isCompatible || isOccupied) {
    return {
      backgroundColor: colors.grey.primary,
      iconFill: colors.lightGrey.secondary,
      iconStroke: colors.lightGrey.secondary,
      innerCircleStroke: colors.lightGrey.secondary,
      outerCircleStroke: colors.lightGrey.secondary,
    };
  }
  if (selected || isHovered) {
    return {
      backgroundColor: colors.green.primary,
      iconFill: colors.white.primary,
      iconStroke: colors.white.primary,
      innerCircleStroke: colors.white.primary,
      outerCircleStroke: colors.green.primary,
    };
  }
  return {
    backgroundColor: colors.white.primary,
    iconFill: colors.green.primary,
    iconStroke: colors.green.primary,
    innerCircleStroke: colors.green.primary,
    outerCircleStroke: colors.green.primary,
  };
};
